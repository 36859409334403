import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'components';
import { Container, Title, Card, Options } from 'components/Admin/Shared';
import { FirebaseContext } from 'context';

function AlgoliaSearch() {
  const { firebase } = useContext(FirebaseContext);
  const [importingAllUsers, setImportingAllUsers] = useState(false);
  const [importingAllSpeakers, setImportingAllSpeakers] = useState(false);

  const handleUpdateAllUsersToAlgolia = async () => {
    try {
      setImportingAllUsers(true);
      await firebase.algolia.updateAllUsers();
    } catch (error) {
      console.error(error);
    } finally {
      setImportingAllUsers(false);
    }
  };

  const handleUpdateAllSpeakersToAlgolia = async () => {
    try {
      setImportingAllSpeakers(true);
      await firebase.algolia.updateAllSpeakers();
    } catch (error) {
      console.error(error);
    } finally {
      setImportingAllSpeakers(false);
    }
  };

  return (
    <Container>
      <Title>Algolia Search Engine</Title>
      <Card>
        <Instruction>
          Both indexes are automatically updated in Algolia, if there&apos;s any changes in
          it&apos;s corresponding firestore collection. <i>But if you need</i> to manually update an
          index do so below.
        </Instruction>
        <Options>
          <Button
            onClick={handleUpdateAllUsersToAlgolia}
            color="primary"
            loading={importingAllUsers}
            loadingButton>
            Update All Users
          </Button>
          <Button
            onClick={handleUpdateAllSpeakersToAlgolia}
            color="primary"
            loading={importingAllSpeakers}
            loadingButton>
            Update All Speakers
          </Button>
        </Options>
      </Card>
    </Container>
  );
}

const Instruction = styled.p`
  color: #004d9a;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.125rem;
`;

export default AlgoliaSearch;
