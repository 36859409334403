import * as React from 'react';

function SvgLogoSmall(props) {
  return (
    <svg viewBox="0 0 246 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M84.544 12.217c0 3.561-2.156 7.035-7.084 7.035h-4.487v7.606h-4.4V5.138h8.843c4.972 0 7.128 3.518 7.128 7.08Zm-4.4 0c0-1.583-.792-3.21-2.816-3.21h-4.311v6.376h4.267c2.068 0 2.86-1.627 2.86-3.166ZM104.87 21.625h-8.14l-1.715 5.188H90.35l8.095-21.675h4.708l8.051 21.675h-4.576l-1.759-5.188Zm-1.32-3.781-2.772-8.134-2.728 8.134h5.5ZM136.195 5.138v21.675h-3.96l-7.875-13.585v13.585h-4.4V5.138h4.048l7.743 13.453V5.138h4.444ZM159.512 21.625h-8.139l-1.716 5.188h-4.663l8.095-21.675h4.707l8.052 21.675h-4.62l-1.716-5.188Zm-1.32-3.781-2.771-8.134-2.728 8.134h5.499ZM172.975 15.953c0-6.111 4.883-11.08 11.043-11.08 3.695 0 6.907 1.76 8.843 4.485l-3.344 2.418c-1.232-1.714-3.035-2.814-5.367-2.814-3.96 0-6.82 3.078-6.82 6.947 0 3.87 2.816 7.035 6.776 7.035 2.332 0 4.179-1.056 5.367-2.814l3.344 2.462c-1.936 2.726-5.148 4.485-8.843 4.485-6.116.043-10.999-4.925-10.999-11.124ZM216.531 22.944v3.869h-13.903V5.138h13.595v3.869h-9.24v5.144h8.096v3.957h-8.096v4.836h9.548ZM238.748 21.625h-8.139l-1.716 5.188h-4.663l8.095-21.675h4.707l8.052 21.675h-4.62l-1.716-5.188Zm-1.32-3.781-2.771-8.178-2.728 8.134h5.499v.044ZM52.562 15.216a1.09 1.09 0 0 0-.418-.835c-.47-.34-1.124-.26-1.463.209-7.235 9.658-13.556 14.643-19.328 15.27-6.034.652-10.996-3.655-15.776-7.831-2.194-1.905-4.44-3.863-6.791-5.325-1.28-.809-2.482-1.41-3.683-1.8a11.757 11.757 0 0 0-4.048-.732c-.575 0-1.045.47-1.045 1.044 0 .575.47 1.045 1.045 1.045 4.701 0 8.802 3.575 13.138 7.334 2.507 2.167 5.093 4.411 7.887 6.03 3.24 1.879 6.347 2.636 9.508 2.296 1.515-.156 3.056-.574 4.597-1.252 1.62-.705 3.265-1.67 4.936-2.924 1.724-1.305 3.526-2.923 5.355-4.855 1.906-1.983 3.865-4.385 5.877-7.073a.93.93 0 0 0 .209-.6Z"
        fill="#004D9A"
      />
      <path
        d="M52.562 15.216c0-.235-.079-.47-.262-.679-.365-.444-1.044-.496-1.488-.13-5.015 4.254-9.299 5.95-13.504 5.35-3.735-.548-6.922-2.819-10.317-5.22-3.448-2.453-7-4.985-11.284-5.638-2.35-.365-4.649-.156-7.078.653-2.612.913-5.328 2.48-8.227 4.855a1.038 1.038 0 0 0-.157 1.461 1.04 1.04 0 0 0 1.463.157c5.014-4.046 9.507-5.716 13.712-5.064 3.787.6 7 2.871 10.37 5.273 3.447 2.453 7 4.985 11.23 5.585 2.325.34 4.597.079 7-.783 2.612-.94 5.277-2.584 8.15-5.037a.93.93 0 0 0 .392-.783Z"
        fill="#2D9CDB"
      />
      <path
        d="M52.562 15.216c0-.34-.157-.653-.444-.862-3.448-2.427-6.53-4.15-9.481-5.194-2.664-.966-5.172-1.41-7.6-1.305-2.038.078-4.102.548-6.243 1.41-1.88.783-3.683 1.774-5.433 2.74-5.93 3.289-11.545 6.395-21.914 2.245a1.05 1.05 0 0 0-1.358.574 1.05 1.05 0 0 0 .574 1.357c2.69 1.07 5.172 1.723 7.575 2.01 2.168.235 4.231.157 6.269-.235 3.787-.757 6.895-2.453 9.873-4.124 7.026-3.889 13.111-7.256 26.537 2.245.47.34 1.123.235 1.462-.261a1.24 1.24 0 0 0 .183-.6Z"
        fill="#36D1B7"
      />
      <path
        d="M52.562 15.216c0-.208-.079-.443-.21-.626-3.63-4.803-6.869-8.3-9.95-10.702-2.9-2.245-5.59-3.497-8.254-3.81a11.14 11.14 0 0 0-4.049.26c-1.201.287-2.403.783-3.657 1.462-2.298 1.253-4.44 3.028-6.686 4.881-5.224 4.28-10.578 8.718-18.545 7.517-.574-.104-1.097.287-1.201.862a1.075 1.075 0 0 0 .888 1.2c8.88 1.331 14.862-3.602 20.164-7.96 4.362-3.603 8.123-6.709 12.824-6.135 2.273.262 4.624 1.384 7.21 3.394 2.925 2.297 6.059 5.664 9.585 10.31.34.47.993.548 1.463.209.26-.235.418-.548.418-.862Z"
        fill="#36A9E1"
      />
    </svg>
  );
}
export default SvgLogoSmall;
