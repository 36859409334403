import React, { useState, useContext, useRef } from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { FirebaseContext, LocalContext } from 'context';
import { defaultColors } from 'styles';
import { Button, Dialog } from 'components';
import { scrollToAnchor } from 'utils';

function Downloads({ entries }) {
  const { firebase, user } = useContext(FirebaseContext);
  const { theme, handleDialog, setShowLoginOrSignUpDialog, isMobile } = useContext(LocalContext);
  const [showRequireRegistrationDialog, setShowRequireRegistrationDialog] = useState(false);
  const [downloadingFile, setDownloadingFile] = useState({
    ...entries.reduce((obj, entry) => {
      obj[entry.filename] = false;
      return obj;
    }, {})
  });

  const requireRegistrationRef = useRef(null);

  const handleClick = async (filename) => {
    if (user) {
      try {
        setDownloadingFile((currentValues) => ({
          ...currentValues,
          [filename]: true
        }));
        const docRef = await firebase.downloads.downloadFile(filename);
        if (docRef.exists) {
          const { url } = docRef.data();
          if (url) {
            firebase.downloads.trackDownloads({ uid: user.uid, filename });
            return window.open(url, '_blank') || window.location.replace(url);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setDownloadingFile((currentValues) => ({
          ...currentValues,
          [filename]: false
        }));
      }
    } else {
      setShowRequireRegistrationDialog(true);
    }
  };

  return (
    <Entries>
      {entries.map((entry, index) => (
        <Entry key={index}>
          <Thumbnail image={getImage(entry.thumbnail)} alt={entry.title} />
          <Details colors={defaultColors}>
            <h3>{entry.title}</h3>
            <Button
              onClick={() => handleClick(entry.filename)}
              loading={downloadingFile[entry.fileName]}
              loadingButton>
              Read More
            </Button>
          </Details>
        </Entry>
      ))}
      <Dialog
        ref={requireRegistrationRef}
        isVisible={showRequireRegistrationDialog}
        onDismiss={() =>
          handleDialog({
            dialogRef: requireRegistrationRef,
            animation: 'dismiss',
            stateHandler: () => setShowRequireRegistrationDialog(false)
          })
        }
        contentStyle={{
          maxWidth: '25rem'
        }}>
        <RequireRegistration theme={theme}>
          <H5>
            You must be registered to
            <br />
            download this resource
          </H5>
          <Button
            width="100%"
            onClick={() => {
              handleDialog({
                dialogRef: requireRegistrationRef,
                animation: 'close',
                stateHandler: () => setShowRequireRegistrationDialog(false),
                callback: () => {
                  if (isMobile) {
                    setShowLoginOrSignUpDialog(true);
                  } else {
                    scrollToAnchor('login-or-signup', {
                      duration: 1200,
                      offset: -90
                    });
                  }
                }
              });
            }}>
            Register Now
          </Button>
        </RequireRegistration>
      </Dialog>
    </Entries>
  );
}

const Entries = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto, minmax(auto, 320px));
  justify-content: center;
  margin: 0 auto;

  @media screen and (min-width: 1100px) {
    grid-template-columns: repeat(auto-fit, minmax(auto, 420px));
    justify-content: space-around;
    max-width: 1024px;
    width: 100%;
  }

  @media screen and (min-width: 1444px) {
    justify-content: space-between;
  }
`;

const Entry = styled.div`
  background: #fff;
  box-shadow: 0px 6px 20px rgba(196, 196, 196, 0.4);
  display: flex;
  flex-direction: column;
`;

const Thumbnail = styled(GatsbyImage)`
  flex-shrink: 0;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  justify-content: space-between;
  padding: 2rem 1.25rem;
  h3 {
    color: ${({ colors }) => colors.primary};
    margin-left: 0.5rem;
  }
`;

const RequireRegistration = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.contrast};
  border-radius: 0.625rem;
  color: ${({ theme }) => theme.primary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 2.5rem 1rem;
  width: 100%;
  h5 {
    text-align: center;
  }
  button {
    background: ${({ theme }) => theme.tertiary};
  }
  @media only screen and (min-width: 1440px) {
    padding: 2.5rem 1.25rem;
  }
`;

const H5 = styled.h5`
  color: inherit;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.4em;
  margin-bottom: 0.5em;
  margin-top: 0px;
  @media only screen and (min-width: 1150px) {
    font-size: 1.5rem;
    margin-bottom: 1em;
  }
`;

export default Downloads;
