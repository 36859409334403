import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, ErrorMessage } from 'components';
import { LocalContext } from 'context';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { fadeInAndOutAndAnimateHeightVariants } from 'styles';
import { clearQueryParams } from 'utils';
import { FormContainer, FormLabel, FormInput } from '../FormComponents';

function PasswordLoginDialog({ colors }) {
  const { theme } = useContext(LocalContext);
  const [loggingIn, setLoggingIn] = useState(false);
  const [formValues, setFormValues] = useState({
    action: 'login',
    email: '',
    password: ''
  });
  const [error, setError] = useState({
    code: '',
    message: ''
  });

  useEffect(() => () => setLoggingIn(false), []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((currentState) => ({
      ...currentState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { email } = formValues;

    try {
      setLoggingIn(true);
      const auth = getAuth();
      await signInWithEmailAndPassword(auth, email.toLowerCase(), formValues.password);
      clearQueryParams();
      setLoggingIn(false);
    } catch (_error) {
      setLoggingIn(false);
      switch (_error.code) {
        case 'permission-denied':
          console.error(_error);
          setError({
            code: _error.code,
            message: 'Error logging in. Please contact support'
          });
          break;
        case 'auth/user-not-found':
          console.error(_error.toString());
          setError({
            code: _error.code,
            message: 'There is no user account associated with this email address.<br />'
          });
          break;
        case 'auth/wrong-password':
          setError({
            code: _error.code,
            message: 'Password invalid.<br />Please double check and try again.'
          });
          break;
        default:
          console.error(_error);
          setError({
            code: _error.code,
            message: _error.message
          });
          break;
      }
    }
  };

  return (
    <FullScreenContainer>
      <StyledFormContainer name="Password Login" onSubmit={handleSubmit} colors={colors}>
        <FormLabel>Password Login</FormLabel>
        <FormLabel hide htmlFor="email">
          Email
        </FormLabel>
        <FormInput
          id="email"
          name="email"
          onChange={handleInputChange}
          placeholder="Email"
          type="email"
          value={formValues.email}
          required
          style={{
            marginBottom: '1.5rem'
          }}
        />
        <FormLabel hide htmlFor="email">
          Password
        </FormLabel>
        <FormInput
          id="password"
          name="password"
          onChange={handleInputChange}
          placeholder="Password"
          type="password"
          value={formValues.password}
          required
          style={{
            marginBottom: '1.5rem'
          }}
        />
        <ErrorMessage
          errorMessage={error.message}
          style={{
            color: theme.className === 'contrast' ? theme.primary : theme.contrast,
            marginBottom: '0.575em'
          }}
          variants={fadeInAndOutAndAnimateHeightVariants()}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row'
          }}>
          <Button
            color="tertiary"
            disabled={!formValues.email || !formValues.password}
            type="submit"
            loading={loggingIn}
            loadingButton
            style={{
              marginTop: '1rem'
            }}>
            Login
          </Button>
        </div>
      </StyledFormContainer>
    </FullScreenContainer>
  );
}

const StyledFormContainer = styled(FormContainer)`
  background: ${({ theme, colors }) =>
    theme.className === 'contrast' ? theme.contrast : colors.primary};
  margin: 0 1rem;
  max-width: 500px;
  position: relative;
  width: 100%;
`;

const FullScreenContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
`;

export default PasswordLoginDialog;
