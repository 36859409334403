import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { increment, onValue, push, ref, set, update } from 'firebase/database';

function PollsFactory(firebase) {
  const openPoll = async ({ eventId, selectedPoll, currentlyOpenPoll }) => {
    if (currentlyOpenPoll?.pollId) {
      const pollRef = ref(firebase.rtdb, `polls/${eventId}`);

      if (selectedPoll.type === 'general poll' && selectedPoll.timer?.enabled) {
        return update(pollRef, {
          [`${currentlyOpenPoll?.pollId}/isOpen`]: false,
          [`${selectedPoll.pollId}/isOpen`]: true,
          [`${selectedPoll.pollId}/timer`]: {
            ...selectedPoll.timer,
            startedAt: new Date().getTime()
          }
        });
      }

      const promises = [];

      if (currentlyOpenPoll.type === 'word cloud') {
        if (selectedPoll.type === 'word cloud') {
          promises.push(
            updateDoc(doc(firebase.fsdb, 'events', eventId), {
              currentlyOpenWordCloudPollId: selectedPoll.pollId
            })
          );
        } else if (selectedPoll.type !== 'word cloud') {
          promises.push(
            updateDoc(doc(firebase.fsdb, 'events', eventId), {
              currentlyOpenWordCloudPollId: null
            })
          );
        }
      }

      promises.push(
        update(pollRef, {
          [`${currentlyOpenPoll?.pollId}/isOpen`]: false,
          [`${selectedPoll.pollId}/isOpen`]: true
        })
      );

      return Promise.all(promises);
    }

    if (selectedPoll.type === 'general poll' && selectedPoll.timer?.enabled) {
      return update(ref(firebase.rtdb, `polls/${eventId}/${selectedPoll.pollId}`), {
        isOpen: true,
        timer: {
          ...selectedPoll.timer,
          startedAt: new Date().getTime()
        }
      });
    }

    const promises = [];
    if (selectedPoll.type === 'word cloud') {
      promises.push(
        updateDoc(doc(firebase.fsdb, 'events', eventId), {
          currentlyOpenWordCloudPollId: selectedPoll.pollId
        })
      );
    }

    promises.push(
      update(ref(firebase.rtdb, `polls/${eventId}/${selectedPoll.pollId}`), {
        isOpen: true
      })
    );

    return Promise.all(promises);
  };

  const closePoll = async ({ eventId, poll }) => {
    const promises = [];

    if (poll.type === 'word cloud') {
      promises.push(
        updateDoc(doc(firebase.fsdb, 'events', eventId), {
          currentlyOpenWordCloudPollId: null
        })
      );
    }

    promises.push(
      update(ref(firebase.rtdb, `polls/${eventId}/${poll.pollId}`), {
        isOpen: false,
        isQueued: false
      })
    );

    return Promise.all(promises);
  };

  const sharePollAnalytics = async ({ eventId, poll }) =>
    update(ref(firebase.rtdb, `polls/${eventId}/${poll.pollId}`), {
      shareResults: true
    });

  const stopSharingPollAnalytics = async ({ eventId, poll }) =>
    update(ref(firebase.rtdb, `polls/${eventId}/${poll.pollId}`), {
      shareResults: false
    });

  const saveNewPoll = async ({ eventId, poll }) => {
    const pollId = push(ref(firebase.rtdb, `polls/${eventId}`)).key;

    if (poll.type === 'general poll') {
      const answers = {};
      poll.answers.forEach((answer) => {
        answers[`${answer.id}`] = 0;
      });

      return update(ref(firebase.rtdb), {
        [`/polls/${eventId}/${pollId}`]: poll,
        [`/pollAnalytics/${eventId}/${pollId}`]: {
          newPollType: poll.type,
          question: poll.question.text,
          correctAnswers: poll.answers.some((answer) => answer.isCorrect)
            ? poll.answers.filter((answer) => answer.isCorrect).map((answer) => answer.id)
            : [],
          answers,
          totalParticipants: 0
        }
      });
    }

    if (poll.type === 'word cloud') {
      return update(ref(firebase.rtdb), {
        [`/polls/${eventId}/${pollId}`]: poll,
        [`/pollAnalytics/${eventId}/${pollId}`]: {
          newPollType: poll.type,
          question: poll.question.text,
          totalParticipants: 0
        }
      });
    }

    if (poll.type === 'feedback poll') {
      const answers = {};
      poll.ratingOption.ratingOptions.forEach((answer) => {
        answers[`${answer.rid}`] = 0;
      });
      return update(ref(firebase.rtdb), {
        [`/polls/${eventId}/${pollId}`]: poll,
        [`/pollAnalytics/${eventId}/${pollId}`]: {
          newPollType: poll.type,
          question: poll.question.text,
          answers,
          totalParticipants: 0
        }
      });
    }
  };

  const editPoll = async ({ eventId, poll, pollId }) => {
    if (poll.type === 'general poll') {
      const answers = {};

      poll.answers.forEach((answer) => {
        answers[`${answer.id}`] = 0;
      });

      return update(ref(firebase.rtdb), {
        [`/polls/${eventId}/${pollId}`]: poll,
        [`/pollAnalytics/${eventId}/${pollId}`]: {
          newPollType: poll.type,
          question: poll.question.text,
          correctAnswers: poll.answers.some((answer) => answer.isCorrect)
            ? poll.answers.filter((answer) => answer.isCorrect).map((answer) => answer.id)
            : [],
          answers,
          totalParticipants: 0
        }
      });
    }

    if (poll.type === 'word cloud') {
      return update(ref(firebase.rtdb), {
        [`/polls/${eventId}/${pollId}`]: poll,
        [`/pollAnalytics/${eventId}/${pollId}`]: {
          newPollType: poll.type,
          question: poll.question.text,
          totalParticipants: 0
        }
      });
    }

    if (poll.type === 'feedback poll') {
      const answers = {};
      poll.ratingOption.ratingOptions.forEach((answer) => {
        answers[`${answer.rid}`] = 0;
      });
      return update(ref(firebase.rtdb), {
        [`/polls/${eventId}/${pollId}`]: poll,
        [`/pollAnalytics/${eventId}/${pollId}`]: {
          newPollType: poll.type,
          question: poll.question.text,
          totalParticipants: 0,
          answers
        }
      });
    }
  };

  const deleteWordCloudEntry = async ({ eventId, pollId, entries }) =>
    set(ref(firebase.rtdb, `/pollAnalytics/${eventId}/${pollId}/entries`), entries);

  const deletePoll = async ({ eventId, poll }) => {
    const { pollId, type } = poll;

    const promises = [];

    if (type === 'word cloud') {
      promises.push(
        updateDoc(doc(firebase.fsdb, 'events', eventId), {
          currentlyOpenWordCloudPollId: null
        })
      );
    }

    promises.push(
      update(ref(firebase.rtdb), {
        [`/polls/${eventId}/${pollId}`]: null,
        [`/pollAnalytics/${eventId}/${pollId}`]: null
      })
    );

    return Promise.all(promises);
  };

  const submitPollAnswer = async ({ eventId, poll, uid, selectedAnswerIds }) => {
    const { pollId } = poll;

    const answers = {};

    selectedAnswerIds.forEach((id) => {
      answers[`${id}`] = increment(1);
    });

    const totalParticipants = increment(1);

    /* TODO: Finish the fsdb query */
    /* Make each answer an object, with answer.id and answer.correct on it */
    return Promise.all([
      update(ref(firebase.rtdb, `/pollAnalytics/${eventId}/${pollId}/answers`), answers),
      update(ref(firebase.rtdb, `/pollAnalytics/${eventId}/${pollId}`), {
        totalParticipants
      }),
      setDoc(doc(firebase.fsdb, 'users', uid, 'pollAnalytics', `${eventId}_${pollId}`), {
        uid,
        answers: selectedAnswerIds
      })
    ]);
  };

  const submitFeedbackAnswer = async ({
    eventId,
    poll,
    uid,
    rating,
    personalMessage,
    contactAgreement
  }) => {
    const { pollId } = poll;

    const pollRef = ref(firebase.rtdb, `/pollAnalytics/${eventId}/${pollId}`);
    const answersRef = ref(firebase.rtdb, `/pollAnalytics/${eventId}/${pollId}/answers`);
    return Promise.all([
      update(pollRef, {
        totalParticipants: increment(1)
      }),
      update(answersRef, {
        [rating.id]: increment(1)
      }),
      setDoc(doc(firebase.fsdb, 'users', uid, 'pollAnalytics', `${eventId}_${pollId}`), {
        uid,
        rating: {
          position: rating.position + 1,
          id: rating.id
        },
        message: personalMessage,
        contactAgreement
      })
    ]);
  };

  const answerWordCloudPoll = async ({ eventId, poll, uid, entries }) => {
    const { pollId } = poll;
    const totalParticipants = increment(1);

    const _entries = {};

    entries.forEach((entry) => {
      _entries[entry] = increment(1);
    });

    return Promise.all([
      update(ref(firebase.rtdb, `/pollAnalytics/${eventId}/${pollId}`), {
        totalParticipants
      }),
      update(ref(firebase.rtdb, `/pollAnalytics/${eventId}/${pollId}/entries`), _entries),
      setDoc(doc(firebase.fsdb, 'users', uid, 'pollAnalytics', `${eventId}_${pollId}`), {
        uid,
        entries
      })
    ]);
  };

  const checkIfUserHasAlreadyAnsweredThisPoll = async ({ uid, eventId, pollId }) =>
    getDoc(doc(firebase.fsdb, 'users', uid, 'pollAnalytics', `${eventId}_${pollId}`));

  const subscribeToServerTimeOffset = ({ snapshot }) => {
    const serverTimeOffsetRef = ref(firebase.rtdb, '.info/serverTimeOffset');
    return onValue(serverTimeOffsetRef, snapshot);
  };

  const subscribeToPolls = ({ eventId, snapshot }) => {
    const pollsRef = ref(firebase.rtdb, `polls/${eventId}`);
    return onValue(pollsRef, snapshot);
  };

  const subscribeToPollAnalytics = ({ eventId, pollId, snapshot }) => {
    const pollAnalyticsRef = ref(firebase.rtdb, `/pollAnalytics/${eventId}/${pollId}`);
    return onValue(pollAnalyticsRef, snapshot);
  };

  return {
    openPoll,
    closePoll,
    sharePollAnalytics,
    stopSharingPollAnalytics,
    saveNewPoll,
    editPoll,
    deleteWordCloudEntry,
    deletePoll,
    submitPollAnswer,
    submitFeedbackAnswer,
    answerWordCloudPoll,
    checkIfUserHasAlreadyAnsweredThisPoll,
    subscribeToServerTimeOffset,
    subscribeToPolls,
    subscribeToPollAnalytics
  };
}

export default PollsFactory;
