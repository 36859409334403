import * as React from 'react';

function SvgPills(props) {
  return (
    <svg viewBox="0 0 118 118" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M58.9 117.8c32.53 0 58.9-26.37 58.9-58.9S91.43 0 58.9 0 0 26.37 0 58.9s26.37 58.9 58.9 58.9Z"
        fill="#004D9A"
      />
      <path
        d="M36.682 87.231A2.991 2.991 0 0 1 33.7 84.25V33.55a2.991 2.991 0 0 1 2.982-2.982h25.35a2.991 2.991 0 0 1 2.982 2.982V84.1a2.991 2.991 0 0 1-2.983 2.982H36.682v.15Zm0-54.277c-.298 0-.596.298-.596.596V84.1c0 .298.298.596.596.596h25.35c.298 0 .596-.298.596-.596V33.55c0-.149 0-.298-.15-.447-.148-.15-.298-.15-.447-.15H36.682Z"
        fill="#fff"
      />
      <path
        d="M43.243 75.75h-2.386v2.385h2.386V75.75ZM57.707 75.75H55.32v2.385h2.386V75.75ZM43.243 68.592h-2.386v2.386h2.386v-2.386ZM57.707 68.592H55.32v2.386h2.386v-2.386ZM43.243 61.286h-2.386v2.386h2.386v-2.386ZM57.707 61.286H55.32v2.386h2.386v-2.386ZM43.243 54.128h-2.386v2.386h2.386v-2.386ZM57.707 54.128H55.32v2.386h2.386v-2.386ZM43.243 46.821h-2.386v2.386h2.386v-2.386ZM57.707 46.821H55.32v2.386h2.386v-2.386ZM43.243 39.664h-2.386v2.386h2.386v-2.386ZM57.707 39.664H55.32v2.386h2.386v-2.386ZM49.356 79.925c-.596 0-1.192-.597-1.192-1.193V39.067c0-.596.596-1.193 1.192-1.193.597 0 1.193.597 1.193 1.193v39.814c0 .596-.596 1.044-1.193 1.044ZM76.495 87.231c-4.175 0-7.604-3.43-7.604-7.605V59.794c0-4.175 3.43-7.604 7.604-7.604 4.176 0 7.605 3.43 7.605 7.604v19.832c0 4.176-3.43 7.605-7.605 7.605Zm-4.473-16.253c-.298 0-.596.298-.596.596v8.052a5.028 5.028 0 0 0 5.07 5.07 5.028 5.028 0 0 0 5.07-5.07v-8.2c0-.3-.299-.597-.597-.597h-8.947v.149Zm4.473-16.254a5.028 5.028 0 0 0-5.07 5.07v8.202c0 .298.299.596.597.596h9.096c.298 0 .596-.298.596-.596v-8.202c0-2.833-2.385-5.07-5.219-5.07Z"
        fill="#fff"
      />
    </svg>
  );
}
export default SvgPills;
