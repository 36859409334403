import {
  onChildAdded,
  onValue,
  orderByChild,
  push,
  query as rtdbQuery,
  ref,
  set,
  startAfter as rtdbStartAfter,
  serverTimestamp as rtdbServerTimestamp
} from 'firebase/database';

function EmojisFactory(firebase) {
  const sendEmoji = async ({ eventId, emojiType }) =>
    set(push(ref(firebase.rtdb, `/emojis/${eventId}`)), {
      emojiType,
      timestamp: rtdbServerTimestamp()
    });

  const subscribeToEmojis = ({ eventId, snapshot }) => {
    const emojisRef = ref(firebase.rtdb, `/emojis/${eventId}`);
    return onChildAdded(
      rtdbQuery(emojisRef, orderByChild('timestamp'), rtdbStartAfter(Date.now())),
      snapshot
    );
  };

  const subscribeToEventEmojis = ({ eventId, snapshot }) =>
    onValue(ref(firebase.rtdb, `/emojis/${eventId}`), snapshot);

  return {
    sendEmoji,
    subscribeToEmojis,
    subscribeToEventEmojis
  };
}

export default EmojisFactory;
