import {
  arrayRemove,
  arrayUnion,
  doc,
  updateDoc,
  onSnapshot,
  query,
  where,
  collection,
  orderBy
} from 'firebase/firestore';

function ModerationFactory(firebase) {
  const grantRegistrantAccessToTheseEvents = async ({ uid, eventIds }) =>
    updateDoc(doc(firebase.fsdb, 'users', uid), {
      eventsUserWantsToAccess: arrayRemove(...eventIds),
      eventsUserCanAccess: arrayUnion(...eventIds),
      eventsUserHasBeenDeniedAccessTo: arrayRemove(...eventIds),
      pendingSiteRegistrant: false
    });

  const denyRegistrantAccessToTheseEvents = async ({ uid, eventIds }) =>
    updateDoc(doc(firebase.fsdb, 'users', uid), {
      eventsUserHasBeenDeniedAccessTo: arrayUnion(...eventIds),
      eventsUserCanAccess: arrayRemove(...eventIds),
      eventsUserWantsToAccess: arrayRemove(...eventIds)
    });

  const denyRegistrantAccessToSite = async ({ uid }) =>
    updateDoc(doc(firebase.fsdb, 'users', uid), {
      eventsUserCanAccess: [],
      eventsUserWantsToAccess: [],
      eventsUserHasBeenDeniedAccessTo: [],
      pendingSiteRegistrant: false
    });

  const subscribeToPendingEventRegistrants = ({ eventId, snapshot }) =>
    onSnapshot(
      query(
        collection(firebase.fsdb, 'users'),
        where('eventsUserWantsToAccess', 'array-contains', eventId),
        orderBy('name', 'asc')
      ),
      snapshot
    );

  const subscribeToApprovedEventRegistrants = ({ eventId, snapshot }) =>
    onSnapshot(
      query(
        collection(firebase.fsdb, 'users'),
        where('eventsUserCanAccess', 'array-contains', eventId),
        orderBy('name', 'asc')
      ),
      snapshot
    );

  const subscribeToDeniedEventRegistrants = ({ eventId, snapshot }) =>
    onSnapshot(
      query(
        collection(firebase.fsdb, 'users'),
        where('eventsUserHasBeenDeniedAccessTo', 'array-contains', eventId),
        orderBy('name', 'asc')
      ),
      snapshot
    );

  const subscribeToPendingSiteRegistrants = ({ snapshot }) =>
    onSnapshot(
      query(
        collection(firebase.fsdb, 'users'),
        where('eventsUserWantsToAccess', '==', []),
        where('eventsUserCanAccess', '==', []),
        where('pendingSiteRegistrant', '==', true),
        orderBy('name', 'asc')
      ),
      snapshot
    );

  const subscribeToApprovedSiteRegistrants = ({ snapshot }) =>
    onSnapshot(
      query(
        collection(firebase.fsdb, 'users'),
        where('eventsUserWantsToAccess', '==', []),
        where('pendingSiteRegistrant', '==', false),
        orderBy('name', 'asc')
      ),
      snapshot
    );

  const subscribeToDeniedSiteRegistrants = ({ snapshot }) =>
    onSnapshot(
      query(
        collection(firebase.fsdb, 'users'),
        where('eventsUserCanAccess', '==', []),
        where('eventsUserWantsToAccess', '==', []),
        where('eventsUserHasBeenDeniedAccessTo', '==', []),
        where('pendingSiteRegistrant', '==', false),
        orderBy('name', 'asc')
      ),
      snapshot
    );

  return {
    grantRegistrantAccessToTheseEvents,
    denyRegistrantAccessToTheseEvents,
    denyRegistrantAccessToSite,
    subscribeToPendingEventRegistrants,
    subscribeToApprovedEventRegistrants,
    subscribeToDeniedEventRegistrants,
    subscribeToPendingSiteRegistrants,
    subscribeToApprovedSiteRegistrants,
    subscribeToDeniedSiteRegistrants
  };
}

export default ModerationFactory;
