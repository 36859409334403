import { httpsCallable } from 'firebase/functions';

function StripeFactory(firebase) {
  const createPayment = async ({ uid, email, amount, currency, paymentMethod }) => {
    const createPaymentCallable = httpsCallable(firebase.functions, 'user-stripe-createPayment');
    return createPaymentCallable({
      uid,
      email: email.toLowerCase().trim(),
      amount,
      currency,
      paymentMethod
    });
  };

  return {
    createPayment
  };
}

export default StripeFactory;
