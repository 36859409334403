import React, { useEffect, useState, useContext, useRef } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { LogoSmall, LogoSmallContrast, User } from 'assets/svgs';
import { AccessibilityBar, UserProfile, BulletPoint, EventList, Dialog } from 'components';
import { LocalContext, FirebaseContext } from 'context';
import { useLocation } from '@reach/router';
import { motion, AnimatePresence } from 'framer-motion';
import { useWindowSize, useEventData } from 'hooks';
import { easeInOut } from '@popmotion/easing';
import { AnimatedEyeIcon } from 'assets/dynamic-svgs';
import { HelpIcon } from '../../../assets/svgs';

function Navbar() {
  const { edges: events } = useEventData();
  const {
    theme,
    isMobile,
    scrollTo,
    galleryRef,
    contactRef,
    faqRef,
    handleDialog,
    setShowLoginOrSignUpDialog
  } = useContext(LocalContext);
  const { windowHeight } = useWindowSize();
  const { pathname } = useLocation();
  const { user, firebase, loading } = useContext(FirebaseContext);
  const [navOpen, setNavOpen] = useState(false);
  const [themeToggleOpen, setThemeToggleOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeLink, setActiveLink] = useState(pathname);
  const [showUserProfileDialog, setShowUserProfileDialog] = useState(false);
  const userProfileDialogRef = useRef(null);

  const location = useLocation();

  useEffect(() => setActiveLink(pathname), [pathname]);

  const handleScroll = () => {
    const scrollNavTop = window.scrollY;
    if (scrollNavTop >= 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleNav = () => {
    setThemeToggleOpen(false);
    setNavOpen(!navOpen);
  };

  const toggleTheme = () => {
    setNavOpen(false);
    setThemeToggleOpen(!themeToggleOpen);
  };

  const toggleActiveLink = (link) => {
    toggleNav();
    setActiveLink(link);
  };

  return (
    <StyledNavbar
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      scrolled={isScrolled}
      themeToggle={themeToggleOpen}>
      <Background />
      <LogoContainer
        to="/"
        aria-label="Home"
        onClick={() => {
          toggleActiveLink('welcome');
        }}>
        {theme.className === 'contrast' ? <LogoSmallContrast /> : <LogoSmall />}
      </LogoContainer>
      {(navOpen || !isMobile) && (
        <NavLinks
          windowHeight={windowHeight}
          initial={isMobile ? { x: '100%' } : { x: 0 }}
          animate={{ x: 0 }}
          exit={{ x: '100%' }}
          transition={{ duration: 0.5, ease: easeInOut }}>
          {!user?.uid && (
            <NavLink
              to="/"
              className={activeLink === '/' ? 'active' : null}
              onClick={() => {
                toggleActiveLink('/');
              }}>
              Welcome
            </NavLink>
          )}
          {isMobile && !loading && firebase?.auth?.currentUser && user?.uid && (
            <LogInOrSignUpButton onClick={() => setShowUserProfileDialog(true)}>
              <p style={{ marginRight: '.625rem', fontWeight: '700' }}>
                {user?.name?.split(' ')[0]}
              </p>
              {user.avatarUrl ? <ProfileImage src={user.avatarUrl} alt={user?.name} /> : <User />}
            </LogInOrSignUpButton>
          )}
          {events?.length > 1 && user?.uid && <EventList toggleNav={toggleNav} />}
          {events?.length === 1 && user?.uid && (
            <NavLink
              to="/livestream"
              className={activeLink === '/livestream/' ? 'active' : null}
              onClick={() => {
                toggleActiveLink('/livestream/');
              }}>
              Livestream
            </NavLink>
          )}
          {location.pathname !== '/' && location.pathname !== '/auth/' && (
            <NavLink
              to="#gallery"
              className={activeLink === 'gallery' ? 'active ' : null}
              onClick={(e) => {
                e.preventDefault();
                scrollTo(galleryRef);
                toggleActiveLink('gallery');
              }}>
              Gallery
            </NavLink>
          )}
          <NavLink
            to="#faq"
            className={activeLink === 'faq' ? 'active ' : null}
            onClick={(e) => {
              e.preventDefault();
              scrollTo(faqRef);
              toggleActiveLink('faq');
            }}>
            FAQ
          </NavLink>
          <NavLink
            to="#contact"
            className={activeLink === 'contact' ? 'active ' : null}
            onClick={(e) => {
              e.preventDefault();
              scrollTo(contactRef);
              toggleActiveLink('contact');
            }}>
            Contact
          </NavLink>
          {isMobile && !loading && !firebase?.auth?.currentUser && !user && !user?.uid && (
            <LogInOrSignUpButton onClick={() => setShowLoginOrSignUpDialog(true)}>
              <p>Log In / Sign Up</p>
              <User />
            </LogInOrSignUpButton>
          )}
        </NavLinks>
      )}
      {!isMobile &&
        !loading &&
        firebase &&
        (firebase.auth?.currentUser && user?.uid ? (
          <UserIcon onClick={() => setShowUserProfileDialog(true)}>
            <div>
              <p style={{ marginRight: '.625rem', fontWeight: '700' }}>
                {user?.name?.split(' ')[0]}
              </p>
              {user.avatarUrl ? <ProfileImage src={user.avatarUrl} alt={user?.name} /> : <User />}
            </div>
          </UserIcon>
        ) : (
          <UserIcon onClick={() => setShowLoginOrSignUpDialog(true)}>
            <div>
              <p style={{ marginRight: '.625rem', fontWeight: '700' }}>Log In / Sign Up</p>
              <User />
            </div>
          </UserIcon>
        ))}
      <HelpHub
        to="/help-hub"
        className={activeLink === '/help-hub' ? 'active' : null}
        onClick={() => {
          toggleActiveLink('/help-hub');
        }}>
        <HelpIcon />
      </HelpHub>
      {(location.hostname === 'panacea.eventxlive.com' || 'localhost') && (
        <>
          <ThemeToggle onClick={() => toggleTheme()} open={themeToggleOpen}>
            {user?.uid && (
              <BulletPoint
                top=".5rem"
                right="1rem"
                width="11rem"
                content="<p>Accessibility Bar</p>"
              />
            )}
            <AnimatedEyeIcon />
          </ThemeToggle>
          <AnimatePresence>{themeToggleOpen && <AccessibilityBar />}</AnimatePresence>
        </>
      )}
      <NavToggler onClick={() => toggleNav()} aria-label="Menu">
        <NavBurger navOpen={navOpen}>
          <BurgerBar navOpen={navOpen} />
          <BurgerBar navOpen={navOpen} />
          <BurgerBar navOpen={navOpen} />
        </NavBurger>
      </NavToggler>
      <Dialog
        ref={userProfileDialogRef}
        isVisible={showUserProfileDialog}
        onDismiss={() =>
          handleDialog({
            dialogRef: userProfileDialogRef,
            animation: 'dismiss',
            stateHandler: () => setShowUserProfileDialog(false)
          })
        }
        contentStyle={{
          maxWidth: '23.75rem'
        }}>
        <UserProfile
          user={user}
          userProfileDialogRef={userProfileDialogRef}
          setShowUserProfileDialog={setShowUserProfileDialog}
        />
      </Dialog>
    </StyledNavbar>
  );
}

const ProfileImage = styled.img`
  background: #c4c4c4;
  border: 0.125rem solid ${({ theme }) => theme.tertiary};
  border-radius: 50%;
  height: 1.625rem;
  object-fit: cover;
  width: 1.625rem;
`;

const LogoContainer = styled(Link)`
  grid-area: logo;
  svg {
    height: 1.5rem;
    margin-left: 1.25rem;
    object-fit: cover;
    width: auto;
    z-index: 2;
  }
  @media only screen and (min-width: 1150px) {
    grid-column: 2/4;
  }
`;

const LogInOrSignUpButton = styled.button`
  align-items: center;
  background: ${({ theme }) => theme.tertiary};
  border: none;
  color: ${({ theme }) => theme.contrast};
  cursor: pointer;
  display: flex;
  font-family: Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  gap: 0.5rem;
  justify-content: center;
  letter-spacing: 0.5px;
  line-height: 3rem;
  max-width: 100%;
  outline: none;
  padding-top: 0.125rem;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  div {
    align-items: center;
    color: ${({ theme }) => theme.primary};
    display: flex;
    justify-content: center;
  }
  svg {
    max-height: 1.25rem;

    path {
      fill: ${({ theme }) => theme.contrast};
    }
    circle {
      stroke: ${({ theme }) => theme.contrast};
    }
  }
  @media only screen and (min-width: 1150px) {
    background: transparent;
    justify-content: flex-end;

    svg {
      margin-left: 0.5em;
    }
  }
`;

const UserIcon = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
  grid-area: user;
  grid-column: 10/12;
  height: 100%;
  margin-left: auto;
  outline: 0;
  width: 100%;

  div {
    align-items: center;
    color: ${({ theme }) => theme.primary};
    display: flex;
    font-family: Helvetica, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    justify-content: center;
    svg {
      max-height: 1.25rem;
    }
  }
  @media only screen and (min-width: 1150px) {
    justify-content: end;
    div {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: flex-end;
      width: 100%;
    }
  }
  svg {
    circle {
      stroke: ${({ theme }) => theme.primary};
    }
    path {
      fill: ${({ theme }) => theme.primary};
    }
  }
`;

const NavToggler = styled.button`
  align-items: center;
  background: ${({ theme }) => theme.primary};
  cursor: pointer;
  display: flex;
  grid-area: toggle;
  height: 3.75rem;
  justify-content: center;
  justify-self: end;
  width: 3.75rem;
  @media only screen and (min-width: 1150px) {
    display: none;
  }
`;

const NavBurger = styled.div`
  height: 1rem;
  position: relative;
  width: ${({ navOpen }) => (navOpen === true ? '1rem' : '1.25rem')};
`;

const BurgerBar = styled.span`
  background: ${({ theme }) => (theme.className === 'contrast' ? theme.primary : theme.contrast)};
  content: '';
  display: block;
  height: 0.185rem;
  left: 0;
  position: absolute;
  transform-origin: center center;
  transition: 0.375s;
  width: 1.25rem;
  &:first-child {
    top: ${({ navOpen }) => (navOpen === true ? '50%' : '0')};
    transform: ${({ navOpen }) => (navOpen === true ? 'rotate(45deg)' : 'none')};
  }
  &:nth-child(2) {
    opacity: ${({ navOpen }) => (navOpen === true ? '0' : '1')};
    top: 50%;
    transform: translateY(-50%);
  }
  &:last-child {
    bottom: ${({ navOpen }) => (navOpen === true ? 'initial' : '0')};
    top: ${({ navOpen }) => (navOpen === true ? '50%' : 'initial')};
    transform: ${({ navOpen }) => (navOpen === true ? 'rotate(-45deg)' : 'none')};
  }
`;

const ThemeToggle = styled.div`
  align-items: center;
  background: ${({ open, theme }) =>
    open ? `${theme.className === 'contrast' ? theme.contrast : '#EAF5F9'}` : 'transparent'};
  cursor: pointer;
  display: flex;
  grid-area: eye;
  height: 3.75rem;
  justify-content: center;
  justify-self: end;
  position: relative;
  transition: 0.375s;
  width: 3.75rem;
  z-index: 1;
  svg {
    height: 1.25rem;
    max-height: 1.25rem;
    path {
      stroke: ${({ theme }) => theme.primary};
      :last-child {
        fill: ${({ theme }) => theme.primary};
      }
    }
  }
  @media only screen and (min-width: 1150px) {
    grid-column: 13/14;
    height: 4rem;
    justify-self: start;
    width: 4rem;
  }
`;

const NavLink = styled(Link)`
  background: ${({ theme }) => theme.primary};
  color: ${({ theme }) => (theme.className === 'contrast' ? theme.primary : theme.contrast)};
  cursor: pointer;
  font-family: Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 3rem;
  padding-top: 0.125rem;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  @media only screen and (min-width: 1150px) {
    background: transparent;
    border-bottom: 0.185rem solid transparent;
    color: ${({ theme }) => theme.primary};
    line-height: 1.5rem;
    width: auto;
    &.active {
      border-bottom: 0.185rem solid ${({ theme }) => theme.primary};
    }
    &:nth-child(4) {
      color: ${({ theme }) => theme.tertiary};
      &.active {
        border-bottom: 0.185rem solid ${({ theme }) => theme.tertiary};
      }
    }
  }
`;

const HelpHub = styled(Link)`
  align-items: center;
  background: ${({ open, theme }) =>
    open ? `${theme.className === 'contrast' ? theme.contrast : '#EAF5F9'}` : 'transparent'};
  cursor: pointer;
  display: flex;
  grid-area: help;
  height: 3.75rem;
  justify-content: center;
  justify-self: end;
  position: relative;
  transition: 0.375s;
  width: 3.75rem;
  z-index: 1;
  svg {
    height: 1.25rem;
    max-height: 1.25rem;
    circle {
      stroke: ${({ theme }) => theme.primary};
    }
    path {
      fill: ${({ theme }) => theme.primary};
      stroke: ${({ theme }) => theme.primary};
    }
  }
  @media only screen and (min-width: 1150px) {
    grid-column: 12/13;
    height: 4rem;
    justify-self: center;
    width: 100%;
  }
`;

const NavLinks = styled(motion.div)`
  align-items: center;
  backdrop-filter: blur(2px);
  box-shadow: 0px 0.185rem 0.75rem rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  grid-area: links;
  grid-column: 1/7;
  grid-gap: 0px;
  height: ${({ windowHeight }) => `calc(${windowHeight}px - 60px)`};
  justify-content: flex-start;
  position: relative;
  z-index: 2;
  @media only screen and (min-width: 1150px) {
    align-items: center;
    background: transparent;
    box-shadow: none;
    flex-direction: row;
    grid-column: 4/10;
    height: 4rem;
    justify-content: space-around;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0px;
  }
`;

const Background = styled.div`
  background: ${({ theme }) => theme.contrast};
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
`;

const StyledNavbar = styled(motion.nav)`
  align-items: center;
  box-shadow: 0px 0.185rem 0.75rem rgba(0, 0, 0, 0.25);
  display: grid;
  grid-gap: 0px;
  grid-template-areas:
    'logo logo logo help eye toggle'
    '. . links links . .';
  grid-template-columns: 1fr 1fr 1fr 60px 60px 60px;
  height: 3.75rem;
  left: 0;
  padding: 0px;
  position: fixed;
  top: 0;
  transition-duration: 0.75s;
  width: 100%;
  z-index: 4;

  @media only screen and (min-width: 1150px) {
    box-shadow: ${({ scrolled, themeToggle }) =>
      scrolled || themeToggle ? '0px .185rem .75rem rgba(0,0,0,.25)' : 'transparent'};
    grid-template-areas: 'logo links user help eye';
    grid-template-columns: repeat(14, 1fr);
    height: 4rem;
  }
`;

export default Navbar;
