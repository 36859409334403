import * as React from 'react';

function SvgHelpIcon(props) {
  return (
    <svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.544 16.696h2.144v2h-2.144v-2ZM9 10.904c0-.587.085-1.12.256-1.6.181-.48.437-.89.768-1.232a3.461 3.461 0 0 1 1.216-.784A4.273 4.273 0 0 1 12.84 7c.49 0 .944.075 1.36.224.427.139.795.347 1.104.624.32.267.57.603.752 1.008.181.405.272.864.272 1.376 0 .373-.043.699-.128.976a2.366 2.366 0 0 1-.32.704 3.01 3.01 0 0 1-.448.528l-.512.448c-.203.17-.39.336-.56.496-.17.16-.32.341-.448.544-.128.192-.23.421-.304.688a4.249 4.249 0 0 0-.096.976h-1.728c0-.47.021-.864.064-1.184a3.61 3.61 0 0 1 .24-.864c.107-.245.235-.459.384-.64.16-.192.347-.379.56-.56.17-.15.33-.288.48-.416.16-.128.299-.267.416-.416a1.7 1.7 0 0 0 .288-.528c.075-.192.112-.421.112-.688 0-.32-.059-.592-.176-.816a1.606 1.606 0 0 0-.4-.56c-.16-.139-.33-.24-.512-.304a1.468 1.468 0 0 0-.48-.096c-.661 0-1.152.219-1.472.656-.31.427-.464 1.003-.464 1.728H9Z"
        fill="#004D9A"
      />
      <circle cx={13} cy={13} r={12.5} stroke="#004D9A" />
    </svg>
  );
}
export default SvgHelpIcon;
