import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'components';
import { isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';

import * as D3 from 'd3';
import Pie from '../../Shared/PieChart';

function AnalyticsChart({ companies, professions }) {
  const [toggle, setToggle] = useState(false);
  const [chartColors, setChartColors] = useState([
    '#ED6E85',
    '#FF9559',
    '#54A1E5',
    '#6CBEBF',
    '#F8CD6B',
    '#613F75',
    '#003E1F',
    '#FCD581',
    '#FF9F1C',
    '#DC6ACF'
  ]);
  const [registereeProfessions, setRegistereeProfessions] = useState([]);
  const [registereeCompanies, setRegistereeCompanies] = useState([]);
  const [total, setTotal] = useState(0);
  const colors = D3.scaleOrdinal(chartColors);

  useEffect(() => {
    if (toggle) {
      setTotal(companies.length);
    } else {
      setTotal(professions.length);
    }
  }, [toggle, companies, professions]);

  useEffect(() => {
    const companyCounts = companies.reduce((obj, company) => {
      obj[company] = obj[company] + 1 || 1;
      return obj;
    }, {});
    Object.entries(companyCounts).forEach((array) => {
      const [company, count] = array;
      setRegistereeCompanies((currentState) => [...currentState, { label: company, value: count }]);
    });
    return () => setRegistereeCompanies([]);
  }, [companies]);

  useEffect(() => {
    const professionCounts = professions.reduce((obj, profession) => {
      obj[profession] = obj[profession] + 1 || 1;
      return obj;
    }, {});
    Object.entries(professionCounts).forEach((array) => {
      const [profession, count] = array;
      setRegistereeProfessions((currentState) => [
        ...currentState,
        { label: profession, value: count }
      ]);
    });
    return () => setRegistereeProfessions([]);
  }, [professions]);

  useEffect(() => {
    const _chartColors = [];
    if (companies.length > professions) {
      companies.forEach(() => {
        const newColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
        _chartColors.push(newColor);
      });
      setChartColors(_chartColors);
    } else {
      professions.forEach(() => {
        const newColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
        _chartColors.push(newColor);
      });
    }
    setChartColors(_chartColors);
  }, [companies, professions]);

  const handleButtonToggle = (event) => {
    event.persist();
    setToggle((prevState) => !prevState);
  };

  return (
    <Container>
      <Toggle>
        <Button
          color="primary"
          onClick={handleButtonToggle}
          variant={!toggle ? 'contained' : 'outlined'}>
          Companies
        </Button>
        <Button
          color="primary"
          onClick={handleButtonToggle}
          variant={toggle ? 'contained' : 'outlined'}>
          Profession
        </Button>
      </Toggle>
      <Description>
        Toggle between companies and industries to see participants breakdown.
      </Description>
      <Chart>
        <Legends>
          {!toggle
            ? registereeCompanies.map((d, index) => (
                <div style={{ display: 'flex', justifyContent: 'center' }} key={index}>
                  <Color style={{ background: colors(index), alignSelf: 'center' }} />
                  <p
                    style={{
                      fontSize: '12px',
                      margin: '0 0.5rem',
                      padding: '0',
                      width: '100%'
                    }}>
                    {d.label}
                  </p>
                </div>
              ))
            : registereeProfessions.map((d, i) => (
                <div style={{ display: 'flex', justifyContent: 'center' }} key={i}>
                  <Color style={{ background: colors(i), alignSelf: 'center' }} />
                  <p
                    style={{
                      fontSize: '12px',
                      margin: '0 0.5rem',
                      padding: 'none',
                      width: '100%'
                    }}>
                    {d.label}
                  </p>
                </div>
              ))}
        </Legends>
        <Pie
          data={!toggle ? registereeCompanies : registereeProfessions}
          total={total}
          width={isMobile ? 360 : 460}
          height={isMobile ? 360 : 460}
          innerRadius={0}
          outerRadius={isMobile ? 180 : 230}
          cornerRadius={0}
          colors={colors}
        />
      </Chart>
    </Container>
  );
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  margin-top: 4.75rem;
`;

const Toggle = styled.div`
  display: flex;
  gap: 1rem;
  padding: 1.125rem;
`;

const Description = styled.p`
  font-size: 1.25rem;
  line-height: 1.5rem;
  text-align: center;
  width: 100%;
`;

const Chart = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: 799px) {
    width: 100vw;
  }
`;

const Legends = styled.div`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 71.25rem;
  padding: 3rem;
`;

const Color = styled.div`
  height: 0.625rem;
  min-width: 2.5rem;
`;
export default AnalyticsChart;
