import React, { useState, useContext, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby';
import { FirebaseContext } from 'context';
import { Button } from 'components';
import { FormLabel } from 'components/Forms/FormComponents';
import { hexToRGB } from 'utils';

function ClosedEventDialog({ event, colors, dismissClosedEventDialog }) {
  const [sendingRequest, setSendingRequest] = useState(false);
  const { loading, firebase, user } = useContext(FirebaseContext);

  const handleRegisterForEventIfUserAlreadyHasAnAccount = async () => {
    setSendingRequest(true);

    firebase.registration
      .registerForEventIfAccountAlreadyExists({
        uid: user.uid,
        eventId: event.eventId
      })
      .then(({ data: _user }) => {
        const { eventsUserCanAccess } = _user;
        if (eventsUserCanAccess.includes(event.eventId)) {
          // firebase.sendEventWelcomeEmail({
          //   attendeeName: user.fullName,
          //   attendeeEmail: user.email,
          //   event: {
          //     name,
          //     slug,
          //     start,
          //     end,
          //     location
          //   }
          // });
        } else {
          // firebase.sendAwaitingAccessApprovalEmail({
          //   fullName: _user.fullName,
          //   email: _user.email,
          //   uid: _user.uid,
          //   eventName: event.name
          // })
        }
        setSendingRequest(false);
      })
      .catch((error) => {
        console.error(error);
        setSendingRequest(false);
      });
  };

  useEffect(() => {
    if (user.eventsUserCanAccess.includes(event.eventId)) {
      dismissClosedEventDialog();
    }
  }, [user]);

  return (
    <Container colors={colors}>
      {!loading &&
      firebase &&
      user?.emailVerified &&
      !user.emailDomainIsWhitelisted &&
      event &&
      (user.eventsUserWantsToAccess.includes(event.eventId) ||
        user.eventsUserHasBeenDeniedAccessTo.includes(event.eventId)) ? (
        <div>
          <FormLabel style={{ textAlign: 'center' }}>Approval Pending</FormLabel>
          <Message colors={colors}>
            <p>
              Our moderators will review your registration application and grant you access to the
              event as soon as possible.
            </p>
          </Message>
          <Button
            onClick={() => {
              const callback = () => navigate('/');
              dismissClosedEventDialog(callback);
            }}
            style={{
              marginTop: '2rem'
            }}>
            Go Back
          </Button>
        </div>
      ) : (
        <div>
          <FormLabel style={{ textAlign: 'center' }}>
            This is a closed event.
            <br />
            <br />
            {`If you'd like to attend the event please register ${
              user?.emailDomainIsWhitelisted ? 'below' : 'your interest below'
            }.
            `}
          </FormLabel>
          <Button
            onClick={() => handleRegisterForEventIfUserAlreadyHasAnAccount()}
            loadingButton
            loading={sendingRequest}
            shine
            disabled={user?.emailVerified === false || sendingRequest}
            changeColorWhenDisabled={false}
            style={{
              cursor: user?.emailVerified ? 'pointer' : 'not-allowed'
            }}>
            Register
          </Button>
        </div>
      )}
    </Container>
  );
}

const Container = styled.div`
  align-items: center;
  background: ${({ theme, colors }) =>
    theme.className === 'contrast' ? theme.tertiary : colors.primary};
  border-radius: 0.625rem;
  color: ${({ theme }) => (theme.className === 'contrast' ? theme.primary : theme.contrast)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2.5rem 1rem;

  div {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      position: absolute;
      top: calc(1rem - 50vh);
    `};

  @media screen and (min-width: 1150px) {
    background: ${({ theme, colors }) =>
      hexToRGB({
        color: theme.className === 'contrast' ? theme.contrast : colors.primary,
        alpha: 0.9
      })};
    padding: 2.5rem 1.25rem;
  }
`;

const Message = styled.div`
  align-items: center;
  border: 1px solid #fff;
  border-radius: 0.625rem;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
  padding: 1.25rem 1em;
  padding-top: 1.25em;
  text-align: center;

  h3 {
    font-size: 1.75rem;
    margin-bottom: 0.625em;
  }

  p {
    margin-bottom: 0.75em;
  }
`;

export default ClosedEventDialog;
