import * as React from 'react';

function SvgRobotHead(props) {
  return (
    <svg viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 5.83a3.001 3.001 0 1 0-2 0v1.196c-6.83.367-11 4.5-11 10.118C0 22.817 4.835 26 12 26s12-3.183 12-8.856c0-5.617-4.17-9.751-11-10.118V5.83ZM12 24c5.97 0 10-2.513 10-6.991C22 12.36 18.183 9 12 9 5.816 9 2 12.361 2 17.009 2 21.487 6.03 24 12 24Zm-6-7.914c0 .52.056 1.038.148 1.538.37 1.835 3.039 1.835 3.41 0 .092-.5.148-1 .148-1.538a7.15 7.15 0 0 0-.297-2.093c-.444-1.575-2.686-1.575-3.131 0A8.645 8.645 0 0 0 6 16.086Zm11.557 1.538c.093-.5.149-1.019.149-1.538a7.82 7.82 0 0 0-.278-2.093c-.445-1.575-2.687-1.575-3.132 0A7.147 7.147 0 0 0 14 16.086c0 .538.056 1.038.148 1.538.37 1.835 3.039 1.835 3.41 0Z"
        fill="#fff"
      />
    </svg>
  );
}
export default SvgRobotHead;
