import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { AnimatePresence, motion } from 'framer-motion';
import { Button } from 'components';
import { LocalContext } from 'context';
import { FacebookIcon, InstagramIcon, LinkedinIcon, TwitterIcon } from '../../../../assets/svgs';

// Adds a comma between multiple listed roles.
const listRoles = ({ user, selectedEvent }) => {
  const roles = ['Admin', 'Moderator', 'Editor'];

  const list = [];

  roles.forEach((role) => {
    // The typeof check is to see if the role is a boolean (i.e. 'Admin') or an array (i.e. 'Moderator')
    if (
      (typeof user[`is${role}`] === 'boolean' && user[`is${role}`]) ||
      (typeof user[`is${role}`] === 'object' &&
        selectedEvent &&
        user[`is${role}`].includes(selectedEvent.eventId))
    ) {
      list.push(role);
    }
  });

  return list.join(', ');
};

function MainProfile({
  user,
  handleLogout,
  setEditingProfileInfo,
  setShowUserProfileDialog,
  userProfileDialogRef,
  handleDialog,
  loggingOutUser
}) {
  const { selectedEvent, dialogAnimationTime } = useContext(LocalContext);
  return (
    <Container transition={{ duration: dialogAnimationTime }}>
      <Button
        color="primary"
        variant="outlined"
        onClick={() => setEditingProfileInfo(true)}
        style={{
          padding: '0 1rem'
        }}>
        Edit Profile Info
      </Button>
      <Title>
        <Roles>{listRoles({ user, selectedEvent })}</Roles>
        <motion.h3>{user.name}</motion.h3>
        <motion.p>
          {user.profession}, {user.company}
        </motion.p>
      </Title>
      <Icons>
        {user.socials.facebook && (
          <IconLink href={user.socials.facebook} target="_blank" rel="noreferrer">
            <FacebookIcon />
          </IconLink>
        )}
        {user.socials.linkedIn && (
          <IconLink href={user.socials.linkedIn} target="_blank" rel="noreferrer">
            <LinkedinIcon />
          </IconLink>
        )}
        {user.socials.twitter && (
          <IconLink
            href={`https://www.twitter.com/${user.socials.twitter.replace('@', '')}`}
            target="_blank"
            rel="noreferrer">
            <TwitterIcon />
          </IconLink>
        )}
        {user.socials.instagram && (
          <IconLink
            href={`https://www.instagram.com/${user.socials.instagram.replace('@', '')}`}
            target="_blank"
            rel="noreferrer">
            <InstagramIcon />
          </IconLink>
        )}
      </Icons>
      <Actions>
        {selectedEvent && (
          <AnimatePresence>
            {(user.isAdmin || user.isModerator.includes(selectedEvent.eventId)) && (
              <Link to={`/events/${selectedEvent.slug}/event-analytics`}>
                <Button
                  color="quaternary"
                  onClick={() =>
                    handleDialog({
                      dialogRef: userProfileDialogRef,
                      animation: 'close',
                      stateHandler: () => setShowUserProfileDialog(false)
                    })
                  }>
                  Event Analytics
                </Button>
              </Link>
            )}
          </AnimatePresence>
        )}
        <Button type="button" onClick={() => handleLogout()} loading={loggingOutUser} loadingButton>
          Logout
        </Button>
      </Actions>
    </Container>
  );
}

const Container = styled(motion.div).attrs({
  initial: false,
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  align-items: center;
  color: ${({ theme }) => theme.primary};
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  justify-content: center;
  margin-bottom: 2rem;
  width: 100%;
`;

const Title = styled(motion.div)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Roles = styled(motion.div)`
  align-items: center;
  color: ${({ theme }) =>
    theme.className === 'contrast'
      ? theme.primary
      : theme.className === 'grayscale'
        ? theme.primary
        : '#ff9559'};
  display: flex;
  font-size: 0.75rem;
  gap: 1rem;
  justify-content: center;
`;

const Icons = styled(motion.div)`
  align-items: center;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
`;

const IconLink = styled(motion.a)`
  border-radius: 50%;
  height: 24px;
  width: 24px;
  svg {
    height: 100%;
    width: 100%;
    circle {
      fill: ${({ theme }) => theme.primary};
    }
    path {
      fill: ${({ theme }) => theme.contrast};
    }
  }
`;

const Actions = styled(motion.div)`
  align-items: center;
  display: flex;
  gap: 0.75rem;
  justify-content: center;

  > * {
    white-space: nowrap;
  }
`;

export default MainProfile;
