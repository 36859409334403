import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'components';
import { FirebaseContext } from 'context';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

const isZeroDecimalCurrency = (amount, currency) => {
  const numberFormat = new Intl.NumberFormat(['en-US'], {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol'
  });
  const parts = numberFormat.formatToParts(amount);
  let zeroDecimalCurrency = true;
  // eslint-disable-next-line no-restricted-syntax
  for (const part of parts) {
    if (part.type === 'decimal') {
      zeroDecimalCurrency = false;
    }
  }
  return zeroDecimalCurrency;
};

const formatAmountForStripe = (amount, currency) =>
  isZeroDecimalCurrency(amount, currency) ? amount : Math.round(amount * 100);

const formatAmount = (amount, currency = 'EUR') =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency
  }).format(amount);

function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const { user, firebase } = useContext(FirebaseContext);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [customAmount, setCustomAmount] = useState(5);
  const [formValues, setFormValues] = useState({
    currency: 'eur',
    cardholderName: ''
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        name: formValues.cardholderName,
        email: user.email
      }
    });

    await firebase.stripe
      .createPayment({
        uid: user.uid,
        email: user.email,
        amount: formatAmountForStripe(customAmount, formValues.currency),
        currency: formValues.currency,
        paymentMethod
      })
      .then((result) => {
        // eslint-disable-next-line no-unused-expressions
        if (result.data.status === 'succeeded') {
          setSuccess(true);
        } else {
          setFailure(true);
        }
        setFormValues({ cardholderName: '' });
        elements.getElement(CardElement).clear();
        const timer = setTimeout(() => {
          setSuccess(false);
        }, 5000);

        return () => {
          clearTimeout(timer);
        };
      });
  };

  const handleAmount = (event) => {
    event.persist();
    setCustomAmount(event.target.value);
  };

  const handleInput = (event) => {
    event.persist();
    setFormValues((currentValues) => ({
      ...currentValues,
      [event.target.name]: event.target.value
    }));
  };

  const emptyCurrency = formatAmount(0, formValues.currency);
  return (
    <Container onSubmit={handleSubmit}>
      <CurrencyOptions>
        <label>
          Currency:
          <select name="currency" onChange={handleInput}>
            <option value="eur">EUR</option>
            <option value="usd">USD</option>
            <option value="gbp">GBP</option>
            <option value="jpy">JPY</option>
          </select>
        </label>
      </CurrencyOptions>
      <Options>
        <button
          type="button"
          onClick={() => setCustomAmount(5)}
          style={{
            border: customAmount === 5 ? '3px solid #004d9a' : '3px solid transparent'
          }}>
          {formatAmount(5, formValues.currency)}
        </button>
        <button
          type="button"
          onClick={() => setCustomAmount(10)}
          style={{
            border: customAmount === 10 ? '3px solid #004d9a' : '3px solid transparent'
          }}>
          {formatAmount(10, formValues.currency)}
        </button>
        <button
          type="button"
          onClick={() => setCustomAmount(25)}
          style={{
            border: customAmount === 25 ? '3px solid #004d9a' : '3px solid transparent'
          }}>
          {formatAmount(25, formValues.currency)}
        </button>
        <span>
          {formValues.currency === 'jpy'
            ? emptyCurrency.substring(0, emptyCurrency.length - 1)
            : emptyCurrency.substring(0, emptyCurrency.length - 4)}
          <input type="number" name="amount" placeholder="?" onChange={handleAmount} />
        </span>
      </Options>
      <Statement>Donate {formatAmount(customAmount, formValues.currency)}</Statement>
      <CardholderInput
        type="text"
        name="cardholderName"
        placeholder="Cardholder's name"
        onChange={handleInput}
        value={formValues.cardholderName}
      />
      <CardElement
        options={{
          style: {
            base: {
              iconColor: '#87BBFD',
              color: '#004D9A',
              fontWeight: '500',
              fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
              fontSize: '16px',
              fontSmoothing: 'antialiased',
              ':-webkit-autofill': {
                color: '#fce883'
              },
              '::placeholder': {
                color: '#87BBFD'
              }
            },
            invalid: {
              iconColor: '#FFC7EE',
              color: '#FFC7EE'
            }
          },
          hidePostalCode: true
        }}
      />
      {success && (
        <p style={{ width: '100%', textAlign: 'center' }}>
          Thank you for your donation {user.name}.
        </p>
      )}
      {failure && (
        <p style={{ width: '100%', textAlign: 'center' }}>
          Sorry {user.name}, we weren&apos;t able to process your donation.
        </p>
      )}
      <Button type="submit" disabled={!stripe || !elements}>
        Pay
      </Button>
    </Container>
  );
}

const CardholderInput = styled.input`
  background: transparent;
  border: none;
  color: #004d9a;
  font-family:
    Roboto,
    Open Sans,
    Segoe UI,
    sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 1rem;
  margin-left: 1.75rem;
  outline: none;
  &::placeholder {
    color: #87bbfd;
  }
`;

const Container = styled.form`
  background: white;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1rem;
  width: 600px;
  p {
    margin: 2rem 0 0.75rem 0;
  }
`;

const CurrencyOptions = styled.div`
  align-items: center;
  color: #004d9a;
  display: flex;
  font-size: 24px;
  margin: 1rem 0;
  select {
    border: none;
    color: #004d9a;
    font-size: 20px;
    margin-left: 1rem;
    outline: none;
  }
`;

const Options = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  button,
  span,
  input {
    background: none;
    border: none;
    color: #004d9a;
    font-size: 20px;
    outline: none;
    cursor: pointer;
  }
  button {
    border-radius: 2px;
    padding: 0.625rem;
  }
  input {
    padding-left: 0;
    width: 40px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
  span {
    border: 3px solid #87bbfd;
    border-radius: 2px;
    padding: 0.625rem;
  }
`;

const Statement = styled.h4`
  color: #004d9a;
  font-size: 24px;
  margin: 1rem 0;
`;

export default CheckoutForm;
