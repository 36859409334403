import { arrayUnion, doc, getDoc, updateDoc } from 'firebase/firestore';

function DownloadsFactory(firebase) {
  const downloadFile = (fileName) => getDoc(doc(firebase.fsdb, 'downloads', fileName));

  const trackDownloads = async ({ uid, filename }) => {
    updateDoc(doc(firebase.fsdb, 'users', uid), {
      downloads: arrayUnion(filename)
    });
  };

  return {
    downloadFile,
    trackDownloads
  };
}

export default DownloadsFactory;
