import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import {
  AnswerPollModal,
  WordPollModal,
  FeedbackAnswerModal,
  LoadingSpinner,
  GeneralAnalyticsModal,
  FeedbackAnalyticsModal,
  WordCloudAnalyticsModal
} from 'components';
import { FirebaseContext, LocalContext } from 'context';
import { fadeInAndOutVariants } from 'styles';

function UserPanel({ currentlyOpenPoll, setCurrentlyOpenPoll }) {
  const { firebase } = useContext(FirebaseContext);
  const { selectedEvent, browserTabIsActive } = useContext(LocalContext);
  const [arePollsLoading, setArePollsLoading] = useState(true);
  const [isAnswerPollModalOpen, setIsAnswerPollModalOpen] = useState(false);
  const [isAnalyticsModalOpen, setIsAnalyticsModalOpen] = useState(false);
  const [
    pollAnalyticsBeingCurrentlySharedWithUsers,
    setPollAnalyticsBeingCurrentlySharedWithUsers
  ] = useState(false);
  const [serverTimeOffset, setServerTimeOffset] = useState(null);

  const showNoOpenPollsMessage = () => {
    setCurrentlyOpenPoll(null);
    setIsAnswerPollModalOpen(false);
    setIsAnalyticsModalOpen(false);
    setPollAnalyticsBeingCurrentlySharedWithUsers(null);
    setArePollsLoading(false);
  };

  useEffect(() => {
    let unsubscribeFromServerTimeOffset;
    let unsubscribeFromPolls;

    if (firebase && browserTabIsActive) {
      unsubscribeFromServerTimeOffset = firebase.interaction.polls.subscribeToServerTimeOffset({
        snapshot: (snapshot) => {
          if (snapshot.exists()) {
            setServerTimeOffset(snapshot.val());
          }
        }
      });
      unsubscribeFromPolls = firebase.interaction.polls.subscribeToPolls({
        eventId: selectedEvent.eventId,
        snapshot: (snapshot) => {
          if (snapshot.exists()) {
            const polls = [];

            snapshot.forEach((child) => {
              polls.push({
                pollId: child.key,
                ...child.val()
              });
            });

            const _currentlyOpenPoll = polls.find((poll) => poll.isOpen);
            const _pollAnalyticsBeingCurrentlySharedWithUsers = polls.find(
              (poll) => poll.shareResults
            );

            if (_currentlyOpenPoll) {
              setCurrentlyOpenPoll(_currentlyOpenPoll);
              setIsAnswerPollModalOpen(true);
              setArePollsLoading(false);
            } else {
              showNoOpenPollsMessage();
            }

            if (_pollAnalyticsBeingCurrentlySharedWithUsers) {
              setPollAnalyticsBeingCurrentlySharedWithUsers(
                _pollAnalyticsBeingCurrentlySharedWithUsers
              );
              setIsAnalyticsModalOpen(true);
              setArePollsLoading(false);
            } else {
              setIsAnalyticsModalOpen(false);
              setPollAnalyticsBeingCurrentlySharedWithUsers(null);
            }
          } else if (!snapshot.exists()) {
            showNoOpenPollsMessage();
          }
        }
      });
    }
    return () => {
      if (unsubscribeFromServerTimeOffset) {
        unsubscribeFromServerTimeOffset();
      }
      if (unsubscribeFromPolls) {
        unsubscribeFromPolls();
      }
    };
  }, [firebase, browserTabIsActive]);

  return (
    <>
      <AnimatePresence>
        {!arePollsLoading && !isAnswerPollModalOpen && (
          <motion.p
            style={{ fontStyle: 'italic', fontWeight: '300' }}
            initial="initial"
            animate="animate"
            exit="exit"
            variants={fadeInAndOutVariants()}>
            No Open Polls At The Moment
          </motion.p>
        )}
      </AnimatePresence>
      {arePollsLoading && (
        <LoadingSpinnerWrapper>
          <LoadingSpinner style={{ width: '6rem', color: selectedEvent.colors.secondary }} />
        </LoadingSpinnerWrapper>
      )}
      <AnimatePresence>
        {isAnswerPollModalOpen && currentlyOpenPoll?.type === 'general poll' && (
          <AnswerPollModal
            poll={currentlyOpenPoll}
            isAnswerPollModalOpen={isAnswerPollModalOpen}
            setIsAnswerPollModalOpen={setIsAnswerPollModalOpen}
            serverTimeOffset={serverTimeOffset}
          />
        )}
        {isAnswerPollModalOpen && currentlyOpenPoll?.type === 'word cloud' && (
          <WordPollModal
            poll={currentlyOpenPoll}
            isAnswerPollModalOpen={isAnswerPollModalOpen}
            setIsAnswerPollModalOpen={setIsAnswerPollModalOpen}
            serverTimeOffset={serverTimeOffset}
          />
        )}
        {isAnswerPollModalOpen && currentlyOpenPoll?.type === 'feedback poll' && (
          <FeedbackAnswerModal
            poll={currentlyOpenPoll}
            isAnswerPollModalOpen={isAnswerPollModalOpen}
            setIsAnswerPollModalOpen={setIsAnswerPollModalOpen}
            serverTimeOffset={serverTimeOffset}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isAnalyticsModalOpen &&
          pollAnalyticsBeingCurrentlySharedWithUsers?.type === 'general poll' && (
            <GeneralAnalyticsModal
              poll={pollAnalyticsBeingCurrentlySharedWithUsers}
              setIsAnalyticsModalOpen={setIsAnalyticsModalOpen}
            />
          )}
      </AnimatePresence>
      <AnimatePresence>
        {isAnalyticsModalOpen &&
          pollAnalyticsBeingCurrentlySharedWithUsers?.type === 'feedback poll' && (
            <FeedbackAnalyticsModal
              poll={pollAnalyticsBeingCurrentlySharedWithUsers}
              setIsAnalyticsModalOpen={setIsAnalyticsModalOpen}
            />
          )}
      </AnimatePresence>
      <AnimatePresence>
        {isAnalyticsModalOpen &&
          pollAnalyticsBeingCurrentlySharedWithUsers?.type === 'word cloud' && (
            <WordCloudAnalyticsModal
              poll={pollAnalyticsBeingCurrentlySharedWithUsers}
              setIsAnalyticsModalOpen={setIsAnalyticsModalOpen}
            />
          )}
      </AnimatePresence>
    </>
  );
}

const LoadingSpinnerWrapper = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  > svg {
    margin-bottom: 0.9rem;
    @media screen and (min-width: 410px) {
      margin-bottom: 1.125rem;
    }
    @media screen and (min-width: 1150px) and (max-width: 1365px) {
      margin-bottom: 0.9rem;
    }
    @media screen and (min-width: 1366px) {
      margin-bottom: 1.125rem;
    }
  }
`;

export default UserPanel;
