import * as React from 'react';

function SvgStrand(props) {
  return (
    <svg viewBox="0 0 435 543" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M289.976 46.01c-2.675-.926-5.744-.593-8.311 1.11-4.284 2.954-5.447 8.782-2.634 13.096 59.583 89.718 82.689 158.083 70.547 209.011-12.691 53.331-63.741 82.962-113.063 111.597-22.553 13.078-45.91 26.625-65.188 42.283-10.612 8.638-19.22 17.173-26.305 26.135-7.993 10.144-14.027 21.015-17.994 32.303-1.734 4.933.808 10.299 5.677 11.985 4.824 1.67 10.178-.963 11.911-5.896 14.087-40.085 56.348-64.585 101.038-90.536 25.77-14.955 52.405-30.409 74.373-49.522 25.52-22.22 41.128-46.476 47.723-74.201 3.154-13.314 4.252-27.639 3.289-42.677-1.023-15.708-4.346-32.71-9.875-50.575-5.736-18.536-14.104-38.681-24.823-59.838-11.129-21.948-25.151-45.745-41.77-70.69-1.113-1.731-2.796-2.962-4.595-3.585Z"
        fill="#004D9A"
      />
      <path
        d="M289.157 45.718c-2.015-.698-4.28-.686-6.444.158-4.803 1.923-7.249 7.352-5.42 12.221 20.783 55.032 22.249 96.494 4.572 130.473-15.705 30.18-44.365 50.787-74.697 72.657-30.839 22.194-62.722 45.121-81.084 79.511-10.052 18.836-15.119 39.151-15.519 62.13-.428 25.16 4.755 52.814 15.945 84.493a9.182 9.182 0 0 0 11.903 5.571c4.859-1.803 7.394-7.202 5.668-12.085-19.206-54.486-19.701-97.454-1.614-131.342 16.283-30.427 44.975-51.122 75.395-72.963 30.838-22.193 62.721-45.121 80.492-79.367 9.727-18.749 14.425-38.992 14.293-61.857-.103-25.046-5.98-52.542-17.909-84.078-.96-2.724-3.083-4.656-5.581-5.522Z"
        fill="#2D9CDB"
      />
      <path
        d="M288.882 45.692a9.191 9.191 0 0 0-8.503 1.283c-30.708 22.17-54.215 43.549-71.958 65.434-16.175 19.939-27.194 39.874-33.634 60.899-5.404 17.649-7.613 36.406-6.749 57.326.755 18.291 3.788 36.526 6.701 54.122 9.903 60.114 19.255 116.899-46.581 193.024-3.399 3.95-3.032 9.905.839 13.29s9.772 2.942 13.171-1.009c17.082-19.724 29.952-38.917 39.395-58.701 8.477-17.729 13.94-35.407 16.661-54.086 5.058-34.305-.142-65.544-5.097-95.801-11.755-71.216-21.881-132.653 97.998-219.215 4.204-3.024 5.247-8.938 2.259-13.162-1.186-1.656-2.751-2.797-4.502-3.404Z"
        fill="#36D1B7"
      />
      <path
        d="M288.639 45.272c-1.842-.638-3.886-.698-5.93-.06-51.217 16.852-90.424 34.374-119.819 53.65-27.547 18.071-46.097 37.415-56.733 59.15-5.387 10.993-8.716 22.848-9.966 35.225-1.135 11.07-.648 22.702 1.435 35.534 3.77 23.285 12.162 46.576 21.056 71.237 20.437 56.619 41.556 115.219 7.637 179.429-2.463 4.629-.723 10.315 3.807 12.682 4.558 2.425 10.216.597 12.62-4.003 37.838-71.724 14.28-137.1-6.563-194.806-17.188-47.669-32.05-88.8-13.309-127.166 9.071-18.539 25.423-35.404 50.015-51.509 28.082-18.335 65.818-35.17 115.42-51.485 4.948-1.626 7.645-6.922 6.14-11.879-.942-2.918-3.136-5.073-5.81-6Z"
        fill="#36A9E1"
      />
    </svg>
  );
}
export default SvgStrand;
