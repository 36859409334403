import React, { useContext, useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { Button, SubmittedQuestion, ConfirmDelete, Dialog } from 'components';
import { SelectQuestionCheckbox } from 'components/Events/Livestream/Interaction/QAndA/SubmittedQuestion';
import { LocalContext, FirebaseContext } from 'context';

function QAndA({
  eventId,
  questionCurrentlyBeingAnsweredLive,
  submittedQuestions,
  textArea,
  setTextArea,
  submitAnonymousQuestion,
  setSubmitAnonymousQuestion,
  selectedQuestion,
  setSelectedQuestion,
  editQuestion,
  setEditQuestion
}) {
  const {
    selectedEvent,
    handleDialog,
    confirmDeletePollOrQuestionDialogRef,
    showConfirmDeleteDialog,
    setShowConfirmDeleteDialog
  } = useContext(LocalContext);
  const [userQuestions, setUserQuestions] = useState([]);
  const [questionToBeDeleted, setQuestionToBeDeleted] = useState(null);

  const { colors } = selectedEvent;
  const { firebase, user } = useContext(FirebaseContext);

  const submittedQuestionsRef = useRef();

  useEffect(() => {
    if (submittedQuestions.length > 1) {
      const _userQuestions = submittedQuestions.reduce((accumulator, currentValue) => {
        if (currentValue.uid === user.uid) {
          return [currentValue, ...accumulator];
        }
        return accumulator;
      }, []);
      setUserQuestions(_userQuestions);
    }
  }, [submittedQuestions]);

  const handleTextAreaChange = (e) => {
    e.persist();
    if (e.target.value !== ' ') {
      setTextArea(e.target.value);
    }
  };

  const handleSubmitNewQuestion = (e) => {
    e.preventDefault();
    if (textArea !== '' || textArea !== ' ') {
      if (editQuestion.edit) {
        if (textArea === editQuestion.question.text) {
          setTextArea('');
          setEditQuestion({
            edit: false,
            question: {
              uid: '',
              questionId: '',
              text: ''
            }
          });
        } else {
          firebase.interaction.qAndA
            .editQuestion({
              uid: user.uid,
              questionId: editQuestion.question.questionId,
              text: textArea
            })
            .then(() => {
              setTextArea('');
              setEditQuestion({
                edit: false,
                question: {
                  uid: '',
                  questionId: '',
                  text: ''
                }
              });
            });
        }
      } else {
        firebase.interaction.qAndA
          .submitNewQuestion({
            uid: user.uid,
            eventId,
            text: textArea,
            name: submitAnonymousQuestion ? '' : user.name
          })
          .then(() => {
            setTextArea('');
            setSubmitAnonymousQuestion(false);
          });
      }
    }
  };

  const handleAnswerQuestionLive = () => {
    if (questionCurrentlyBeingAnsweredLive === selectedQuestion?.text) {
      firebase.interaction.qAndA.stopShowingAnswerLiveOverlay({ eventId });
      setSelectedQuestion(null);
    } else {
      firebase.interaction.qAndA.answerThisQuestionLive({
        eventId,
        text: selectedQuestion.text
      });
    }
  };

  const handleSendAnswer = () => {
    if (textArea !== '' || textArea !== ' ') {
      firebase.interaction.qAndA
        .submitAnswer({
          text: textArea,
          eventId,
          questionId: selectedQuestion.questionId,
          uid: selectedQuestion.uid
        })
        .then(() => {
          setTextArea('');
          setSelectedQuestion(null);
        });
    }
  };

  const handleDeleteQuestion = (e) => {
    e.stopPropagation();
    const { questionId, uid } = questionToBeDeleted;
    if (firebase) {
      firebase.interaction.qAndA.deleteUserQuestion({ uid, questionId }).then(() =>
        handleDialog({
          dialogRef: confirmDeletePollOrQuestionDialogRef,
          animation: 'close',
          stateHandler: () => setShowConfirmDeleteDialog(false),
          callback: () => {
            setQuestionToBeDeleted(null);
            setSelectedQuestion(null);
          }
        })
      );
    }
  };

  const handleDismissDeleteQuestion = (e) => {
    e.stopPropagation();
    handleDialog({
      dialogRef: confirmDeletePollOrQuestionDialogRef,
      animation: 'dismiss',
      stateHandler: () => setShowConfirmDeleteDialog(false),
      callback: () => setQuestionToBeDeleted(null)
    });
  };

  return (
    <Wrapper colors={colors} isModerator={user?.isModerator?.includes(eventId)}>
      {user?.isModerator?.includes(eventId) ? (
        <p>Moderator&apos;s Control Panel</p>
      ) : (
        <p>
          Here you can submit questions,
          <br />
          which will be discussed during the livestream.
        </p>
      )}
      <div>
        <SubmittedQuestions
          ref={submittedQuestionsRef}
          submittedQuestions={submittedQuestions}
          colors={colors}>
          <AnimatePresence initial={false}>
            {submittedQuestions?.map((submittedQuestion, index) => (
              <SubmittedQuestion
                key={submittedQuestion.questionId}
                eventId={eventId}
                submittedQuestion={submittedQuestion}
                selectedQuestion={selectedQuestion}
                setSelectedQuestion={setSelectedQuestion}
                submittedQuestionsRef={submittedQuestionsRef}
                questionToBeDeleted={questionToBeDeleted}
                setQuestionToBeDeleted={setQuestionToBeDeleted}
                editQuestion={editQuestion}
                setEditQuestion={setEditQuestion}
                setTextArea={setTextArea}
                index={index}
                userQuestions={userQuestions}
                colors={colors}
              />
            ))}
          </AnimatePresence>
          <Dialog
            ref={confirmDeletePollOrQuestionDialogRef}
            isVisible={showConfirmDeleteDialog}
            onDismiss={handleDismissDeleteQuestion}
            contentStyle={{
              maxWidth: '23.75rem'
            }}>
            <ConfirmDelete
              handleDelete={handleDeleteQuestion}
              handleDismiss={handleDismissDeleteQuestion}
              type="question"
            />
          </Dialog>
        </SubmittedQuestions>
        {user?.isModerator?.includes(eventId) ? (
          <AnswerQuestion colors={colors}>
            <TextArea
              id="answer"
              name="answer"
              colors={colors}
              value={textArea}
              disabled={!selectedQuestion}
              onChange={handleTextAreaChange}
              placeholder="Select a question then type your answer here to send an answer privately to the user, or click 'Answer Live' to answer it publically during the livestream."
            />
            <div>
              <Button
                onClick={handleAnswerQuestionLive}
                disabled={!selectedQuestion}
                variant={
                  questionCurrentlyBeingAnsweredLive === selectedQuestion?.text
                    ? 'contained'
                    : 'outlined'
                }
                color={
                  questionCurrentlyBeingAnsweredLive === selectedQuestion?.text
                    ? 'tertiary'
                    : 'secondary'
                }>
                {questionCurrentlyBeingAnsweredLive === selectedQuestion?.text
                  ? 'Stop Showing'
                  : 'Answer Live'}
              </Button>
              <Button
                onClick={handleSendAnswer}
                disabled={!textArea || !selectedQuestion}
                color="secondary">
                Send Answer
              </Button>
            </div>
          </AnswerQuestion>
        ) : (
          <SubmitNewQuestion onSubmit={handleSubmitNewQuestion} colors={colors}>
            <TextArea
              id="question"
              name="question"
              colors={colors}
              value={textArea}
              onChange={handleTextAreaChange}
              placeholder="Type your question..."
            />
            <AnimatePresence>
              <div>
                {!editQuestion.edit ? (
                  <motion.label
                    htmlFor="anonymousCheckbox"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 0.2 } }}>
                    <AnonymousCheckbox
                      checked={submitAnonymousQuestion}
                      id="anonymousCheckbox"
                      name="anonymousCheckbox"
                      onChange={(e) => setSubmitAnonymousQuestion(e.target.checked)}
                      colors={colors}
                      type="checkbox"
                    />
                    Submit Anonymously
                  </motion.label>
                ) : (
                  <div />
                )}
                <Button type="submit" disabled={!textArea} style={{ whiteSpace: 'nowrap' }}>
                  {editQuestion.edit ? 'Submit Edit' : 'Submit Question'}
                </Button>
              </div>
            </AnimatePresence>
          </SubmitNewQuestion>
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  background-color: #fff;
  padding: 0.75rem 0.75rem 0.5rem;

  @media screen and (min-width: 410px) {
    padding: 1.275rem 1.0125rem 0.95rem;
  }

  @media screen and (min-width: 1150px) and (max-width: 1365px) {
    padding: 0.75rem 0.75rem 0.5rem;
  }

  @media screen and (min-width: 1366px) {
    padding: 1.275rem 1.0125rem 1.15rem;
  }

  > p {
    color: ${({ colors }) => colors.secondary};
    font-size: 0.875rem;
    font-style: ${({ isModerator }) => (isModerator ? 'normal' : 'italic')};
    font-weight: ${({ isModerator }) => (isModerator ? 600 : 300)};
    line-height: 1.25em;
    margin: 0.5em 0 1em;
    text-align: center;
    ${({ isModerator }) => isModerator && `transform: scale3d(1.125, 1.125, 1.125)`}

    @media screen and (min-width: 410px) {
      font-size: 1rem;
      margin: 0.063em 0 1.25em;
    }

    @media screen and (min-width: 1150px) and (max-width: 1365px) {
      font-size: 0.875rem;
      margin: 0.5em 0 1em;
    }

    @media screen and (min-width: 1366px) {
      font-size: 1rem;
      margin: 0.063em 0 1.25em;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    height: calc(100% - 2.6rem);
    justify-content: space-between;

    @media screen and (min-width: 410px) {
      height: calc(100% - 2.15rem);
    }

    @media screen and (min-width: 1150px) and (max-width: 1365px) {
      height: calc(100% - 2.65rem);
    }

    @media screen and (min-width: 1366px) {
      height: calc(100% - 2.2rem);
    }
  }
`;

const SubmittedQuestions = styled.div`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  ${({ submittedQuestions }) =>
    submittedQuestions &&
    submittedQuestions.length > 0 &&
    css`
      ::-webkit-scrollbar {
        width: 0.5rem;
      }

      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 0.31rem grey;
        border-radius: 0.625rem;
      }

      ::-webkit-scrollbar-thumb {
        background-color: ${({ colors }) => colors.secondary};
        border-radius: 0.625rem;
      }
    `}
`;

const TextArea = styled.textarea`
  border: 0.063em solid ${({ colors }) => colors.secondary};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'text')};
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  height: 6.25em;
  margin-top: 0.5em;
  outline-color: ${({ colors }) => colors.secondary};
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0.625em;
  resize: none;
  width: 100%;

  &::placeholder {
    color: #c4c4c4;
    font-size: 1rem;
  }

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.31rem grey;
    border-radius: 0.625rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ colors }) => colors.secondary};
    border-radius: 0.625rem;
  }
`;

const AnswerQuestion = styled.div`
  border-top: 0.063rem solid #bdbdbd;

  > div {
    display: flex;
    justify-content: space-between;
    margin-top: 9px;

    button {
      border-radius: 0.125rem;
      max-width: 11.875rem;
    }
  }
`;

const AnonymousCheckbox = styled(SelectQuestionCheckbox)`
  border: 1px solid #bdbdbd;
  height: 1rem;
  margin-right: 0.375rem;
  margin-top: unset;
  position: relative;
  width: 1rem;

  &:focus {
    border: 2px solid ${({ colors }) => colors.secondary};
    margin-right: 0.375rem;
  }

  &:checked {
    background-color: ${({ colors }) => colors.secondary};
    border: 2px solid ${({ colors }) => colors.secondary};
    margin-right: 0.375rem;
  }

  &:checked:before {
    color: ${({ contrast }) => (contrast ? `#000` : `#fff`)};
    font-size: 0.8rem;
    height: 0.8rem;
    width: 0.8rem;
  }
`;

const SubmitNewQuestion = styled.form`
  border-top: 0.063rem solid #bdbdbd;

  > div {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0.438rem 0 1rem;

    button {
      border-radius: 0.125rem;
      max-width: 11.875rem;
    }

    label {
      align-items: center;
      color: #c4c4c4;
      display: flex;
      font-size: 0.875rem;
    }

    @media screen and (min-width: 500px) and (max-width: 1300px) {
      label {
        margin-right: 1em;
      }
    }
  }
`;

export default QAndA;
