import * as React from 'react';

function SvgPlus(props) {
  return (
    <svg viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M5 10V0M0 5h10" stroke="#fff" strokeWidth={2} />
    </svg>
  );
}
export default SvgPlus;
