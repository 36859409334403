import * as React from 'react';

function SvgAgencyxLogo(props) {
  return (
    <svg viewBox="0 0 278 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M53.945 0c5.045 0 8.709 2.052 11.499 5.272L63.47 6.696c-2.142-2.593-5.637-4.302-9.526-4.302-7.187 0-12.74 5.5-12.74 13.563 0 7.979 5.553 13.62 12.74 13.62 4.086 0 7.3-2.05 8.99-3.76v-7.324H50.281l1.043-2.422h14.204v10.8C62.766 29.92 58.736 32 53.945 32c-8.625 0-15.502-6.497-15.502-16.043C38.443 6.44 45.32 0 53.945 0ZM118.769 4.73v26.671h-2.622V.513h2.65l19.165 26.3V.514h2.621V31.4h-2.565L118.769 4.73ZM155.549 15.986C155.549 6.496 162.37 0 170.938 0c4.96 0 8.624 2.166 11.217 5.471l-2.198 1.425c-1.917-2.679-5.271-4.502-9.019-4.502-7.103 0-12.655 5.5-12.655 13.563 0 7.979 5.552 13.564 12.655 13.564 3.748 0 7.102-1.795 9.019-4.502l2.198 1.396c-2.705 3.39-6.285 5.5-11.217 5.5-8.568.056-15.389-6.44-15.389-15.93ZM204.393 18.208 192.245.513h3.157L205.717 15.7 216.033.513h3.156l-12.147 17.695v13.193h-2.649V18.208ZM15.755.513h-3.241L0 31.4h2.931l11.19-28.295L21.447 21.4h-9.075l-.986 2.422h10.879l3.072 7.58h2.93L15.756.511ZM100.984.513H80.579v2.422h20.405V.513ZM95.742 13.849H80.579v2.393h14.12l1.043-2.393ZM100.984 29.008H80.579v2.422h20.405v-2.422Z"
        fill="#000"
      />
      <path d="M260.395 17.382 249.431 31.46h3.297l9.273-12.168-1.606-1.909Z" fill="#CE9200" />
      <path
        d="M265.045 15.53 276.657.513h-3.298L263.41 13.62 253.518.513h-3.354l23.928 30.889h3.241L265.045 15.53Z"
        fill="#000"
      />
    </svg>
  );
}
export default SvgAgencyxLogo;
