import React, { useState, useContext, useEffect } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { Button, ErrorMessage, LoadingSpinner, DialogCloseIcon } from 'components';
import { DefaultAvatar } from 'assets/dynamic-svgs';
import { LocalContext, FirebaseContext } from 'context';
import { AnimatePresence, motion } from 'framer-motion';
import { useLocation } from '@reach/router';
import { defaultColors, fadeInAndOutAndAnimateHeightVariants } from 'styles';
import MainProfile from './MainProfile';
import EditProfile from './EditProfile';

function UserProfile({ user, userProfileDialogRef, setShowUserProfileDialog }) {
  const {
    selectedEvent,
    setShowUserAccountDeletedDialog,
    theme,
    siteName,
    dialogAnimationTime,
    handleDialog
  } = useContext(LocalContext);
  const { firebase } = useContext(FirebaseContext);
  const [avatar, setAvatar] = useState(user?.avatarUrl || '');
  const { pathname } = useLocation();
  const [savingAvatar, setSavingAvatar] = useState(false);
  const [editingProfileInfo, setEditingProfileInfo] = useState(false);
  const [deletingUserAccount, setDeletingUserAccount] = useState(false);
  const [loggingOutUser, setLoggingOutUser] = useState(false);
  const [requestAccountDeletionConfirmation, setRequestAccountDeletionConfirmation] =
    useState(false);
  const [error, setError] = useState({
    code: '',
    message: ''
  });

  let isMounted = true;

  useEffect(
    () => () => {
      isMounted = false;
    },
    []
  );

  const [saveStates, setSaveStates] = useState({
    avatar: null,
    name: null,
    email: null,
    profession: null,
    company: null,
    socials: {
      facebook: null,
      twitter: null,
      linkedIn: null,
      instagram: null
    }
  });

  const handleLogout = async () => {
    try {
      setLoggingOutUser(true);
      await firebase.userProfile.logout(user);
      handleDialog({
        dialogRef: userProfileDialogRef,
        animation: 'close',
        stateHandler: () => setShowUserProfileDialog(false),
        callback: async () => {
          if (pathname !== '/') {
            await navigate('/');
          }
          setLoggingOutUser(false);
        }
      });
    } catch (_error) {
      if (isMounted) {
        console.error(_error.message);
      }
      setLoggingOutUser(false);
    }
  };

  const handleAccountDeletion = async () => {
    try {
      setDeletingUserAccount(true);
      await firebase?.auth?.currentUser.delete();
      handleDialog({
        dialogRef: userProfileDialogRef,
        animation: 'close',
        stateHandler: () => setShowUserProfileDialog(false),
        callback: async () => {
          if (pathname !== '/') {
            await navigate('/');
          }
          setDeletingUserAccount(false);
          setTimeout(() => setShowUserAccountDeletedDialog(true), dialogAnimationTime);
        }
      });
    } catch (err) {
      console.error(err);
      switch (err.code) {
        case 'auth/requires-recent-login':
          setError({
            code: err.code,
            message:
              'Deleting your account is a sensitive operation and requires recent authentication. Please log out and then log in again before retrying.'
          });
          break;
        default:
          setError({
            code: err.code,
            message: err.message
          });
          break;
      }
      setDeletingUserAccount(false);
    }
  };

  return (
    <Container
      editingProfileInfo={editingProfileInfo}
      colors={selectedEvent?.colors || defaultColors}
      requestAccountDeletionConfirmation={requestAccountDeletionConfirmation}>
      <DialogCloseIcon
        onClick={() =>
          handleDialog({
            dialogRef: userProfileDialogRef,
            animation: 'close',
            stateHandler: () => setShowUserProfileDialog(false)
          })
        }
        selectedEvent={selectedEvent}
        width="0.875em"
        style={{ top: '0.5rem', right: '0.5rem' }}
      />
      {requestAccountDeletionConfirmation ? (
        <>
          <p
            style={{
              fontSize: '1.25rem',
              fontWeight: 600,
              lineHeight: '1.75rem',
              marginBottom: '2.25rem',
              color: theme.primary,
              textAlign: 'center'
            }}>
            <br />
            Are you sure you want to delete
            <br />
            your {siteName} account?
          </p>
          <ErrorMessage
            errorMessage={error.message}
            style={{
              margin: '-0.75rem auto 2rem',
              maxWidth: '360px'
            }}
            variants={fadeInAndOutAndAnimateHeightVariants()}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '12px',
              width: '100%',
              margin: '0 auto',
              whiteSpace: 'nowrap'
            }}>
            <Button
              onClick={() => {
                if (error.message) {
                  setError({
                    code: '',
                    message: ''
                  });
                }
                setRequestAccountDeletionConfirmation(false);
              }}
              style={{
                height: '2.75rem',
                padding: '0 2rem'
              }}>
              Go Back
            </Button>
            {error.code === 'auth/requires-recent-login' ? (
              <Button
                onClick={() => handleLogout()}
                style={{
                  height: '2.75rem'
                }}>
                Logout
              </Button>
            ) : (
              <Button
                onClick={() => handleAccountDeletion()}
                loading={deletingUserAccount}
                loadingButton
                style={{
                  height: '2.75rem',
                  padding: '0 3rem'
                }}>
                Confirm Deletion
              </Button>
            )}
          </div>
        </>
      ) : (
        <>
          <ProfileImageWrapper>
            {savingAvatar ? (
              <AnimatePresence>
                {savingAvatar && (
                  <LoadingSpinner
                    style={{
                      width: '6rem',
                      color:
                        theme.className === 'contrast'
                          ? theme.primary
                          : theme.className === 'grayscale'
                            ? theme.secondary
                            : theme.tertiary
                    }}
                  />
                )}
              </AnimatePresence>
            ) : (
              <AnimatePresence>
                {avatar ? (
                  <ProfileImage
                    src={avatar}
                    alt={user?.name}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  />
                ) : (
                  <DefaultAvatar
                    width="70"
                    fill={
                      theme.className === 'contrast'
                        ? theme.primary
                        : theme.className === 'grayscale'
                          ? theme.secondary
                          : theme.tertiary
                    }
                  />
                )}
              </AnimatePresence>
            )}
          </ProfileImageWrapper>
          <AnimatePresence>
            {user &&
              (editingProfileInfo ? (
                <EditProfile
                  avatar={avatar}
                  setAvatar={setAvatar}
                  setEditingProfileInfo={setEditingProfileInfo}
                  error={error}
                  setError={setError}
                  savingAvatar={savingAvatar}
                  setSavingAvatar={setSavingAvatar}
                  saveStates={saveStates}
                  setSaveStates={setSaveStates}
                />
              ) : (
                <MainProfile
                  user={user}
                  handleLogout={handleLogout}
                  loggingOutUser={loggingOutUser}
                  setEditingProfileInfo={setEditingProfileInfo}
                  setShowUserProfileDialog={setShowUserProfileDialog}
                  userProfileDialogRef={userProfileDialogRef}
                  handleDialog={handleDialog}
                />
              ))}
          </AnimatePresence>
          {error.code === 'auth/requires-recent-login' && (
            <>
              <ErrorMessage
                errorMessage={error.message}
                style={{
                  color: '#fd5a45',
                  lineHeight: '1.25rem',
                  margin: user.isAdmin ? '-0.125rem auto 1.5rem' : '0.5rem auto 1.5rem'
                }}
                variants={fadeInAndOutAndAnimateHeightVariants()}
              />
              <Button onClick={() => handleLogout()}>Logout</Button>
            </>
          )}
          <DeleteAccount
            type="button"
            whileHover={{
              scale: 1.05
            }}
            whileTap={{
              scale: 0.95
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              fontSize: '0.825rem',
              marginBottom: '1.75em'
            }}
            onClick={() => {
              if (error.code) {
                setError({
                  code: '',
                  message: ''
                });
              }
              setRequestAccountDeletionConfirmation(true);
            }}>
            Delete Account
          </DeleteAccount>
        </>
      )}
    </Container>
  );
}

const ProfileImageWrapper = styled.div`
  align-items: center;
  align-self: center;
  background: ${({ theme }) => theme.contrast};
  border: 0.2rem solid
    ${({ theme }) => (theme.className === 'contrast' ? theme.primary : theme.tertiary)};
  border-radius: 50%;
  display: flex;
  height: 10rem;
  justify-content: center;
  margin-bottom: 1.75rem;
  margin-top: -7.5rem;
  overflow: hidden;
  width: 10rem;
`;

const ProfileImage = styled(motion.img)`
  background: transparent;
  filter: ${({ theme }) => (theme.className === 'grayscale' ? 'grayscale(100%)' : 'none')};
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

const Container = styled.div`
  background-color: ${({ theme }) => theme.contrast};
  border-radius: 2px;
  color: ${({ theme }) => (theme.className === 'contrast' ? theme.primary : theme.contrast)};
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  margin: ${({ editingProfileInfo, requestAccountDeletionConfirmation }) =>
    editingProfileInfo && !requestAccountDeletionConfirmation ? '18.125rem auto 0 auto' : '0 auto'};
  max-width: 100%;
  padding: 2.5rem 1rem;
  position: relative;
  width: 360px;

  @media only screen and (min-width: 520px) {
    padding: 2.5rem 1.25rem;
    width: ${({ requestAccountDeletionConfirmation }) =>
      requestAccountDeletionConfirmation ? '500px' : '380px'};
  }
`;

const DeleteAccount = styled(motion.button)`
  background-color: transparent;
  color: ${({ theme }) => theme.primary};
  cursor: pointer;
  font-size: 1rem;
  margin: 0 auto !important;
  text-decoration: underline;
`;

export default UserProfile;
