import React from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

function PictureInPictureOverlay({ handlePictureInPictureOverlayClick, colors, isPipModeEnabled }) {
  return (
    <AnimatePresence>
      {isPipModeEnabled && (
        <Container onClick={() => handlePictureInPictureOverlayClick()} colors={colors}>
          Click To Exit
          <br />
          Popout Player
        </Container>
      )}
    </AnimatePresence>
  );
}

const Container = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  align-items: center;
  background-color: ${({ colors }) => colors.secondary};
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 2rem;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: 0;
  width: 100%;
`;

export default PictureInPictureOverlay;
