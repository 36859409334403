/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line no-unused-vars
import { Button, BulletPoint, CheckoutForm } from 'components';
import { LocalContext, FirebaseContext } from 'context';
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon,
  LogoWhite,
  AgencyxLogo,
  CampaignxLogo,
  EventxLogo,
  AgencyxLogoContrast,
  GetBroadcastingLogo,
  MobIcon,
  LocoIcon
} from 'assets/svgs';
import { useStripe } from '@stripe/react-stripe-js';

function HubFooter() {
  const { contactRef } = useContext(LocalContext);
  // eslint-disable-next-line no-unused-vars
  const { user, firebase } = useContext(FirebaseContext);
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const redirectToCheckout = async (event) => {
    event.preventDefault();
    setLoading(true);
    const { error } = await stripe.redirectToCheckout({
      mode: 'payment',
      lineItems: [{ price: 'price_1K46umGDJn3cV8ol8GFGRTq0', quantity: 1 }],
      successUrl: `${window.location.origin}`,
      cancelUrl: `${window.location.origin}`
    });

    if (error) {
      setLoading(false);
    }
  };
  return (
    <>
      <FlexContainer>
        <Title>
          {user && (
            <BulletPoint
              top="-1rem"
              left="1.25rem"
              content={`
              <h4>Our Sponsors / Partners Section<br/> - More than Logos!</h4>
              <p>Event X Live events give sponsors and partners great opportunities to build brand engagement. As all attendees are online anyhow, it’s an easier consideration to engage digitally with sponsors and partners.</p>
              <p>Along with sponsor logos, we can play video and ads from their portfolio, they can sponsor show segments, we can showcase their latest products, branded imagery on all event X promotional material, digital media pack of assets to share re their event, at event virtual stage branding, downloadable content and links to their own websites as well as branding all post show content.</p>
              <p>We can encourage social media connections, run at event competitions, research and surveys and allow options for event participants to subscribe to sponsors databases directly. Customised analytic reporting can also be made available post event for sponsors to calculate their ROI.</p>
            `}
            />
          )}
          <H3>Our Partners</H3>
        </Title>
        <Partners>
          <AgencyxLogo />
          <CampaignxLogo />
          <EventxLogo />
          <GetBroadcastingLogo />
        </Partners>
      </FlexContainer>
      <Donations>
        {user && (
          <BulletPoint
            top="-1rem"
            bottom="1rem"
            right="0rem"
            content={`
            <p>For our charity and not for profit clients there is an option to Make a Donation running through all stages of the event, making it easy for the audience to contribute.</p>
            `}
          />
        )}

        <div>
          <H3 style={{ marginBottom: '0px', width: '100%' }}>Make A Donation</H3>
          <p>
            For Charity and Not-For-Profit events consider a `Make a Donation CTA` which can be
            simply linked to your existing online payment process.
          </p>
        </div>
        <Button loadingButton loading={loading} onClick={redirectToCheckout}>
          Donate
        </Button>
        {/* <CheckoutForm firebase={firebase} /> */}
      </Donations>
      <GridContainer>
        <Subscribe>
          <a href="https://panacea.eventxlive.com" target="blank">
            <LogoWhite width={250} />
          </a>
          <P style={{ marginBottom: '3rem' }}>Brought to you by our team of experts at:</P>
          <a href="https://agencyx.ie" target="blank">
            <AgencyxLogoContrast className="ax-logo" width={150} />
          </a>
        </Subscribe>
        <Contact id="contact" ref={contactRef}>
          <H3>Contact us</H3>
          <ContactDetail href="tel:+353 1 6706122">
            <MobIcon />+ 353 (0)1 6706122
          </ContactDetail>
          <ContactDetail>
            <LocoIcon />
            Media House, 24 South William Street
            <br />
            Dublin 2, Ireland
          </ContactDetail>
        </Contact>
        <Socials>
          <H3>Follow us on Social</H3>
          <div>
            <SocialLink href="https://www.facebook.com/AgencyXdublin" target="blank">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com/AgencyX_Dublin" target="blank">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://www.linkedin.com/company/agencyx-dublin">
              <LinkedinIcon />
            </SocialLink>
            <SocialLink href="https://www.instagram.com/agency_x_dublin/" target="blank">
              <InstagramIcon />
            </SocialLink>
          </div>
        </Socials>
      </GridContainer>
      <FooterInfo>
        <FooterCopyright>
          Copyright © 2020 - Panacea - Designed and Developed by Agency X{' '}
        </FooterCopyright>
        <FooterTerms>
          <a href="/">Terms & Conditions</a>
          <a href="/">Privacy Policy</a>
        </FooterTerms>
      </FooterInfo>
    </>
  );
}

const FlexContainer = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  margin: 0 auto;

  @media only screen and (min-width: 48rem) {
    grid-column: 2/6;
  }

  @media only screen and (min-width: 1150px) {
    grid-column: 2/12;
  }

  @media only screen and (min-width: 1441px) {
    grid-column: 3/11;
  }
`;

const GridContainer = styled.footer`
  background: ${({ theme }) => (theme.className === 'contrast' ? theme.contrast : theme.primary)};
  color: ${({ theme }) => (theme.className === 'contrast' ? theme.primary : theme.contrast)};
  display: grid;
  grid-column: 1/7;
  grid-gap: 0.625rem;
  grid-row-gap: 3.75rem;
  grid-template-columns: repeat(6, 1fr);
  padding: 2.25rem 1.25rem;
  position: relative;

  @media only screen and (min-width: 48rem) {
    padding: 40px;
  }

  @media only screen and (min-width: 1150px) {
    grid-column: 1/13;
    grid-gap: 1.25rem;
    grid-template-columns: repeat(12, 1fr);
    padding: 2.25rem 0 5rem;
  }
`;

const H3 = styled.h3`
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
  margin-bottom: 2.5rem;
  margin-top: 0px;
`;

const P = styled.p`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  margin-top: 0px;
`;

const Partners = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  justify-content: center;
  margin: 2rem auto;
  padding: 0px 1.25rem;
  width: 100%;

  svg {
    height: 1.5rem;
    margin: 2rem;
    width: auto;
    path {
      fill: ${({ theme }) => (theme.className === 'contrast' ? theme.primary : 'auto')};
    }
    :last-child {
      height: 5rem;
    }
  }
`;

const Donations = styled(FlexContainer)`
  align-items: center;
  background-color: ${({ theme }) => (theme.className === 'contrast' ? theme.contrast : '#EAF5F9')};
  color: #000;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  margin: 1.25rem;
  max-width: 100%;
  padding: 1.25rem 2.25rem;
  position: relative;
  text-align: center;

  h3,
  p {
    color: ${({ theme }) => theme.primary};
  }

  p {
    margin-bottom: 0.625rem;
    margin-top: 0.625rem;
  }

  button {
    justify-self: center;
  }

  @media only screen and (min-width: 48rem) {
    flex-direction: row;
    grid-column: 1/7;
    grid-gap: 1.25rem;
    margin: 2.5rem 1.25rem;
    text-align: left;

    p {
      margin-bottom: 0px;
    }

    button {
      justify-self: end;
    }
  }

  @media only screen and (min-width: 1150px) {
    grid-column: 2/12;
    grid-gap: 1.25rem;
    margin: 2.5rem auto;
    text-align: left;
  }
`;

const Subscribe = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  svg {
    margin-bottom: 2rem;
    max-width: 225px;
    path {
      fill: ${({ theme }) => (theme.className === 'contrast' ? theme.primary : 'auto')};
    }
  }
  @media only screen and (min-width: 1150px) {
    grid-column: 2/5;
  }
`;

const Contact = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  @media only screen and (min-width: 1150px) {
    grid-column: 6/9;
  }
`;

const ContactDetail = styled.a`
  align-items: center;
  color: ${({ theme }) => (theme.className === 'contrast' ? theme.primary : theme.contrast)};
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1.25rem;
  text-decoration: none;
  transition: 0.5s;
  :visited {
    color: ${({ theme }) => (theme.className === 'contrast' ? theme.primary : theme.contrast)};
  }
  svg {
    height: 1.75rem;
    margin-right: 0.75rem;
    min-width: 1.75rem;
    width: 1.75rem;
    circle {
      fill: ${({ theme }) => (theme.className === 'contrast' ? theme.primary : theme.tertiary)};
      transition: 0.5s;
    }
    path {
      stroke: ${({ theme }) => theme.contrast};
      transition: 0.5s;
    }
  }
  &:hover {
    color: ${({ theme }) => theme.tertiary};
    svg {
      circle {
        fill: ${({ theme }) => theme.contrast};
      }
      path {
        stroke: ${({ theme }) => (theme.className === 'contrast' ? theme.primary : theme.tertiary)};
      }
    }
  }
`;

const Socials = styled.div`
  grid-column: 1/7;
  div {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
  h3 {
    width: 100%;
  }
  @media only screen and (min-width: 1150px) {
    grid-column: 9/12;
  }
`;

const SocialLink = styled.a`
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1.25rem;
  transition: 0.5s;
  svg {
    height: 2.5rem;
    margin-right: 1.5rem;
    width: 2.5rem;
    circle {
      fill: ${({ theme }) => (theme.className === 'contrast' ? theme.primary : theme.tertiary)};
      transition: 0.5s;
    }
    path {
      fill: ${({ theme }) => theme.contrast};

      transition: 0.5s;
    }
  }
  &:hover {
    color: ${({ theme }) => theme.tertiary};
    svg {
      circle {
        fill: ${({ theme }) => theme.contrast};
      }
      path {
        fill: ${({ theme }) => theme.tertiary};
      }
    }
  }
`;

const FooterInfo = styled.footer`
  align-items: start;
  display: grid;
  grid-column: 1/7;
  grid-gap: 0.625rem;
  grid-template-columns: repeat(6, 1fr);
  padding: 1rem 1.25rem;
  @media only screen and (min-width: 48rem) {
    grid-column: 1/7;
  }
  @media only screen and (min-width: 1150px) {
    grid-column: 2/13;
    grid-template-columns: repeat(10, 1fr);
    grid-gap: 1.25rem;
    padding: 9px 0px;
  }
  @media only screen and (min-width: 1441px) {
    grid-column: 3/11;
  }
`;

const FooterCopyright = styled.p`
  color: ${({ theme }) => theme.primary};

  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  grid-column: 1/7;
  line-height: 1.25rem;
  margin-top: 0px;
  @media only screen and (min-width: 48rem) {
    grid-column: 1/7;
  }
  @media only screen and (min-width: 1150px) {
    grid-column: 1/8;
  }
`;

const FooterTerms = styled.div`
  display: flex;
  grid-column: 1/7;
  justify-content: flex-start;
  a {
    color: ${({ theme }) => theme.primary};
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    margin-right: 1.25rem;
  }
  @media only screen and (min-width: 1150px) {
    grid-column: 8/11;
  }
`;

const Title = styled.div`
  position: relative;
  text-align: center;
  width: 100%;

  h3 {
    margin-top: 6rem;
  }
`;

export default HubFooter;
