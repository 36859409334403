import React, { useContext, useState } from 'react';
import { FirebaseContext } from 'context';
import { Button } from 'components';
import { Container, Title, Card } from 'components/Admin/Shared';

function Miscellaneous() {
  const { firebase } = useContext(FirebaseContext);
  const [retrievingUsers, setRetrievingUsers] = useState(false);
  const [forcingEmailVerification, setForcingEmailVerification] = useState(false);

  const handleGetInvalidUIDs = async () => {
    try {
      setRetrievingUsers(true);
      const { data: allUsers } = await firebase.admin.fetchAllFirebaseAuthUserData();

      const promises = [];
      const results = [];

      allUsers.forEach((_user) => {
        promises.push(
          firebase.admin.getUserFromDatabaseWithUID({ uid: _user.uid }).then((res) => {
            if (res.empty) {
              // eslint-disable-next-line no-console
              results.push('INVALID UID: ', _user.uid);
            } else {
              // eslint-disable-next-line no-console
              results.push('OK');
            }
          })
        );
      });

      await Promise.all(promises);

      // eslint-disable-next-line no-console
      results.forEach((result) => console.log(result));
    } catch (error) {
      console.error(error);
    } finally {
      setRetrievingUsers(false);
    }
  };

  // We're using this to force verification of any accounts we manually create for CookiePro
  // to login to the site with when we want to scan private pages for any cookies.
  const handleForceEmailVerification = async () => {
    try {
      setForcingEmailVerification(true);
      await firebase.admin.forceEmailVerification();
      setForcingEmailVerification(false);
    } catch (error) {
      console.error(error);
      setForcingEmailVerification(false);
    }
  };

  return (
    <Container>
      <Title>Miscellaneous</Title>
      <Card>
        <Button
          color="primary"
          onClick={handleGetInvalidUIDs}
          loading={retrievingUsers}
          loadingButton
          style={{ margin: '0 auto' }}>
          Get Invalid UIDS
        </Button>
        <Button
          color="primary"
          onClick={handleForceEmailVerification}
          loading={forcingEmailVerification}
          loadingButton
          style={{ margin: '0 auto' }}>
          Force Email Verification (CookiePro)
        </Button>
      </Card>
    </Container>
  );
}

export default Miscellaneous;
