import React from 'react';
import styled from 'styled-components';
import { Button } from 'components';
import { Markup } from 'interweave';
import { FormContainer } from '../FormComponents';

function AuthError({ authError, closeAuthErrorDialog }) {
  return (
    <CustomContainer>
      <H3>
        <Markup content={authError.message} />
      </H3>
      {(authError.code === 'auth/invalid-action-code' ||
        authError.code === 'functions/not-found') && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={closeAuthErrorDialog}>Close</Button>
        </div>
      )}
    </CustomContainer>
  );
}

const H3 = styled.h3`
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  text-align: center;
`;

const CustomContainer = styled(FormContainer)`
  margin: 20px auto;
  max-width: 450px;

  @media screen and (min-width: 1150px) {
    padding: 40px 20px;
  }
`;

export default AuthError;
