import React, { createContext, useState, useEffect, useRef } from 'react';
import { useWindowSize, useLocalStorage } from 'hooks';

const LocalContext = createContext();

const fontSizes = {
  default: '16px',
  medium: '18px',
  large: '20px'
};

const defaultTheme = {
  className: 'default',
  primary: '#004D9A',
  secondary: '#2D9CDB',
  tertiary: '#36D1B7',
  contrast: '#FFFFFF'
};

const contrastTheme = {
  className: 'contrast',
  primary: '#FFFF00',
  secondary: '#FFFF00',
  tertiary: '#FFF000',
  contrast: '#000000'
};

const grayscaleTheme = {
  className: 'grayscale',
  primary: '#282828',
  secondary: '#787878',
  tertiary: '#808080',
  contrast: '#FFFFFF'
};

const professionOptions = [
  {
    value: 'Consultant',
    label: 'Consultant'
  },
  {
    value: 'Intern',
    label: 'Intern'
  },
  {
    value: 'Nurse',
    label: 'Nurse'
  },
  {
    value: 'Pharmacist',
    label: 'Pharmacist'
  },
  {
    value: 'Physician',
    label: 'Physician'
  },
  {
    value: 'Registrar',
    label: 'Registrar'
  },
  {
    value: 'Technician',
    label: 'Technician'
  }
];

function LocalContextProvider(props) {
  const siteName = 'Panacea';
  const [theme, setTheme] = useLocalStorage(`${siteName.toLowerCase()}-theme`, defaultTheme);
  const [fontSizing, setFontSizing] = useLocalStorage(
    `${siteName.toLowerCase()}-fontSize`,
    fontSizes.default
  );
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedPollDocumentation, setSelectedPollDocumentation] = useState('general');
  const [showUserAccountDeletedDialog, setShowUserAccountDeletedDialog] = useState(false);
  const [minimisedQuestions, setMinimisedQuestions] = useState([]);
  const [browserTabIsActive, setBrowserTabIsActive] = useState(true);
  const [showGroupWatchingDialog, setShowGroupWatchingDialog] = useState(false);
  const [tickingId, setTickingId] = useState(null);
  const { windowWidth } = useWindowSize();
  const [isMobile, setIsMobile] = useState(true);
  const [magicLinkEmailStatus, setMagicLinkEmailStatus] = useState({
    sending: false,
    sent: false
  });
  const [showLoginOrSignUpDialog, setShowLoginOrSignUpDialog] = useState(false);
  const [registrationFormSubmissionStatus, setRegistrationFormSubmissionStatus] = useState({
    submitting: false,
    submitted: false
  });
  const [showClosedEventDialog, setShowClosedEventDialog] = useState(false);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
  const [returningVisitorsUnverifiedEmailAddress, setReturningVisitorsUnverifiedEmailAddress] =
    useState(null);
  const [showSignUpForm, setShowSignUpForm] = useState(false);
  const registerRef = useRef(null);
  const galleryRef = useRef(null);
  const contactRef = useRef(null);
  const faqRef = useRef(null);
  const streamRef = useRef(null);
  const loginOrSignUpDialogRef = useRef(null);
  const confirmDeletePollOrQuestionDialogRef = useRef(null);
  const authInitiatedFromAnotherDeviceOrBrowserDialogRef = useRef(null);

  const dialogAnimationTime = 300;

  const scrollToRef = (ref) =>
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });

  useEffect(() => {
    if (windowWidth >= 1150) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [isMobile, windowWidth]);

  const handleBrowserTabActivity = (forcedFlag) => {
    if (typeof forcedFlag === 'boolean') {
      return forcedFlag ? setBrowserTabIsActive(true) : setBrowserTabIsActive(false);
    }
    return document.hidden ? setBrowserTabIsActive(false) : setBrowserTabIsActive(true);
  };

  useEffect(() => {
    const handleBrowserTabActivityFalse = () => handleBrowserTabActivity(false);
    const handleBrowserTabActivityTrue = () => handleBrowserTabActivity(true);

    document.addEventListener('visibilitychange', handleBrowserTabActivity);
    document.addEventListener('blur', handleBrowserTabActivityFalse);
    window.addEventListener('blur', handleBrowserTabActivityFalse);
    window.addEventListener('focus', handleBrowserTabActivityTrue);
    document.addEventListener('focus', handleBrowserTabActivityTrue);

    return () => {
      window.removeEventListener('blur', handleBrowserTabActivity);
      document.removeEventListener('blur', handleBrowserTabActivityFalse);
      window.removeEventListener('focus', handleBrowserTabActivityFalse);
      document.removeEventListener('focus', handleBrowserTabActivityTrue);
      document.removeEventListener('visibilitychange', handleBrowserTabActivityTrue);
    };
  }, []);

  const toggleTheme = (themeName) => {
    if (themeName === 'contrast') {
      setTheme(contrastTheme);
    } else if (themeName === 'grayscale') {
      setTheme(grayscaleTheme);
    } else {
      setTheme(defaultTheme);
    }
  };

  const toggleFontSizing = (fontSize) => {
    if (fontSize === fontSizes.large) setFontSizing(fontSizes.large);
    else if (fontSize === fontSizes.medium) setFontSizing(fontSizes.medium);
    else setFontSizing(fontSizes.default);
  };

  const handleDialog = ({ dialogRef, animation, stateHandler, callback }) => {
    dialogRef.current.setAttribute('data-state', animation);
    dialogRef.current.children[0].setAttribute('data-state', animation);

    stateHandler();

    if (callback) {
      setTimeout(() => callback(), dialogAnimationTime);
    }
  };

  return (
    <LocalContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        browserTabIsActive,
        contactRef,
        dialogAnimationTime,
        faqRef,
        fontSizing,
        galleryRef,
        isMobile,
        magicLinkEmailStatus,
        minimisedQuestions,
        registerRef,
        scrollTo: scrollToRef,
        selectedEvent,
        selectedPollDocumentation,
        setMagicLinkEmailStatus,
        setMinimisedQuestions,
        setSelectedEvent,
        setSelectedPollDocumentation,
        setShowGroupWatchingDialog,
        setShowUserAccountDeletedDialog,
        setTickingId,
        showGroupWatchingDialog,
        showUserAccountDeletedDialog,
        siteName,
        streamRef,
        theme,
        tickingId,
        toggle: toggleTheme,
        toggleFont: toggleFontSizing,
        showLoginOrSignUpDialog,
        setShowLoginOrSignUpDialog,
        loginOrSignUpDialogRef,
        handleDialog,
        showConfirmDeleteDialog,
        setShowConfirmDeleteDialog,
        confirmDeletePollOrQuestionDialogRef,
        authInitiatedFromAnotherDeviceOrBrowserDialogRef,
        registrationFormSubmissionStatus,
        setRegistrationFormSubmissionStatus,
        returningVisitorsUnverifiedEmailAddress,
        setReturningVisitorsUnverifiedEmailAddress,
        professionOptions,
        showSignUpForm,
        setShowSignUpForm,
        showClosedEventDialog,
        setShowClosedEventDialog
      }}>
      {props.children}
    </LocalContext.Provider>
  );
}

export { LocalContextProvider };
export default LocalContext;
