import React, { useContext } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { LocalContext } from 'context';

function DialogCloseIcon(props) {
  const { theme } = useContext(LocalContext);
  const handleKeyDown = (event) => {
    if (event.code === 'Enter' || event.code === 'Space') {
      props.onClick();
    }
  };

  return (
    <Container
      tabIndex="0"
      onClick={() => props.onClick()}
      onKeyDown={handleKeyDown}
      theme={theme}
      style={props.style}
      width={props.width || '1rem'}
      whileHover={{
        scale: 1.025
      }}
      whileTap={{ scale: 0.95 }}>
      <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M13 1 1 13M13 13 1 1" stroke="#fff" strokeWidth={2} />
      </svg>
    </Container>
  );
}

const Container = styled(motion.div)`
  align-items: center;
  background-color: ${({ theme }) =>
    theme.className === 'contrast' ? theme.primary : theme.tertiary};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: calc(${({ width }) => width} * 2.25);
  justify-content: center;
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: calc(${({ width }) => width} * 2.25);
  z-index: 10;

  path {
    stroke: ${({ theme }) => (theme.className === 'contrast' ? theme.contrast : '#fff')};
  }

  :focus {
    outline: 2px solid ${({ theme }) => (theme.className === 'contrast' ? '#000' : '#fff')};
  }
`;

export default DialogCloseIcon;
