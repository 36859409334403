import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Html, MeshDistortMaterial, Shadow } from '@react-three/drei';
import { useSpring } from '@react-spring/core';
import { a } from '@react-spring/three';

const AnimatedMaterial = a(MeshDistortMaterial);

function Hotspot({ text, positioning, link, isMobile }) {
  const hotspot = useRef();
  const [showModal, setShowModal] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [down, setDown] = useState(false);
  const [{ wobble, color }] = useSpring(
    {
      wobble: down ? 18 : hovered ? 17 : 15,
      color: hovered ? '#004d9a' : '#36D1B7'
    },
    [hovered, down]
  );
  useEffect(() => {
    document.body.style.cursor = hovered ? 'pointer' : 'auto';
  }, [hovered]);
  return (
    <a.mesh
      position={positioning}
      scale={wobble}
      onPointerOver={() => {
        setHovered(true);
        setShowModal(true);
      }}
      onClick={() => {
        if (link) {
          window.open(`${link}`, '_blank');
        }
      }}
      onPointerOut={() => {
        setHovered(false);
        setShowModal(false);
      }}
      onPointerDown={() => setDown(true)}
      onPointerUp={() => setDown(false)}
      ref={hotspot}>
      <sphereBufferGeometry args={[0.075, 64, 64]} />
      <AnimatedMaterial
        attach="material"
        distort={0.1}
        speed={5}
        clearcoat="0.4"
        clearcoatRoughness={0}
        roughness={0}
        color={color}
        emissive={color}
        envMapIntensity={0.3}
        metalness={0.1}
      />
      <Shadow
        rotation={[-Math.PI / 2, 0, 0]}
        scale={wobble}
        position={[0, -wobble, 0]}
        color={color}
        opacity={0.5}
      />
      {showModal && (
        <Html>
          <Modal
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            $isMobile={isMobile}>
            <p style={{ alignSelf: 'flex-start' }}>{text}</p>
          </Modal>
        </Html>
      )}
    </a.mesh>
  );
}

const Modal = styled(motion.div)`
  align-items: center;
  background: white;
  border-radius: 3px;
  box-shadow: 7px 10px 47px -20px rgba(0, 0, 0, 0.75);
  color: #004d9a;
  display: flex;
  flex-direction: column;
  height: auto;
  padding: ${({ $isMobile }) => ($isMobile ? '0.5rem' : '1rem')};
  width: auto;
  img {
    height: ${({ $isMobile }) => ($isMobile ? '200px' : '400px')};
    margin: ${({ $isMobile }) => ($isMobile ? '0.5rem' : '1rem')};
    overflow: hidden;
  }
`;

export default Hotspot;
