import React, { useState, useRef, useContext } from 'react';
import { LocalContext } from 'context';
import { Dialog, AddOrEditSpeakerDialog } from 'components';
import { EditorState } from 'draft-js';

const getFormInitialState = () => ({
  name: '',
  bio: EditorState.createEmpty(),
  titleOrRole: '',
  company: '',
  presentationTitle: '',
  socials: {
    facebook: '',
    twitter: '',
    linkedIn: '',
    instagram: ''
  }
});

function AddOrEditSpeakerContainer({
  eventId,
  speakers,
  showAddOrEditSpeakerDialog,
  setShowAddOrEditSpeakerDialog,
  speakerCurrentlyBeingEdited,
  setSpeakerCurrentlyBeingEdited
}) {
  const { handleDialog } = useContext(LocalContext);
  const [formValues, setFormValues] = useState(getFormInitialState());

  const addOrEditSpeakerDialogRef = useRef(null);

  const resetForm = () => setFormValues(getFormInitialState());

  const dismissAddOrEditSpeakerDialog = () =>
    handleDialog({
      dialogRef: addOrEditSpeakerDialogRef,
      animation: 'dismiss',
      stateHandler: () => setShowAddOrEditSpeakerDialog(false),
      callback: () => {
        if (speakerCurrentlyBeingEdited) {
          setSpeakerCurrentlyBeingEdited(null);
        }
        resetForm();
      }
    });

  const closeAddOrEditSpeakerDialog = () =>
    handleDialog({
      dialogRef: addOrEditSpeakerDialogRef,
      animation: 'close',
      stateHandler: () => setShowAddOrEditSpeakerDialog(false),
      callback: () => {
        if (speakerCurrentlyBeingEdited) {
          setSpeakerCurrentlyBeingEdited(null);
        }
        resetForm();
      }
    });

  return (
    <Dialog
      ref={addOrEditSpeakerDialogRef}
      isVisible={showAddOrEditSpeakerDialog}
      onDismiss={dismissAddOrEditSpeakerDialog}
      contentStyle={{
        maxWidth: '45.375rem'
      }}>
      <AddOrEditSpeakerDialog
        eventId={eventId}
        setSpeakerCurrentlyBeingEdited={setSpeakerCurrentlyBeingEdited}
        speakerCurrentlyBeingEdited={speakerCurrentlyBeingEdited}
        speakers={speakers}
        getFormInitialState={getFormInitialState}
        formValues={formValues}
        setFormValues={setFormValues}
        dismissAddOrEditSpeakerDialog={dismissAddOrEditSpeakerDialog}
        closeAddOrEditSpeakerDialog={closeAddOrEditSpeakerDialog}
      />
    </Dialog>
  );
}

export default AddOrEditSpeakerContainer;
