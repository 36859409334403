import React from 'react';
import styled from 'styled-components';
import Registration from './Registration';
import LivestreamAndInteractions from './LivestreamAndInteractions';
import Emojis from './Emojis';
import PieChart from './PieChart';

function TheNumbers({
  eventId,
  numberOfPeopleRegistered,
  companies,
  professions,
  groups,
  totalGroupMembers
}) {
  return (
    <Container id="the-numbers">
      <Title>The Numbers</Title>
      <Registration
        numberOfPeopleRegistered={numberOfPeopleRegistered}
        groups={groups}
        totalGroupMembers={totalGroupMembers}
      />
      <LivestreamAndInteractions eventId={eventId} />
      <Emojis eventId={eventId} />
      <PieChart companies={companies} professions={professions} />
    </Container>
  );
}

const Container = styled.section`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.75rem;
  text-align: center;
`;

export default TheNumbers;
