import React from 'react';
import { Button } from 'components';
import { FormContainer } from '../FormComponents';

function AccountDeleted({ closeAccountDeletedDialog }) {
  return (
    <FormContainer style={{ margin: '0 auto' }}>
      <h3
        style={{
          alignItems: 'center',
          display: 'flex',
          fontSize: '1.625rem',
          fontStyle: 'normal',
          fontWeight: 'bold',
          justifyContent: 'center',
          lineHeight: '1.75rem',
          textAlign: 'center'
        }}>
        Account Deleted
      </h3>
      <Button
        onClick={closeAccountDeletedDialog}
        style={{
          margin: '1.75rem auto 0'
        }}>
        Ok
      </Button>
    </FormContainer>
  );
}

export default AccountDeleted;
