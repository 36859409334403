import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { StaticQuery, graphql } from 'gatsby';
import defaultThumbnail from 'assets/images/panacea.jpg';

function SEO({
  children,
  lang = 'en',
  meta = [],
  pageSpecificTitle,
  pageSpecificSiteTitle,
  pageSpecificDescription,
  pageSpecificThumbnail,
  pageSpecificThumbnailAlt
}) {
  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              title
              description
              defaultThumbnailAlt
              siteUrl
              twitterPage
              author
            }
          }
        }
      `}
      render={(data) => {
        const { title, description, defaultThumbnailAlt, siteUrl, twitterPage, author } =
          data.site.siteMetadata;
        const _title = pageSpecificSiteTitle || title;
        const _description = pageSpecificDescription || description;
        const thumbnail = pageSpecificThumbnail || defaultThumbnail;
        const thumbnailAlt = pageSpecificThumbnailAlt || defaultThumbnailAlt;
        const { pathname } = useLocation();
        const url = `${siteUrl}${pathname}`;
        const image = `${siteUrl}${thumbnail}`;
        return (
          <Helmet
            htmlAttributes={{
              lang
            }}
            title={pageSpecificTitle}
            titleTemplate={`%s | ${_title}`}
            link={[
              {
                rel: 'canonical',
                href: url
              }
            ]}
            meta={[
              {
                property: 'og:site_name',
                content: title
              },
              {
                name: `description`,
                content: `${_description}`
              },
              {
                property: `og:locale`,
                content: 'en_GB'
              },
              {
                property: `og:url`,
                content: url
              },
              {
                property: `og:title`,
                content: `${pageSpecificTitle} | ${title}`
              },
              {
                property: `og:description`,
                content: _description
              },
              {
                property: `og:type`,
                content: `website`
              },
              {
                property: `og:image`,
                content: image
              },
              {
                property: `og:image:url`,
                content: image
              },
              {
                property: `og:image:secure_url`,
                content: image
              },
              {
                property: `og:image:alt`,
                content: thumbnailAlt
              },
              {
                property: 'og:image:width',
                content: '1200'
              },
              {
                property: 'og:image:height',
                content: '630'
              },
              {
                name: `twitter:card`,
                content: `summary_large_image`
              },
              {
                name: `twitter:image`,
                content: image
              },
              {
                property: 'twitter:image:alt',
                content: thumbnailAlt
              },
              {
                name: `twitter:site`,
                content: twitterPage
              },
              {
                name: `twitter:creator`,
                content: twitterPage
              },
              {
                name: `twitter:title`,
                content: `${pageSpecificTitle} | ${title}`
              },
              {
                name: `twitter:description`,
                content: `${_description}`
              },
              {
                name: `author`,
                content: author
              }
            ].concat(meta)}>
            {children}
          </Helmet>
        );
      }}
    />
  );
}

export default SEO;
