import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

function LoadingSpinnerFullScreenWrapper({ initial, animate, exit, children }) {
  return (
    <Wrapper initial={initial} animate={animate} exit={exit}>
      {children}
    </Wrapper>
  );
}

const Wrapper = styled(motion.div)`
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 100vh;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 1000;
`;

export default LoadingSpinnerFullScreenWrapper;
