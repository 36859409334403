import React from 'react';
import styled, { keyframes } from 'styled-components';
import { WonkyAvocado, DashedCircle, CornerBubble } from 'assets/svgs';

function BannerBackgroundImage({ colors }) {
  return (
    <Background colors={colors}>
      <WonkyAvocado className="avocado" />
      <DashedCircle className="broken-circle" colors={colors} />
      <CornerBubble className="corner-bubble" colors={colors} />
      <Circles colors={colors}>
        <Circle />
        <Circle />
        <Circle />
      </Circles>
    </Background>
  );
}

const rotate = keyframes`
  from{
    transform: rotate(-30deg);
  }
  to{
    transform: rotate(120deg);
  }
`;

const sway = keyframes`
  0%{
    transform: rotate(0deg) translateX(10px) rotate(0deg);
  }
  to{
    transform: rotate(360deg) translateX(10px) rotate(-360deg);
  }
`;

const swayAlt = keyframes`
  0%{
    transform: rotate(0deg) translateX(15px) rotate(0deg);
  }
  to{
    transform: rotate(-360deg) translateX(15px) rotate(360deg);
  }
`;

const Circle = styled.div`
  height: 10rem;
  position: absolute;
  width: 10rem;
`;

const Circles = styled.div`
  bottom: 0;
  display: none;
  height: 100%;
  position: absolute;
  right: 0;
  width: 50%;
  @media only screen and (min-width: 48rem) {
    display: block;
  }
  ${Circle} {
    border-radius: 50%;
    &:nth-child(1) {
      animation: ${swayAlt} 8s infinite alternate;
      background: ${({ theme, colors }) =>
        theme.className === 'contrast' ? theme.primary : colors.primary};
      height: 4rem;
      right: 60%;
      top: 15%;
      transform: rotate(45deg) translateX(5px) rotate(-45deg);
      width: 4rem;
    }
    &:nth-child(2) {
      animation: ${sway} 10s infinite alternate;
      background: ${({ theme, colors }) =>
        theme.className === 'contrast' ? theme.tertiary : colors.tertiary};
      bottom: 2rem;
      height: 15rem;
      opacity: 0.2;
      right: 55%;
      transform: rotate(45deg) translateX(10px) rotate(-45deg);
      width: 15rem;
    }
    &:nth-child(3) {
      background: ${({ theme, colors }) =>
        theme.className === 'contrast' ? theme.secondary : colors.secondary};
      bottom: -3rem;
      height: 10rem;
      right: 55%;
      width: 10rem;
    }
  }
`;

const Background = styled.div`
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  svg {
    position: absolute;
    &.avocado {
      right: -40%;
      top: -50%;
      width: 100%;
    }
    &.broken-circle {
      path {
        fill: ${({ theme, colors }) =>
          theme.className === 'contrast' ? theme.primary : colors.primary};
      }
      animation: ${rotate} 10s infinite;
      animation-direction: alternate;
      right: 12%;
      top: 12%;
      transform: rotate(30deg);
      width: 10rem;
    }
    &.corner-bubble {
      bottom: 0;
      left: 0;
      width: 7.5rem;
    }
  }
  @media only screen and (min-width: 48rem) {
    svg {
      &.corner-bubble {
        bottom: 0;
        left: 0;
        width: 9rem;
      }
    }
  }
`;

export default BannerBackgroundImage;
