import React, { useState, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { LocalContext } from 'context';
import { Arrow } from 'assets/svgs';
import { useEventData } from 'hooks';
import { Link } from 'gatsby';

function EventList({ toggleNav }) {
  const { isMobile } = useContext(LocalContext);
  const [expanded, setExpanded] = useState(false);
  const dropdownToggle = () => setExpanded((currentValue) => !currentValue);

  const { edges: events } = useEventData();

  const currentEvents = events
    .filter(({ node }) => node.frontmatter.status === 'upcoming')
    .sort((a, b) => b.node.frontmatter.eventId - a.node.frontmatter.eventId);

  const pastEvents = events
    .filter(({ node }) => node.frontmatter.status === 'previous')
    .sort((a, b) => b.node.frontmatter.eventId - a.node.frontmatter.eventId);

  return (
    <Dropdown expanded={expanded}>
      <Heading
        onClick={() => {
          if (isMobile) {
            dropdownToggle();
          }
        }}
        onMouseEnter={() => {
          if (!isMobile) {
            dropdownToggle();
          }
        }}
        onMouseLeave={() => setExpanded(false)}
        expanded={expanded}>
        <DownArrow />
        <span>Events</span>
        <ContentContainer expanded={expanded}>
          <AnimatePresence initial={false}>
            {expanded && (
              <Content
                onClick={() => {
                  setExpanded(false);
                  toggleNav();
                }}
                initial="exit"
                animate={expanded ? 'enter' : 'exit'}
                exit="exit"
                variants={{
                  enter: {
                    opacity: 1,
                    rotateX: 0,
                    transition: {
                      duration: 0.5
                    },
                    display: 'block'
                  },
                  exit: {
                    opacity: 0,
                    rotateX: -15,
                    transition: {
                      duration: 0.5,
                      delay: 0.3
                    },
                    transitionEnd: {
                      display: 'none'
                    }
                  }
                }}>
                {currentEvents.length > 0 && (
                  <>
                    <H4>Upcoming</H4>
                    {currentEvents
                      .map((event, i) => (
                        <StyledLink
                          key={i}
                          to={`/events/${event.node.frontmatter.slug}`}
                          title={event.node.frontmatter.name}
                          $colors={event.node.frontmatter.colors}
                          onClick={() => {
                            setExpanded(false);
                            toggleNav();
                          }}>
                          {event.node.frontmatter.name}
                        </StyledLink>
                      ))
                      .slice(0, 5)}
                  </>
                )}
                {pastEvents.length > 0 && (
                  <>
                    <H4>Previous</H4>
                    {pastEvents
                      .map((event, i) => (
                        <StyledLink
                          key={i}
                          to={`/events/${event.node.frontmatter.slug}`}
                          title={event.node.frontmatter.name}
                          $colors={event.node.frontmatter.colors}
                          onClick={() => {
                            setExpanded(false);
                            toggleNav();
                          }}>
                          {event.node.frontmatter.name}
                        </StyledLink>
                      ))
                      .slice(0, 5)}
                  </>
                )}
              </Content>
            )}
          </AnimatePresence>
        </ContentContainer>
      </Heading>
    </Dropdown>
  );
}

const DownArrow = styled(Arrow)`
  height: auto;
  margin-left: 0;
  margin-right: 0.5rem;
  min-width: 1rem;
  /* transform: ${({ expanded }) => (expanded ? 'none' : 'scale(-1)')}; */
  transition-duration: 0.5s;
  width: 1rem;

  path {
    stroke: ${({ expanded, theme }) => (expanded ? theme.primary : theme.tertiary)};
    transition: 0.25s;
  }
  @media only screen and (min-width: 1150px) {
    height: 0.75rem;
    min-width: 0.75rem;
    width: 0.75rem;
    path {
      stroke: ${({ expanded, theme }) => (expanded ? theme.primary : theme.tertiary)};
    }
  }
`;

const StyledLink = styled(Link)`
  align-items: center;
  background-color: transparent;
  color: ${({ theme, $colors }) =>
    theme.className === 'contrast' ? theme.primary : $colors.secondary};
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  height: 2.4325rem;
  justify-content: center;
  transition: color 200ms ease-in-out;
  width: 100%;

  &:hover,
  &:focus {
    color: ${({ theme, $colors }) =>
      theme.className === 'contrast' ? theme.primary : $colors.primary};
  }

  @media only screen and (min-width: 1150px) {
    justify-content: flex-start;
  }
`;

const H4 = styled.h4`
  color: ${({ theme }) => theme.primary};
  font-size: 0.75rem;
  height: 2.4325rem;
  text-transform: uppercase;

  @media only screen and (min-width: 1150px) {
    font-size: 0.875rem;
    margin-top: 0.75rem;
    height: unset;
  }
`;

const Content = styled(motion.section)`
  background: ${({ theme }) => (theme.className === 'contrast' ? theme.contrast : '#EAF5F9')};
  overflow: hidden;
  padding: 0.75rem 1rem 1.125rem;

  @media only screen and (min-width: 1150px) {
    background: transparent;
    padding: 0;

    ${StyledLink} {
      text-align: left;
    }
  }
`;

const ContentContainer = styled.div`
  background: white;
  height: auto;
  text-align: center;
  width: 100%;
  @media only screen and (min-width: 1150px) {
    background: ${({ theme }) => (theme.className === 'contrast' ? theme.contrast : '#EAF5F9')};
    border-radius: 0.5rem;
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.15);
    display: ${({ expanded }) => (expanded ? 'flex' : 'none')};
    height: auto;
    left: 0;
    padding: 0.75rem 1.5rem 1.5rem;
    position: absolute;
    text-align: left;
    top: 100%;
    width: 300px;
  }
`;

const Heading = styled(motion.div)`
  background: ${({ theme }) => theme.primary};
  color: ${({ theme }) => (theme.className === 'contrast' ? theme.primary : theme.contrast)};
  cursor: pointer;
  font-family: Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 3rem;
  padding-top: 0.125rem;
  text-align: center;
  transition: 0.5s;
  width: 100%;

  > span {
    cursor: pointer;
    text-transform: uppercase;
  }

  ${DownArrow} {
    path {
      stroke: white;
    }
  }

  @media only screen and (min-width: 1150px) {
    background: transparent;
    border-bottom: 0.185rem solid transparent;
    color: ${({ expanded, theme }) => (expanded ? theme.tertiary : theme.primary)};
    line-height: 1.5rem;
    width: auto;
    ${DownArrow} {
      path {
        stroke: ${({ expanded, theme }) => (expanded ? theme.tertiary : theme.primary)};
      }
    }
  }
`;

const Dropdown = styled.div`
  height: auto;
  position: relative;
  width: 100%;
  @media only screen and (min-width: 1150px) {
    margin-left: 1rem;
    overflow: visible;
    width: auto;
  }
`;

export default EventList;
