import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { FirebaseContext, LocalContext } from 'context';
import {
  CommentArrow,
  DefaultCommenterAvatar,
  EmojiClap,
  EmojiLike,
  EmojiLove,
  EmojiSmile
} from 'assets/svgs';
import { Button } from 'components';
import { fadeInAndOutVariants } from 'styles';
import { scrollToAnchor, hexToRGB, getCaretCoordinates } from 'utils';
import { isMobile } from 'react-device-detect';
import Interweave from 'interweave';
import { UrlMatcher, EmailMatcher } from 'interweave-autolink';
import { format } from 'date-fns';
import { Heart, Pin, ThumbsUp } from 'assets/dynamic-svgs';
import * as differ from 'diff';
import { MentionsMatcher } from './matchers';

function getPosition(string, subString, index) {
  return string.split(subString, index).join(subString).length;
}

function Chat({
  colors,
  eventId,
  handleParticipantAvatarOrNameClick,
  chatTextInputValue,
  setChatTextInputValue,
  chatPreviousTextInputValue,
  setChatPreviousTextInputValue,
  editingComment,
  setEditingComment,
  idOfCommentCurrentlyBeingEdited,
  setIdOfCommentCurrentlyBeingEdited,
  textOfCommentBeforeBeingEdited,
  setTextOfCommentBeforeBeingEdited,
  taggedUsers,
  setTaggedUsers
}) {
  const { firebase, user } = useContext(FirebaseContext);
  const { browserTabIsActive } = useContext(LocalContext);
  const [allComments, setAllComments] = useState([]);
  const [displaySuggestions, setDisplaySuggestions] = useState(false);
  const [searchResults, setSearchResults] = useState(null);
  const [showEmojisSelector, setShowEmojisSelector] = useState(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [caretCoordinates, setCaretCoordinates] = useState({ x: 0, y: 0 });

  const disableSubmitCommentButton =
    chatTextInputValue === '' ||
    chatTextInputValue === ' ' ||
    (editingComment && chatTextInputValue === textOfCommentBeforeBeingEdited);

  let debounceCallsToAlgolia;

  useEffect(() => {
    let unsubscribeFromEventComments;

    if (firebase && browserTabIsActive) {
      unsubscribeFromEventComments = firebase.interaction.chat.subscribeToEventComments({
        eventId,
        snapshot: (snapshot) => {
          if (!snapshot.empty) {
            const comments = [];

            snapshot.forEach((doc) => {
              comments.push({
                commentId: doc.id,
                ...doc.data()
              });
            });

            setAllComments(comments);
          } else if (snapshot.empty) {
            setAllComments([]);
          }
        }
      });
    }
    return () => {
      if (unsubscribeFromEventComments) {
        unsubscribeFromEventComments();
      }
    };
  }, [firebase, browserTabIsActive]);

  useEffect(() => {
    const tooltip = document.getElementById('tooltip');
    tooltip.setAttribute(
      'style',
      `opacity: ${displaySuggestions ? '1' : '0'}; left: ${caretCoordinates.x - 16}px;`
    );
  }, [displaySuggestions, caretCoordinates]);

  useEffect(() => {
    if (displaySuggestions) {
      debounceCallsToAlgolia = setTimeout(() => {
        const position = getPosition(chatTextInputValue, '@', taggedUsers.length + 1);
        const stringToSearch = chatTextInputValue.slice(position + 1);

        firebase.algolia.search({ indexType: 'users', tag: stringToSearch }).then((res) => {
          if (res.data.length > 0) {
            const _searchResults = res.data.filter(
              (hit) =>
                hit.uid !== user.uid &&
                !taggedUsers.includes(hit.uid) &&
                hit.eventsUserCanAccess.includes(eventId)
            );
            setSearchResults(_searchResults);
          } else {
            setSearchResults(null);
            setSelectedSuggestion(null);
          }
        });
      }, 300);
    }

    if (taggedUsers.length > 0) {
      if (!chatTextInputValue.match(/@[a-zA-Z]+/g)) {
        setTaggedUsers([]);
        setSelectedSuggestion(null);
        setDisplaySuggestions(false);
      }
      if (
        chatTextInputValue.match(/@[a-zA-Z]+/g)?.length <
        chatPreviousTextInputValue.match(/@[a-zA-Z]+/g)?.length
      ) {
        const difference = differ.diffChars(chatPreviousTextInputValue, chatTextInputValue);
        setChatPreviousTextInputValue(chatTextInputValue);
        const indexWhereRemoved = difference.findIndex((element) => element.removed);
        const removedTag = difference[indexWhereRemoved].value.split(' ')[0];

        firebase.algolia.search({ indexType: 'users', tag: removedTag }).then((res) => {
          if (res.data.length > 0) {
            res.data.forEach((hit) => {
              if (taggedUsers.includes(hit.uid)) {
                const _taggedUsers = taggedUsers.filter((uid) => uid !== hit.uid);
                setTaggedUsers(_taggedUsers);
              }
            });
          } else {
            console.log('No results');
          }
        });
      }
    }

    return () => {
      if (debounceCallsToAlgolia) {
        clearTimeout(debounceCallsToAlgolia);
      }
    };
  }, [chatTextInputValue, displaySuggestions]);

  const handleTextInput = async (e) => {
    e.persist();
    setChatTextInputValue(e.target.value);
  };

  const handleSuggestionSelection = ({ result }) => {
    setTaggedUsers((previousState) => [...previousState, result.uid]);
    const position = getPosition(chatTextInputValue, '@', taggedUsers.length + 1);
    const stringAtStart = chatTextInputValue.slice(0, position);
    setChatTextInputValue(`${stringAtStart}@${result.name.replace(/\s+/g, '')}`);
    setChatPreviousTextInputValue(`${stringAtStart}@${result.name.replace(/\s+/g, '')}`);
    setSelectedSuggestion(null);
    setDisplaySuggestions(false);
    setSearchResults(null);
    document.getElementById('comment').focus();
  };

  const handleTextInputKeydown = (e) => {
    e.persist();

    const { key, target, code } = e;
    const returnLastWord = (text, caretPosition) => {
      const preText = text.substring(0, caretPosition);
      if (preText.indexOf(' ') > 0) {
        const words = preText.split(' ');
        return words[words.length - 1];
      }
      return preText;
    };

    if (key === '@') {
      if (target.value[target.selectionStart - 1]) {
        if (target.value[target.selectionStart - 1] === ' ') {
          setSelectedSuggestion(0);
          setDisplaySuggestions(true);
          const coordinates = getCaretCoordinates(target, target.selectionEnd);
          setCaretCoordinates(coordinates);
        }
      } else {
        setSelectedSuggestion(0);
        setDisplaySuggestions(true);
        const coordinates = getCaretCoordinates(target, target.selectionEnd);
        setCaretCoordinates(coordinates);
      }
    }

    if (target.value[target.selectionEnd] === '@') {
      if (target.value[target.selectionStart - 1]) {
        if (target.value[target.selectionStart - 1] === ' ') {
          setSelectedSuggestion(0);
          setDisplaySuggestions(true);
          const coordinates = getCaretCoordinates(target, target.selectionEnd);
          setCaretCoordinates(coordinates);
        }
      } else {
        setSelectedSuggestion(0);
        setDisplaySuggestions(true);
        const coordinates = getCaretCoordinates(target, target.selectionEnd);
        setCaretCoordinates(coordinates);
      }
    }

    if (key === 'Backspace' || key === 'Escape') {
      let lastWord = '';
      lastWord = returnLastWord(target.value, target.selectionStart);
      const punctuationRegex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/;
      if (
        lastWord.charAt(0) === '@' &&
        !punctuationRegex.test(lastWord.charAt(lastWord.length - 1))
      ) {
        const _textInputValue = chatTextInputValue.replace(lastWord, '');
        setChatTextInputValue(
          key === 'Backspace' && displaySuggestions ? `${_textInputValue} ` : _textInputValue
        );
        setSelectedSuggestion(null);
        setDisplaySuggestions(false);
      }
      if (target.value[target.selectionStart - 1] === '@') {
        setSelectedSuggestion(null);
        setDisplaySuggestions(false);
      }
    }

    if (key === 'Delete') {
      if (target.value[target.selectionStart] === '@') {
        setSelectedSuggestion(null);
        setDisplaySuggestions(false);
      }
    }

    if (code === 'Space') {
      if (displaySuggestions) {
        setSelectedSuggestion(null);
        setDisplaySuggestions(false);
      }
    }

    if (key === 'Enter') {
      if (selectedSuggestion !== null) {
        e.preventDefault();
        if (searchResults.length > 0) {
          handleSuggestionSelection({ result: searchResults[selectedSuggestion] });
        }
      }
    }

    if (key === 'ArrowDown') {
      if (selectedSuggestion !== null) {
        if (searchResults.length === selectedSuggestion + 1) {
          setSelectedSuggestion(0);
        } else {
          setSelectedSuggestion(selectedSuggestion + 1);
        }
      }
    }

    if (key === 'ArrowUp') {
      e.preventDefault();
      if (selectedSuggestion !== null) {
        if (selectedSuggestion === 0) {
          setSelectedSuggestion(searchResults.length - 1);
        } else {
          setSelectedSuggestion(selectedSuggestion - 1);
        }
      }
    }
  };

  const handleTextInputFocus = (e) => {
    e.persist();

    if (isMobile && showEmojisSelector) {
      setShowEmojisSelector(false);
    }
  };

  const resetEditingCommentState = () => {
    setEditingComment(false);
    setIdOfCommentCurrentlyBeingEdited(null);
    setChatTextInputValue('');
    setTextOfCommentBeforeBeingEdited('');
    setChatPreviousTextInputValue('');
    setSelectedSuggestion(null);
    setDisplaySuggestions(false);
    setTaggedUsers([]);
  };

  const handleSubmitEventComment = (e) => {
    e.preventDefault();
    if (e.target.value !== '' && e.target.value !== ' ') {
      if (!editingComment) {
        firebase.interaction.chat
          .submitEventComment({
            avatarUrl: user.avatarUrl,
            eventId,
            name: user.name,
            profession: user.profession,
            text: chatTextInputValue,
            uid: user.uid,
            company: user.company,
            socials: user.socials,
            tags: {
              status: taggedUsers.length > 0,
              taggedUsers: taggedUsers.length > 0 ? taggedUsers : []
            }
          })
          .then(() => {
            setChatTextInputValue('');
            setChatPreviousTextInputValue('');
            setSelectedSuggestion(null);
            setDisplaySuggestions(false);
            setTaggedUsers([]);
            if (isMobile) {
              scrollToAnchor('livestream', { offset: -90, duration: 800 });
            }
          })
          .catch(console.error);
      } else {
        firebase.interaction.chat
          .editEventComment({
            eventId,
            commentId: idOfCommentCurrentlyBeingEdited,
            updatedText: chatTextInputValue,
            updatedTags: {
              status: taggedUsers.length > 0,
              taggedUsers
            }
          })
          .then(() => {
            resetEditingCommentState();
            if (isMobile) {
              scrollToAnchor('livestream', { offset: -90, duration: 800 });
            }
          })
          .catch(console.error);
      }
    }
  };

  const handleEditComment = ({ commentId, text, tagsArray }) => {
    if (editingComment && commentId === idOfCommentCurrentlyBeingEdited) {
      resetEditingCommentState();
    } else {
      setEditingComment(true);
      setIdOfCommentCurrentlyBeingEdited(commentId);
      setChatTextInputValue(text);
      setTextOfCommentBeforeBeingEdited(text);
      setTaggedUsers(tagsArray);
      setChatPreviousTextInputValue(text);
    }
  };

  const handleDeleteComment = ({ commentId }) => {
    if (editingComment && commentId === idOfCommentCurrentlyBeingEdited) {
      resetEditingCommentState();
    }
    firebase.interaction.chat.deleteEventComment({ eventId, commentId });
  };

  const handlePinComment = ({ commentId, isPinned }) => {
    if (editingComment && commentId !== idOfCommentCurrentlyBeingEdited) {
      resetEditingCommentState();
    }
    if (isPinned) {
      firebase.interaction.chat.unpinEventComment({ eventId, commentId });
    } else {
      firebase.interaction.chat.pinEventComment({ eventId, commentId });
    }
  };

  const handleLikeComment = ({ commentId }) => {
    firebase.interaction.chat.likeEventComment({ eventId, commentId, uid: user?.uid });
  };

  const handleEmojiClick = ({ emojiType }) =>
    firebase.interaction.emojis.sendEmoji({ eventId, emojiType }).catch(console.error);

  const formatTimestamp = (commentTimestamp) => {
    if (new Date().getUTCFullYear() > new Date(commentTimestamp.toMillis()).getUTCFullYear()) {
      return format(commentTimestamp.toDate(), 'h:mmaaa  ·  MMM d, YYY');
    }

    if (
      new Date().setHours(0, 0, 0, 0) > new Date(commentTimestamp.toMillis()).setHours(0, 0, 0, 0)
    ) {
      return format(commentTimestamp.toDate(), 'h:mmaaa  ·  MMM d');
    }

    return format(commentTimestamp.toDate(), 'h:mmaaa');
  };

  const handleEmojisKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      setShowEmojisSelector(true);
    } else if (e.key === 'Escape' && showEmojisSelector) {
      setShowEmojisSelector(false);
    }
  };

  const handleEmojiKeyDown = ({ e, emojiType }) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      handleEmojiClick({ emojiType });
    } else if (e.key === 'Escape' && showEmojisSelector) {
      setShowEmojisSelector(false);
    }
  };

  return (
    <Wrapper>
      <EventComments colors={colors}>
        {allComments.length > 0 ? (
          <AnimatePresence initial={false}>
            {allComments.map((comment) => (
              <Comment
                layout
                key={comment.commentId}
                colors={colors}
                isPinned={comment.pinned.status}
                initial={{
                  scale: 0,
                  opacity: 0
                }}
                animate={{
                  scale: 1,
                  opacity: 1,
                  transition: {
                    duration: 0.9,
                    type: 'spring'
                  }
                }}
                exit={{
                  scale: 0,
                  opacity: 0,
                  transition: {
                    duration: 0.6,
                    type: 'tween'
                  }
                }}>
                {comment.avatarUrl ? (
                  <CommenterAvatar
                    onClick={() => handleParticipantAvatarOrNameClick(comment)}
                    size="1.625rem"
                    src={comment.avatarUrl}
                    alt={comment.name}
                    colors={colors}
                  />
                ) : (
                  <DefaultCommenterAvatar
                    onClick={() => handleParticipantAvatarOrNameClick(comment)}
                  />
                )}
                <CommentTextAndButtons colors={colors}>
                  <p>
                    <span
                      onClick={() => handleParticipantAvatarOrNameClick(comment)}
                      role="button"
                      tabIndex={0}>
                      {comment.name}
                    </span>
                    <Interweave
                      content={comment.text}
                      matchers={[
                        new UrlMatcher('url'),
                        new MentionsMatcher('mention'),
                        new EmailMatcher('email')
                      ]}
                      colors={colors}
                      noWrap
                      handleParticipantAvatarOrNameClick={handleParticipantAvatarOrNameClick}
                      taggedUsers={comment.tags.status ? comment.tags.taggedUsers : null}
                      tagName="q"
                      newWindow
                    />
                  </p>
                  {comment.timestamp && <Timestamp>{formatTimestamp(comment.timestamp)}</Timestamp>}
                  {(user?.isModerator?.includes(eventId) || user?.uid === comment.uid) && (
                    <CommentOptions
                      whileTap={{ scale: 0.9 }}
                      onClick={() => handleDeleteComment({ commentId: comment.commentId })}>
                      Delete
                    </CommentOptions>
                  )}
                  {user?.uid === comment.uid && (
                    <CommentOptions
                      whileTap={{ scale: 0.9 }}
                      onClick={() =>
                        handleEditComment({
                          commentId: comment.commentId,
                          text: comment.text,
                          tagsArray: comment.tags.taggedUsers
                        })
                      }
                      style={{
                        opacity:
                          editingComment && comment.commentId === idOfCommentCurrentlyBeingEdited
                            ? 0.4
                            : 1
                      }}>
                      Edit
                    </CommentOptions>
                  )}
                  {user?.isModerator?.includes(eventId) && (
                    <PinComment
                      isPinned={comment.pinned.status}
                      whileTap={{ scale: 0.9 }}
                      onClick={() =>
                        handlePinComment({
                          commentId: comment.commentId,
                          isPinned: comment.pinned.status
                        })
                      }
                      colors={colors}>
                      <Pin
                        stroke={comment.pinned.status ? colors.secondary : '#000'}
                        fill={comment.pinned.status ? colors.secondary : 'transparent'}
                      />
                      {comment.pinned.status ? 'Unpin' : 'Pin'}
                    </PinComment>
                  )}
                  <LikeComment>
                    <Heart
                      fill={comment.likes.likedBy.includes(user?.uid) ? '#FF7AA1' : '#fff'}
                      stroke={comment.likes.likedBy.includes(user?.uid) ? '#FF7AA1' : '#000'}
                      onClick={() => handleLikeComment({ commentId: comment.commentId })}
                      disabled={
                        comment.uid === user?.uid || comment.likes.likedBy.includes(user?.uid)
                      }
                    />
                    <span>{comment.likes.total > 0 ? comment.likes.total : ''}</span>
                  </LikeComment>
                </CommentTextAndButtons>
              </Comment>
            ))}
          </AnimatePresence>
        ) : (
          <p style={{ color: '#000', paddingTop: '1.25rem' }}>Add a comment below...</p>
        )}
      </EventComments>
      <CommentForm onSubmit={handleSubmitEventComment} autoComplete="off">
        <AddComment>
          <Suggestions
            colors={colors}
            id="tooltip"
            aria-hidden={displaySuggestions ? 'false' : 'true'}
            display={displaySuggestions}>
            <ul>
              {searchResults?.length > 0 ? (
                searchResults.map((result, index) => (
                  <Suggestion
                    key={result.uid}
                    colors={colors}
                    style={{}}
                    selected={selectedSuggestion === index}>
                    <button
                      type="button"
                      tabIndex={index}
                      onClick={() => {
                        setSelectedSuggestion(index);
                        handleSuggestionSelection({ result });
                      }}>
                      {result.avatarUrl ? (
                        <CommenterAvatar
                          size="1.625rem"
                          src={result.avatarUrl}
                          alt={result.name}
                          colors={colors}
                        />
                      ) : (
                        <DefaultCommenterAvatar />
                      )}
                      {result.name}
                    </button>
                  </Suggestion>
                ))
              ) : (
                <Suggestion key="no-users" colors={colors} selected={false}>
                  <div>
                    <DefaultCommenterAvatar />
                    No participants found
                  </div>
                </Suggestion>
              )}
            </ul>
          </Suggestions>
          <CommentInput
            onChange={handleTextInput}
            onKeyDown={handleTextInputKeydown}
            onFocus={handleTextInputFocus}
            value={chatTextInputValue}
            id="comment"
            name="comment"
            type="text"
            placeholder="Type your message..."
            colors={colors}
          />
          <SubmitComment disabled={disableSubmitCommentButton} type="submit" color="secondary">
            <CommentArrow stroke={disableSubmitCommentButton ? '#c4c4c4' : '#fff'} width="1rem" />
          </SubmitComment>
          <div
            style={{ position: 'relative', overflow: 'visible' }}
            onMouseLeave={() => !isMobile && setShowEmojisSelector(false)}>
            <Emojis
              onTouchEnd={() => isMobile && setShowEmojisSelector((currentValue) => !currentValue)}
              onMouseEnter={() => !isMobile && setShowEmojisSelector(true)}
              onKeyDown={handleEmojisKeyDown}
              tabIndex={0}
              disableRipple
              color="secondary"
              variant={showEmojisSelector ? 'contained' : 'outlined'}>
              <ThumbsUp fill={showEmojisSelector ? '#fff' : colors.secondary} width="1.25rem" />
            </Emojis>
            <AnimatePresence>
              {showEmojisSelector && (
                <EmojiSelector
                  variants={fadeInAndOutVariants({
                    exitDelay: 0.1
                  })}
                  initial="initial"
                  animate="animate"
                  exit="exit">
                  <div>
                    <Emoji
                      tabIndex={0}
                      onClick={() => handleEmojiClick({ emojiType: 'smile' })}
                      onKeyDown={(e) => handleEmojiKeyDown({ e, emojiType: 'smile' })}>
                      <EmojiSmile />
                    </Emoji>
                    <Emoji
                      tabIndex={0}
                      onClick={() => handleEmojiClick({ emojiType: 'love' })}
                      onKeyDown={(e) => handleEmojiKeyDown({ e, emojiType: 'love' })}>
                      <EmojiLove />
                    </Emoji>
                    <Emoji
                      tabIndex={0}
                      onClick={() => handleEmojiClick({ emojiType: 'like' })}
                      onKeyDown={(e) => handleEmojiKeyDown({ e, emojiType: 'like' })}>
                      <EmojiLike />
                    </Emoji>
                    <Emoji
                      tabIndex={0}
                      onClick={() => handleEmojiClick({ emojiType: 'clap' })}
                      onKeyDown={(e) => handleEmojiKeyDown({ e, emojiType: 'clap' })}>
                      <EmojiClap />
                    </Emoji>
                    <div />
                  </div>
                </EmojiSelector>
              )}
            </AnimatePresence>
          </div>
        </AddComment>
      </CommentForm>
    </Wrapper>
  );
}

const Wrapper = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  background-color: #fff;
`;

export const CommenterAvatar = styled.img`
  border: 0.125rem solid ${({ colors }) => colors.secondary};
  border-radius: 50%;
  height: ${({ size }) => size};
  object-fit: cover;
  position: relative;
  width: ${({ size }) => size};
`;

const AddComment = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const CommentForm = styled.form`
  background: white;
  border-top: 1px solid #c4c4c4;
  bottom: 0;
  height: auto;
  left: 0;
  padding: 1rem;
  position: absolute;
  width: 100%;
  z-index: 1;
`;

const EventComments = styled.div`
  border-top: none;
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  height: calc(100% - 4.5rem);
  min-height: 18.75rem;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 1rem;
  width: 100%;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.31rem grey;
    border-radius: 0.625rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ colors }) => colors.secondary};
    border-radius: 0.625rem;
  }
`;

const Comment = styled(motion.div)`
  align-items: flex-start;
  background-color: ${({ isPinned, colors }) =>
    isPinned ? hexToRGB({ color: colors.secondary, alpha: 0.2 }) : 'transparent'};
  color: #000;
  display: flex;
  margin-bottom: 0.7em;
  padding: 0.125em 0.25em 0;

  &:first-of-type {
    margin-top: 1.25rem;
  }

  /* Avatars */
  > img:first-child,
  > svg:first-child {
    flex-shrink: 0;
    margin-right: 0.5rem;
    position: relative;
    top: 0.313rem;
    width: 1.625rem;
    cursor: pointer;
  }

  > div {
    bottom: 0.375rem;
    position: relative;

    p {
      color: ${({ isPinned, colors }) => (isPinned ? colors.secondary : '#000')};
      font-size: 1rem;
      line-height: 1.25em;
      margin-top: 0.575em;

      q {
        &:before {
          content: '';
        }
        &:after {
          content: '';
        }
      }

      span {
        color: ${({ isPinned, colors }) => (isPinned ? colors.secondary : '#c4c4c4')};
        cursor: pointer;
        font-weight: 600;
        margin-right: 0.475rem;
      }
    }
  }
`;

const CommentTextAndButtons = styled.div`
  p {
    color: ${({ colors }) => colors.secondary};
  }

  a {
    color: ${({ colors }) => colors.tertiary};
    text-decoration: underline;
  }
`;

const Timestamp = styled.span`
  bottom: 0.125em;
  display: inline-block;
  font-size: 0.65rem;
  left: 0.063em;
  position: relative;
`;

const CommentOptions = styled(motion.button)`
  background-color: transparent;
  bottom: 0.125em;
  cursor: pointer;
  font-size: 0.65rem;
  margin-left: 0.5em;
  position: relative;
  text-decoration: underline;
`;

export const PinComment = styled(CommentOptions)`
  color: ${({ isPinned, colors }) => (isPinned ? colors.secondary : '#000')};
  margin-left: 2.15em;
  position: relative;

  > svg {
    bottom: -0.1em;
    height: 1.2em;
    left: -1.45em;
    position: absolute;
    width: 1.2em;
  }
`;

const LikeComment = styled.div`
  align-items: center;
  bottom: 0.25em;
  color: #000;
  display: inline-flex;
  font-size: 0.65rem;
  margin-left: 0.85em;
  position: absolute;
  text-decoration: none;

  > span {
    margin-left: 0.45em;
  }
`;

const Buttons = styled.div`
  /* display: flex; */
  /* position: relative; */
  /* width: 6.25rem; */
`;

const CommentInput = styled.input`
  align-items: center;
  background-color: transparent;
  border: 1px solid #c4c4c4;
  border-bottom: 1px solid;
  border-color: #c4c4c4;
  border-radius: 2px;
  color: #00004e;
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  height: 2.5rem;
  justify-content: flex-start;
  letter-spacing: 0.5px;
  line-height: 2rem;
  outline-color: #c4c4c4;
  padding: 0.625rem;
  width: calc(100% - 6.25rem);

  span {
    color: ${({ colors }) => colors.secondary};
  }

  &:focus {
    outline-color: ${({ colors, theme }) =>
      theme.className === 'contrast' ? theme.secondary : colors.secondary};
  }

  ::placeholder {
    color: #bdbdbd;
    font-size: 1rem;
    font-style: italic;
    font-weight: 400;
  }
`;

const SubmitComment = styled(Button)`
  border-radius: 2px !important;
  height: 2.5rem;
  min-width: initial !important;
  padding: initial !important;
  position: relative !important;
  width: 2.5rem;
`;

const Emojis = styled(Button)`
  border-radius: 2px !important;
  height: 2.5rem !important;
  min-width: initial !important;
  padding: initial !important;
  width: 2.5rem !important;
`;

const EmojiSelector = styled(motion.div)`
  cursor: pointer;
  height: 3.25rem;
  position: absolute;
  right: -0.063rem;
  top: -3.125rem;
  width: 10rem;

  > div {
    background-color: #fff;
    box-shadow: 0px 0.25rem 0.625rem rgba(0, 0, 0, 0.14);
    display: flex;
    height: 2.5rem;
    justify-content: space-between;
    padding: 0.5rem 0.688rem;
    position: relative;

    /* Speech bubble triangle */
    > div {
      background-color: #fff;
      bottom: -0.25rem;
      height: 0.5rem;
      pointer-events: none;
      position: absolute;
      right: 0.875rem;
      transform: rotate(45deg);
      width: 0.5rem;
    }
  }
`;

const Emoji = styled(motion.span).attrs({
  whileTap: {
    scale: 0.9
  },
  whileHover: {
    scale: 1.1
  }
})`
  display: inline-flex;

  svg {
    width: 1.5rem;
  }
`;

const Suggestions = styled(motion.div)`
  bottom: 90%;
  height: auto;
  left: 0%;
  opacity: ${({ display }) => (display ? '1' : '0')};
  padding-left: 1rem;
  pointer-events: ${({ display }) => (display ? 'auto' : 'none')};
  position: absolute;
  transition: ${({ display }) => (display ? 'opacity 500ms ease-in' : 'none')};
  ul {
    -ms-overflow-style: none;
    background: #ffffff;
    border: ${({ colors }) => `2px solid ${colors.secondary}`};
    border-radius: 4px;
    list-style-type: none;
    position: relative;
    width: 100%;

    li {
      align-items: center;
      display: flex;
      font-size: 0.75rem;
      width: 100%;

      button {
        align-items: center;
        cursor: pointer;
        display: flex;
        gap: 0.5rem;
        padding: 0.5rem;
        width: 100%;
        svg {
          height: 1.625rem;
          width: 1.625rem;
        }
      }

      div {
        align-items: center;
        background: transparent;
        color: #c4c4c4;
        cursor: default;
        display: flex;
        gap: 0.5rem;
        padding: 0.5rem;
        width: 100%;
        svg {
          height: 1.625rem;
          width: 1.625rem;
        }
      }

      &:not(:last-child) {
        padding-bottom: 0.5rem;
      }

      &:hover {
        button {
          background-color: ${({ colors }) => colors.secondary};
          color: #ffffff !important;
          transition: all 200ms ease-in-out;
        }
      }
    }
  }
`;

const Suggestion = styled.li`
  button {
    background: ${({ selected, colors }) => (selected ? colors.secondary : 'transparent')};
    color: ${({ selected }) => (selected ? '#FFFFFF' : '#c4c4c4')};
  }

  &:hover {
    button {
      background-color: ${({ colors }) => colors.secondary};
      color: #ffffff !important;
      transition: all 200ms ease-in-out;
    }
  }
`;

export default Chat;
