import React, { useContext, useState } from 'react';
import { FirebaseContext, LocalContext } from 'context';
import BaseSelect from 'react-select';
import FixRequiredSelect from 'components/Forms/FixRequiredSelect';
import { Button } from 'components';
import { Container, Title, Card, Label } from 'components/Admin/Shared';
import { hexToRGB } from 'utils';

function FormSelect(props) {
  return <FixRequiredSelect {...props} SelectComponent={BaseSelect} options={props.options} />;
}

const customDropdownMenuStyles = (isMulti) => ({
  container: (provided) => ({
    ...provided,
    alignItems: 'center',
    alignSelf: 'stretch',
    background: 'transparent',
    border: '1px solid #C4C4C4',
    borderRadius: '0px',
    display: 'flex',
    fontFamily: "'Helvetica Neue', sans-serif",
    fontSize: '1rem',
    minHeight: '40px',
    height: '100%',
    paddingLeft: '1.25rem',
    marginTop: '12px'
  }),
  control: (provided, state) => ({
    ...provided,
    background: 'transparent',
    border: 'none',
    borderRadius: '0px',
    boxShadow: 'none',
    padding: state.isFocused ? '0 0 0 0.571em' : '0 0 0 0.571em',
    transition: 'none',
    cursor: 'pointer',
    height: '100%',
    width: '100%'
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    alignItems: isMulti ? 'start' : 'center'
  }),
  clearIndicator: (provided) => ({
    ...provided,
    '&:hover': {
      color: '#004D9A'
    }
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#004D9A',
    '&:hover': {
      color: '#004D9A'
    }
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  input: (provided) => ({
    ...provided,
    color: '#004D9A',
    margin: 0
  }),
  menu: (provided) => ({
    ...provided,
    background: '#FFFFFF',
    border: 'none',
    borderRadius: '2px',
    boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.25)',
    fontFamily: "'Helvetica Neue', sans-serif",
    fontSize: '1rem',
    left: '-1px',
    margin: '0',
    top: '100%',
    width: 'calc(100% + 2px)'
  }),
  menuList: (provided) => ({
    ...provided,
    height: '100%',
    maxHeight: '200px',
    overflowY: 'auto',
    padding: '0',
    '::-webkit-scrollbar': {
      width: '0.5rem'
    },
    '::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 0.31rem grey',
      borderRadius: '0.625rem'
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#004D9A',
      borderRadius: '0.625rem'
    }
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    color: '#004D9A'
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'rgba(255, 122, 161, 0.15)' : 'transparent',
    paddingLeft: '1.75rem',
    color: '#004D9A',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(255, 122, 161, 0.35)'
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#004D9A',
    fontFamily: "'Helvetica Neue', sans-serif",
    fontSize: '1rem',
    fontStyle: 'italic',
    fontWeight: '300',
    margin: 0,
    opacity: 0.75
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#004D9A',
    fontWeight: '700',
    margin: 0
  }),
  multiValueContainer: (provided) => ({
    ...provided,
    position: 'absolute'
  }),
  multiValue: (provided) => ({
    ...provided,
    fontWeight: '700',
    backgroundColor: `${hexToRGB({ color: '#004D9A', alpha: 0.25 })}`,
    margin: 0
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: '#004D9A'
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: '#004D9A',
    '&:hover': {
      backgroundColor: `${hexToRGB({ color: '#004D9A', alpha: 0.25 })}`,
      color: '#004D9A'
    }
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0.5rem 0',
    gap: '0.25rem'
  })
});

const reports = [
  {
    label: 'User Report',
    value: 'User Report'
  },
  {
    label: 'Logged In On Day Report',
    value: 'Logged In On Day Report'
  },
  {
    label: 'Comments Report',
    value: 'Comments Report'
  }
];

function Reports({ events }) {
  const { theme } = useContext(LocalContext);
  const { firebase } = useContext(FirebaseContext);
  const [selectedEvent, setSelectedEvents] = useState(events.length === 1 ? events[0] : null);
  const [selectedReport, setSelectedReport] = useState(null);
  const [generatingReport, setGeneratingReport] = useState(null);

  const handleGenerateUserReport = () => {
    setGeneratingReport(true);
    firebase.userProfile
      .generateUsersReport({
        selectedEvent: {
          eventId: selectedEvent.eventId,
          name: selectedEvent.name
        }
      })
      .then(({ data }) => {
        setGeneratingReport(false);
        return window.open(data, '_blank') || window.location.replace(data);
      })
      .catch((err) => {
        console.error(err);
        setGeneratingReport(false);
      });
  };

  const handleGenerateLoggedInOnTheDayReport = async () => {
    setGeneratingReport(true);
    try {
      firebase.userProfile
        .generateLoggedInOnTheDayReport({
          eventId: selectedEvent.eventId,
          eventName: selectedEvent.name,
          startTime: selectedEvent.start[0]
        })
        .then(({ data: _data }) => window.open(_data, '_blank') || window.location.replace(_data))
        .catch(console.error)
        .finally(() => setGeneratingReport(false));
    } catch (error) {
      console.error(error);
    }
  };

  const handleGenerateEventCommentsReport = async () => {
    try {
      setGeneratingReport(true);
      const { data } = await firebase.userProfile.generateEventCommentsReport({
        eventId: selectedEvent.eventId,
        eventName: selectedEvent.name
      });
      return window.open(data, '_blank') || window.location.replace(data);
    } catch (error) {
      console.error(error);
    } finally {
      setGeneratingReport(false);
    }
  };

  const handleGenerateReport = () => {
    if (selectedReport.value === 'User Report') {
      handleGenerateUserReport();
    }

    if (selectedReport.value === 'Logged In On Day Report') {
      handleGenerateLoggedInOnTheDayReport();
    }

    if (selectedReport.value === 'Comments Report') {
      handleGenerateEventCommentsReport();
    }
  };

  return (
    <Container id="reports">
      <Title>Reports</Title>
      <Card>
        {events.length > 1 && (
          <div>
            <Label>Event</Label>
            <FormSelect
              value={events.find(
                ({ value }) => value === `${selectedEvent?.eventId} - ${selectedEvent?.name}`
              )}
              onChange={setSelectedEvents}
              // isMulti
              placeholder="Select"
              styles={customDropdownMenuStyles()}
              options={events}
            />
          </div>
        )}
        <div>
          <Label>Report Type</Label>
          <FormSelect
            value={selectedReport}
            onChange={setSelectedReport}
            placeholder="Select"
            styles={customDropdownMenuStyles()}
            options={reports}
          />
        </div>
        <Button
          disabled={!selectedEvent || !selectedReport}
          onClick={() => handleGenerateReport()}
          loading={generatingReport}
          loadingButton
          color="primary"
          size="large"
          style={{
            margin: '0.75rem auto 0'
          }}>
          Generate Report
        </Button>
      </Card>
    </Container>
  );
}

export default Reports;
