import React, { useState, useEffect, useContext, useRef } from 'react';
import { BioEditor } from 'components';
import { LocalContext, FirebaseContext } from 'context';
import styled, { css } from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { FieldContainer, FieldSection } from '../Shared';
import { FormInput, FormInputLabel } from '../../FormComponents';

function MainFields({
  formValues,
  setFormValues,
  handleInputChange,
  isSearchResultDropDownMenuOpen,
  setIsSearchResultDropDownMenuOpen,
  searchResultSpeakers,
  setSpeakerCurrentlyBeingEdited,
  errorMessage,
  setErrorMessage,
  saveButtonRef
}) {
  const { selectedEvent } = useContext(LocalContext);
  const { firebase } = useContext(FirebaseContext);
  const [userIsSelectingSpeaker, setUserIsSelectingSpeaker] = useState(false);
  const [currentlyFocusedSpeakerId, setCurrentlyFocusedSpeakerId] = useState('');

  const nameInputRef = useRef(null);
  const searchResultSpeakersDropdownRef = useRef(null);

  const closeSearchResultDropdownMenuAndResetValues = () => {
    setCurrentlyFocusedSpeakerId('');
    setUserIsSelectingSpeaker(false);
    setIsSearchResultDropDownMenuOpen(false);
  };

  useEffect(
    () => () => {
      if (isSearchResultDropDownMenuOpen) {
        closeSearchResultDropdownMenuAndResetValues();
      }
    },
    []
  );

  const handleNameInputClick = () => {
    if (isSearchResultDropDownMenuOpen) {
      closeSearchResultDropdownMenuAndResetValues();
    }
  };

  const handleNameInputBlur = () => {
    if (formValues.name && searchResultSpeakers.length && !userIsSelectingSpeaker) {
      closeSearchResultDropdownMenuAndResetValues();
    }
  };

  const handleSearchResultSpeakerSelection = (searchResultSpeaker) => {
    firebase.admin
      .getSpeakerFromDatabaseWithSpeakerId({ speakerId: searchResultSpeaker.speakerId })
      .then((res) => {
        if (!res.empty) {
          setSpeakerCurrentlyBeingEdited(res.docs[0].data());
          closeSearchResultDropdownMenuAndResetValues();

          // Scrolls to and focuses the 'Save' button after a Speaker has been selected from the
          // search result dropdown menu.
          setTimeout(() => {
            saveButtonRef.current.scrollIntoView({
              behavior: 'smooth'
            });
          }, 300);
          setTimeout(() => {
            saveButtonRef.current.focus();
          }, 550);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleBioEditorChange = (editorState) => {
    if (errorMessage) {
      setErrorMessage('');
    }
    setFormValues((currentValues) => ({
      ...currentValues,
      bio: editorState
    }));
  };

  const handleKeyDown = (e) => {
    if (!userIsSelectingSpeaker) {
      setUserIsSelectingSpeaker(true);
    }
    if (isSearchResultDropDownMenuOpen && searchResultSpeakers.length) {
      if (e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'Tab') {
        e.preventDefault();
        const listItems = Array.from(searchResultSpeakersDropdownRef.current.children);

        if (currentlyFocusedSpeakerId) {
          const indexOfCurrentlyHighlightedSpeaker = searchResultSpeakers.findIndex(
            ({ speakerId }) => speakerId === currentlyFocusedSpeakerId
          );

          if (e.key === 'ArrowUp' || (e.shiftKey && e.key === 'Tab')) {
            if (indexOfCurrentlyHighlightedSpeaker === 0) {
              closeSearchResultDropdownMenuAndResetValues();
              nameInputRef.current.focus();
            } else {
              // Highlight the previous list item
              listItems[indexOfCurrentlyHighlightedSpeaker - 1].focus();

              setCurrentlyFocusedSpeakerId(
                searchResultSpeakers[indexOfCurrentlyHighlightedSpeaker - 1].speakerId
              );
            }
          } else if (
            (e.key === 'ArrowDown' || e.key === 'Tab') &&
            indexOfCurrentlyHighlightedSpeaker !== searchResultSpeakers.length - 1
          ) {
            // Highlight the next list item
            listItems[indexOfCurrentlyHighlightedSpeaker + 1].focus();

            setCurrentlyFocusedSpeakerId(
              searchResultSpeakers[indexOfCurrentlyHighlightedSpeaker + 1].speakerId
            );
          }
        } else if (e.key === 'ArrowUp') {
          setIsSearchResultDropDownMenuOpen(false);
        } else if (e.key === 'ArrowDown' || e.key === 'Tab') {
          listItems[0].focus();
          setCurrentlyFocusedSpeakerId(searchResultSpeakers[0].speakerId);
        }
      } else if ((e.key === 'Enter' || e.key === ' ') && currentlyFocusedSpeakerId) {
        e.preventDefault();
        // Select the currently highlighted list item
        const indexOfCurrentlyHighlightedSpeaker = searchResultSpeakers.findIndex(
          ({ speakerId }) => speakerId === currentlyFocusedSpeakerId
        );
        handleSearchResultSpeakerSelection(
          searchResultSpeakers[indexOfCurrentlyHighlightedSpeaker]
        );
      } else if (e.key === 'Escape') {
        e.preventDefault();
        nameInputRef.current.focus();
        closeSearchResultDropdownMenuAndResetValues();
      }
    }
  };

  return (
    <FieldContainer>
      <FieldSection>
        <FormInputLabel htmlFor="name" style={{ fontWeight: 400 }}>
          Full Name
        </FormInputLabel>
        <div style={{ position: 'relative' }}>
          <NameInput
            ref={nameInputRef}
            id="name"
            name="name"
            autoComplete="off"
            type="text"
            placeholder="e.g. John Smith"
            required
            value={formValues.name}
            isSearchResultDropDownMenuOpen={isSearchResultDropDownMenuOpen}
            onChange={handleInputChange}
            onBlur={handleNameInputBlur}
            onKeyDown={handleKeyDown}
            onClick={handleNameInputClick}
          />
          <AnimatePresence>
            {isSearchResultDropDownMenuOpen && searchResultSpeakers.length && (
              <SearchResultSpeakersDropdown
                ref={searchResultSpeakersDropdownRef}
                colors={selectedEvent.colors}
                onMouseEnter={() => setUserIsSelectingSpeaker(true)}
                onMouseLeave={() => setUserIsSelectingSpeaker(false)}>
                {searchResultSpeakers.map((searchResultSpeaker) => (
                  <SearchResultSpeaker
                    tabIndex={0}
                    id={searchResultSpeaker.speakerId}
                    key={searchResultSpeaker.speakerId}
                    colors={selectedEvent.colors}
                    onKeyDown={handleKeyDown}
                    onClick={() => handleSearchResultSpeakerSelection(searchResultSpeaker)}
                    onBlur={handleNameInputBlur}
                    onMouseEnter={() => setCurrentlyFocusedSpeakerId(searchResultSpeaker.speakerId)}
                    onMouseLeave={() => setCurrentlyFocusedSpeakerId('')}
                    onMouseDown={(e) => {
                      // Prevents temporary loss of focus on the text input element when you
                      // select a speaker in the dropdown.
                      e.preventDefault();
                    }}>
                    {`${searchResultSpeaker.name} - ${searchResultSpeaker.titleOrRole}`}
                  </SearchResultSpeaker>
                ))}
              </SearchResultSpeakersDropdown>
            )}
          </AnimatePresence>
        </div>
        <FormInputLabel htmlFor="titleOrRole" style={{ fontWeight: 400 }}>
          Title/Role
        </FormInputLabel>
        <FormInput
          id="titleOrRole"
          name="titleOrRole"
          type="text"
          required
          value={formValues.titleOrRole}
          onChange={handleInputChange}
          autoComplete="off"
          placeholder="e.g. Head of Medical, CEO, etc."
        />
        <FormInputLabel htmlFor="company" style={{ fontWeight: 400 }}>
          Company/Workplace (Optional)
        </FormInputLabel>
        <FormInput
          id="company"
          name="company"
          type="text"
          value={formValues.company}
          onChange={handleInputChange}
          autoComplete="off"
        />
        <FormInputLabel htmlFor="presentationTitle" style={{ fontWeight: 400 }}>
          Presentation Title (Optional)
        </FormInputLabel>
        <FormInput
          id="presentationTitle"
          name="presentationTitle"
          type="text"
          value={formValues.presentationTitle}
          onChange={handleInputChange}
          autoComplete="off"
        />
      </FieldSection>
      <FieldSection>
        <FormInputLabel htmlFor="bio" style={{ fontWeight: 400 }}>
          Bio
        </FormInputLabel>
        <BioEditor
          editorState={formValues.bio}
          handleBioEditorChange={handleBioEditorChange}
          selectedEvent={selectedEvent}
        />
      </FieldSection>
    </FieldContainer>
  );
}

const NameInput = styled(FormInput)`
  ${({ isSearchResultDropDownMenuOpen }) =>
    isSearchResultDropDownMenuOpen &&
    css`
      border: 0.188em solid
        ${({ theme }) => (theme.className === 'contrast' ? theme.primary : theme.contrast)};
    `};
`;

const SearchResultSpeakersDropdown = styled(motion.ul).attrs({
  initial: {
    opacity: 0,
    height: 0
  },
  animate: {
    opacity: 1,
    height: 'auto'
  },
  exit: {
    opacity: 0,
    height: 0
  }
})`
  background-color: ${({ colors }) => colors.primary};
  border: 0.188em solid #fff;
  border-top: none;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  height: 100%;
  list-style-type: none;
  max-height: 267px;
  position: absolute;
  top: 2.75rem;
  overflow-y: auto;
  width: 100%;
  z-index: 1;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.31rem grey;
    border-radius: 0.625rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ colors }) => colors.tertiary};
    border-radius: 0.625rem;
  }
`;

const SearchResultSpeaker = styled(motion.li)`
  background-color: ${({ colors }) => colors.primary};
  cursor: pointer;
  outline: none;
  padding: 0.625rem 1.25rem;
  transition: background-color 100ms ease-in-out;

  :hover,
  :focus {
    background-color: ${({ colors }) => colors.tertiary};
  }
`;

export default MainFields;
