import React, { useContext } from 'react';
import styled from 'styled-components';
import { Livestream } from 'components';
import { FirebaseContext } from 'context';

function EventHeader(props) {
  const { user } = useContext(FirebaseContext);
  return (
    <Container initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2 }}>
      {user && <Livestream {...props} />}
    </Container>
  );
}

const Container = styled.header`
  align-items: center;
  background: ${({ theme }) => theme.contrast};
  color: ${({ theme }) => theme.primary};
  display: grid;
  grid-column: 1/7;
  grid-template-columns: repeat(6, 1fr);
  height: auto;
  justify-content: center;
  min-height: 100vh;
  overflow: hidden;
  padding: 5rem 0.625rem 0;
  position: relative;
  width: 100%;

  @media only screen and (min-width: 48rem) and (min-height: 1000px) and (max-width: 1150px) {
    min-height: 70vh;
    padding: 5rem 0;
  }
  @media only screen and (min-width: 1150px) {
    grid-column: 1/13;
    grid-template-columns: repeat(12, 1fr);
  }
`;

export default EventHeader;
