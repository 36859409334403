import {
  arrayUnion,
  collectionGroup,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  increment as firestoreIncrement,
  where
} from 'firebase/firestore';

function QAndAFactory(firebase) {
  const submitNewQuestion = async ({ uid, eventId, text, name }) =>
    setDoc(
      doc(firebase.fsdb, 'users', uid, 'questions', `${uid}_${Date.now()}`),
      name
        ? {
            eventId,
            text,
            timestamp: serverTimestamp(),
            uid,
            name,
            starred: {
              status: false,
              timestamp: 0
            },
            seen: {
              status: false,
              timestamp: 0
            },
            upvotes: {
              total: 0,
              upvoters: []
            }
          }
        : {
            eventId,
            text,
            timestamp: serverTimestamp(),
            uid,
            starred: {
              status: false,
              timestamp: 0
            },
            seen: {
              status: false,
              timestamp: 0
            },
            upvotes: {
              total: 0,
              upvoters: []
            }
          }
    );

  const answerThisQuestionLive = async ({ eventId, text }) =>
    updateDoc(doc(firebase.fsdb, 'events', eventId), {
      questionCurrentlyBeingAnsweredLive: text
    });

  const stopShowingAnswerLiveOverlay = async ({ eventId }) =>
    updateDoc(doc(firebase.fsdb, 'events', eventId), {
      questionCurrentlyBeingAnsweredLive: null
    });

  const submitAnswer = async ({ text, questionId, uid }) =>
    updateDoc(doc(firebase.fsdb, 'users', uid, 'questions', questionId), {
      answer: {
        text,
        timestamp: serverTimestamp()
      }
    });

  const deleteUserQuestion = async ({ uid, questionId }) =>
    deleteDoc(doc(firebase.fsdb, 'users', uid, 'questions', questionId));

  const editQuestion = async ({ uid, questionId, text }) =>
    Promise.all([
      updateDoc(doc(firebase.fsdb, 'users', uid, 'questions', questionId), {
        text,
        timestamp: serverTimestamp()
      })
    ]);

  const upvoteQuestion = async ({ quid, questionId, uid }) =>
    Promise.all([
      updateDoc(doc(firebase.fsdb, 'users', quid, 'questions', questionId), {
        'upvotes.total': firestoreIncrement(1),
        'upvotes.upvoters': arrayUnion(uid)
      })
    ]);

  const markQuestionSeen = async ({ uid, questionId }) =>
    updateDoc(doc(firebase.fsdb, 'users', uid, 'questions', questionId), {
      seen: {
        status: true,
        timestamp: serverTimestamp()
      }
    });

  const starQuestion = async ({ uid, questionId }) =>
    updateDoc(doc(firebase.fsdb, 'users', uid, 'questions', questionId), {
      starred: {
        status: true,
        timestamp: serverTimestamp()
      }
    });

  const unstarQuestion = async ({ uid, questionId }) =>
    updateDoc(doc(firebase.fsdb, 'users', uid, 'questions', questionId), {
      starred: {
        status: false,
        timestamp: 0
      }
    });

  const subscribeToEventQuestions = ({ eventId, snapshot }) =>
    onSnapshot(
      query(collectionGroup(firebase.fsdb, 'questions'), where('eventId', '==', eventId)),
      snapshot
    );

  const subscribeToSubmittedQuestions = ({ snapshot, eventId, isModerator }) => {
    let q;
    if (isModerator) {
      q = query(
        collectionGroup(firebase.fsdb, 'questions'),
        where('eventId', '==', eventId),
        orderBy('starred.status', 'desc'),
        orderBy('starred.timestamp', 'asc'),
        orderBy('upvotes.total', 'desc'),
        orderBy('timestamp', 'desc')
      );
    } else {
      q = query(
        collectionGroup(firebase.fsdb, 'questions'),
        where('eventId', '==', eventId),
        orderBy('timestamp', 'desc')
      );
    }
    return onSnapshot(q, snapshot);
  };

  return {
    submitNewQuestion,
    answerThisQuestionLive,
    stopShowingAnswerLiveOverlay,
    submitAnswer,
    deleteUserQuestion,
    editQuestion,
    upvoteQuestion,
    markQuestionSeen,
    starQuestion,
    unstarQuestion,
    subscribeToEventQuestions,
    subscribeToSubmittedQuestions
  };
}

export default QAndAFactory;
