import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { DialogCloseIcon } from 'components';
import { LocalContext, FirebaseContext } from 'context';
import { motion, AnimatePresence } from 'framer-motion';
import { wrap } from '@popmotion/popcorn';
import { hexToRGB } from 'utils';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Arrow, DefaultCommenterAvatar, CommentArrow } from 'assets/svgs';

const variants = {
  enter: (direction) => ({
    opacity: 0,
    x: direction > 0 ? 1000 : -1000
  }),
  center: {
    opacity: 1,
    x: 0,
    zIndex: 1
  },
  exit: (direction) => ({
    opacity: 0,
    x: direction < 0 ? 1000 : -1000,
    zIndex: 0
  })
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => Math.abs(offset) * velocity;

function ProjectDialog({ startPoint, projects, eventId, handleDialog }) {
  const { isMobile } = useContext(LocalContext);
  const [[page, direction], setPage] = useState([startPoint, 0]);
  const [readMoreOpen, setReadMoreOpen] = useState(false);
  const { user, firebase } = useContext(FirebaseContext);
  const [userCommentText, setUserCommentText] = useState('');
  const [commentToEdit, setCommentToEdit] = useState(null);
  const [editComment, setEditComment] = useState(false);
  const [allComments, setAllComments] = useState([]);
  const [deleteCommentId, setDeleteCommentId] = useState();

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  const projectIndex = wrap(0, projects.length, page);

  const project = projects[projectIndex].node;

  useEffect(() => {
    if (firebase) {
      const unsubscribe = firebase.interaction.chat.subscribeToProjectComments({
        projectDocument: `${project.title}-${project.timestamp}`,
        snapshot: (snapshot) => {
          const snapshotComments = [];
          if (snapshot.docs) {
            snapshot.docs.forEach((doc) => {
              snapshotComments.push({
                id: doc.id,
                ...doc.data()
              });
            });
          }

          setAllComments(snapshotComments);
        }
      });
      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [firebase, project.index]);

  const handleUserCommentChange = (e) => {
    e.persist();
    setUserCommentText(e.target.value);
  };

  const handleUserCommentSubmit = (e) => {
    e.preventDefault();
    if (userCommentText !== '') {
      if (!editComment) {
        firebase.interaction.chat
          .postProjectComment({
            avatarUrl: user.avatarUrl,
            eventId,
            name: user.name,
            projectDocument: `${project.title}-${project.timestamp}`,
            text: userCommentText,
            uid: user.uid
          })
          .then(() => setUserCommentText(''))
          .catch(console.error);
      } else {
        firebase.interaction.chat
          .editProjectComment({
            projectDocument: `${project.title}-${project.timestamp}`,
            commentId: commentToEdit,
            updatedText: userCommentText
          })
          .then(() => {
            setEditComment(false);
            setCommentToEdit('');
            setUserCommentText('');
          });
      }
    }
  };

  const handleDeleteComment = ({ commentId }) => {
    setDeleteCommentId(commentId);
    if (commentId === commentToEdit) {
      setEditComment(false);
      setCommentToEdit('');
      setUserCommentText('');
    }
    firebase.interaction.chat
      .deleteProjectComment({
        projectDocument: `${project.title}-${project.timestamp}`,
        commentId
      })
      .catch(console.error);
  };

  const handleEditComment = ({ commentId }) => {
    firebase.interaction.chat
      .fetchProjectComment({
        eventId,
        projectDocument: `${project.title}-${project.timestamp}`,
        commentId
      })
      .then((doc) => {
        if (doc.exists) {
          setEditComment(true);
          setUserCommentText(doc.data().text);
          setCommentToEdit(commentId);
        }
      });
  };

  return (
    <Backdrop>
      {isMobile && (
        <Carousel>
          <PosterContainer>
            <LiveStream>
              <a href={project.localPDF.publicURL} target="__blank">
                <StreamPlaceholder image={getImage(project.localPreviewImg)} />
                <span>View Poster</span>
              </a>
            </LiveStream>
            <Project>
              <ProjectAuthor>{project.author}</ProjectAuthor>
              {/* <ProjectPosition>{project.position}</ProjectPosition> */}
              {/* <ProjectSocials>
                <ProjectSocial href={project.fbLink}>
                  <FB />
                </ProjectSocial>
                <ProjectSocial href={project.linkedinLink}>
                  <IN />
                </ProjectSocial>
                <ProjectSocial href={project.twLink}>
                  <TW />
                </ProjectSocial>
                <ProjectSocial href={project.emailLink}>
                  <Email />
                </ProjectSocial>
              </ProjectSocials> */}
              <ProjectTitles>
                {/* {project.title.map((topic, i) => (
                  <ProjectTitle key={i}>{topic}</ProjectTitle>
                ))} */}
                <ProjectTitle>{project.title}</ProjectTitle>
              </ProjectTitles>
              <ProjectSummary open={readMoreOpen}>{project.summary}</ProjectSummary>
              <ReadMoreButton
                open={readMoreOpen}
                onClick={() => {
                  setReadMoreOpen(true);
                }}>
                Read More
              </ReadMoreButton>
              {firebase && allComments.length && (
                <ProjectComments>
                  {allComments.map((comment) => (
                    <AnimatePresence key={comment.id}>
                      {comment.id !== deleteCommentId && (
                        <motion.div
                          initial={{
                            opacity: 0,
                            height: 0
                          }}
                          animate={{
                            opacity: 1,
                            height: 'auto',
                            transition: {
                              type: 'tween',
                              ease: 'easeInOut',
                              height: {
                                duration: 0.4
                              },
                              opacity: {
                                duration: 0.65,
                                delay: 0.1
                              }
                            }
                          }}
                          exit={{
                            opacity: 0,
                            height: 0,
                            transition: {
                              type: 'tween',
                              ease: 'easeInOut',
                              height: {
                                duration: 0.4,
                                delay: 0.55
                              },
                              opacity: {
                                duration: 0.65
                              }
                            }
                          }}>
                          {comment.avatarUrl ? (
                            <CommenterAvatar
                              size="1.625rem"
                              src={comment.avatarUrl}
                              alt={comment.name}
                            />
                          ) : (
                            <DefaultCommenterAvatar />
                          )}
                          <CommentWrapper>
                            <p>
                              <span>{comment.name}</span>
                              {comment.text}
                            </p>
                            <small>{format(comment.timestamp, 'HH:mm')}</small>
                            {(user?.isAdmin || user?.uid === comment?.uid) && (
                              <DeleteComment
                                whileTap={{ scale: 0.9 }}
                                onClick={() =>
                                  handleDeleteComment({
                                    commentId: comment.id,
                                    commentTimestamp: comment.timestamp
                                  })
                                }>
                                Delete
                              </DeleteComment>
                            )}
                          </CommentWrapper>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  ))}
                  <NewCommentForm onSubmit={handleUserCommentSubmit} autoComplete="off">
                    <AddComment>
                      <NewCommentInput
                        onChange={handleUserCommentChange}
                        value={userCommentText}
                        id="comment"
                        name="comment"
                        type="text"
                        placeholder="Type your message..."
                      />
                      <SubmitComment brighten={userCommentText} type="submit">
                        <CommentArrow />
                      </SubmitComment>
                    </AddComment>
                  </NewCommentForm>
                </ProjectComments>
              )}
            </Project>
          </PosterContainer>
          <DialogCloseIcon onClick={handleDialog} width="0.875em" />
        </Carousel>
      )}
      {!isMobile && (
        <AnimatePresence initial={false} custom={direction} exitBeforeEnter>
          <>
            <Prev onClick={() => paginate(-1)}>
              <Arrow />
            </Prev>
            <Carousel
              key={page}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{ duration: 0.5 }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x);
                if (swipe < -swipeConfidenceThreshold) {
                  paginate(1);
                } else if (swipe > swipeConfidenceThreshold) {
                  paginate(-1);
                }
              }}>
              <PosterContainer>
                <LiveStream>
                  <a href={project.localPDF.publicURL} target="__blank">
                    <StreamPlaceholder image={getImage(project.localPreviewImg)} />
                    <span>View Poster</span>
                  </a>
                </LiveStream>
                <Project>
                  <ProjectAuthor>{project.author}</ProjectAuthor>
                  {/* <ProjectPosition>{project.position}</ProjectPosition> */}
                  {/* <ProjectSocials>
                  <ProjectSocial href={project.fbLink}>
                    <FB />
                  </ProjectSocial>
                  <ProjectSocial href={project.linkedinLink}>
                    <IN />
                  </ProjectSocial>
                  <ProjectSocial href={project.twLink}>
                    <TW />
                  </ProjectSocial>
                  <ProjectSocial href={project.emailLink}>
                    <Email />
                  </ProjectSocial>
                </ProjectSocials> */}
                  <ProjectTitles>
                    {/* {project.title.map((topic, i) => (
                    <ProjectTitle key={i}>{topic}</ProjectTitle>
                  ))} */}
                    <ProjectTitle>{project.title}</ProjectTitle>
                  </ProjectTitles>
                  <ProjectSummary open={readMoreOpen}>{project.summary}</ProjectSummary>
                  <ReadMoreButton
                    open={readMoreOpen}
                    onClick={() => {
                      setReadMoreOpen(true);
                    }}>
                    Read More
                  </ReadMoreButton>
                  {firebase && (
                    <ProjectComments>
                      {allComments.map((comment) => (
                        <AnimatePresence key={comment.id}>
                          {comment.id !== deleteCommentId && (
                            <motion.div
                              initial={{
                                opacity: 0,
                                height: 0
                              }}
                              animate={{
                                opacity: 1,
                                height: 'auto',
                                transition: {
                                  type: 'tween',
                                  ease: 'easeInOut',
                                  height: {
                                    duration: 0.4
                                  },
                                  opacity: {
                                    duration: 0.65,
                                    delay: 0.1
                                  }
                                }
                              }}
                              exit={{
                                opacity: 0,
                                height: 0,
                                transition: {
                                  type: 'tween',
                                  ease: 'easeInOut',
                                  height: {
                                    duration: 0.4,
                                    delay: 0.55
                                  },
                                  opacity: {
                                    duration: 0.65
                                  }
                                }
                              }}>
                              {comment.avatarUrl ? (
                                <CommenterAvatar
                                  size="1.625rem"
                                  src={comment.avatarUrl}
                                  alt={comment.name}
                                />
                              ) : (
                                <DefaultCommenterAvatar />
                              )}
                              <CommentWrapper>
                                <p>
                                  <span>{comment.name}</span>
                                  {comment.text}
                                </p>
                                <small>{format(comment.timestamp, 'HH:mm')}</small>
                                {(user?.isAdmin || user?.uid === comment?.uid) && (
                                  <DeleteComment
                                    whileTap={{ scale: 0.9 }}
                                    onClick={() =>
                                      handleDeleteComment({
                                        commentId: comment.id,
                                        commentTimestamp: comment.timestamp
                                      })
                                    }>
                                    Delete
                                  </DeleteComment>
                                )}
                                {user?.uid === comment?.uid && (
                                  <DeleteComment
                                    whileTap={{ scale: 0.9 }}
                                    onClick={() =>
                                      handleEditComment({
                                        commentId: comment.id,
                                        commentTimestamp: comment.timestamp
                                      })
                                    }
                                    style={
                                      editComment && comment?.id === commentToEdit
                                        ? { cursor: 'default', opacity: '0.4' }
                                        : {}
                                    }>
                                    Edit
                                  </DeleteComment>
                                )}
                              </CommentWrapper>
                            </motion.div>
                          )}
                        </AnimatePresence>
                      ))}
                      <NewCommentForm onSubmit={handleUserCommentSubmit} autoComplete="off">
                        <AddComment>
                          <NewCommentInput
                            onChange={handleUserCommentChange}
                            value={userCommentText}
                            id="comment"
                            name="comment"
                            type="text"
                            placeholder="Type your message..."
                          />
                          <SubmitComment brighten={userCommentText} type="submit">
                            <CommentArrow />
                          </SubmitComment>
                        </AddComment>
                      </NewCommentForm>
                    </ProjectComments>
                  )}
                </Project>
              </PosterContainer>
              <DialogCloseIcon onClick={handleDialog} width="0.875em" />
            </Carousel>
            <Next onClick={() => paginate(1)}>
              <Arrow />
            </Next>
          </>
        </AnimatePresence>
      )}
    </Backdrop>
  );
}

const ReadMoreButton = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.tertiary};
  cursor: pointer;
  display: ${({ open }) => (open ? 'none' : 'flex')};
  font-family: Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  height: 3rem;
  justify-content: center;
  margin-bottom: 1rem;
  outline: none;
  text-transform: uppercase;
  transition: 0.5s;
  width: auto;
`;

const LiveStream = styled.div`
  grid-column: 1/7;
  height: auto;
  margin: 2rem 0;
  position: relative;
  a {
    color: ${({ theme }) => theme.primary};
    display: flex;
    span {
      margin: 2rem auto;
      text-decoration: underline;
    }
    flex-direction: column;
    :visited {
      color: ${({ theme }) => theme.primary};
    }
  }
  @media only screen and (min-width: 48rem) {
    grid-column: 1/3;
  }
  @media only screen and (min-width: 1150px) {
    grid-column: 1/5;
    height: 100%;
    margin: 0px;
  }
`;

const StreamPlaceholder = styled(GatsbyImage)`
  border-radius: 0px;
  box-shadow: ${({ theme }) =>
    theme.className === 'contrast' ? 'none' : '0px.375rem 0.75rem rgba(0,0,0,.25)'};
  cursor: pointer;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  object-position: center;
  transition: 0.5s;
  width: 450px;
`;

const Project = styled.div`
  align-items: flex-start;
  color: ${({ theme }) => theme.primary};
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  height: 100%;
  justify-content: flex-start;
  position: relative;
  @media only screen and (min-width: 48rem) {
    grid-column: 4/7;
  }
  @media only screen and (min-width: 1150px) {
    grid-column: 6/11;
  }
`;

const CommentWrapper = styled.div`
  padding-bottom: 0.8em;
  p,
  small {
    color: ${({ theme }) => theme.primary};
  }
`;

const DeleteComment = styled(motion.small)`
  cursor: pointer;
  margin-left: 0.5em;
  text-decoration: underline;
`;

// const UserAvatar = styled.div`
//   grid-column: 1/2;
//   width: 3.5rem;
//   height: 3.5rem;
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;

//   svg {
//     width: 2.5rem;
//     height: 2.5rem;
//   }
// `;

const Paginate = styled(motion.button)`
  align-items: center;
  background-color: ${({ theme }) => theme.tertiary};
  border: none;
  cursor: pointer;
  display: flex;
  height: 3rem;
  justify-content: center;
  justify-self: end;
  left: initial;
  opacity: 0.8;
  outline: none;
  position: absolute;
  right: initial;
  top: calc(50% - 1.5rem);
  width: 3rem;
  z-index: 3;
  svg {
    transform: rotate(90deg);
    transform-origin: center;
    width: 1.5rem;
    path {
      stroke: ${({ theme }) => (theme.className === 'contrast' ? theme.contrast : '#EAF5F9')};
    }
  }
`;

const Prev = styled(Paginate)`
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  left: 4rem;
  opacity: 0.8;
  padding-left: 0.625rem;
  svg {
    margin-right: 0.5rem;
    transform: rotate(90deg);
  }
`;

const Next = styled(Paginate)`
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
  padding-right: 0.625rem;
  right: 4rem;
  svg {
    margin-left: 0.5rem;
    transform: rotate(-90deg);
  }
`;

const ProjectTitles = styled.p`
  font-size: 1.25rem;
  margin-bottom: 2rem;
  margin-top: 0.5rem;
  text-align: center;
`;

const ProjectTitle = styled.span`
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 0;
  margin-right: 0.375rem;
  margin-top: auto;
  &:after {
    content: ',';
  }
  &:last-child {
    &:after {
      content: '';
    }
  }
`;

const ProjectAuthor = styled.h3`
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: center;
`;

/* TODO: -webkit-box is long deprecated and box-orient is non-standard. Maybe use flexbox here? */
// const ProjectSummary = styled.p`
//   -webkit-line-clamp: ${({ open }) => (open ? 'none' : '5')};
//   box-orient: vertical; /* stylelint-disable-line property-no-unknown */
//   display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
//   font-size: 1rem;
//   font-style: normal;
//   font-weight: 400;
//   height: auto;
//   line-height: 1.5rem;
//   margin-bottom: ${({ open }) => (open ? '3rem' : '.5rem')};
//   max-height: 12rem;
//   max-width: 100%;
//   overflow-y: ${({ open }) => (open ? 'scroll' : 'hidden')};
//   padding-right: 10px;
//   ::-webkit-scrollbar {
//     appearance: none;
//     width: 4px;
//   }

//   ::-webkit-scrollbar-thumb {
//     background-color: rgba(0, 0, 0, 0.5);
//     border-radius: 4px;
//     box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
//   }
// `;

// NOTE: See the commented out code above, specifically the 'box-orient: vertical', and 'display: -webkit-box' CSS. I replcaed them below with flexbox, just so the code would pass Stylelint's pre-commit hook.
// Not sure if they are the right styles though. Will find out when we next clone this repo and need projects/posters.
const ProjectSummary = styled.p`
  -webkit-line-clamp: ${({ open }) => (open ? 'none' : '5')};
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  height: auto;
  line-height: 1.5rem;
  margin-bottom: ${({ open }) => (open ? '3rem' : '.5rem')};
  max-height: 12rem;
  max-width: 100%;
  overflow-y: ${({ open }) => (open ? 'scroll' : 'hidden')};
  padding-right: 10px;
  ::-webkit-scrollbar {
    appearance: none;
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const CommenterAvatar = styled.img`
  border: 0.125rem solid ${({ theme }) => theme.primary};
  border-radius: 50%;
  height: ${({ size }) => size};
  object-fit: cover;
  position: relative;
  width: ${({ size }) => size};
`;

const SubmitComment = styled.button`
  align-items: center;
  background: ${({ brighten, theme }) =>
    brighten
      ? theme.tertiary
      : theme.className === 'contrast'
        ? hexToRGB({ color: theme.primary, alpha: 0.3 })
        : hexToRGB({ color: '#C4C4C4', alpha: 0.3 })};
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  height: 2.5rem;
  justify-content: center;
  margin-left: 1rem;
  min-width: 2.5rem;
  transition: 0.25s;
  svg {
    height: 1rem;
    width: 1rem;
    path {
      stroke: ${({ brighten, theme }) =>
        brighten ? theme.contrast : hexToRGB({ color: theme.contrast, alpha: 0.3 })};
    }
  }
`;

const AddComment = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`;

const NewCommentInput = styled.input`
  align-items: center;
  background: transparent;
  border: 1px solid ${({ theme }) => (theme.className === 'contrast' ? theme.primary : '#c4c4c4')};
  border-bottom: 1px solid;
  border-color: ${({ theme }) => (theme.className === 'contrast' ? theme.primary : '#c4c4c4')};
  border-radius: 2px;
  color: ${({ theme }) => theme.primary};
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  justify-content: flex-start;
  letter-spacing: 0.5px;
  line-height: 2rem;
  outline-color: ${({ theme }) => (theme.className === 'contrast' ? theme.primary : '#c4c4c4')};
  padding: 0.625rem;
  width: 100%;
  ::placeholder {
    color: ${({ theme }) => (theme.className === 'contrast' ? theme.primary : '#BDBDBD')};
    font-size: 1rem;
    font-style: italic;
    font-weight: 400;
  }
`;

const NewCommentForm = styled.form`
  background: ${({ theme }) => theme.contrast};
  border-top: 1px solid
    ${({ theme }) => (theme.className === 'contrast' ? theme.primary : '#c4c4c4')};
  bottom: 0;
  height: auto;
  left: 0;
  padding: 1rem;
  position: absolute;
  width: 100%;
  z-index: 1;
`;

const ProjectComments = styled.div`
  background: ${({ theme }) => theme.contrast};
  border: 1px solid;
  height: 20rem;
  margin-bottom: 0px;
  max-height: 100%;
  overflow-y: scroll;
  padding: 1.25rem;
  position: relative;
  width: 100%;
  > div {
    display: flex;
    img,
    svg {
      flex-shrink: 0;
      margin-right: 0.625rem;
      width: 1.625rem;
    }
    > div {
      bottom: 0.375rem;
      position: relative;
      p {
        color: ${({ theme }) => theme.primary};
        font-size: 1rem;
        line-height: 1.25em;
        margin-top: 0.575em;
      }
      small {
        bottom: 0.125em;
        font-size: 0.65rem;
        left: 0.063em;
        position: relative;
      }
      span {
        color: #c4c4c4;
        font-weight: 700;
        margin-right: 0.475rem;
      }
    }
  }
`;

const PosterContainer = styled.div`
  align-items: center;
  display: grid;
  grid-column: 1/7;
  grid-gap: 0.625rem;
  grid-template-columns: repeat(6, 1fr);
  height: auto;
  max-width: calc(100% + 40px);
  padding: 4rem 1rem;
  position: relative;
  width: 1200px;
  @media only screen and (min-width: 48rem) {
    grid-column: 2/6;
  }
  @media only screen and (min-width: 1150px) {
    grid-column: 2/12;
    grid-template-columns: repeat(10, 1fr);
    grid-gap: 1.25rem;
  }
  @media only screen and (min-width: 1441px) {
    grid-column: 3/11;
  }

  h3,
  label,
  input {
    color: ${({ theme }) => theme.primary} !important;
  }
  @media only screen and (min-width: 1150px) {
    padding: 5rem 7.5rem;
  }
`;

const Carousel = styled(motion.div)`
  align-items: center;
  background: ${({ theme }) => theme.contrast};
  box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.15);
  color: ${({ theme }) => theme.primary};
  display: flex;
  height: auto;
  justify-content: center;
  max-width: 100%;
  min-height: 100vh;
  position: relative;
  width: 1400px;
`;

const Backdrop = styled.div`
  height: auto;
  width: auto;
  @media (max-width: 1150px) {
    overflow-x: hidden;
  }
`;

export default ProjectDialog;
