import { httpsCallable } from 'firebase/functions';

function EmailsFactory(firebase) {
  const sendVerificationEmail = async ({
    firstName,
    email,
    actionCodeSettings,
    colors,
    origin,
    pathname
  }) => {
    const sendVerificationEmailCallable = httpsCallable(
      firebase.functions,
      'emails-send-verification'
    );
    return sendVerificationEmailCallable({
      firstName,
      email: email.toLowerCase().trim(),
      actionCodeSettings,
      colors,
      origin,
      pathname
    });
  };

  const resendVerificationEmail = async ({ email }) => {
    const resendVerificationEmailCallable = httpsCallable(
      firebase.functions,
      'emails-resend-verification'
    );
    return resendVerificationEmailCallable({
      email: email.toLowerCase().trim()
    });
  };

  const sendLoginEmail = async ({ email, actionCodeSettings, colors, origin, pathname }) => {
    const sendLoginEmailCallable = httpsCallable(firebase.functions, 'emails-send-login');
    return sendLoginEmailCallable({
      email: email.toLowerCase().trim(),
      actionCodeSettings,
      colors,
      origin,
      pathname
    });
  };

  const sendReminderEmail = async () => {
    const sendReminderEmailCallable = httpsCallable(firebase.functions, 'sendReminderEmail');
    return sendReminderEmailCallable();
  };

  const sendEventReminderEmail = async ({ event, templateAlias }) => {
    const sendEventReminderEmailCallable = httpsCallable(
      firebase.functions,
      'emails-send-eventReminder'
    );
    return sendEventReminderEmailCallable({ event, templateAlias });
  };

  const sendEventCancellationEmail = async ({ event }) => {
    const sendEventCancellationEmailCallable = httpsCallable(
      firebase.functions,
      'emails-send-eventCancellation'
    );
    return sendEventCancellationEmailCallable({ event });
  };

  const sendEventRegistrationApprovalEmail = async ({ name, email, event }) => {
    const sendEventRegistrationApprovalCallable = httpsCallable(
      firebase.functions,
      'emails-send-registrationApproval'
    );
    return sendEventRegistrationApprovalCallable({ name, email, event });
  };

  const sendAttendanceCerts = async ({
    participants,
    eventId,
    title,
    colors,
    speakers,
    date,
    streamDuration,
    origin
  }) => {
    const sendAttendanceCertsCallable = httpsCallable(
      firebase.functions,
      'emails-send-attendanceCerts'
    );
    return sendAttendanceCertsCallable({
      participants,
      eventId,
      title,
      colors,
      speakers,
      date,
      streamDuration,
      origin
    });
  };

  const parseShortUrl = async (shortUrlId) => {
    const parseShortUrlCallable = httpsCallable(firebase.functions, 'emails-parseShortUrl');
    return parseShortUrlCallable({ shortUrlId });
  };

  return {
    sendVerificationEmail,
    resendVerificationEmail,
    sendLoginEmail,
    sendReminderEmail,
    sendEventReminderEmail,
    sendEventCancellationEmail,
    sendEventRegistrationApprovalEmail,
    sendAttendanceCerts,
    parseShortUrl
  };
}

export default EmailsFactory;
