import { useEffect, useState, useContext } from 'react';
import { initializeApp } from 'firebase/app';
import { onAuthStateChanged } from 'firebase/auth';
import {
  AdminFactory,
  AlgoliaFactory,
  ChatFactory,
  DownloadsFactory,
  EditorFactory,
  EmailHandlerFactory,
  EmailsFactory,
  EmojisFactory,
  EventAnalyticsFactory,
  InteractionFactory,
  LivestreamFactory,
  ModerationFactory,
  ParticipantsFactory,
  PollsFactory,
  ProjectsFactory,
  QAndAFactory,
  RegistrationFactory,
  StripeFactory,
  UserProfileFactory
} from 'firebase';
import { LocalContext } from 'context';
import getFirebaseInstance from './firebase';
import firebaseConfig from './config';

function useAuth() {
  const { browserTabIsActive } = useContext(LocalContext);
  const [user, setUser] = useState(null);
  const [firebase, setFirebase] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userAuthInfo, setUserAuthInfo] = useState(null);

  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    const firebaseInstance = getFirebaseInstance(app);

    firebaseInstance.admin = AdminFactory(firebaseInstance);
    firebaseInstance.algolia = AlgoliaFactory(firebaseInstance);
    firebaseInstance.downloads = DownloadsFactory(firebaseInstance);
    firebaseInstance.editor = EditorFactory(firebaseInstance);
    firebaseInstance.emailHandler = EmailHandlerFactory(firebaseInstance);
    firebaseInstance.emails = EmailsFactory(firebaseInstance);
    firebaseInstance.eventAnalytics = EventAnalyticsFactory(firebaseInstance);
    firebaseInstance.interaction = InteractionFactory(firebaseInstance);
    firebaseInstance.interaction.chat = ChatFactory(firebaseInstance);
    firebaseInstance.interaction.emojis = EmojisFactory(firebaseInstance);
    firebaseInstance.interaction.participants = ParticipantsFactory(firebaseInstance);
    firebaseInstance.interaction.polls = PollsFactory(firebaseInstance);
    firebaseInstance.interaction.qAndA = QAndAFactory(firebaseInstance);
    firebaseInstance.livestream = LivestreamFactory(firebaseInstance);
    firebaseInstance.moderation = ModerationFactory(firebaseInstance);
    firebaseInstance.projects = ProjectsFactory(firebaseInstance);
    firebaseInstance.registration = RegistrationFactory(firebaseInstance);
    firebaseInstance.stripe = StripeFactory(firebaseInstance);
    firebaseInstance.userProfile = UserProfileFactory(firebaseInstance);

    setFirebase(firebaseInstance);

    const unsubscribeFromFirebaseAuthStateChanges = onAuthStateChanged(
      firebaseInstance.auth,
      (_userAuthInfo) => {
        if (_userAuthInfo) {
          setUserAuthInfo(_userAuthInfo);
        } else {
          setUser(null);
          setLoading(false);
        }
      }
    );

    return () => {
      if (unsubscribeFromFirebaseAuthStateChanges) {
        unsubscribeFromFirebaseAuthStateChanges();
      }
    };
  }, []);

  useEffect(() => {
    let unsubscribeFromUserUpdates;

    if (firebase && userAuthInfo && browserTabIsActive) {
      unsubscribeFromUserUpdates = firebase.subscribeToUserUpdates({
        uid: userAuthInfo.uid,
        snapshot: (_user) =>
          firebase.auth.currentUser
            .getIdTokenResult(true)
            .then((token) => {
              if (_user.exists) {
                const userDatabaseInfo = _user.data();
                const lastRefreshAt = new Date(userAuthInfo.reloadUserInfo.lastRefreshAt).getTime();
                setUser({
                  ...userAuthInfo,
                  ...userDatabaseInfo,
                  lastRefreshAt,
                  isAdmin: token.claims.isAdmin,
                  isModerator: token.claims.isModerator,
                  isEditor: token.claims.isEditor
                });
              }
            })
            .catch((error) => console.error(error.message))
            .finally(() => setLoading(false))
      });
    }
    return () => {
      if (unsubscribeFromUserUpdates) {
        unsubscribeFromUserUpdates();
      }
    };
    // We pass 'browserTabIsActive' into the dependency array so that if a user clicks on an old
    // tab after verifying in another tab then we can trigger all the above code again and get all
    // the 'user' doc data. If we didn't do this then the user's name would not show on the top
    // right and the 'Events' dropdown menu would not appear.
  }, [firebase, userAuthInfo, browserTabIsActive]);

  return { user, firebase, loading };
}

export default useAuth;
