import React from 'react';
import styled from 'styled-components';
import { Shine } from 'components';
import { motion, AnimatePresence } from 'framer-motion';
import { MutedIcon } from 'assets/svgs';

function UnmuteOverlay({ handleUnmuteOverlayClick, colors, isStreamMuted }) {
  return (
    <AnimatePresence>
      {isStreamMuted && (
        <Container
          whileTap={{ scale: 0.95 }}
          onClick={() => handleUnmuteOverlayClick()}
          colors={colors}>
          <div>
            <StyledMutedIcon /> Click To Unmute
            <Shine shineDuration={1.325} />
          </div>
        </Container>
      )}
    </AnimatePresence>
  );
}

const Container = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  background-color: ${({ colors, theme }) =>
    theme.className === 'contrast' ? theme.tertiary : colors.tertiary};
  border-radius: 20px;
  cursor: pointer;
  font-size: 1rem;
  position: absolute;
  overflow: hidden;
  text-transform: uppercase;
  top: calc(50% - 30px);
  left: calc(50% - 150px);
  width: 300px;
  height: 60px;
  @media screen and (min-width: 386px) {
    top: 10px;
  }
  @media screen and (min-width: 48rem) {
    left: calc(50% - 200px);
    width: 400px;
    height: 80px;
  }
  > div {
    align-items: center;
    color: ${({ theme }) => theme.contrast};
    display: flex;
    font-weight: bold;
    height: 100%;
    justify-content: center;
    position: relative;
    text-align: center;
    width: 100%;
    svg {
      path {
        fill: ${({ theme }) => theme.contrast};
      }
    }
  }
`;

const StyledMutedIcon = styled(MutedIcon)`
  margin-right: 0.95rem;
  width: 22px;
`;

export default UnmuteOverlay;
