import React from 'react';
import styled from 'styled-components';

function Filters({ selectedFilter, setSelectedFilter }) {
  return (
    <Wrapper
      onClick={(e) => {
        e.stopPropagation();
        setSelectedFilter(e.target.innerText === 'Pending');
      }}>
      <Filter type="button" selected={selectedFilter}>
        Pending
      </Filter>
      <Filter type="button" selected={!selectedFilter}>
        Approved
      </Filter>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  align-self: flex-start;
  margin-bottom: 1.75rem;
`;

const Filter = styled.button`
  background-color: transparent;
  color: ${({ selected }) => (selected ? '#000' : '#808080')};
  cursor: pointer;
  font-size: 1.5rem;
  text-decoration: underline;

  &:first-of-type {
    margin-right: 1em;
  }

  @media only screen and (min-width: 48rem) {
    font-size: 2rem;
  }
`;

export default Filters;
