import React, { useContext, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import {
  SpeakerCard,
  BulletPoint,
  Button,
  Dialog,
  SpeakerCarousel,
  AddOrEditSpeakerContainer
} from 'components';
import { LocalContext, FirebaseContext } from 'context';
import { AnimatePresence } from 'framer-motion';
import { DndContext, closestCenter, PointerSensor, useSensor, DragOverlay } from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { restrictToParentElement } from '@dnd-kit/modifiers';

function Speakers({ staticSpeakers, eventId, colors }) {
  const { theme, selectedEvent, handleDialog, browserTabIsActive } = useContext(LocalContext);
  const { firebase, user } = useContext(FirebaseContext);
  const [speakers, setSpeakers] = useState([]);
  const [speakerCurrentlyBeingEdited, setSpeakerCurrentlyBeingEdited] = useState(null);
  const [speakerCurrentlyBeingDeleted, setSpeakerCurrentlyBeingDeleted] = useState(null);
  const [openSpeakerCarouselAtThisIndex, setOpenSpeakerCarouselAtThisIndex] = useState(null);
  const [idOfSpeakerCurrentlyBeingDragged, setIdOfSpeakerCurrentlyBeingDragged] = useState(null);
  const [firestoreSpeakers, setFirestoreSpeakers] = useState([]);
  const [showAddOrEditSpeakerDialog, setShowAddOrEditSpeakerDialog] = useState(false);
  const [showSpeakerCarouselDialog, setShowSpeakerCarouselDialog] = useState(false);
  const [heightBeforeCardCollapse, setHeightBeforeCardCollapse] = useState(null);

  const speakerCarouselDialogRef = useRef(null);

  const sensors = [useSensor(PointerSensor)];

  const sortSpeakers = (_speakers) => {
    if (_speakers.every((_speaker) => _speaker.orders[eventId])) {
      return _speakers.sort((a, b) => a.orders[eventId] - b.orders[eventId]);
    }
    return _speakers.sort((a, b) => a.lastModified - b.lastModified);
  };

  useEffect(() => {
    let unsubscribeFromFirestoreSpeakers;

    if (firebase && browserTabIsActive && user?.isEditor?.includes(eventId)) {
      unsubscribeFromFirestoreSpeakers = firebase.editor.subscribeToFirestoreSpeakers({
        eventId,
        snapshot: (snapshot) => {
          if (!snapshot.empty) {
            const _firestoreSpeakers = [];

            snapshot.forEach((doc) => {
              _firestoreSpeakers.push(doc.data());
            });

            setFirestoreSpeakers(_firestoreSpeakers);
          } else if (snapshot.empty) {
            setFirestoreSpeakers([]);
          }
        }
      });
    }
    return () => {
      if (unsubscribeFromFirestoreSpeakers) {
        unsubscribeFromFirestoreSpeakers();
      }
    };
  }, [firebase, browserTabIsActive, user]);

  // Diffs the already statically generated speakers and the speakers added to Firestore that
  // haven't been converted to static assets yet.
  useEffect(() => {
    if (user?.isEditor?.includes(eventId)) {
      if (firestoreSpeakers.length) {
        if (staticSpeakers.length) {
          const diffedSpeakers = firestoreSpeakers.map((firestoreSpeaker) => {
            const staticSpeaker = staticSpeakers.find(
              (_staticSpeaker) =>
                _staticSpeaker.speakerId === firestoreSpeaker.speakerId &&
                _staticSpeaker.orders[eventId] === firestoreSpeaker.orders[eventId] &&
                _staticSpeaker.lastModified === firestoreSpeaker.lastModified
            );
            if (staticSpeaker) {
              return staticSpeaker;
            }
            return firestoreSpeaker;
          });
          setSpeakers(sortSpeakers(diffedSpeakers));
        } else {
          setSpeakers(sortSpeakers(firestoreSpeakers));
        }
      } else {
        setSpeakers([]);
      }
    } else if (staticSpeakers.length) {
      setSpeakers(sortSpeakers(staticSpeakers));
    }
  }, [eventId, user?.isEditor, staticSpeakers, firestoreSpeakers]);

  const saveNewOrderOfSpeakers = (reorderedSpeakers) =>
    firebase.editor.saveNewOrderOfSpeakers({
      eventId,
      reorderedSpeakers: reorderedSpeakers.map(({ speakerId }) => ({
        speakerId
      }))
    });

  // We use this function on the EditorButtons component instead of a seperate handler
  // on each individual button because if we just called e.stopPropagation() on the <EditButton />
  // itself then the event would not bubble up to it's <Dialog /> parent, thereby preventing
  // <AddOrEditSpeakerDialog /> from opening.
  const handleEditSpeaker = async ({ e, speaker }) => {
    e.stopPropagation();
    setSpeakerCurrentlyBeingEdited(speaker);
    setShowAddOrEditSpeakerDialog(true);
  };

  const handleDeleteSpeaker = async ({ e, speaker }) => {
    e.stopPropagation();
    try {
      setSpeakerCurrentlyBeingDeleted(speaker);
      await firebase.editor.deleteSpeaker({ eventId, speakerId: speaker.speakerId });
      setSpeakers((currentSpeakers) => {
        const updatedSpeakers = currentSpeakers.filter(
          (_speaker) => _speaker.speakerId !== speaker.speakerId
        );
        saveNewOrderOfSpeakers(updatedSpeakers);
        return sortSpeakers(
          updatedSpeakers.map((_speaker, i) => {
            const order = i + 1;
            let orders;
            if (_speaker.orders) {
              orders = {
                ..._speaker.orders,
                [eventId]: order
              };
            } else {
              orders = {
                [eventId]: order
              };
            }
            return {
              ..._speaker,
              orders
            };
          })
        );
      });
    } catch (error) {
      console.error(error);
      console.error(error.message);
    } finally {
      setSpeakerCurrentlyBeingDeleted(null);
    }
  };

  const handleDragStart = (event) => {
    document.body.style.setProperty('cursor', 'grabbing');
    setIdOfSpeakerCurrentlyBeingDragged(event.active.id);
  };

  const handleDragEnd = ({ active, over }) => {
    document.body.style.setProperty('cursor', '');

    // Briefly prevents the hover animation from being triggered again when you start or stop dragging
    document.body.style.setProperty('pointer-events', 'none');
    setTimeout(() => document.body.style.setProperty('pointer-events', 'auto'), 300);

    if (active.id !== over.id) {
      setSpeakers((_speakers) => {
        const oldIndex = _speakers.findIndex((speaker) => speaker.speakerId === active.id);
        const newIndex = _speakers.findIndex((speaker) => speaker.speakerId === over.id);
        const reorderedSpeakers = arrayMove(_speakers, oldIndex, newIndex);
        saveNewOrderOfSpeakers(reorderedSpeakers);
        setIdOfSpeakerCurrentlyBeingDragged(null);
        return sortSpeakers(
          reorderedSpeakers.map((speaker, i) => ({
            ...speaker,
            orders: {
              [eventId]: i + 1
            }
          }))
        );
      });
    }
  };

  // 'DragOverlay' is the SpeakerCard you've grabbed and are dragging to a new position.
  // 'Placeholder' is the blue outline of the position you're dragging it to.
  const getDragOverlay = (_idOfSpeakerCurrentlyBeingDragged) => {
    const speaker =
      speakers[
        speakers.findIndex((_speaker) => _speaker.speakerId === _idOfSpeakerCurrentlyBeingDragged)
      ];
    return (
      speaker && (
        <SpeakerCard
          isDragOverlay
          key={speaker.speakerId}
          speaker={speaker}
          speakers={speakers}
          eventId={eventId}
          heightBeforeCardCollapse={heightBeforeCardCollapse}
        />
      )
    );
  };

  return (
    <>
      <Container>
        <H5 style={{ textAlign: 'center', color: theme.primary }}>Our Keynote Speakers</H5>
        {user?.isEditor?.includes(eventId) && (
          <Button
            onClick={() => setShowAddOrEditSpeakerDialog(true)}
            disabled={speakerCurrentlyBeingDeleted}
            style={{
              display: 'block',
              margin: '0px auto 3.5rem',
              width: '200px'
            }}>
            Add Speaker
            {user && (
              <BulletPoint
                top="-0.5rem"
                right="1rem"
                content={`
              <p>Your speakers can be the main draw card of your event. Having strong speaker bio’s with pictures and a synopsis of their subject matter is vital.</p>
              <p>Consider a short video 'promo' from one or 2 of your Keynote speakers encouraging registration and promoting the key points of their presentation.</p>
              <p>Where you have permission, share links to your speakers social channels such as linked in , twitter etc which will help build their audience too.</p>
            `}
              />
            )}
          </Button>
        )}
        <SpeakersList>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToParentElement]}>
            <SortableContext items={speakers.map(({ speakerId }) => speakerId)}>
              <AnimatePresence>
                {speakers.map((speaker) => (
                  <SpeakerCard
                    key={speaker.speakerId}
                    speaker={speaker}
                    speakers={speakers}
                    eventId={eventId}
                    handleEditSpeaker={handleEditSpeaker}
                    handleDeleteSpeaker={handleDeleteSpeaker}
                    speakerCurrentlyBeingDeleted={speakerCurrentlyBeingDeleted}
                    setShowSpeakerCarouselDialog={setShowSpeakerCarouselDialog}
                    setOpenSpeakerCarouselAtThisIndex={setOpenSpeakerCarouselAtThisIndex}
                    speakerCarouselDialogRef={speakerCarouselDialogRef}
                    setHeightBeforeCardCollapse={setHeightBeforeCardCollapse}
                  />
                ))}
              </AnimatePresence>
            </SortableContext>
            <DragOverlay>
              {idOfSpeakerCurrentlyBeingDragged
                ? getDragOverlay(idOfSpeakerCurrentlyBeingDragged)
                : null}
            </DragOverlay>
          </DndContext>
        </SpeakersList>
      </Container>
      {user?.isEditor?.includes(eventId) && (
        <AddOrEditSpeakerContainer
          eventId={eventId}
          speakers={speakers}
          showAddOrEditSpeakerDialog={showAddOrEditSpeakerDialog}
          setShowAddOrEditSpeakerDialog={setShowAddOrEditSpeakerDialog}
          speakerCurrentlyBeingEdited={speakerCurrentlyBeingEdited}
          setSpeakerCurrentlyBeingEdited={setSpeakerCurrentlyBeingEdited}
        />
      )}
      <Dialog
        ref={speakerCarouselDialogRef}
        isVisible={showSpeakerCarouselDialog}
        onDismiss={() =>
          handleDialog({
            dialogRef: speakerCarouselDialogRef,
            animation: 'dismiss',
            stateHandler: () => setShowSpeakerCarouselDialog(false),
            callback: () => setOpenSpeakerCarouselAtThisIndex(null)
          })
        }
        contentStyle={{
          maxWidth: '100%'
        }}>
        <SpeakerCarousel
          selectedEvent={selectedEvent}
          openSpeakerCarouselAtThisIndex={openSpeakerCarouselAtThisIndex}
          speakers={speakers}
          colors={colors}
          eventId={eventId}
          closeSpeakerCarouselDialog={() =>
            handleDialog({
              dialogRef: speakerCarouselDialogRef,
              animation: 'close',
              stateHandler: () => setShowSpeakerCarouselDialog(false),
              callback: () => setOpenSpeakerCarouselAtThisIndex(null)
            })
          }
        />
      </Dialog>
    </>
  );
}

const Container = styled.section`
  grid-column: 1/-1;
  margin: 0 auto;
  max-width: 77rem;
  padding: 0 1rem 6rem;
  width: 100%;
`;

const SpeakersList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
`;

const H5 = styled.h5`
  color: inherit;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.4em;
  margin-bottom: 0.5em;
  margin-top: 0px;
  @media only screen and (min-width: 1150px) {
    font-size: 1.5rem;
    margin-bottom: 1em;
  }
`;

export default Speakers;
