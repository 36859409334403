import * as React from 'react';

function SvgMicroscope(props) {
  return (
    <svg viewBox="0 0 83 83" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M41.3 82.6c22.81 0 41.3-18.49 41.3-41.3S64.11 0 41.3 0 0 18.49 0 41.3s18.49 41.3 41.3 41.3Z"
        fill="#36D1B7"
      />
      <path
        d="M52.2 31.1c0-.1.1-.2.1-.2l4.1-4.1c.3-.3.3-.9 0-1.2l-3.8-3.8c-.2-.2-.4-.2-.6-.2-.2 0-.4.1-.6.2l-4.1 4.1c-.1.1-.2.1-.2.1-2.1.2-3.9 2-4.1 4.1 0 .1-.1.2-.1.2L37.2 36c-.1.1-.2.1-.3.1-.1 0-.2 0-.3-.1l-.4-.4c-.2-.2-.4-.2-.6-.2-.2 0-.4.1-.6.2-.2.2-.2.4-.2.6 0 .2.1.4.2.6l.4.4c.2.2.2.4 0 .6l-2.9 2.9c-.2.2-.2.4-.2.6 0 .2.1.4.2.6l3.8 3.8c.2.2.4.2.6.2.2 0 .4-.1.6-.2l2.9-2.9c.1-.1.2-.1.3-.1.1 0 .2 0 .3.1l.4.4c.2.2.4.2.6.2.2 0 .4-.1.6-.2.3-.3.3-.9 0-1.2l-.4-.4c-.1-.1-.1-.2-.1-.3 0-.1 0-.2.1-.3l5.7-5.7c.1-.1.2-.1.2-.1 1.1-.1 2.1-.6 2.9-1.4.1-.1.2-.1.3-.1.1 0 .2.1.3.2 1.4 1.8 2.1 4 2.1 6.3 0 3.5-1.8 6.8-4.7 8.8 0 0-.1 0-.1.1-.1.1-.2.1-.3.1-.1 0-.2 0-.2-.1-.8-.6-1.7-.9-2.7-.9H29.2c-.5 0-.9.4-.9.9s.4.9.9.9h12.1c.1 0 .3.1.4.2.1.1.1.3 0 .4-.4.7-.6 1.5-.6 2.3V54c0 .2-.2.4-.4.4H26.8c-.5 0-.9.4-.9.9v5.1c0 .5.4.9.9.9h27.9c.5 0 .9-.4.9-.9v-5.1c0-.5-.4-.9-.9-.9h-3.8c-.2 0-.4-.2-.4-.4v-1.3c0-.7-.2-1.4-.5-2-.1-.2 0-.4.1-.5 3.4-2.3 5.4-6.1 5.4-10.1 0-3-1.1-5.9-3.1-8.2-.1-.1-.1-.2-.1-.4-.1-.1-.1-.3-.1-.4Zm-9.5 21.6c0-1.6 1.3-2.9 2.9-2.9 1.6 0 2.9 1.3 2.9 2.9V54c0 .2-.2.4-.4.4H43c-.2 0-.4-.2-.4-.4v-1.3h.1Zm10.6 3.4c.2 0 .4.2.4.4V59c0 .2-.2.4-.4.4H28c-.2 0-.4-.2-.4-.4v-2.5c0-.2.2-.4.4-.4h25.3ZM39.1 41.6l-2 2c-.1.1-.2.1-.3.1-.1 0-.2 0-.3-.1l-2-2c-.1-.1-.1-.2-.1-.3 0-.1 0-.2.1-.3l2-2c.1-.1.2-.1.3-.1.1 0 .2 0 .3.1l2 2c.2.2.2.5 0 .6Zm6.3-6.3-4.5 4.5c-.1.1-.2.1-.3.1-.1 0-.2 0-.3-.1l-2-2c-.1-.1-.1-.2-.1-.3 0-.1 0-.2.1-.3l4.5-4.5c.1-.1.2-.1.3-.1h.1c.1 0 .2.1.3.2 0 .1.1.1.1.2v.1c.1.1.1.2.2.2l.2.2v.1l.4.4h.1l.2.2c.1.1.2.1.2.2h.1c.1 0 .1.1.2.1.1.1.2.2.2.3.2.3.1.4 0 .5Zm2.2-1.8c-1.6 0-2.9-1.3-2.9-2.9 0-1.6 1.3-2.9 2.9-2.9 1.6 0 2.9 1.3 2.9 2.9 0 1.6-1.3 2.9-2.9 2.9Zm4.4-5c-.2 0-.3-.1-.4-.2 0-.1-.1-.1-.1-.2V28c-.1-.1-.1-.2-.2-.2l-.2-.2v-.1l-.4-.4h-.1l-.2-.2c-.1-.1-.2-.1-.2-.2h-.1c-.1 0-.1-.1-.2-.1-.1-.1-.2-.2-.2-.3 0-.1 0-.3.1-.4l2-2c.1-.1.2-.1.3-.1.1 0 .2 0 .3.1l2 2c.1.1.1.2.1.3 0 .1 0 .2-.1.3l-2 2H52Z"
        fill="#fff"
      />
      <path d="M47.6 29.7c-.5 0-.9.4-.9.9s.4.9.9.9.9-.4.9-.9c-.1-.5-.4-.9-.9-.9Z" fill="#fff" />
    </svg>
  );
}
export default SvgMicroscope;
