import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import { useWindowSize } from 'hooks';
import { DialogCloseIcon } from 'components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Markup } from 'interweave';
import { wrap } from '@popmotion/popcorn';
import {
  LeftArrow as Arrow,
  SolidCircle1,
  SolidCircle2,
  DashedCircle,
  EmailIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon
} from 'assets/svgs';

const variants = {
  enter: (direction) => ({
    x: direction > 0 ? 1000 : -1000,
    opacity: 1
  }),
  center: {
    x: 0,
    opacity: 1
  },
  exit: (direction) => ({
    x: direction > 0 ? -1000 : 1000,
    opacity: 0
  })
};

function SpeakerCarousel({
  selectedEvent,
  openSpeakerCarouselAtThisIndex,
  closeSpeakerCarouselDialog,
  speakers,
  colors,
  eventId
}) {
  const { windowWidth } = useWindowSize();
  const [[slide, direction], setSlide] = useState([openSpeakerCarouselAtThisIndex, 0]);

  const paginate = (_direction) => setSlide([slide + _direction, _direction]);

  const index = wrap(0, speakers.length, slide);

  const speaker = speakers[index];

  const speakerHasSocialLinksOrContactEmail =
    speaker.socials?.linkedIn ||
    speaker.socials?.twitter ||
    speaker.socials?.facebook ||
    speaker.socials?.instagram ||
    speaker.contactEmail;

  return (
    <Container>
      {speakers.length > 1 && windowWidth > 545 && (
        <LeftButton
          onClick={() => paginate(-1)}
          custom={index === 0}
          disabled={index === 0}
          colors={colors}
          type="button">
          <Arrow colors={colors} />
        </LeftButton>
      )}
      <AnimatePresence initial={false} custom={direction}>
        {speaker && (
          <Slide
            key={speaker.speakerId}
            colors={colors}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset }) => {
              if (offset.x > 100) {
                paginate(-1);
              } else if (offset.x < -100) {
                paginate(1);
              }
            }}>
            <DialogCloseIcon
              onClick={closeSpeakerCarouselDialog}
              selectedEvent={selectedEvent}
              width="0.875em"
            />
            <AvatarContainer>
              <StyledSolidCircle1 colors={colors} />
              <StyledSolidCircle2 colors={colors} />
              <StyledDashedCircle colors={colors} />
              {speaker?.staticAvatar?.childImageSharp ? (
                <StaticAvatar image={getImage(speaker.staticAvatar)} alt={speaker.name} />
              ) : (
                <RemoteAvatar src={speaker.avatar.remoteUrl} alt={speaker.name} />
              )}
            </AvatarContainer>
            <SpeakerInfo
              speakerHasSocialLinksOrContactEmail={speakerHasSocialLinksOrContactEmail}
              colors={colors}>
              <h3>{speaker.name}</h3>
              <h4>{speaker.titleOrRole}</h4>
              {speakerHasSocialLinksOrContactEmail && (
                <SocialLinksAndContactEmail onClick={(e) => e.stopPropagation()}>
                  {speaker.socials.linkedIn && (
                    <SocialLink
                      href={speaker.socials.linkedIn.trim()}
                      target="_blank"
                      colors={colors}>
                      <LinkedinIcon />
                    </SocialLink>
                  )}
                  {speaker.socials.twitter && (
                    <SocialLink
                      href={`https://twitter.com/${speaker.socials.twitter
                        .replace('@', '')
                        .trim()}`}
                      target="_blank"
                      colors={colors}>
                      <TwitterIcon />
                    </SocialLink>
                  )}
                  {speaker.socials.facebook && (
                    <SocialLink
                      href={speaker.socials.facebook.trim()}
                      target="_blank"
                      colors={colors}>
                      <FacebookIcon />
                    </SocialLink>
                  )}
                  {speaker.socials.instagram && (
                    <SocialLink
                      href={`https://www.instagram.com/${speaker.socials.instagram
                        .replace('@', '')
                        .trim()}`}
                      target="_blank"
                      colors={colors}>
                      <InstagramIcon />
                    </SocialLink>
                  )}
                  {speaker.socials.emailLink && (
                    <ContactEmail href={speaker.contactEmail} target="_blank" colors={colors}>
                      <EmailIcon />
                    </ContactEmail>
                  )}
                </SocialLinksAndContactEmail>
              )}
              <br />
              {speaker.presentationTitles[eventId] && (
                <PresentationTitle>
                  <Markup
                    content={speaker.presentationTitles[eventId].replace('<br />', ' ')}
                    noWrap
                  />
                </PresentationTitle>
              )}
              <SpeakerBio colors={colors}>
                <Markup content={speaker.bio} noWrap />
              </SpeakerBio>
            </SpeakerInfo>
          </Slide>
        )}
      </AnimatePresence>
      {speakers.length > 1 && windowWidth > 545 && (
        <RightButton
          onClick={() => paginate(1)}
          custom={index === speakers.length - 1}
          disabled={index === speakers.length - 1}
          colors={colors}
          type="button">
          <Arrow colors={colors} />
        </RightButton>
      )}
    </Container>
  );
}

const SpeakerBio = styled.div`
  @media screen and (min-width: 75rem) {
    max-height: 21.875rem;
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 0.5rem;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 0.31rem grey;
      border-radius: 0.625rem;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${({ colors }) => colors.secondary};
      border-radius: 0.625rem;
    }
  }
`;

const Container = styled(motion.div)`
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  max-width: 32.5rem;
  position: relative;
  width: calc(100% - 1.5rem);

  @media screen and (min-width: 75rem) {
    left: 1.05rem;
    max-width: 75rem;
    width: 100%;
  }
`;

const LeftAndRightButtons = styled(motion.button).attrs({
  whileTap: (disabled) => ({
    scale: disabled ? 1 : 0.925
  })
})`
  align-items: center;
  background-color: ${({ disabled, theme, colors }) =>
    disabled ? '#c4c4c4' : theme.className === 'contrast' ? theme.primary : colors.tertiary};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: flex;
  height: 3rem;
  justify-content: center;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  width: 3rem;
  z-index: 2;

  svg {
    width: 1.5rem;

    path {
      stroke: ${({ theme }) => (theme.className === 'contrast' ? '#000' : '#fff')};
    }
  }
`;

const LeftButton = styled(LeftAndRightButtons)`
  border-radius: 2.5rem 0.125rem 0.125rem 2.5rem;
  left: 0;

  svg {
    margin-left: 0.625rem;
  }
`;

const RightButton = styled(LeftAndRightButtons)`
  border-radius: 0.125rem 2.5rem 2.5rem 0.125rem;
  right: 0;

  @media screen and (min-width: 75rem) {
    right: 2.1rem;
  }

  svg {
    margin-right: 0.625rem;
    transform: rotate(180deg);
  }
`;

const Slide = styled(motion.div)`
  background-color: ${({ theme }) => (theme.className === 'contrast' ? 'black' : '#eaf5f9')};
  border: ${({ theme }) =>
    theme.className === 'contrast' ? `1px solid ${theme.primary}` : 'none'};
  display: flex;
  max-width: 25rem;
  padding: 10rem 1.25rem 2rem;
  position: absolute;
  top: calc(8.75rem - 50vh);
  width: 100%;

  /* Close Icon */
  > div:first-of-type {
    position: absolute;
    right: 0.625rem;
    top: 0.625rem;
  }

  @media screen and (min-width: 75rem) {
    padding: 0 0 0 16.25rem;
    margin-left: 9.15rem;
    min-height: 16.5rem;
    max-width: 53.125rem;
    top: unset;
  }
`;

const StaticAvatar = styled(GatsbyImage)`
  border-radius: 50%;
  width: 100%;
`;

const RemoteAvatar = styled.img`
  border-radius: 50%;
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

const AvatarContainer = styled.div`
  flex-shrink: 1;
  height: 15rem;
  left: calc(50% - 7.5rem);
  margin-bottom: 1.325rem;
  position: absolute;
  top: -7.5rem;
  width: 15rem;

  @media screen and (min-width: 75rem) {
    left: -11.531rem;
    height: 23.063rem;
    width: 23.063rem;
    top: unset;
  }

  > svg {
    opacity: 1;
    position: absolute;
    transition: all 0.2s ease-in-out;
  }

  ${StaticAvatar},
  ${RemoteAvatar} {
    position: relative;
    z-index: 1;
  }
`;

const StyledSolidCircle1 = styled(SolidCircle1)`
  left: -11.38%;
  top: -9.75%;
  transform: scaleX(-1);
  width: 72%;

  path {
    fill: ${({ theme, colors }) =>
      theme.className === 'contrast' ? theme.primary : colors.secondary};
  }
`;

const StyledSolidCircle2 = styled(SolidCircle2)`
  bottom: -9.75%;
  right: -11.38%;
  transform: scaleX(-1);
  width: 72%;

  path {
    fill: ${({ theme, colors }) =>
      theme.className === 'contrast' ? theme.primary : colors.tertiary};
  }
`;

const StyledDashedCircle = styled(DashedCircle)`
  bottom: 0;
  left: 0;
  width: 28.45%;
  z-index: 2;

  path {
    fill: ${({ theme, colors }) =>
      theme.className === 'contrast' ? theme.primary : colors.primary};
  }
`;

const SpeakerInfo = styled.div`
  text-align: center;
  width: 100%;

  h3 {
    font-size: 1.5rem;

    /* Presentation Title */
    &:nth-of-type(2) {
      font-weight: 400;
      margin-top: ${({ speakerHasSocialLinksOrContactEmail }) =>
        speakerHasSocialLinksOrContactEmail ? '0.625em' : 0};
    }
  }

  h4 {
    font-size: 1rem;
    font-weight: 400;

    ${({ speakerHasSocialLinksOrContactEmail }) =>
      speakerHasSocialLinksOrContactEmail && 'margin-bottom: 0.85em'};
  }

  /* SpeakerBio */
  p {
    font-size: 1rem;
    line-height: 1.5em;
    margin-bottom: 0.75em;

    &:first-of-type {
      margin-top: 1em;
    }
  }

  &,
  a {
    color: ${({ theme, colors }) =>
      theme.className === 'contrast' ? theme.primary : colors.primary};
  }

  a {
    font-weight: 600;
    text-decoration: underline;
  }

  @media screen and (min-width: 75rem) {
    padding-bottom: 3.438rem;
    padding-right: 4.688rem;
    padding-top: 3.563rem;
    text-align: left;
  }
`;

const PresentationTitle = styled.h3`
  font-size: 1.25rem;
  line-height: 1.4em;
  margin-top: 0.5em;
`;

const SocialLinksAndContactEmail = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;

  @media screen and (min-width: 75rem) {
    justify-content: flex-start;
    left: -0.375rem;
  }
`;

const SocialLink = styled.a`
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin: 0 0.375rem;

  svg {
    border-radius: 50%;
    height: 1.5rem;
    transition: box-shadow 0.2s ease-in-out;
    width: 1.5rem;

    circle {
      fill: ${({ theme }) => (theme.className === 'contrast' ? theme.primary : '#C4C4C4')};
      transition: fill 0.2s ease-in-out;
    }

    path {
      fill: ${({ theme }) => (theme.className === 'contrast' ? '#000' : '#fff')};
      transition: 0.5s;
    }
  }

  &:hover svg {
    box-shadow: 0 0.188rem 0.5rem rgba(0, 0, 0, 0.25);

    circle {
      fill: ${({ colors }) => colors.secondary};
    }
  }

  &:active svg {
    transform: scale(0.9);
  }
`;

const ContactEmail = styled(SocialLink)``;

export default SpeakerCarousel;
