import React from 'react';
import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';

function LoadingSpinnerFeedback({ style, loading, completed, error, bottom }) {
  const { width, color } = style;
  return (
    <Wrapper
      width={width}
      style={style}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}>
      <Circle color={error ? '#fd5a45' : color} loading={loading}>
        {completed && <Check width={width} color={color} bottom={bottom} />}
        {error && <Error width={width} bottom={bottom} />}
      </Circle>
    </Wrapper>
  );
}

const circleAnimation = (color) => keyframes`
  0% {
    border-color: transparent;
    border-left-color: ${color};
  }
  90% {
    transform: rotate(-360deg);
    border-color: transparent;
    border-left-color: ${color};
  }
  100% {
    transform: rotate(-360deg);
    border-color: ${color};
  }
`;

const checkmarkAnimation = (width) => keyframes`
  0% {
    height: 0;
    width: 0;
    opacity: 0;
  }
  80% {
    height: 0;
    width: 0;
    opacity: 0;
  }
  90% {
    height: calc(${width} / 4);
    width: 0;
    opacity: 1;
  }
  100% {
    height: calc(${width} / 4);
    width: calc(${width} / 2);
    opacity: 1;
  }
`;

const Wrapper = styled(motion.div)`
  height: ${({ width }) => width};
  width: ${({ width }) => width};
  div {
    align-items: center;
    display: flex;
  }
`;

const Circle = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
  &:after {
    border: 1px solid #f8f8f8;
    border-radius: 50%;
    content: '';
    height: 100%;
    inset: 0;
    position: absolute;
    width: 100%;
    z-index: 0;
  }
  &:before {
    animation: ${({ color }) => circleAnimation(color)} linear
      ${({ loading }) => (loading ? 'infinite' : 'forwards')} 0.75s;
    border: 1px solid transparent;
    border-left-color: ${({ color }) => color};
    border-radius: 50%;
    content: '';
    height: 100%;
    inset: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
`;

const Check = styled.div`
  animation: ${({ width }) => checkmarkAnimation(width)} linear both 0.5s;
  border-bottom: 1px solid ${({ color }) => color};
  border-left: 1px solid ${({ color }) => color};
  bottom: ${({ bottom }) => (bottom ? '25%' : '-15%')};
  display: block;
  height: ${({ width }) => `calc(${width} / 4)`};
  left: 25%;
  opacity: 0;
  position: absolute;
  transform: rotate(-45deg);
  transform-origin: left top;
  width: ${({ width }) => `calc(${width} / 2)`};
  z-index: 0;
`;

const Error = styled.div`
  align-items: center;
  bottom: ${({ bottom }) => (bottom ? '25%' : '0')};
  display: flex;
  height: ${({ width }) => `calc(${width} / 2)`};
  justify-content: center;
  left: 25%;
  position: absolute;
  width: ${({ width }) => `calc(${width} / 2)`};
  z-index: 1;
  &:after {
    background-color: #fd5a45;
    content: ' ';
    height: 100%;
    position: absolute;
    transform: rotate(-45deg);
    width: 1px;
  }
  &:before {
    background-color: #fd5a45;
    content: ' ';
    height: 100%;
    position: absolute;
    transform: rotate(45deg);
    width: 1px;
  }
`;

export default LoadingSpinnerFeedback;
