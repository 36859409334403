import React, { useContext } from 'react';
import styled from 'styled-components';
import { LocalContext } from 'context';
import { StaticImage } from 'gatsby-plugin-image';
import { WonkyAvocado } from 'assets/svgs';

function HubHeaderBackground({ colors }) {
  const { theme } = useContext(LocalContext);

  return (
    <Background colors={colors}>
      <WonkyAvocado className="avocado" />
      <StaticImage
        src="../../../../assets/images/doctor.png"
        formats={['auto', 'webp']}
        width={600}
        placeholder="blurred"
        alt="Doctor"
        quality={90}
      />
    </Background>
  );
}

const Background = styled.div`
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  .gatsby-image-wrapper {
    display: none;
  }
  svg {
    position: absolute;
    &.avocado {
      height: 125%;
      right: -100%;
      top: -25%;
    }
  }
  @media only screen and (min-width: 48rem) {
    svg {
      &.avocado {
        right: -50%;
      }
    }
  }
  @media only screen and (min-width: 1150px) {
    .gatsby-image-wrapper {
      display: block;
      min-width: 600px;
      height: 90%;
      position: absolute !important;
      object-fit: contain !important;
      bottom: 0;
      right: 0;
    }
    svg {
      position: absolute;
      &.avocado {
        top: -50%;
        right: -50%;
        height: 200%;
      }
    }
  }
  @media only screen and (min-width: 1441px) {
    svg {
      &.avocado {
        right: -40%;
      }
    }
  }
`;

export default HubHeaderBackground;
