import { collection, onSnapshot, query, where } from 'firebase/firestore';

function EventAnalyticsFactory(firebase) {
  const subscribeToEventData = ({ eventId, snapshot }) =>
    onSnapshot(
      query(
        collection(firebase.fsdb, 'users'),
        where('eventsUserCanAccess', 'array-contains', eventId)
      ),
      snapshot
    );

  return {
    subscribeToEventData
  };
}

export default EventAnalyticsFactory;
