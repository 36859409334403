import React, { useState, useEffect, useContext } from 'react';
import { LocalContext } from 'context';
import { createGlobalStyle } from 'styled-components';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// eslint-disable-next-line react/jsx-no-useless-fragment, react/function-component-definition
let Editor = () => <></>;

const wrapperStyle = {
  height: '19.438rem',
  marginBottom: '1.25rem'
};

const toolbarStyle = {
  height: '2rem',
  padding: '0.313rem 0.25rem'
};

const editorStyle = (editorIsFocused, theme) => ({
  border: editorIsFocused
    ? `0.188rem solid ${theme.className === 'contrast' ? theme.primary : '#fff'}`
    : `0.063rem solid ${theme.className === 'contrast' ? theme.primary : '#fff'}`,
  fontFamily: 'Arial, sans-serif',
  fontWeight: 400,
  height: '100%',
  maxHeight: '16.375rem',
  outline: 'none',
  overflowX: 'hidden',
  overflowY: 'auto',
  padding: '0 0.625rem'
});

const toolbarOptions = {
  options: ['inline', 'link'],
  inline: {
    options: ['bold', 'italic', 'underline'],
    bold: { className: 'react-draft-editor-icon' },
    italic: { className: 'react-draft-editor-icon' },
    underline: { className: 'react-draft-editor-icon' }
  },
  link: {
    defaultTargetOption: '_blank',
    link: { className: 'react-draft-editor-icon' },
    unlink: { className: 'react-draft-editor-icon' }
  }
};

/*  Style overrides for react-draft-wysiwyg */
const StyleOverides = createGlobalStyle`
  .react-draft-editor-icon {
    background-color: ${({ theme, selectedEvent }) =>
      theme.className === 'contrast' ? theme.primary : selectedEvent?.colors?.tertiary} !important;
    border: ${({ theme }) => theme.className === 'contrast' && 'none'} !important;

    &:first-of-type {
      ${({ theme }) => theme.className === 'contrast' && 'margin-left: 0 !important'};
    }
  }

  .react-draft-editor-icon img {
    filter: ${({ theme }) =>
      theme.className !== 'contrast' ? 'brightness(0) invert(1)' : 'none'} !important;
  }

  .react-draft-editor-icon:hover {
    box-shadow: 0.063rem 0.063rem 0 ${({ theme, selectedEvent }) =>
      theme.className === 'contrast' ? theme.contrast : selectedEvent?.colors?.tertiary} !important;
  }

  .react-draft-editor-icon.rdw-option-active {
    box-shadow: 0.094rem 0.094rem 0 #bfbdbd inset !important;
    filter: brightness(114%);
  }

  .rdw-link-modal {
    background-color: ${({ theme, selectedEvent }) =>
      theme.className === 'contrast' ? theme.primary : selectedEvent?.colors?.tertiary} !important;
    border: ${({ theme }) =>
      theme.className !== 'contrast' ? '1px solid #F1F1F1' : 'none'} !important;
    box-shadow: ${({ theme }) =>
      theme.className !== 'contrast' ? '3px 3px 5px #BFBDBD' : 'none'} !important;
    height: 176px !important;
  }

  .rdw-link-modal-btn:not(:disabled) {
    background-color: ${({ theme }) =>
      theme.className === 'contrast' ? theme.contrast : '#fff'} !important;
    border: ${({ theme }) =>
      theme.className !== 'contrast' ? '1px solid #F1F1F1' : 'none'} !important;
    color: ${({ theme }) => (theme.className === 'contrast' ? '#fff' : '#000')} !important;
  }

  .rdw-link-modal-btn:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) =>
      theme.className === 'contrast' ? theme.contrast : '#ece9e9'} !important;
    border: ${({ theme }) =>
      theme.className === 'contrast' ? 'none' : '1px solid #F1F1F1'} !important;
    color: ${({ theme }) =>
      theme.className === 'contrast' ? '#fff' : 'rgba(000, 000, 000, 0.3) !important'} !important;
  }

  .rdw-link-modal-label {
    color: ${({ theme }) => (theme.className === 'contrast' ? '#000' : '#fff')} !important;
  }

  /* Hides the 'open in seperate window?' box in the link modal */
  .rdw-link-modal-target-option {
    display: none !important;
  }

  .rdw-link-modal #linkTitle,
  .rdw-link-modal #linkTarget {
    color: #000 !important;
  }

  /* Links */
  .rdw-link-decorator-wrapper a {
    text-decoration: underline !important;
    text-decoration-color: ${({ theme, selectedEvent }) =>
      theme.className === 'contrast' ? theme.primary : selectedEvent?.colors?.tertiary} !important;

    span {
      color: ${({ theme, selectedEvent }) =>
        theme.className === 'contrast'
          ? theme.primary
          : selectedEvent?.colors?.tertiary} !important;
    }
  }

  .rdw-link-decorator-icon {
    background-color: transparent !important;
    filter: invert(1);
  }

  /* Placeholder text */
  .public-DraftEditorPlaceholder-root {
    color: ${({ theme }) => (theme.className === 'contrast' ? theme.primary : '#fff')} !important;
    font-family: Arial, sans-serif !important;
    font-size: 1rem !important;
    font-style: italic !important;
    font-weight: 600 !important;
    opacity: 0.65 !important;
  }

  .rdw-link-modal-buttonsection {
    margin-top: 9px !important;
  }

  .react-draft-toolbar {
    ${({ theme }) =>
      theme.className === 'contrast' && `background-color: ${theme.contrast} !important`};
    border: ${({ theme }) =>
      theme.className === 'contrast' ? 'none' : '1px solid #fff'} !important;
    padding: ${({ theme }) =>
      theme.className === 'contrast'
        ? '0.25rem 0.313rem 0.313rem 0'
        : '0.313rem 0.25rem'} !important;
  }

  .react-draft-editor {
    font-family: Arial, sans-serif !important;
    font-weight: 400 !important;

    ::-webkit-scrollbar {
      width: 0.45rem !important;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 0.31rem grey !important;
      border-radius: 0.625rem !important;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme, selectedEvent }) =>
        theme.className === 'contrast'
          ? theme.primary
          : selectedEvent?.colors?.tertiary} !important;
      border-radius: 0.625rem !important;
    }
  }
`;

function BioEditor({ editorState, handleBioEditorChange, selectedEvent }) {
  const { theme } = useContext(LocalContext);
  const [editorIsFocused, setEditorIsFocused] = useState(false);
  const [isEditorReady, setIsEditorReady] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line global-require
    Editor = require('react-draft-wysiwyg').Editor;
    setIsEditorReady(true);
  }, []);

  return (
    <>
      <StyleOverides theme={theme} selectedEvent={selectedEvent} />
      {isEditorReady && (
        <Editor
          editorState={editorState}
          wrapperClassName="react-draft-wrapper"
          toolbarClassName="react-draft-toolbar"
          editorClassName="react-draft-editor"
          wrapperStyle={wrapperStyle}
          toolbarStyle={toolbarStyle}
          editorStyle={editorStyle(editorIsFocused, theme)}
          onFocus={() => setEditorIsFocused(true)}
          onBlur={() => setEditorIsFocused(false)}
          onEditorStateChange={handleBioEditorChange}
          toolbar={toolbarOptions}
          placeholder="Enter speaker bio here..."
          stripPastedStyles
        />
      )}
    </>
  );
}

export default BioEditor;
