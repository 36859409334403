import React from 'react';
import { Button } from 'components';
import styled from 'styled-components';
import ICalendarLink from 'react-icalendar-link';
import Icon from '@material-ui/core/Icon';

function DateButton({ eventName, eventDescription, eventStartTime, eventEndTime, eventLocation }) {
  const eventDetails = {
    title: eventName,
    description: eventDescription,
    location: eventLocation,
    startTime: eventStartTime,
    endTime: eventEndTime
  };

  return (
    <ICalendarLink event={eventDetails}>
      <Button
        variant="contained"
        size="large"
        color="primary"
        style={{
          padding: '0.75em 1.625em'
        }}
        startIcon={
          <StyledIcon>
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M5 10V-6.85453e-07" stroke="white" strokeWidth="2" />
              <path d="M0 5L10 5" stroke="white" strokeWidth="2" />
            </svg>
          </StyledIcon>
        }>
        Add To Calendar
      </Button>
    </ICalendarLink>
  );
}

const StyledIcon = styled(Icon)`
  align-items: center;
  background-color: ${({ theme }) => theme.tertiary};
  border-radius: 50%;
  display: flex;
  height: 1.5rem;
  justify-content: center;
  margin-left: -0.25rem;
  margin-right: 0.188rem;
  width: 1.5rem;

  svg {
    width: 0.75rem;
  }
`;

export default DateButton;
