import React, { useContext, useEffect, useState, useRef } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  BannerBackgroundImage,
  DateButton,
  Dialog,
  GroupWatchingDialog,
  Interaction,
  LivestreamOverlays,
  SummariseVideoViaAI
} from 'components';
import Vimeo from '@vimeo/player';
import { useIntersection } from 'hooks';
import { LocalContext, FirebaseContext } from 'context';
import { useLocation } from '@reach/router';
import { AnimatePresence } from 'framer-motion';
import throttle from 'just-throttle';
import { Markup } from 'interweave';

function Livestream({
  colors,
  eventId,
  eventName,
  eventStartTime,
  eventEndTime,
  eventLocation,
  eventDescription,
  eventDates,
  eventStreamPlaceholderImg,
  eventBannerVideo,
  isChatEnabled,
  isQAndAEnabled,
  isPollsEnabled,
  isParticipantsEnabled,
  setIsGroupWatchingEnabled,
  eventStatus,
  summariseVideoViaAI
}) {
  const {
    streamRef,
    showGroupWatchingDialog,
    setShowGroupWatchingDialog,
    dialogAnimationTime,
    browserTabIsActive
  } = useContext(LocalContext);
  const { firebase, user } = useContext(FirebaseContext);
  const [vimeoPlayer, setVimeoPlayer] = useState(null);
  const [isStreamMuted, setIsStreamMuted] = useState(null);
  const [livestreamIsPlaying, setLivestreamIsPlaying] = useState(false);
  const [pipRef, vimeoPlayerObserver] = useIntersection({
    initialInView: true,
    threshold: 0.75
  });
  const [isPipModeEnabled, setIsPipModeEnabled] = useState(false);
  const [questionCurrentlyBeingAnsweredLive, setQuestionCurrentlyBeingAnsweredLive] =
    useState(null);
  const [showOnlinePresenceToUsers, setShowOnlinePresenceToUsers] = useState(false);
  const [activeTab, setActiveTab] = useState('Chat');
  const [forcedActiveTab, setForcedActiveTab] = useState(null);
  const [streamUrl, setStreamUrl] = useState(null);
  const [currentlyOpenWordCloudPollId, setCurrentlyOpenWordCloudPollId] = useState(null);

  const livestreamRef = useRef(null);
  const groupWatchingDialogRef = useRef(null);

  const { state: locationState } = useLocation();
  const referrer = locationState?.referrer;

  const queryString = `dnt=1&pip=1&autoplay=1&fullscreen=1&controls=1&volume=1&muted=${
    referrer ? 0 : 1
  }`;

  /* TODO: Refactor/fix this */
  // useEffect(() => {
  //   const keyDownListener = (e) => {
  //     if (e.code === 'Enter' || e.code === 'NumpadEnter') {
  //       if (activeTab === 'Chat') {
  //         handleUserCommentSubmit(e);
  //       } else if (activeTab === 'Q&A') {
  //         handleSubmitNewQuestion(e);
  //       }
  //     }
  //   };
  //   document.addEventListener('keydown', keyDownListener);
  //   return () => {
  //     document.removeEventListener('keydown', keyDownListener);
  //   };
  // }, [activeTab, userCommentText, textArea]);

  useEffect(() => {
    let unsubscribeFromEventUpdates;

    if (firebase && browserTabIsActive) {
      unsubscribeFromEventUpdates = firebase.subscribeToEventUpdates({
        eventId,
        snapshot: (snapshot) => {
          if (snapshot.exists()) {
            const {
              showOnlinePresenceToUsers: _showOnlinePresenceToUsers,
              forcedActiveTab: _forcedActiveTab,
              questionCurrentlyBeingAnsweredLive: _questionCurrentlyBeingAnsweredLive,
              currentlyOpenWordCloudPollId: _currentlyOpenWordCloudPollId,
              groupWatchingIsEnabled: _groupWatchingIsEnabled,
              streamUrl: _streamUrl
            } = snapshot.data();

            setShowOnlinePresenceToUsers(_showOnlinePresenceToUsers);

            if (_forcedActiveTab || _forcedActiveTab === null) {
              setForcedActiveTab(_forcedActiveTab);
            }

            if (_questionCurrentlyBeingAnsweredLive) {
              setQuestionCurrentlyBeingAnsweredLive(_questionCurrentlyBeingAnsweredLive);
            } else {
              setQuestionCurrentlyBeingAnsweredLive(null);
            }

            if (_currentlyOpenWordCloudPollId) {
              setCurrentlyOpenWordCloudPollId(_currentlyOpenWordCloudPollId);
            } else {
              setCurrentlyOpenWordCloudPollId(null);
            }

            if (_groupWatchingIsEnabled) {
              setIsGroupWatchingEnabled(_groupWatchingIsEnabled);
            } else {
              setIsGroupWatchingEnabled(null);
            }

            if (_streamUrl) {
              setStreamUrl(_streamUrl);
            } else {
              setStreamUrl(null);
            }
          } else if (!snapshot.exists()) {
            /* CONOR TODO: Is there a better way of doing this? Can we automatically add the event doc somehow? Hmmmm */
            console.error(
              "You need to add a doc for this event in the 'Events' collection in Firebase."
            );
          }
        }
      });
    }

    return () => {
      if (unsubscribeFromEventUpdates) {
        unsubscribeFromEventUpdates();
      }
    };
  }, [firebase, browserTabIsActive]);

  useEffect(() => {
    const livestreamIframe = document.getElementById('livestream-iframe');

    if (livestreamIframe && user && eventId) {
      const _vimeoPlayer = new Vimeo(livestreamIframe);

      setVimeoPlayer(_vimeoPlayer);

      // I'm defining a video "session" as a discrete period of time someone is on the page watching
      // a video. If someone watches an hour one day, then navigates away and comes back to the same
      // page the next day, then those are two seperate sessions. Likewise, if they watch 20 mins
      // then navigate to another part of the site and then come back to the same page again and
      // watches another 20 mins, then those are two seperate sessions. But if someone watches 20
      // mins and pauses the video, then unpauses and continues watching, then that's a single
      // session. Likewise, if someone watches 20 mins and then fast-forwards an hour and continues
      // watching, then that's also a single session.
      const updateVideoSessionData = async () => {
        const data = await _vimeoPlayer.getPlayed();

        const timeRange = data.map((arr) => ({
          start: parseFloat(arr[0].toFixed(2)),
          end: parseFloat(arr[1].toFixed(2))
        }));

        return firebase.livestream.updateVideoSessionData({
          uid: user.uid,
          eventId,
          timeRange
        });
      };

      _vimeoPlayer.on('loaded', () => {
        _vimeoPlayer.on('timeupdate', throttle(updateVideoSessionData, 60000));
        _vimeoPlayer.on('play', (data) => {
          // Using an 'if' statement here because when the 'play' event is fired
          // after any 'seek' event 'data' is undefined.
          if (data) {
            setLivestreamIsPlaying(true);
          }
        });
        _vimeoPlayer.on('pause', (data) => {
          setLivestreamIsPlaying(false);

          // The 'pause' event is also fired when the video ends, along with the 'ended' event.
          // We want to ignore it when the video has ended, so we'll check the percent value.
          if (data.percent !== 1) {
            updateVideoSessionData();
          }
        });
        _vimeoPlayer.on('seeked', updateVideoSessionData);
        _vimeoPlayer.on('ended', updateVideoSessionData);

        _vimeoPlayer.on('enterpictureinpicture', () => {
          setIsPipModeEnabled(true);
        });
        _vimeoPlayer.on('leavepictureinpicture', () => {
          setIsPipModeEnabled(false);
        });
      });
    }

    return () => {
      // document.removeEventListener('keydown', keyDownListener);
      vimeoPlayer?.off('timeupdate');
      vimeoPlayer?.off('play');
      vimeoPlayer?.off('pause');
      vimeoPlayer?.off('seeked');
      vimeoPlayer?.off('ended');
      vimeoPlayer?.off('enterpictureinpicture');
      vimeoPlayer?.off('leavepictureinpicture');
    };
  }, [user, eventId]);

  useEffect(() => {
    if (
      document.pictureInPictureEnabled &&
      vimeoPlayer &&
      !vimeoPlayer.disablePictureInPicture &&
      livestreamIsPlaying &&
      !vimeoPlayerObserver.isIntersecting
    ) {
      try {
        vimeoPlayer.requestPictureInPicture();
      } catch (err) {
        console.error(err);
      }
    }
  }, [vimeoPlayer, vimeoPlayerObserver]);

  const handlePictureInPictureOverlayClick = () => vimeoPlayer.exitPictureInPicture();

  useEffect(() => {
    if (vimeoPlayer) {
      vimeoPlayer.ready().then(() => {
        vimeoPlayer.getMuted().then((muted) => {
          if (muted) setIsStreamMuted(muted);
        });
      });
    }
  }, [vimeoPlayer]);

  useEffect(() => {
    if (vimeoPlayer) {
      vimeoPlayer.on('volumechange', () => {
        if (isStreamMuted) setIsStreamMuted(false);
      });
    }
  }, [vimeoPlayer, isStreamMuted]);

  const handleUnmuteOverlayClick = () => {
    if (isStreamMuted) {
      vimeoPlayer.setMuted(false).then(() => setIsStreamMuted(false));
    }
  };

  return (
    <>
      <Container colors={colors}>
        <Banner>
          {eventBannerVideo ? (
            <BannerVideo autoPlay muted id="bgvid">
              <source src={eventBannerVideo} type="video/mp4" />
            </BannerVideo>
          ) : (
            <BannerBackgroundImage colors={colors} />
          )}
        </Banner>
        <Details>
          <div ref={streamRef} style={{ position: 'absolute', top: '-4.25rem' }} />
          <Dates colors={colors}>
            <Markup content={eventDates} />
          </Dates>
          <Title colors={colors}>
            <Markup content={`Welcome to ${eventName}`} />
          </Title>
        </Details>
        <LivestreamAndInteraction>
          <LiveStream ref={livestreamRef} id="livestream">
            <div
              ref={pipRef}
              style={{
                padding: '56.25% 0 0 0',
                height: '100%',
                position: 'relative',
                overflow: 'hidden'
              }}>
              {eventStreamPlaceholderImg ? (
                <div
                  style={{
                    backgroundColor: '#fff',
                    border: '1px solid #bdbdbd',
                    height: '100%',
                    left: 0,
                    position: 'absolute',
                    top: 0,
                    width: '100%'
                  }}>
                  <GatsbyImage
                    image={getImage(eventStreamPlaceholderImg)}
                    alt={`${eventName} Stream Placeholder`}
                    style={{ width: '100%', height: '100%' }}
                  />
                </div>
              ) : (
                <>
                  {streamUrl && (
                    <iframe
                      title={eventName}
                      id="livestream-iframe"
                      src={
                        streamUrl.includes('?')
                          ? `${streamUrl}&${queryString}`
                          : `${streamUrl}?${queryString}`
                      }
                      frameBorder="0"
                      allow="autoplay; fullscreen; picture-in-picture"
                      allowFullScreen
                      referrerPolicy="strict-origin"
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%'
                      }}
                    />
                  )}
                  <LivestreamOverlays
                    colors={colors}
                    currentlyOpenWordCloudPollId={currentlyOpenWordCloudPollId}
                    eventId={eventId}
                    handlePictureInPictureOverlayClick={handlePictureInPictureOverlayClick}
                    handleUnmuteOverlayClick={handleUnmuteOverlayClick}
                    isPipModeEnabled={isPipModeEnabled}
                    isStreamMuted={isStreamMuted}
                    livestreamRef={livestreamRef}
                    questionCurrentlyBeingAnsweredLive={questionCurrentlyBeingAnsweredLive}
                  />
                </>
              )}
            </div>
          </LiveStream>
          {isChatEnabled && (
            <Interaction
              colors={colors}
              eventId={eventId}
              showOnlinePresenceToUsers={showOnlinePresenceToUsers}
              forcedActiveTab={forcedActiveTab}
              questionCurrentlyBeingAnsweredLive={questionCurrentlyBeingAnsweredLive}
              isQAndAEnabled={isQAndAEnabled}
              isPollsEnabled={isPollsEnabled}
              isParticipantsEnabled={isParticipantsEnabled}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          )}
        </LivestreamAndInteraction>
        <BelowLivestreamButtons eventStatus={eventStatus}>
          {summariseVideoViaAI && eventStatus === 'previous' && (
            <SummariseVideoViaAI streamUrl={streamUrl} />
          )}
          {eventStatus === 'upcoming' && (
            <DateButton
              eventName={eventName}
              eventDescription={eventDescription}
              eventLocation={eventLocation}
              eventEndTime={eventEndTime}
              eventStartTime={eventStartTime}
              colors={colors}
            />
          )}
        </BelowLivestreamButtons>
      </Container>
      <AnimatePresence initial={false} mode="wait">
        <Dialog ref={groupWatchingDialogRef} isVisible={showGroupWatchingDialog}>
          <GroupWatchingDialog
            colors={colors}
            question={`Are you ${activeTab === 'Participants' ? 'still ' : ''}watching as a group?`}
            setShowGroupWatchingDialog={setShowGroupWatchingDialog}
            groupWatchingDialogRef={groupWatchingDialogRef}
          />
        </Dialog>
      </AnimatePresence>
    </>
  );
}

const BelowLivestreamButtons = styled.div`
  grid-column: 1/7;
  margin-bottom: 1rem;
  margin-right: ${({ eventStatus }) => (eventStatus === 'previous' ? '1.5rem' : 0)};

  @media only screen and (min-width: 48rem) {
    grid-column: 2/6;
  }

  @media only screen and (min-width: 1150px) {
    grid-column: 2/8;
  }
`;

const Dates = styled.h5`
  color: ${({ colors, theme }) =>
    theme.className === 'contrast' ? theme.tertiary : colors.primary};
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.4em;
  margin-bottom: 0;
  margin-top: 0px;

  @media only screen and (min-width: 1150px) {
    font-size: 1.5rem;
  }
`;

const Title = styled.h1`
  color: ${({ colors, theme }) =>
    theme.className === 'contrast' ? theme.primary : colors.secondary};
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.4em;
  margin: 0;

  @media only screen and (min-width: 1150px) {
    font-size: 3rem;
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  margin-bottom: 1rem;
  position: relative;
  z-index: 2;
  @media only screen and (min-width: 48rem) {
    grid-column: 2/5;
    margin-bottom: 1rem;
    margin-top: 3rem;
  }
  @media only screen and (min-width: 1150px) {
    grid-column: 2/8;
    margin-bottom: 3rem;
    margin-top: 0;
  }
`;

const LiveStream = styled.div`
  height: auto;
  margin: 1.25rem -0.75rem;
  overflow: hidden;
  position: relative;
  z-index: 2;
  @media only screen and (min-width: 48rem) {
    margin: 1.25rem 0;
  }
  @media only screen and (min-width: 1150px) {
    height: 100%;
    margin: 0px;
  }
`;

const BannerVideo = styled.video`
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
`;

const Banner = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
`;

const LivestreamAndInteraction = styled.div`
  display: grid;
  grid-column: 1/7;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  width: 100%;
  @media only screen and (min-width: 48rem) {
    grid-column: 2/6;
  }
  @media only screen and (min-width: 1150px) {
    grid-column: 1/13;
    grid-template-columns: 2fr 1fr;
  }
`;

const Container = styled.div`
  color: #00004e;
  display: grid;
  grid-column: 1/7;
  grid-gap: 1rem;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  height: auto;
  margin: 0 auto;
  padding: 1.25rem 0 0;
  width: 100%;

  @media only screen and (min-width: 1150px) {
    grid-column: 1/13;
    grid-template-columns: repeat(12, 1fr);
  }
`;

export default Livestream;
