import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { fadeInAndOutVariants } from 'styles';

const SpeakerReorderIcon = forwardRef((props, ref) => (
  <Container
    ref={ref}
    className={props.className}
    onClick={(e) => e.stopPropagation()}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    variants={fadeInAndOutVariants()}
    {...props}>
    <path
      d="M16.943 8.005 20 11.06l-3.057 3.056M19.29 11.077H2M5.057 14.113 2 11.057 5.057 8M14.113 16.944 11.056 20 8 16.944M11.04 19.572V2.282M8.004 5.056 11.061 2l3.056 3.056"
      stroke={props.theme.primary}
      strokeWidth={2}
    />
  </Container>
));

export default SpeakerReorderIcon;

const Container = styled.svg`
  bottom: 0;
  cursor: grab;
  opacity: 0;
  outline: none;
  padding: 0.5rem;
  position: absolute;
  right: 0;
  transition: opacity 0.2s ease-in-out;
  width: 2.5rem;
`;
