import React, { forwardRef, useContext } from 'react';
import { useDelayUnmount } from 'hooks';
import { LocalContext } from 'context';
import styled, { keyframes } from 'styled-components';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import '@reach/dialog/styles.css';

const Dialog = forwardRef((props, ref) => {
  const { dialogAnimationTime } = useContext(LocalContext);
  const { isVisible, children, onDismiss, overlayStyle, contentStyle } = props;
  // 'useDelayUnmount' is for adding the Dialog component's exit animation - https://stackoverflow.com/a/54114180
  const shouldRenderChild = useDelayUnmount(isVisible, dialogAnimationTime);
  return (
    (isVisible || (!isVisible && shouldRenderChild)) && (
      <StyledDialogOverlay
        ref={ref}
        onDismiss={onDismiss}
        overlayStyle={overlayStyle}
        dialogAnimationTime={dialogAnimationTime}>
        <StyledDialogContent contentStyle={contentStyle} dialogAnimationTime={dialogAnimationTime}>
          {children}
        </StyledDialogContent>
      </StyledDialogOverlay>
    )
  );
});

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;;
  }

  100% {
    opacity: 0;;
  }
`;

const slideUp = keyframes`
  0% {
    transform: translateY(10%);
  }

  100% {
    transform: translateY(0);
  }
`;

const slideDown = keyframes`
  0% {
    transform: translate(0);
  }

  100% {
    transform: translateY(10%);
  }
`;

const StyledDialogOverlay = styled(DialogOverlay)`
  align-items: center;
  backdrop-filter: blur(0.125rem);
  background-color: rgb(0 0 0 / 0.25);
  display: flex;
  filter: ${({ theme }) => (theme.className === 'grayscale' ? 'grayscale(100%)' : 'none')};
  justify-content: center;
  overflow-x: hidden !important;
  padding: 0.75rem;
  transform-style: preserve-3d;
  z-index: 4;
  ${({ overlayStyle }) => overlayStyle};

  &[data-state='open'] {
    animation: ${fadeIn} ${({ dialogAnimationTime }) => `${dialogAnimationTime}ms`} ease-in-out
      forwards;
  }

  &[data-state='close'],
  &[data-state='dismiss'] {
    animation: ${fadeOut} ${({ dialogAnimationTime }) => `${dialogAnimationTime}ms`} ease-in-out
      forwards;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  background-color: transparent !important;
  box-sizing: border-box;
  padding: 0 !important;
  transform-style: preserve-3d;
  width: 100% !important;
  ${({ contentStyle }) => contentStyle};

  &[data-state='open'] {
    animation: ${slideUp} ${({ dialogAnimationTime }) => `${dialogAnimationTime}ms`} ease-in-out
      forwards;
  }

  &[data-state='dismiss'] {
    animation: ${slideDown} ${({ dialogAnimationTime }) => `${dialogAnimationTime}ms`} ease-in-out
      forwards;
  }
`;

export default Dialog;
