import { httpsCallable } from 'firebase/functions';

function RegistrationFactory(firebase) {
  const checkIfAccountAlreadyExists = async ({ email }) => {
    const checkIfAccountAlreadyExistsCallable = httpsCallable(
      firebase.functions,
      'user-account-checkIfAccountAlreadyExists'
    );
    return checkIfAccountAlreadyExistsCallable({ email: email.toLowerCase().trim() });
  };

  const createAccount = async ({
    email,
    name,
    profession,
    uid,
    eventId,
    referringUrl,
    company
  }) => {
    const createAccountCallable = httpsCallable(firebase.functions, 'user-account-create');
    return createAccountCallable({
      email: email.toLowerCase().trim(),
      name,
      profession,
      uid,
      eventId,
      referringUrl,
      company
    });
  };

  const registerForEventIfAccountAlreadyExists = async ({ eventId, uid }) => {
    const registerForEventIfAccountAlreadyExistsCallable = httpsCallable(
      firebase.functions,
      'user-account-registerForEventIfAccountAlreadyExists'
    );
    return registerForEventIfAccountAlreadyExistsCallable({
      eventId,
      uid
    });
  };

  return {
    checkIfAccountAlreadyExists,
    createAccount,
    registerForEventIfAccountAlreadyExists
  };
}

export default RegistrationFactory;
