import { httpsCallable } from 'firebase/functions';

function ProjectsFactory(firebase) {
  const uploadProject = async ({ eventId, title, author, summary, poster, timestamp }) => {
    const uploadProjectCallable = httpsCallable(firebase.functions, 'user-project-upload');
    return uploadProjectCallable({
      author,
      eventId,
      poster,
      summary,
      timestamp,
      title
    });
  };

  const convertPDFtoPNG = async ({ fileName }) => {
    const convertPDFtoPNGCallable = httpsCallable(
      firebase.functions,
      'user-project-convertPDFToPNG)'
    );
    return convertPDFtoPNGCallable({
      fileName
    });
  };

  return {
    uploadProject,
    convertPDFtoPNG
  };
}

export default ProjectsFactory;
