import React, { useState, useContext, useEffect, useRef } from 'react';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import { Navbar, Dialog, LoginOrSignUp, PasswordLoginDialog, ClosedEventDialog } from 'components';
import { motion } from 'framer-motion';
import { Markup } from 'interweave';
import { LocalContext } from 'context';
import { useLocation } from '@reach/router';
import { FirebaseContext, useAuth } from 'firebase';
import queryString from 'query-string';
import '../../assets/fonts/fonts.css';

import { defaultColors } from 'styles';

const GlobalStyles = createGlobalStyle`
  :root {
    --primary-color: #004D9A;
    --secondary-color: #2D9CDB;
    --tertiary-color: #36D1B7;
    --border-radius-rounded-large: 26px;
    --border-radius-rounded-small: 20px;
    --border-radius-rectangle: 6px;
  }

  * {
    margin:0;
    padding:0;
    box-sizing:border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html{
    font-size: ${({ font }) => font};
  }

  body {
    background: ${({ theme }) => theme.contrast};
    overflow-x:hidden;
    font-family: 'Helvetica Neue', sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  div [class*="PageWrapper"] {
    background: ${({ theme }) => theme.contrast};
  }

  a{
    text-decoration: none;
  }

  button{
    border:none;
    outline:none;
    &:focus{
        outline:none;
        border:none;
    }
  }

  /* Address autocomplete dropdown style overrides */
  .pac-container {
    background: ${({ selectedEvent }) =>
      selectedEvent ? selectedEvent.colors.primary : 'var(--primary-color)'};
    border: 0.063rem solid #fff;
    border-top-width: 0;
    border-radius: 0;
    box-shadow: none;
    @media screen and (min-width: 1440px) {
      max-width: 392px !important;
      width: 100% !important;
    }
  }

  .pac-target-input {
    outline: none;
    width: 100%;
  }

  .pac-target-input:focus {
    border: 0.188rem solid #fff !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .pac-item {
    color: #fff;
    background-color: transparent;
    font-size: 0.875rem;
    font-weight: bold;
    letter-spacing: 0.031rem;
    padding: 0.188rem 1.25rem 0;
    border: none;
    height: 2.3rem;
  }

  .pac-item:hover {
    background-color: ${({ selectedEvent }) =>
      selectedEvent ? selectedEvent.colors.secondary : 'var(--secondary-color)'};
    cursor: pointer;
  }

  .pac-logo {
    box-sizing: content-box;
    border: 0.188rem solid #fff;
    border-top: none;

    @media screen and (min-width: 1440px) {
      box-sizing: border-box;
    }
  }

  .pac-logo:after {
    padding: 0;
    height: 0;
  }

  .hdpi.pac-logo:after {
    padding: 0;
    height: 0;
  }

  .pac-icon,
  .hdpi .pac-icon {
    display: none;
  }

  .pac-item-query {
    color: #fff;
    font-size: 1rem;
  }

  .pac-item-query:after {
    content: ','
  }

  .pac-selected {
    background-color: ${({ selectedEvent }) =>
      selectedEvent ? selectedEvent.colors.tertiary : 'var(--secondary-color)'};
  }
`;

const Wrapper = styled(motion.main)`
  background: ${({ theme }) => theme.contrast};
  box-sizing: border-box;
  display: grid;
  filter: ${({ theme }) => (theme.className === 'grayscale' ? 'grayscale(100%)' : 'none')};
  grid-column-gap: 0.625rem;
  grid-template-columns: repeat(6, 1fr);

  @media only screen and (min-width: 1150px) {
    grid-column-gap: 1.25rem;
    grid-template-columns: repeat(12, 1fr);
  }
`;

function Layout({ children }) {
  const {
    theme,
    fontSizing,
    selectedEvent,
    registrationFormSubmissionStatus,
    showLoginOrSignUpDialog,
    setShowLoginOrSignUpDialog,
    handleDialog,
    loginOrSignUpDialogRef,
    magicLinkEmailStatus,
    returningVisitorsUnverifiedEmailAddress,
    browserTabIsActive,
    showClosedEventDialog,
    setShowClosedEventDialog
  } = useContext(LocalContext);
  const [showPasswordLoginDialog, setShowPasswordLoginDialog] = useState(false);
  const { user, firebase, loading } = useAuth();
  const location = useLocation();
  const { pathname, search } = location;
  const queryParams = queryString.parse(search);
  const passwordLogin = queryParams?.passwordLogin;
  const colors = selectedEvent ? selectedEvent.colors : defaultColors;

  const closedEventDialogRef = useRef(null);

  useEffect(() => {
    if (passwordLogin) {
      setShowPasswordLoginDialog(true);
    }
  }, [passwordLogin]);

  useEffect(() => {
    let unsubscribeFromRTDBServer;

    // Sometimes the user object can initialise briefly with the user data from Firebase Auth but
    // not yet the user data from Firestore, so to prevent any errors here we check for the uid
    // property on the user object, to make sure it has the Firestore data. If we don't do this then
    // the code may try to update 'presence' on a document that doesn't exist yet, resulting in an
    // error.
    if (!loading && firebase && user?.uid && browserTabIsActive) {
      unsubscribeFromRTDBServer = firebase.interaction.participants.subscribeToRTDBServer({
        snapshot: (snapshot) => {
          if (snapshot.val()) {
            return firebase.interaction.participants.updateUserPresence({
              user,
              eventId: selectedEvent?.eventId ?? ''
            });
          }
        }
      });
    }
    return () => {
      if (unsubscribeFromRTDBServer) {
        unsubscribeFromRTDBServer();
      }
    };
    // We're using user?.uid in the dependency array here - instead of just user - to avoid getting
    // into an infinite loop each time presence is updated on the user object.
  }, [loading, firebase, user?.uid, selectedEvent?.eventId, browserTabIsActive]);

  const dismissClosedEventDialog = (callback) =>
    handleDialog({
      dialogRef: closedEventDialogRef,
      animation: 'dismiss',
      stateHandler: () => setShowClosedEventDialog(false),
      callback
    });

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <FirebaseContext.Provider value={{ user, firebase, loading }}>
      <ThemeProvider theme={theme}>
        <Wrapper initial={pathname === '/' ? { opacity: 0 } : false} animate={{ opacity: 1 }}>
          <GlobalStyles font={fontSizing} selectedEvent={selectedEvent} />
          {!location.pathname.includes('preview') && <Navbar colors={colors} />}
          {children}
          {!firebase?.auth?.currentUser &&
            (registrationFormSubmissionStatus.submitted ||
              returningVisitorsUnverifiedEmailAddress) && (
              <EmailAddressNotVerifiedNotification colors={colors}>
                <Markup content="Email address not verified. Please click the link in the email we just sent you to complete your registration application.<br /> Can't find our email? Be sure to check your junk!" />
              </EmailAddressNotVerifiedNotification>
            )}
          {showPasswordLoginDialog && !user ? (
            <PasswordLoginDialog colors={colors} />
          ) : (
            <Dialog
              ref={loginOrSignUpDialogRef}
              isVisible={showLoginOrSignUpDialog}
              onDismiss={() => {
                if (pathname === '/') {
                  handleDialog({
                    dialogRef: loginOrSignUpDialogRef,
                    animation: magicLinkEmailStatus.sent ? 'close' : 'dismiss',
                    stateHandler: () => setShowLoginOrSignUpDialog(false)
                  });
                }
              }}
              contentStyle={{
                maxWidth: '26.5rem'
              }}>
              <LoginOrSignUp colors={colors} isDialog hideCloseIcon />
            </Dialog>
          )}
          <Dialog
            ref={closedEventDialogRef}
            isVisible={showClosedEventDialog}
            contentStyle={{ maxWidth: '31.25rem' }}>
            <ClosedEventDialog
              colors={defaultColors}
              event={selectedEvent || null}
              dismissClosedEventDialog={dismissClosedEventDialog}
            />
          </Dialog>
        </Wrapper>
      </ThemeProvider>
    </FirebaseContext.Provider>
  );
}

const EmailAddressNotVerifiedNotification = styled.div`
  align-items: center;
  animation: slideIn 750ms forwards;
  background-color: ${({ theme, colors }) =>
    theme.className === 'contrast' ? theme.primary : colors.tertiary};
  bottom: -4.188rem;
  color: ${({ theme }) => (theme.className === 'contrast' ? theme.contrast : '#fff')};
  display: flex;
  font-weight: bold;
  height: auto;
  justify-content: center;
  left: 0;
  letter-spacing: 0.05rem;
  line-height: 1.325rem;
  opacity: 0;
  padding: 0.75rem 0.75rem 0.813rem;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 11;

  @keyframes slideIn {
    0% {
      bottom: -4.188rem;
      opacity: 0;
    }

    100% {
      bottom: 0;
      opacity: 1;
    }
  }
`;

export default Layout;
