import * as React from 'react';

function SvgCampaignxLogo(props) {
  return (
    <svg viewBox="0 0 337 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 15.977C0 6.486 6.804.003 15.373.003c2.16-.04 4.3.434 6.248 1.384a13.852 13.852 0 0 1 4.963 4.082l-2.198 1.42a11.153 11.153 0 0 0-3.979-3.329 11.014 11.014 0 0 0-5.034-1.152c-7.093 0-12.625 5.54-12.625 13.57 0 7.944 5.532 13.563 12.625 13.563 1.745.019 3.47-.378 5.035-1.158a11.164 11.164 0 0 0 3.978-3.333l2.198 1.393c-2.7 3.379-6.28 5.508-11.211 5.508C6.804 31.951 0 25.468 0 15.977ZM104.924 3.987 93.801 31.396h-1.005L81.653 3.986v27.41h-2.617V.533h3.889l10.374 25.582L103.636.533h3.936v30.863h-2.654l.006-27.41ZM193.459.533h2.617v30.863h-2.617V.533ZM257.164 4.728v26.668h-2.617V.533h2.653l19.163 26.276V.533h2.617v30.863h-2.617L257.164 4.728ZM53.849.512h-3.25l-12.49 30.884h2.927L52.2 3.107l7.323 18.29h-9.055l-1 2.404h10.876l3.068 7.595h2.93L53.85.512ZM166.676.512h-3.251l-12.488 30.884h2.926l11.164-28.289 7.323 18.29h-9.055l-1 2.404h10.876l3.068 7.595h2.925L166.676.512ZM226.8.004c2.185-.05 4.353.4 6.342 1.317a14.524 14.524 0 0 1 5.142 3.98l-1.968 1.435a12.434 12.434 0 0 0-4.29-3.212A12.302 12.302 0 0 0 226.8 2.43c-7.186-.016-12.724 5.518-12.724 13.548 0 7.944 5.533 13.612 12.719 13.612a12.909 12.909 0 0 0 8.966-3.75v-7.362h-12.614l1.047-2.41h14.179v10.789a15.473 15.473 0 0 1-5.243 3.822 15.318 15.318 0 0 1-6.33 1.32c-8.605 0-15.467-6.482-15.467-16.021 0-9.54 6.862-15.974 15.467-15.974ZM324.438 19.225l9.27 12.17H337l-10.934-14.072-1.628 1.902ZM326.134 13.716 336.314.512h-3.292l-8.484 11.213 1.596 1.991Z"
        fill="#000"
      />
      <path
        d="M313.206.512h-3.34l11.625 15.047-12.31 15.837h3.297l12.159-15.752L313.206.512Z"
        fill="#009245"
      />
      <path
        d="M134.643.533h-11.437v30.863h2.617V2.922h8.558c4.025 0 6.726 2.728 6.726 6.62 0 3.894-2.701 6.621-6.726 6.621h-4.915l-1.23 2.41h6.396c5.816 0 9.197-4.168 9.197-9.004 0-4.835-3.329-9.036-9.186-9.036Z"
        fill="#000"
      />
    </svg>
  );
}
export default SvgCampaignxLogo;
