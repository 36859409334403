/* eslint-disable react/no-unknown-property */
import * as THREE from 'three';
import React, { Suspense, useState, useContext } from 'react';
import { Canvas } from '@react-three/fiber';
import {
  BakeShadows,
  Environment,
  ContactShadows,
  OrbitControls,
  MeshReflectorMaterial,
  Html
} from '@react-three/drei';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { LocalContext } from 'context';
import { LoadingSpinner, Hotspot, PlayButton, Stand } from 'components';
import { fadeInAndOutVariants } from 'styles';

function Loading({ theme }) {
  return (
    <Html center>
      <LoadingSpinner
        style={{
          width: '6rem',
          color: theme.primary
        }}
      />
    </Html>
  );
}

function SponsorBooth() {
  const { theme, isMobile } = useContext(LocalContext);
  const [showInstructions, setShowInstructions] = useState(false);
  const fov = isMobile ? 100 : 65;
  return (
    <Wrapper variants={fadeInAndOutVariants()} initial="initial" animate="animate" exit="exit">
      <>
        <Canvas shadows dpr={[1, 2]} shadowMap camera={{ position: [0, 60, 60], fov }}>
          <Suspense fallback={<Loading theme={theme} />}>
            <pointLight intensity={0.1} position={[10, 20, 30]} />
            <spotLight
              position={[0, 120, 0]}
              angle={0.25}
              penumbra={1}
              castShadow
              color="white"
              shadow-mapSize-height={512}
              shadow-mapSize-width={512}
              intensity={1}
            />
            <spotLight
              position={[80, 40, 0]}
              angle={0.25}
              penumbra={1}
              color="#ffac5f"
              castShadow
              shadow-mapSize-height={512}
              shadow-mapSize-width={512}
              intensity={2.5}
            />
            <spotLight
              position={[50, 40, 20]}
              angle={0.25}
              penumbra={1}
              castShadow
              color="#ffac5f"
              shadow-mapSize-height={512}
              shadow-mapSize-width={512}
              intensity={2.5}
            />
            <ambientLight intensity={0.2} />
            <PlayButton
              positioning={[30, 7.85, -20]}
              theme={theme}
              text="Corporate, campaign or product video"
              src="https://panacea.eventxlive.com/help-hub"
              buttonText="For more information"
              isMobile={isMobile}
            />
            <Hotspot text="Your company representatives" positioning={[11.5, 15.25, -19]} />
            <Hotspot text="Contact information" positioning={[-17, -9, 22]} />
            <Hotspot
              text="Link to website"
              positioning={[44, -3.5, 22]}
              link="https://panacea.eventxlive.com/"
            />
            <Hotspot text="Current marketing campaign" positioning={[-43, 24, 16]} />
            <Hotspot text="Product information, awards and API" positioning={[-16.5, 17.5, -19]} />
            <Stand />
            <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -20, 0]}>
              <circleGeometry args={[60, 768]} />
              <MeshReflectorMaterial
                blur={[400, 100]}
                resolution={1024}
                mixBlur={0.5}
                opacity={2}
                depthScale={1.1}
                minDepthThreshold={0.4}
                maxDepthThreshold={1.25}
                roughness={1}
                color="white"
              />
            </mesh>
            <Environment files="background.hdr" path="/" background resolution={512} />
            <ContactShadows
              resolution={2048}
              frames={1}
              position={[0, -18, 0]}
              scale={100}
              blur={0.75}
              opacity={1}
              far={10}
            />
            <BakeShadows />
          </Suspense>
          <OrbitControls
            autoRotate
            autoRotateSpeed={0.3}
            enablePan={false}
            enableZoom={false}
            minPolarAngle={Math.PI / 2.4}
            maxPolarAngle={Math.PI / 2.4}
          />
        </Canvas>
        <Options>
          <InstructionContainer>
            <OptionButton
              aria-label="Show Instructions"
              aria-labelledby="show-instructions"
              onMouseEnter={() => setShowInstructions(true)}
              onMouseLeave={() => setShowInstructions(false)}>
              <span>&#x2139;</span>
            </OptionButton>
            {showInstructions && (
              <Instruction
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}>
                <p>
                  Hover over the hotspots to see more information, or click the play button to start
                  the video.
                </p>
              </Instruction>
            )}
          </InstructionContainer>
        </Options>
      </>
    </Wrapper>
  );
}

const Wrapper = styled(motion.div)`
  align-content: center;
  background: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  overflow: visible;
  position: relative;
  width: 100%;
`;

const Options = styled.div`
  align-items: flex-end;
  bottom: 0rem;
  display: flex;
  height: auto;
  margin: 3.5rem;
  position: absolute;
  width: 100%;
`;

const OptionButton = styled.button`
  align-items: center;
  background: ${({ theme }) => theme.tertiary};
  border: 0;
  border-radius: 50%;
  box-shadow: 0;
  cursor: pointer;
  display: flex;
  height: 3rem;
  justify-content: center;
  line-height: 1;
  padding: 0.5em;
  position: relative;
  right: 0.75rem;
  text-align: center;
  top: 0.75rem;
  transition: 0.2s;
  width: 3rem;
  z-index: 20;
  &:hover {
    transform: scale3d(1.075, 1.075, 1.075);
    transition: transform 200ms ease-in-out;
  }
  &:first-child {
    margin-right: 0.75rem;
  }
  svg {
    path {
      fill: ${({ theme }) => (theme.className === 'contrast' ? theme.contrast : '#EAF5F9')};
      stroke: ${({ theme }) => (theme.className === 'contrast' ? theme.contrast : '#EAF5F9')};
    }
  }
  span {
    color: ${({ theme }) => (theme.className === 'contrast' ? theme.contrast : '#EAF5F9')};
    font-size: 1.75rem;
    padding-bottom: 0.25rem;
  }
`;

const InstructionContainer = styled.div`
  align-items: flex-end;
  display: flex;
  height: auto;
`;

const Instruction = styled(motion.div)`
  background: ${({ theme }) => (theme.className === 'contrast' ? theme.contrast : '#EAF5F9')};
  border-radius: 6px;
  color: ${({ theme }) => theme.primary};
  padding: 1rem;
  width: 300px;
`;

export default SponsorBooth;
