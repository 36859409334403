import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import {
  DefaultParticipantAvatar,
  ModalCloseIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  InstagramIcon
} from 'assets/svgs';

function ParticipantModal({ colors, participant, handleParticipantAvatarOrNameClick }) {
  return (
    <Wrapper
      onClick={(e) => {
        if (e.target.dataset.underlay === 'true') {
          handleParticipantAvatarOrNameClick();
        }
      }}
      data-underlay
      colors={colors}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}>
      <div>
        <div onClick={() => handleParticipantAvatarOrNameClick()} role="button" tabIndex={0}>
          <ModalCloseIcon width="12" height="12" style={{ top: '1.25rem', right: '1.25rem' }} />
        </div>
        {participant.avatarUrl ? (
          <ParticipantAvatar src={participant.avatarUrl} alt={participant.name} colors={colors} />
        ) : (
          <DefaultParticipantAvatar />
        )}
        <h3>{participant.name}</h3>
        <p>
          {participant.profession
            ? `${participant.profession}, ${participant.company}`
            : participant.company}
        </p>
        <SocialGroup>
          {participant.socials?.linkedIn && (
            <SocialLink href={participant.socials.linkedIn} target="_blank" colors={colors}>
              <LinkedinIcon />
            </SocialLink>
          )}
          {participant.socials?.twitter && (
            <SocialLink
              href={`https://www.twitter.com/${participant.socials.twitter.replace('@', '')}`}
              target="_blank"
              colors={colors}>
              <TwitterIcon />
            </SocialLink>
          )}
          {participant.socials?.instagram && (
            <SocialLink
              href={`https://www.instagram.com/${participant.socials.instagram.replace('@', '')}`}
              target="_blank"
              colors={colors}>
              <InstagramIcon />
            </SocialLink>
          )}
          {participant.socials?.facebook && (
            <SocialLink href={participant.socials.facebook} target="_blank" colors={colors}>
              <FacebookIcon />
            </SocialLink>
          )}
        </SocialGroup>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled(motion.div)`
  backdrop-filter: blur(0.125rem);
  background: rgba(0, 0, 0, 0.6);
  overflow: hidden;
  padding: 0 1.625rem;
  position: relative;
  z-index: 1;

  > div {
    background-color: #fff;
    margin: 6.25rem auto 0;
    max-width: 23.75rem;
    padding: 7.9rem 1.5rem 2rem;
    position: relative;
    width: 100%;

    > div:first-child {
      align-items: center;
      background-color: ${({ colors }) => colors.secondary};
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      height: 2rem;
      justify-content: center;
      position: absolute;
      right: 0;
      top: -4rem;
      width: 2rem;
    }

    > img,
    > svg {
      border-radius: 50%;
      height: 10rem;
      left: calc(50% - 5rem);
      position: absolute;
      top: -4rem;
      width: 10rem;
    }

    > h3,
    > p {
      color: #3c3c3c;
      text-align: center;
    }

    > h3 {
      font-size: 1.25rem;
      margin-bottom: 0.28em;
    }
  }
`;

const ParticipantAvatar = styled.img`
  border: 0.2rem solid ${({ colors }) => colors.secondary};
  object-fit: cover;
`;

const SocialLink = styled.a`
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin: 0 0.4rem;
  transition: 0.5s;

  svg {
    border-radius: 50%;
    height: 1.5rem;
    width: 1.5rem;

    circle {
      transition: 0.5s;
      fill: ${({ colors }) => colors.secondary};
    }

    path {
      fill: #fff;
      transition: 0.5s;
    }
  }

  &:hover svg {
    box-shadow: 0 0.188rem 0.5rem rgba(0, 0, 0, 0.25);
  }

  &:active svg {
    transform: scale(0.9);
  }
`;

const SocialGroup = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 1.75rem auto 0;
`;

export default ParticipantModal;
