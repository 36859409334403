import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import TextInput from 'components/Events/Livestream/Interaction/Polls/Shared/TextInput';

function GroupMembers({ groupMembers, setGroupMembers, handleGroupMemberTextChange, colors }) {
  const [currentlyHoveredMember, setCurrentlyHoveredMember] = useState(null);
  const handleDeleteInput = ({ target }) =>
    setGroupMembers((currentState) =>
      currentState.filter((groupMember) => groupMember.id !== target.parentElement.id)
    );
  return (
    <Container>
      {groupMembers.map((groupMember) => (
        <GroupMember
          id={groupMember.id}
          onMouseEnter={() => setCurrentlyHoveredMember(groupMember.id)}
          onMouseLeave={() => setCurrentlyHoveredMember(null)}>
          <GroupMemberTextInput
            onChange={(e) => handleGroupMemberTextChange(e, groupMember.id)}
            placeholder="Add group member's name"
            type="input"
            value={groupMember.name}
            empty={groupMember.name.length === 0}
            colors={colors}
          />
          {groupMembers.length > 1 && currentlyHoveredMember === groupMember.id && (
            <DeleteButton onClick={handleDeleteInput} role="button" tabIndex="0">
              <svg
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M7 1L0.999999 7" stroke={colors.secondary} strokeWidth="2" />
                <path d="M7 7L0.999999 0.999998" stroke={colors.secondary} strokeWidth="2" />
              </svg>
            </DeleteButton>
          )}
        </GroupMember>
      ))}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 280px;
  padding-bottom: 1.55rem;
  width: 100%;
`;

const GroupMember = styled.div`
  display: flex;
  position: relative;
`;

const GroupMemberTextInput = styled(TextInput)`
  background-color: transparent;
  border: ${({ colors, empty }) =>
    !empty ? `1px solid ${colors.secondary}` : '1px solid transparent'};
  border-bottom-color: ${({ colors, empty }) => (!empty ? colors.secondary : '#bdbdbd')};
  color: ${({ colors, empty }) => (!empty ? colors.secondary : '#000000')};
  height: 1.625rem;
  margin-left: 0.375rem;
  padding-left: 0.5rem;
  &:focus {
    border: 1px solid transparent;
    border-bottom-color: #bdbdbd;
    color: #000000;
    font-weight: 400;
  }
`;

const DeleteButton = styled(motion.div).attrs({
  animate: { opacity: 1 },
  initial: { opacity: 0 },
  exit: { opacity: 0 },
  whileHover: { scale: 1.2 },
  whileTap: { scale: 0.9 }
})`
  cursor: pointer;
  display: flex;
  padding: 0.375rem;
  position: absolute;
  right: 0;
  top: 0.2rem;

  > * {
    pointer-events: none;
  }
`;

export default GroupMembers;
