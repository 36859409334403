import React, { useEffect } from 'react';
import { format } from 'date-fns';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { hexToRGB } from 'utils';

function TimeZoneSelector({ selectedTimeZone, setSelectedTimeZone, timeZones, colors, style }) {
  const handleTimeZoneClick = (e) => setSelectedTimeZone(e.target.innerText);

  useEffect(() => {
    const date = format(new Date(), 'yyyy-MM-dd HH:mm z');
    const timeZoneLetters = date?.slice(date.lastIndexOf(' ') + 1);
    switch (timeZoneLetters) {
      case 'GMT':
        setSelectedTimeZone('GMT');
        break;
      case 'GMT+0':
        setSelectedTimeZone('GMT');
        break;
      case 'CET':
        setSelectedTimeZone('CET');
        break;
      case 'CET+0':
        setSelectedTimeZone('CET');
        break;
      case 'EST':
        setSelectedTimeZone('EST');
        break;
      case 'EST+0':
        setSelectedTimeZone('EST');
        break;
      default:
        setSelectedTimeZone('GMT');
    }
  }, []);

  return (
    <Container colors={colors} style={style}>
      <TimeZones>
        {timeZones.map((timeZone) => (
          <TimeZone
            onClick={handleTimeZoneClick}
            selected={timeZone === selectedTimeZone}
            key={timeZone}
            custom={colors}>
            {timeZone}
          </TimeZone>
        ))}
      </TimeZones>
      <ChooseTimeZone colors={colors}>
        Select
        <br />
        Time Zone
      </ChooseTimeZone>
    </Container>
  );
}

const ChooseTimeZone = styled(motion.div)`
  align-items: center;
  background-color: #fff;
  border: 1px solid ${({ colors }) => colors?.secondary};
  border-radius: 30px;
  color: ${({ colors }) => colors?.secondary};
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  font-weight: 700;
  height: 100%;
  justify-content: center;
  left: 0;
  line-height: 1.25em;
  position: absolute;
  text-align: center;
  top: 0;
  transition:
    border 300ms ease-in-out,
    background-color 300ms ease-in-out;
  width: 120px;
`;

const Container = styled(motion.div)`
  background-color: ${({ colors }) => hexToRGB({ color: colors.secondary, alpha: 0.2 })};
  border-radius: 30px;
  grid-column: 1 / 2;
  grid-row: 2;
  height: 48px;
  overflow: hidden;
  padding-right: 23px;
  position: relative;
  transition: width 300ms ease-in-out;
  width: 120px;
  ${({ style }) => style}

  &:hover {
    width: 100%;

    @media (min-width: 400px) {
      width: 354px;
    }

    @media (min-width: 767px) {
      grid-column: 1 / 3;
    }

    ${ChooseTimeZone} {
      background-color: ${({ colors }) => colors?.secondary};
      color: #fff;
    }
  }
`;

const TimeZones = styled.ul`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  list-style-type: none;
`;

const TimeZone = styled(motion.li).attrs({
  whileHover: (colors) => ({
    backgroundColor: colors.secondary,
    color: '#fff'
    // mixBlendMode: 'multiply'
  })
})`
  align-items: center;
  color: #3c3c3c;
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-size: 1rem;
  text-align: center;
  text-decoration: ${({ selected }) => (selected ? 'underline' : 'none')};
  height: 100%;
  width: 65px;
`;

export default TimeZoneSelector;
