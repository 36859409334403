/* eslint-disable react/no-unknown-property */
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { TextureLoader } from 'three';
import { useLoader, useFrame } from '@react-three/fiber';
import { Html, MeshDistortMaterial } from '@react-three/drei';
import { useSpring } from '@react-spring/core';
import { a } from '@react-spring/three';
import texture from './textures/map.jpg';

const AnimatedMaterial = a(MeshDistortMaterial);

function PlayButton({ text, positioning, buttonRef, src, theme, buttonText }) {
  const colorMap = useLoader(TextureLoader, texture);
  const hotspot = useRef();
  // eslint-disable-next-line no-return-assign
  const [showModal, setShowModal] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [down, setDown] = useState(false);
  const [{ scale }] = useSpring(
    {
      from: { scale: 22 },
      to: { scale: 24 },
      config: { duration: 1000 },
      loop: { reverse: true }
    },
    []
  );
  useEffect(() => {
    document.body.style.cursor = hovered ? 'pointer' : 'auto';
  }, [hovered]);

  return (
    <a.mesh
      position={positioning}
      scale={down ? 23 : scale}
      onPointerOver={() => {
        setHovered(true);
      }}
      onPointerOut={() => {
        setHovered(false);
      }}
      onClick={() => setShowModal(true)}
      onPointerDown={() => setDown(true)}
      onPointerUp={() => setDown(false)}
      ref={hotspot}>
      <circleGeometry attach="geometry" args={[0.15, 32]} />
      <AnimatedMaterial
        attach="material"
        map={colorMap}
        displacementMap={colorMap}
        displacementScale={0.05}
        distort={0}
        speed={5}
        envMapIntensity="1"
        clearcoat="1"
        clearcoatRoughness={0}
        metalness={0.1}
      />
      {showModal && (
        <Html fullscreen style={{ marginLeft: '400px', marginTop: '250px' }}>
          <Modal
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}>
            <ModalClose
              ref={buttonRef}
              aria-label="Close Modal"
              aria-labelledby="close-modal"
              onClick={() => setShowModal(false)}>
              <ModalCloseIcon viewBox="0 0 40 40">
                <path d="M 10,10 L 30,30 M 30,10 L 10,30" />
              </ModalCloseIcon>
            </ModalClose>
            <p style={{ marginTop: '2rem' }}>{text}</p>
            <iframe
              title="Video"
              id="livestream-iframe"
              src="https://player.vimeo.com/video/464250370?h=50e5533b91?pip=1&autoplay=1&fullscreen=1&controls=1"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              style={{
                width: '100%',
                height: '100%'
              }}
            />
            <MoreInformation href={src} target="_blank" alt rel="noreferrer">
              {buttonText}
            </MoreInformation>
          </Modal>
        </Html>
      )}
    </a.mesh>
  );
}

const Modal = styled(motion.div)`
  align-items: center;
  background: white;
  border-radius: 3px;
  box-shadow: 7px 10px 47px -20px rgba(0, 0, 0, 0.75);
  color: #004d9a;
  display: flex;
  flex-direction: column;
  height: 75%;
  padding: 1rem;
  width: 500px;

  img {
    height: 400px;
    margin: 1rem;
    overflow: hidden;
  }
`;

const ModalCloseIcon = styled.svg`
  height: 2rem;
  stroke-linecap: round;
  stroke-width: 3;
  width: 2rem;
`;

const ModalClose = styled.button`
  align-items: center;
  background: ${({ theme }) => theme.tertiary};
  border: 0;
  border-radius: 50%;
  box-shadow: 0;
  cursor: pointer;
  display: flex;
  height: 2rem;
  justify-content: center;
  line-height: 1;
  padding: 0.5em;
  position: relative;
  right: 0.75rem;
  top: 0.75rem;
  transition: 0.2s;
  width: 2rem;
  z-index: 20;
  &:hover {
    transform: scale3d(1.075, 1.075, 1.075);
    transition: transform 200ms ease-in-out;
  }
  svg {
    path {
      fill: ${({ theme }) => (theme.className === 'contrast' ? theme.contrast : '#EAF5F9')};
      stroke: ${({ theme }) => (theme.className === 'contrast' ? theme.contrast : '#EAF5F9')};
    }
  }
  span {
    color: ${({ theme }) => (theme.className === 'contrast' ? theme.contrast : '#EAF5F9')};
    font-size: 1.25rem;
  }
`;

const MoreInformation = styled.a`
  background: ${({ theme }) => theme.tertiary};
  border-radius: 25px;
  color: white;
  line-height: 1;
  padding: 1em 1.5em;
`;

export default PlayButton;
