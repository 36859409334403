import React from 'react';
import styled from 'styled-components';
import { DialogCloseIcon } from 'components';
import { FormContainer } from '../FormComponents';

function Unsubscribe({ closeUnsubscribeDialog }) {
  return (
    <FormContainer style={{ padding: '1.5rem 1rem', position: 'relative' }}>
      <DialogCloseIcon width="0.875em" onClick={closeUnsubscribeDialog} />
      <H3>
        <br />
        You have been unsubscribed
        <br />
        <br />
      </H3>
    </FormContainer>
  );
}

const H3 = styled.h3`
  align-items: center;
  display: flex;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: bold;
  justify-content: center;
  line-height: 1.75rem;
  text-align: center;
`;

export default Unsubscribe;
