import React, { useContext } from 'react';
import { LocalContext } from 'context';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Markup } from 'interweave';
import { useEventData } from 'hooks';
import { DateButton } from 'components';

function UpcomingEvents() {
  const { theme } = useContext(LocalContext);
  const { edges: events } = useEventData();
  const upcomingEvents = events
    .filter(({ node }) => node.frontmatter.status === 'upcoming')
    .sort((a, b) => b.node.frontmatter.eventId - a.node.frontmatter.eventId);

  return (
    <Events>
      {upcomingEvents.map((event, i) => (
        <Event key={i} $colors={event.node.frontmatter.colors}>
          <Link to={`events/${event.node.frontmatter.slug}`}>
            <GatsbyImage
              image={getImage(event.node.frontmatter.thumb)}
              alt={event.node.frontmatter.name}
            />
          </Link>
          <Details>
            <h4>
              <Markup content={event.node.frontmatter.date} />
            </h4>
            <Link to={`events/${event.node.frontmatter.slug}`}>
              <h3>{event.node.frontmatter.name}</h3>
            </Link>
            <p>{event.node.frontmatter.description}</p>
            <DateButton
              eventName={event.node.frontmatter.name}
              eventDescription={event.node.frontmatter.description}
              eventStartTime={event.node.frontmatter.startTime}
              eventEndTime={event.node.frontmatter.endTime}
              eventLocation={event.node.frontmatter.location}
              colors={event.node.frontmatter.colors}
            />
          </Details>
        </Event>
      ))}
    </Events>
  );
}

const Details = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  h3 {
    font-size: 2.5rem;
    font-weight: 400;
    margin: 0;
    margin-bottom: 0.5em;
  }
  h4 {
    font-size: 1.25rem;
    font-weight: 600 !important;
    margin-bottom: 1em !important;
    text-align: left !important;
    width: 100% !important;
  }
  p {
    font-size: 1rem;
    line-height: 1.5em;
    margin-bottom: 1em !important;
    text-align: left !important;
    width: 100% !important;
  }
`;

const Event = styled.div`
  background: ${({ theme }) => theme.contrast};
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: 1fr;
  padding: 1.25rem;
  img {
    max-width: 100%;
    object-position: top left !important;
  }
  .gatsby-image-wrapper {
    height: 100%;
  }
  h3 {
    color: ${({ theme, $colors }) =>
      theme.className === 'contrast' ? theme.primary : $colors.secondary};
  }
  h4 {
    color: ${({ theme, $colors }) =>
      theme.className === 'contrast' ? theme.primary : $colors.primary};
  }
  p {
    color: ${({ theme }) => (theme.className === 'contrast' ? theme.primary : '#3c3c3c')};
  }
  @media only screen and (min-width: 1150px) {
    grid-template-columns: 20rem 1fr;
  }
`;

const Events = styled.div`
  background: white;
  grid-column: 1/7;
  height: auto;
  width: 100%;
  @media only screen and (min-width: 1150px) {
    grid-column: 2/12;
  }
`;

export default UpcomingEvents;
