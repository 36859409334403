import debounce from 'just-debounce-it';

function asyncDebounce(func, { debounceInterval = 400, leading = false }) {
  const debounced = debounce(
    (resolve, reject, args) => {
      func(...args)
        .then(resolve)
        .catch(reject);
    },
    debounceInterval,
    leading
  );

  return (...args) =>
    new Promise((resolve, reject) => {
      debounced(resolve, reject, args);
    });
}

export default asyncDebounce;
