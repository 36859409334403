import React, { useState, useContext, useEffect } from 'react';
import { FirebaseContext, LocalContext } from 'context';
import styled from 'styled-components';
import { hexToRGB } from 'utils';
import { EmojiStar, EmojiClap, EmojiLike, EmojiLove, EmojiSmile } from 'assets/svgs';

function AnalyticsEmojiBar({ eventId }) {
  const { browserTabIsActive } = useContext(LocalContext);
  const { firebase } = useContext(FirebaseContext);
  const [emojis, setEmojis] = useState([]);
  const [emojiValues, setEmojiValues] = useState({
    smile: 0,
    thumbsUp: 0,
    heart: 0,
    clap: 0
  });
  useEffect(() => {
    let unsubscribeFromEventEmojis;
    if (firebase && browserTabIsActive) {
      unsubscribeFromEventEmojis = firebase.interaction.emojis.subscribeToEventEmojis({
        eventId,
        snapshot: (snapshot) => {
          if (snapshot.size > 0) {
            const emids = Object.keys(snapshot.val());
            setEmojis(emids.map((emid) => snapshot.val()[emid].emojiType));
          }
        }
      });
    }
    return () => {
      if (unsubscribeFromEventEmojis) {
        unsubscribeFromEventEmojis();
      }
    };
  }, [firebase, browserTabIsActive]);

  useEffect(() => {
    setEmojiValues({
      smile: emojis.filter((emoji) => emoji === 'smile').length,
      thumbsUp: emojis.filter((emoji) => emoji === 'like').length,
      heart: emojis.filter((emoji) => emoji === 'love').length,
      clap: emojis.filter((emoji) => emoji === 'clap').length
    });
  }, [emojis]);

  return (
    <Container>
      <Title>Emojis</Title>
      <Bar>
        <Analytic>
          <EmojiStar />
          <span>{emojis.length}</span>
          <p>Total number of&nbsp;emoji reactions</p>
        </Analytic>
        <Analytic>
          <EmojiSmile />
          <span>{emojiValues.smile}</span>
          <p>Total number&nbsp;of smiles</p>
        </Analytic>
        <Analytic>
          <EmojiLike />
          <span>{emojiValues.thumbsUp}</span>
          <p>Total number of&nbsp;thumbs-up</p>
        </Analytic>
        <Analytic>
          <EmojiLove />
          <span>{emojiValues.heart}</span>
          <p>Total number of&nbsp;hearts</p>
        </Analytic>
        <Analytic>
          <EmojiClap />
          <span>{emojiValues.clap}</span>
          <p>Total number of&nbsp;claps</p>
        </Analytic>
      </Bar>
    </Container>
  );
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.h5`
  color: #36d1b7;
  font-size: 0.825rem;
  font-weight: 400;
  line-height: 0.875rem;
  margin: 5.5em auto 1.667em;
  text-transform: uppercase;
`;

const Bar = styled.div`
  background: ${({ theme }) =>
    theme.className === 'contrast' ? '#000000' : hexToRGB({ color: '#0095FF', alpha: 0.1 })};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  padding: 0 1.25rem 4rem 1.25rem;
  row-gap: 1.75rem;
  @media only screen and (min-width: 900px) {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
    padding: 1.5rem 1.25rem;
  }
`;

const Analytic = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: start;
  svg {
    max-height: 48px;
    max-width: 48px;
  }
  span {
    font-size: 3rem;
    font-weight: 700;
    line-height: 3rem;
  }
  p {
    font-size: 1.25rem;
    line-height: 1.5rem;
    max-width: 200px;
    text-align: center;
  }
  &:first-of-type {
    grid-column: span 2;
  }
  @media only screen and (min-width: 900px) {
    &:first-of-type {
      grid-column: span 1;
    }
  }
`;

export default AnalyticsEmojiBar;
