import styled from 'styled-components';

export const Container = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
  justify-content: center;
  position: relative;
  width: 100%;
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.primary};
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.75rem;
  text-align: center;
  width: 100%;
`;

export const Card = styled.div`
  background-color: ${({ theme }) =>
    theme.className === 'contrast' ? theme.primary : theme.contrast};
  border-radius: 2px;
  box-shadow: ${({ theme }) =>
    theme.className === 'contrast'
      ? '0px 6px 20px rgba(255, 240, 0, 0.4)'
      : '0px 6px 20px rgba(196, 196, 196, 0.4)'};
  display: flex;
  flex-direction: column;
  font-weight: 300;
  gap: 1.75rem;
  max-width: 575px;
  padding: 3.875rem 1.75rem 2.75rem 1.75rem;
  position: relative;
  width: 100%;
`;

export const Label = styled.label`
  align-items: center;
  color: #004d9a;
  display: flex;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.125rem;
`;

export const Options = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: flex-start;

  @media only screen and (min-width: 800px) {
    flex-direction: row;
  }
`;
