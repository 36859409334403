import React, { useContext, useRef } from 'react';
import styled, { css } from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import { LocalContext } from 'context';
import { DefaultAvatar as DefaultSpeakerAvatar } from 'assets/dynamic-svgs';
import { defaultColors } from 'styles';
import { HiddenFileUpload } from '../../FormComponents';

function SpeakerAvatar({
  formValues,
  fileReader,
  errorMessage,
  setErrorMessage,
  speakerCurrentlyBeingEdited,
  hiddenFileUploadButtonRef
}) {
  const { theme, selectedEvent } = useContext(LocalContext);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      hiddenFileUploadButtonRef.current.click();
    }
  };

  const handleFileSelection = (e) => {
    if (e.target.files.length) {
      if (errorMessage) {
        setErrorMessage('');
      }
      fileReader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <>
      <AvatarWrapper $theme={theme} $colors={selectedEvent?.colors || defaultColors}>
        {formValues.staticAvatar?.childImageSharp ? (
          <StaticAvatar image={getImage(formValues.staticAvatar)} alt={formValues.name} />
        ) : formValues.localMemoryAvatar || formValues.avatar?.remoteUrl ? (
          <RemoteAvatar
            src={formValues.localMemoryAvatar || formValues.avatar?.remoteUrl}
            alt={formValues.name}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
        ) : (
          <DefaultSpeakerAvatar
            width="70"
            fill={
              theme.className === 'contrast'
                ? 'yellow'
                : selectedEvent?.colors.tertiary || defaultColors.primary
            }
          />
        )}
      </AvatarWrapper>
      <SetOrChangeSpeakerAvatarButton
        ref={hiddenFileUploadButtonRef}
        htmlFor="avatar"
        tabIndex={0}
        onKeyDown={handleKeyDown}
        whileTap={{ scale: 0.95 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        selectedEvent={selectedEvent}>
        {`${
          formValues.localMemoryAvatar || formValues.staticAvatar || formValues.avatar?.remoteUrl
            ? 'Change'
            : 'Add'
        } Speaker Image`}
        <HiddenFileUpload
          tabIndex={-1}
          type="file"
          name="avatar"
          id="avatar"
          selectedEvent={selectedEvent}
          required={!speakerCurrentlyBeingEdited}
          onChange={handleFileSelection}
        />
      </SetOrChangeSpeakerAvatarButton>
    </>
  );
}

const AvatarWrapper = styled.div`
  align-items: center;
  align-self: center;
  background: ${({ theme }) => (theme.className === 'contrast' ? '#000' : '#c4c4c4')};
  border: 0.2rem solid;
  border-color: ${({ $theme, $colors }) =>
    $theme.className === 'contrast' ? 'yellow' : $colors.tertiary};
  border-radius: 50%;
  display: flex;
  height: 10rem;
  justify-content: center;
  margin-bottom: 1.75rem;
  margin-top: -7.5rem;
  overflow: hidden;
  width: 10rem;
`;

const StaticAvatar = styled(GatsbyImage)``;

const RemoteAvatar = styled(motion.img)`
  background: transparent;
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

const SetOrChangeSpeakerAvatarButton = styled(motion.label)`
  align-items: center;
  align-self: center;
  border: 1px solid ${({ theme }) => (theme.className === 'contrast' ? theme.primary : '#fff')};
  border-radius: 3rem;
  box-sizing: border-box;
  color: ${({ theme }) => (theme.className === 'contrast' ? theme.primary : '#fff')};
  cursor: pointer;
  display: flex;
  height: 2rem;
  justify-content: center;
  margin-bottom: 2rem;
  outline: none;
  padding: 0 1.625rem;
  position: relative;
  text-transform: uppercase;
  transition:
    background-color 100ms ease-in-out,
    border-color 100ms ease-in-out,
    color 100ms ease-in-out;

  :hover,
  :focus:not(:active) {
    background-color: ${({ theme, selectedEvent }) =>
      theme.className === 'contrast'
        ? theme.primary
        : selectedEvent?.colors.tertiary || defaultColors.tertiary};
    color: ${({ theme }) => (theme.className === 'contrast' ? theme.contrast : '#fff')};
  }

  :hover {
    border-color: ${({ theme, selectedEvent }) =>
      theme.className === 'contrast'
        ? theme.primary
        : selectedEvent?.colors.tertiary || defaultColors.tertiary};
  }

  :focus:not(:active) {
    border: 2px solid ${({ theme }) => (theme.className === 'contrast' ? theme.primary : '#fff')};
  }
`;

export default SpeakerAvatar;
