import { httpsCallable } from 'firebase/functions';
import { collection, onSnapshot, query, where } from 'firebase/firestore';

function EditorFactory(firebase) {
  const createSpeaker = async (
    eventId,
    {
      bio,
      company,
      name,
      orders,
      presentationTitles,
      socials,
      speakerId,
      avatar,
      titleOrRole,
      eventsSpeakingAt
    }
  ) => {
    const createSpeakerCallable = httpsCallable(firebase.functions, 'editor-speakers-create');
    return createSpeakerCallable({
      eventId,
      speaker: {
        bio,
        company,
        name,
        orders,
        presentationTitles,
        socials,
        speakerId,
        avatar,
        titleOrRole,
        eventsSpeakingAt
      }
    });
  };

  const updateSpeaker = async (
    eventId,
    {
      bio,
      company,
      dateCreated,
      name,
      orders,
      presentationTitles,
      socials,
      speakerId,
      avatar,
      titleOrRole,
      eventsSpeakingAt
    }
  ) => {
    const updateSpeakerCallable = httpsCallable(firebase.functions, 'editor-speakers-update');
    return updateSpeakerCallable({
      eventId,
      speaker: {
        bio,
        company,
        dateCreated,
        name,
        orders,
        presentationTitles,
        socials,
        speakerId,
        avatar,
        titleOrRole,
        eventsSpeakingAt
      }
    });
  };

  const deleteSpeaker = async ({ eventId, speakerId }) => {
    const deleteSpeakerCallable = httpsCallable(firebase.functions, 'editor-speakers-delete');
    return deleteSpeakerCallable({
      eventId,
      speakerId
    });
  };

  const saveNewOrderOfSpeakers = async ({ eventId, reorderedSpeakers }) => {
    const saveNewOrderOfSpeakersCallable = httpsCallable(
      firebase.functions,
      'editor-speakers-saveNewOrder'
    );
    return saveNewOrderOfSpeakersCallable({
      eventId,
      reorderedSpeakers
    });
  };

  const subscribeToFirestoreSpeakers = ({ eventId, snapshot }) =>
    onSnapshot(
      query(
        collection(firebase.fsdb, 'speakers'),
        where('eventsSpeakingAt', 'array-contains', eventId)
      ),
      snapshot
    );

  return {
    createSpeaker,
    updateSpeaker,
    deleteSpeaker,
    saveNewOrderOfSpeakers,
    subscribeToFirestoreSpeakers
  };
}

export default EditorFactory;
