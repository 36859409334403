import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import BaseSelect from 'react-select';
import { LocalContext, FirebaseContext } from 'context';
import { CustomError, ErrorMessage, Button } from 'components';
import { Container, Title, Card, Label } from 'components/Admin/Shared';
import { format } from 'date-fns';
import FixRequiredSelect from 'components/Forms/FixRequiredSelect';
import { fadeInAndOutAndAnimateHeightVariants } from 'styles';

function FormSelect(props) {
  return <FixRequiredSelect {...props} SelectComponent={BaseSelect} options={props.options} />;
}

const emailTypes = [
  {
    label: '2 Days Before Reminder',
    value: 'reminder-email-2-days-before'
  },
  {
    label: '1 Day Before Reminder',
    value: 'reminder-email-1-day-before'
  },
  {
    label: 'Same Day Reminder',
    value: 'reminder-email-same-day'
  },
  {
    label: 'Attendance Certs',
    value: 'attendance-certs'
  },
  {
    label: 'Event Cancelled',
    value: 'event-cancelled-email'
  }
];

function Emails({ events, customDropdownMenuStyles }) {
  const { theme } = useContext(LocalContext);
  const { firebase } = useContext(FirebaseContext);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedEmailType, setSelectedEmailType] = useState(null);
  const [formIncomplete, setFormIncomplete] = useState(true);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [participants, setParticpants] = useState([]);
  const [error, setError] = useState({
    code: '',
    message: ''
  });

  const clearFields = () => {
    setSelectedEvent(null);
    setSelectedEmailType(null);
  };

  const populateEventParticipantsList = (result) => {
    if (!result.empty) {
      setParticpants((currrentValue) => [
        ...currrentValue,
        ...result.docs.map((doc) => {
          const { name: _name, email, uid } = doc.data();
          return {
            name: _name,
            email,
            uid
          };
        })
      ]);
    }
  };

  useEffect(() => {
    if (firebase && selectedEvent) {
      firebase.interaction.participants
        .fetchAllEventParticipants(selectedEvent.eventId)
        .then(populateEventParticipantsList);
    }
    return () => setParticpants([]);
  }, [firebase, selectedEvent]);

  const getFormattedDate = ({ startTime: _startTime, endTime: _endTime }) => {
    let formattedDate;

    const startDate = format(new Date(_startTime), 'do');
    const endDate = format(new Date(_endTime), 'do');
    const startMonth = format(new Date(_startTime), 'LLLL');
    const endMonth = format(new Date(_endTime), 'LLLL');

    if (startMonth === endMonth) {
      if (startDate === endDate) {
        // 1st scenario: If the startTime and endTime months are the same and the dates are the
        // same then we know it's a one day event, so we format it to 'January 31st, 2022', for example.
        formattedDate = `${format(new Date(_startTime), 'LLLL')} ${format(
          new Date(_endTime),
          'do, yyyy'
        )}`;
      } else if (startDate !== endDate) {
        // 2nd scenario: If the startTime and endTime months are the same but the dates are
        // different, e.g. January 30th and January 31st, so we format it to 'Janaury 30th - 31st, 2022'.
        formattedDate = `${format(new Date(_startTime), 'LLLL do')} - ${format(
          new Date(_endTime),
          'do, yyyy'
        )}`;
      }
    } else if (startMonth !== endMonth) {
      // 3rd scenario: If the months are different, e.g. January 31st and February 1st,
      // so we format it to 'January 31st - February 1st, 2022'.
      formattedDate = `${format(new Date(_startTime), 'LLLL do')} - ${format(
        new Date(_endTime),
        'LLLL do, yyyy'
      )}`;
    }

    return formattedDate;
  };

  const handleSendAttendanceCerts = async () => {
    const mockParticipants = [];
    const numberOfMockParticipants = 1;
    for (let i = 1; i <= numberOfMockParticipants; i++) {
      // Uncomment the below code to mock accounts for testing.
      // Replace with your own name, email address, and uid.
      mockParticipants.push({
        name: `Conor Doyle`,
        email: 'conor.d@agencyx.ie',
        uid: 'YeLrEoFcAHO9poDN1XpM2RQ8ppJ3'
      });
    }

    const { eventId, startTime, endTime, speakers, colors, streamDuration } = selectedEvent;

    const date = getFormattedDate({ startTime, endTime });

    try {
      setSendingEmail(true);
      if (participants.length <= 100) {
        // Have one cloud function call send all the certs to the participants
        const result = await firebase.emails.sendAttendanceCerts({
          participants: mockParticipants.length ? mockParticipants : participants,
          eventId,
          title: selectedEvent.name,
          speakers: speakers.length
            ? speakers.map(({ node: speaker }) => ({
                name: speaker.name,
                titleOrRole: speaker.titleOrRole,
                presentationTitle: speaker.presentationTitles[eventId],
                avatar: speaker.staticAvatar.publicURL
              }))
            : [],
          date,
          colors,
          streamDuration,
          origin: window.location.origin
        });

        // eslint-disable-next-line no-console
        console.log('Result:', result);
      } else if (participants.length > 100) {
        // Paginate cloud function calls
        const amountOf100ParticpantSegments = Math.floor(participants.length / 100);
        const remainingParticipants = participants.length % 100;

        const promises = [];

        const getPaginatedFunctionCalls = (paginatedParticipants) =>
          firebase.emails.sendAttendanceCerts({
            participants: paginatedParticipants,
            eventId,
            title: selectedEvent.name,
            speakers: speakers.map(({ node: speaker }) => ({
              name: speaker.name,
              titleOrRole: speaker.titleOrRole,
              presentationTitle: speaker.presentationTitles[eventId],
              avatar: speaker.staticAvatar.publicURL
            })),
            date,
            colors,
            streamDuration,
            origin: window.location.origin
          });

        for (let i = 0; i < amountOf100ParticpantSegments; i++) {
          promises.push(getPaginatedFunctionCalls(participants.slice(i * 100, (i + 1) * 100)));
        }

        promises.push(getPaginatedFunctionCalls(participants.slice(-remainingParticipants)));

        await Promise.all(promises);
      }
    } catch (_error) {
      console.error(_error.message);
    } finally {
      setSendingEmail(false);
    }
  };

  const handleSendEventReminderEmail = async () => {
    try {
      setSendingEmail(true);
      await firebase.emails.sendEventReminderEmail({
        event: selectedEvent,
        templateAlias: selectedEmailType.value
      });
    } catch (_error) {
      console.error(_error);
    } finally {
      setSendingEmail(false);
      clearFields();
    }
  };

  const handleSendEventCancellationEmail = async () => {
    try {
      setSendingEmail(true);
      await firebase.emails.sendEventCancellationEmail({ event: selectedEvent });
    } catch (_error) {
      console.error(_error);
    } finally {
      setSendingEmail(false);
      clearFields();
    }
  };

  useEffect(() => {
    if (selectedEvent && selectedEmailType) {
      setFormIncomplete(false);
    } else {
      setFormIncomplete(true);
    }
    if (error) {
      setError({
        code: '',
        message: ''
      });
    }
  }, [selectedEvent, selectedEmailType]);

  const handleSendEmail = () => {
    try {
      if (selectedEmailType.value.includes('reminder-email')) {
        handleSendEventReminderEmail();
      }

      if (selectedEmailType.value === 'attendance-certs') {
        if (process.env.NODE_ENV === 'development') {
          throw new CustomError(
            'permission-denied',
            'You must be on the live production site to send attendance certs.'
          );
        } else {
          handleSendAttendanceCerts();
        }
      }

      if (selectedEmailType.value === 'event-cancelled-email') {
        handleSendEventCancellationEmail();
      }
    } catch (_error) {
      console.error(_error);
      setError({
        code: _error.code,
        message: _error.message
      });
    }
  };

  return (
    <Container>
      <Title>Emails</Title>
      <Card>
        <div>
          <Label>Event</Label>
          <FormSelect
            value={events.filter(
              ({ value }) => value === `${selectedEvent?.eventId} - ${selectedEvent?.name}`
            )}
            onChange={setSelectedEvent}
            placeholder="Select"
            styles={customDropdownMenuStyles()}
            options={events}
          />
        </div>
        <div>
          <Label>Email Type</Label>
          <FormSelect
            value={selectedEmailType}
            onChange={setSelectedEmailType}
            placeholder="Select"
            styles={customDropdownMenuStyles()}
            options={emailTypes}
          />
        </div>
        <ErrorMessage
          errorMessage={error.message}
          style={{
            color: theme.className === 'contrast' ? theme.primary : '#F55151'
          }}
          variants={fadeInAndOutAndAnimateHeightVariants()}
        />
        <AnimatePresence>
          <Button
            disabled={formIncomplete}
            onClick={() => handleSendEmail()}
            loading={sendingEmail}
            loadingButton
            color="primary"
            style={{
              margin: '0.75rem auto 0'
            }}>
            Send {selectedEmailType?.value === 'event-cancelled-email' ? 'Event Cancellation ' : ''}
            Email
          </Button>
        </AnimatePresence>
      </Card>
    </Container>
  );
}

export default Emails;
