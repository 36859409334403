import React, { useContext, useState, useEffect } from 'react';
import { LocalContext } from 'context';
import { Button } from 'components';
import { Link, navigate } from 'gatsby';
import { AnimatePresence, motion } from 'framer-motion';
import { fadeInAndOutAndAnimateHeightVariants, defaultColors } from 'styles';
import { FormContainer, FormLabel, FormInput } from '../FormComponents';

const regex =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

function AuthInitiatedFromAnotherDeviceOrBrowser({
  action,
  authenticateUser,
  authError,
  setAuthError,
  setShowAuthInitiatedFromAnotherDeviceOrBrowserDialog
}) {
  const { theme, selectedEvent, handleDialog, authInitiatedFromAnotherDeviceOrBrowserDialogRef } =
    useContext(LocalContext);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);

  const [formValues, setFormValues] = useState({
    email: ''
  });

  useEffect(() => {
    setDisableSubmitButton(
      !regex.test(formValues?.email) || authError?.code === 'auth/invalid-email'
    );
  }, [authError, formValues?.email]);

  const handleInputChange = (e) => {
    if (authError?.code === 'auth/invalid-email') {
      setAuthError({
        code: null,
        message: null
      });
    }
    const { name, value } = e.target;
    setFormValues((currentState) => ({
      ...currentState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setShowLoadingSpinner(true);
      await authenticateUser({
        emailOfUserWhoInitiatedAuthFromAnotherDeviceOrBrowser: formValues.email
      });
    } catch (_error) {
      console.error(_error);
    } finally {
      setShowLoadingSpinner(false);
    }
  };

  return (
    <FormContainer
      onSubmit={handleSubmit}
      style={{ margin: '0 auto', alignItems: 'center', textAlign: 'center' }}>
      {action === 'registration' && (
        <h3 style={{ maxWidth: '26.875rem' }}>
          You filled out the registration form on another browser/device. Please re-enter your email
          address below to complete your registration:
        </h3>
      )}
      {action === 'login' && (
        <h3>
          You initiated login from another browser/device. Please re-enter your email address to
          continue:
        </h3>
      )}
      <FormLabel hide htmlFor="email">
        Email
      </FormLabel>
      <FormInput
        id="email"
        name="email"
        onChange={handleInputChange}
        placeholder="Your Email Address"
        type="email"
        value={formValues.email}
        required
        style={{
          margin: '2.125rem 0px 2.5rem'
        }}
      />
      <AnimatePresence>
        {authError.code === 'auth/invalid-email' && (
          <>
            <motion.p
              initial="initial"
              animate="animate"
              exit="exit"
              variants={fadeInAndOutAndAnimateHeightVariants()}
              style={{
                textAlign: 'center',
                marginBottom: '1.725rem',
                marginTop: '-0.875rem',
                maxWidth: '27.5rem'
              }}>
              Email address just entered does not match the email address you initiated{' '}
              {action === 'registration' ? 'registration' : 'login'} with. Please check you typed it
              correctly or try{' '}
              <Link
                to="/"
                onClick={() =>
                  handleDialog({
                    dialogRef: authInitiatedFromAnotherDeviceOrBrowserDialogRef,
                    animation: 'dismiss',
                    stateHandler: () => setShowAuthInitiatedFromAnotherDeviceOrBrowserDialog(false),
                    callback: () => {
                      navigate('/');
                    }
                  })
                }
                style={{
                  color: selectedEvent ? selectedEvent.colors.tertiary : defaultColors.tertiary,
                  textDecoration: 'underline'
                }}>
                {action === 'registration' ? 'registering' : 'logging in'}
              </Link>{' '}
              again.
              <br />
            </motion.p>
            <br />
          </>
        )}
      </AnimatePresence>
      <Button
        type="submit"
        disabled={disableSubmitButton}
        loading={showLoadingSpinner}
        loadingButton>
        {action === 'registration' ? 'Complete Registration' : 'Continue'}
      </Button>
    </FormContainer>
  );
}

export default AuthInitiatedFromAnotherDeviceOrBrowser;
