import styled from 'styled-components';

const FieldContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 48rem) {
    grid-template-columns: 1fr 1fr;
  }
`;

const FieldSection = styled.div`
  display: flex;
  flex-direction: column;

  input {
    height: 2.75rem;
    margin-bottom: 1.25rem;
  }

  @media screen and (min-width: 34.375rem) {
    padding: 0 1rem;
  }
`;

export { FieldContainer, FieldSection };
