import React, { useContext, useRef } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import { Button, LoadingSpinner } from 'components';
import { Markup } from 'interweave';
import { LocalContext, FirebaseContext } from 'context';
import { hexToRGB } from 'utils';
import { AnimatePresence, motion } from 'framer-motion';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  SolidCircle1,
  SolidCircle2,
  DashedCircle,
  EmailIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon
} from 'assets/svgs';
import { SpeakerReorderIcon } from 'assets/dynamic-svgs';

function SpeakerCard({
  isDragOverlay,
  speaker,
  speakers,
  eventId,
  handleEditSpeaker,
  handleDeleteSpeaker,
  speakerCurrentlyBeingDeleted,
  setOpenSpeakerCarouselAtThisIndex,
  setShowSpeakerCarouselDialog,
  heightBeforeCardCollapse,
  setHeightBeforeCardCollapse
}) {
  const { theme, selectedEvent } = useContext(LocalContext);
  const { user } = useContext(FirebaseContext);

  const heightWrapperRef = useRef(null);

  const isEditor = user?.isEditor?.includes(eventId);

  const {
    attributes,
    listeners,
    setNodeRef,
    isDragging,
    transform,
    transition,
    setActivatorNodeRef,
    active
  } = useSortable({
    id: speaker.speakerId
  });

  const isPlaceholder = active?.id === speaker.speakerId && !isDragOverlay;

  const speakerHasSocialLinksOrContactEmail =
    speaker.socials?.linkedIn ||
    speaker.socials?.twitter ||
    speaker.socials?.facebook ||
    speaker.socials?.instagram ||
    speaker.contactEmail;

  const handleHeightBeforeCardCollapse = () => {
    const styles = getComputedStyle(heightWrapperRef.current);
    setHeightBeforeCardCollapse(styles.height);
  };

  return (
    <Container
      ref={(node) => {
        // We need to set 2 refs on this component, the first is required for multi-axis reordering
        // to work, and the second is to save the height of the container before dragging. If we
        // don't then the container height can collapse if it has no social media links but a
        // container in the same row does have social media links.
        // https://stackoverflow.com/questions/63869088/can-i-assign-multiple-refs-to-the-same-element-node
        setNodeRef(node);
        heightWrapperRef.current = node;
      }}
      id={speaker.speakerId}
      initial={{ opacity: isDragOverlay ? 1 : 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      isEditor={isEditor}
      isDragging={isDragging}
      isDragOverlay={isDragOverlay}
      isPlaceholder={isPlaceholder}
      heightBeforeCardCollapse={heightBeforeCardCollapse}
      transform={CSS.Transform.toString(transform)}
      transition={transition}
      avatarIsResizing={speaker.avatar?.resizing}
      onClick={() => {
        setOpenSpeakerCarouselAtThisIndex(speakers.findIndex((_speaker) => _speaker === speaker));
        setShowSpeakerCarouselDialog(true);
      }}>
      <InnerContainer
        isDragOverlay={isDragOverlay}
        heightBeforeCardCollapse={heightBeforeCardCollapse}
        speakerHasSocialLinksOrContactEmail={speakerHasSocialLinksOrContactEmail}>
        <SpeakerAvatarContainer>
          <StyledSolidCircle1 />
          <StyledSolidCircle2 />
          <StyledDashedCircle />
          <AnimatePresence>
            {speaker.avatar?.resizing ? (
              <LoadingSpinner style={{ width: '45%', color: theme.primary }} />
            ) : speaker?.staticAvatar?.childImageSharp ? (
              <StaticAvatar
                image={getImage(speaker.staticAvatar)}
                alt={speaker.name}
                loading="eager"
              />
            ) : (
              <RemoteAvatar
                src={speaker?.avatar.remoteUrl}
                alt={speaker.name}
                custom={isDragOverlay}
              />
            )}
          </AnimatePresence>
        </SpeakerAvatarContainer>
        <SpeakerName>{speaker.name}</SpeakerName>
        <SpeakerTitleAndCompany>
          {speaker.company ? `${speaker.titleOrRole}, ${speaker.company}` : speaker.titleOrRole}
        </SpeakerTitleAndCompany>
        {speakerHasSocialLinksOrContactEmail && (
          <SocialLinksAndContactEmail onClick={(e) => e.stopPropagation()}>
            {speaker.socials.linkedIn && (
              <SocialLink href={speaker.socials.linkedIn.trim()} target="_blank">
                <LinkedinIcon />
              </SocialLink>
            )}
            {speaker.socials.twitter && (
              <SocialLink
                href={`https://twitter.com/${speaker.socials.twitter.replace('@', '').trim()}`}
                target="_blank">
                <TwitterIcon />
              </SocialLink>
            )}
            {speaker.socials.facebook && (
              <SocialLink href={speaker.socials.facebook.trim()} target="_blank">
                <FacebookIcon />
              </SocialLink>
            )}
            {speaker.socials.instagram && (
              <SocialLink
                href={`https://www.instagram.com/${speaker.socials.instagram
                  .replace('@', '')
                  .trim()}`}
                target="_blank">
                <InstagramIcon />
              </SocialLink>
            )}
            {speaker.socials.emailLink && (
              <ContactEmail href={speaker.contactEmail.trim()} target="_blank">
                <EmailIcon />
              </ContactEmail>
            )}
          </SocialLinksAndContactEmail>
        )}
        {speaker.presentationTitles[eventId] && (
          <PresentationTitle>
            <Markup content={speaker.presentationTitles[eventId]} noWrap />
          </PresentationTitle>
        )}
        <ReadOverview>Read Overview</ReadOverview>
        {isEditor && (
          <>
            <EditorButtons>
              <Button
                color="secondary"
                onClick={(e) => handleEditSpeaker({ e, speaker })}
                disabled={speakerCurrentlyBeingDeleted?.speakerId === speaker.speakerId}
                changeColorWhenDisabled={false}
                selectedEvent={selectedEvent}
                style={{
                  width: '5em'
                }}>
                Edit
              </Button>
              <Button
                color="warning"
                onClick={(e) => handleDeleteSpeaker({ e, speaker })}
                disabled={speakerCurrentlyBeingDeleted}
                changeColorWhenDisabled={false}
                loadingButton
                loading={speakerCurrentlyBeingDeleted?.speakerId === speaker.speakerId}
                loadingSpinnerSize="0.9rem"
                style={{
                  width: '6.667em'
                }}>
                Delete
              </Button>
            </EditorButtons>
            <AnimatePresence>
              {speakers.length > 1 && (
                <SpeakerReorderIcon
                  {...listeners}
                  {...attributes}
                  theme={theme}
                  ref={setActivatorNodeRef}
                  onMouseEnter={handleHeightBeforeCardCollapse}
                />
              )}
            </AnimatePresence>
          </>
        )}
      </InnerContainer>
    </Container>
  );
}

const StyledSolidCircle1 = styled(SolidCircle1)`
  right: -0.75rem;
  top: -1.1rem;
  width: 15rem;

  path {
    fill: ${({ theme }) => theme.secondary};
  }
`;

const StyledSolidCircle2 = styled(SolidCircle2)`
  bottom: -1.25rem;
  left: -0.35rem;
  width: 15rem;

  path {
    fill: ${({ theme }) => theme.primary};
  }
`;

const StyledDashedCircle = styled(DashedCircle)`
  left: 0;
  top: -0.5rem;
  width: 12rem;

  path {
    fill: ${({ theme }) => theme.primary};
  }
`;

const SpeakerAvatarContainer = styled.div`
  align-items: center;
  display: flex;
  height: 16.5rem;
  justify-content: center;
  margin-bottom: 1.325rem;
  position: relative;
  width: 16.5rem;

  > svg:not(.loading-spinner) {
    opacity: 0;
    position: absolute;
    transition: all 0.2s ease-in-out;
  }
`;

const StaticAvatar = styled(GatsbyImage)`
  border-radius: 50%;
  width: 100%;
`;

const RemoteAvatar = styled(motion.img)`
  border-radius: 50%;
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

const SpeakerName = styled.h3`
  font-size: 1.5rem;
  line-height: 1.333em;
`;

const SpeakerTitleAndCompany = styled.p`
  font-size: 1rem;
  line-height: 1.5em;
  margin-bottom: 0.55em;
  text-align: center;
`;

const PresentationTitle = styled.p`
  font-size: 1.25rem;
  line-height: 1.4em;
  margin-top: 0.275em;
  text-align: center;
`;

const SocialLinksAndContactEmail = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

const ReadOverview = styled.h4`
  bottom: 2.938em;
  font-size: 1rem;
  line-height: 1.2em;
  margin-top: 1.55em;
  position: absolute;
  text-transform: uppercase;
`;

const EditorButtons = styled.div`
  bottom: -0.75rem;
  display: flex;
  justify-content: space-between;
  opacity: 0;
  position: absolute;
  transition: opacity 0.2s ease-in-out;
  width: 9.375rem;

  button {
    font-size: 0.75rem;
    height: 2em;
    min-width: unset;
    padding: 0;
  }
`;

const Container = styled(motion.li)`
  background-color: ${({ isDragOverlay, theme }) =>
    isDragOverlay && theme.className === 'contrast'
      ? hexToRGB({ color: theme.primary, alpha: 0.2 })
      : isDragOverlay
        ? 'rgba(255, 255, 255, 0.7)'
        : 'transparent'};
  cursor: ${({ avatarIsResizing }) => (avatarIsResizing ? 'default' : 'pointer')};
  flex: 1;
  font-family: Helvetica, sans-serif;
  height: ${({ isDragOverlay, heightBeforeCardCollapse }) =>
    isDragOverlay ? heightBeforeCardCollapse : 'initial'};
  margin-bottom: 1.5rem;
  max-width: 18.75rem;
  outline: ${({ isDragOverlay, theme }) =>
    isDragOverlay ? `0.063rem solid ${theme.secondary}` : ''};
  pointer-events: ${({ isDragOverlay, avatarIsResizing }) =>
    isDragOverlay || avatarIsResizing ? 'none' : 'auto'};
  position: relative;
  transform: ${({ transform }) => transform};
  transition: ${({ isPlaceholder, transition }) =>
    isPlaceholder
      ? ''
      : transition
        ? `background-color 0.2s ease-in-out, outline 0.2s ease-in-out, ${transition}`
        : 'background-color 0.2s ease-in-out, outline 0.2s ease-in-out'};
  z-index: ${({ isDragging }) => (isDragging ? 1 : 0)};

  ${({ isPlaceholder, theme }) =>
    isPlaceholder
      ? css`
          opacity: 0.5 !important;
          outline: 0.063rem dashed ${theme.secondary};
          transition: none !important;

          ${EditorButtons} {
            opacity: 0 !important;
            transition: opacity 0.1s ease-in-out !important;
          }

          ${StaticAvatar},
          ${RemoteAvatar},
          ${SpeakerName},
          ${SpeakerTitleAndCompany},
          ${PresentationTitle},
          ${SocialLinksAndContactEmail},
          ${ReadOverview} {
            opacity: 0 !important;
          }
        `
      : css`
          ${SpeakerName},
          ${SpeakerTitleAndCompany},
          ${PresentationTitle},
          ${ReadOverview} {
            color: ${theme.primary};
            transition: color 0.2s ease-in-out;
          }

          ${ReadOverview} {
            color: ${({ isDragOverlay }) => (isDragOverlay ? theme.tertiary : theme.primary)};
          }

          /* > svg:last-of-type is <SpeakerReorderIcon /> */
          > div > svg:last-of-type {
            opacity: ${({ isDragOverlay }) => (isDragOverlay ? 1 : 0)};
            touch-action: none; /* https://docs.dndkit.com/api-documentation/draggable#recommendations */
          }

          ${StaticAvatar}, ${RemoteAvatar} {
            box-shadow: ${({ isDragOverlay }) =>
              isDragOverlay ? '0 0.375rem 0.75rem rgba(0, 0, 0, 0.25)' : 'none'};
            transition: box-shadow 0.2s ease-in-out;
          }

          &:hover {
            ${StaticAvatar}, ${RemoteAvatar} {
              box-shadow: 0 0.375rem 0.75rem rgba(0, 0, 0, 0.25);
            }

            ${ReadOverview} {
              color: ${theme.tertiary};
            }

            ${({ isEditor, avatarIsResizing }) => {
              if (isEditor) {
                if (!avatarIsResizing) {
                  return css`
                    background-color: ${hexToRGB({ color: theme.secondary, alpha: 0.1 })};
                    outline: ${`0.063rem solid ${theme.secondary}`};

                    /* > svg:last-of-type is <SpeakerReorderIcon /> */
                    ${EditorButtons}, > div > svg:last-of-type {
                      opacity: 1;
                    }
                  `;
                }
              }
              return css`
                ${SpeakerAvatarContainer} {
                  svg {
                    opacity: 1;
                  }

                  ${StyledSolidCircle1} {
                    right: -2.45rem;
                  }

                  ${StyledSolidCircle2} {
                    bottom: -1.25rem;
                    left: -2.45rem;
                  }

                  ${StyledDashedCircle} {
                    left: -0.5rem;
                    top: -2.5rem;
                  }
                }
              `;
            }}
          }
        `}
`;

const InnerContainer = styled.div`
  align-items: center;
  background-color: ${({ isDragOverlay, theme }) =>
    isDragOverlay ? hexToRGB({ color: theme.secondary, alpha: 0.1 }) : 'transparent'};
  display: flex;
  flex-direction: column;
  height: ${({ isDragOverlay, heightBeforeCardCollapse }) =>
    isDragOverlay ? heightBeforeCardCollapse : 'initial'};
  padding: 1rem 1rem 5.5rem;
  transition: background-color 0.2s ease-in-out;
  width: 100%;

  > * {
    user-select: none;
  }
`;

const SocialLink = styled.a`
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin: 0 0.375rem;

  svg {
    border-radius: 50%;
    height: 1.5rem;
    transition: box-shadow 0.2s ease-in-out;
    width: 1.5rem;

    circle {
      fill: ${({ theme }) => (theme.className === 'contrast' ? theme.primary : '#C4C4C4')};
      transition: fill 0.2s ease-in-out;
    }

    path {
      fill: ${({ theme }) => (theme.className === 'contrast' ? '#000' : '#fff')};
      transition: 0.5s;
    }
  }

  &:hover svg {
    box-shadow: 0 0.188rem 0.5rem rgba(0, 0, 0, 0.25);

    circle {
      fill: ${({ theme }) => theme.secondary};
    }
  }

  &:active svg {
    transform: scale(0.9);
  }
`;

const ContactEmail = styled(SocialLink)``;

export default SpeakerCard;
