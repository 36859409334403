import React, { useContext } from 'react';
import styled from 'styled-components';
import { hexToRGB } from 'utils';
import { LocalContext } from 'context';
import { Button } from 'components';

function ConfirmDelete({ handleDelete, handleDismiss, type }) {
  const { selectedEvent } = useContext(LocalContext);

  return (
    <Container colors={selectedEvent.colors}>
      <Question colors={selectedEvent.colors}>
        <p>Are you sure you want to delete this {type}?</p>
      </Question>
      <h4>The {type} will be permanently deleted and you wont be able to recover it!</h4>
      <Button color="secondary" onClick={handleDelete} style={{ height: '2.5rem' }}>
        Delete
      </Button>
      <Button variant="text" size="small" onClick={handleDismiss}>
        Cancel
      </Button>
    </Container>
  );
}

const Container = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 23.75rem;
  position: relative;
  h4 {
    color: #4f4f4f;
    font-size: 1rem;
    font-weight: 400;
    padding: 1.75rem 1.5rem 1.25rem 1.5rem;
    text-align: center;
  }
  button {
    &:nth-of-type(1) {
      border-radius: 2px;
      margin-top: 0.25rem;
    }
    &:nth-of-type(2) {
      background: transparent;
      color: #999;
      font-size: 0.875rem;
      font-weight: 300;
      margin: 0.5em auto 0.625em;
      text-decoration: underline;
      text-transform: capitalize;
    }
  }
`;

const Question = styled.div`
  background-color: ${({ colors }) => hexToRGB({ color: colors.secondary, alpha: 0.2 })};
  color: ${({ colors }) => colors.secondary};
  padding: 0.75rem 1.5rem;
  text-align: center;
  width: 100%;
  p:nth-of-type(1) {
    font-weight: 700;
  }

  p:nth-of-type(2) {
    font-weight: 300;
  }
`;

export default ConfirmDelete;
