import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { LocalContext, FirebaseContext } from 'context';
import { AnimatePresence, motion } from 'framer-motion';
import { RobotHead } from 'assets/svgs';
import { Shine } from 'components';
import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';
import { SplitText } from 'gsap/SplitText';
import { Markup } from 'interweave';
import { fadeInAndOutVariants } from 'styles';

function SummariseVideoViaAI({ streamUrl }) {
  const { theme } = useContext(LocalContext);
  const { firebase } = useContext(FirebaseContext);
  const [summarisingVideo, setSummarisingVideo] = useState(false);
  const [summarisedText, setSummarisedText] = useState(false);
  const [error, setError] = useState({
    code: '',
    message: ''
  });

  useGSAP(
    () => {
      gsap.registerPlugin(SplitText);

      if (summarisedText) {
        const split = new SplitText('#summarisedText', {
          type: 'chars,words,lines',
          charsClass: 'char++',
          linesClass: 'line++',
          wordsClass: 'word++',
          position: 'absolute'
        });
        gsap.from(split.chars, {
          duration: 0.1,
          autoAlpha: 0,
          delay: 0.625,
          stagger: 0.0175
        });
      }
    },
    { dependencies: [summarisedText] }
  );

  const handleSummariseVideo = async () => {
    const videoId = streamUrl?.match(/video\/(\d+)/)[1];
    if (videoId && Number(videoId)) {
      try {
        if (error.message) {
          setError({
            code: '',
            message: ''
          });
        }
        if (summarisedText) {
          setSummarisedText('');
        }
        setSummarisingVideo(true);
        const response = await firebase.livestream.summariseVideoViaAI({ videoId });
        if (response?.data) {
          setSummarisedText(response.data);
        }
      } catch (_error) {
        console.error(_error);
        setError({
          code: _error.code,
          message: _error.message
        });
      } finally {
        setSummarisingVideo(false);
      }
    }
  };

  return (
    <Container>
      <AnimatePresence>
        {error.code === 'functions/not-found' ? (
          <NotAvailable>No summary available for this video</NotAvailable>
        ) : (
          <>
            <CustomButton
              onClick={handleSummariseVideo}
              custom={{ summarisedText, summarisingVideo }}
              disabled={summarisingVideo || summarisedText}
              theme={theme}
              tabIndex={0}
              transition={{
                ease: 'linear',
                duration: 0.3,
                outline: {
                  duration: 0
                }
              }}>
              <RobotHeadWrapper custom={summarisedText}>
                <RobotHead />
              </RobotHeadWrapper>
              <AnimatePresence exitBeforeEnter>
                {!summarisedText && summarisingVideo && (
                  <LoadingDots>
                    <LoadingDot delay={125} />
                    <LoadingDot delay={225} />
                    <LoadingDot delay={325} />
                  </LoadingDots>
                )}
                {!summarisedText && !summarisingVideo && (
                  <ButtonText>
                    <span>Summarise</span>&nbsp;via AI
                  </ButtonText>
                )}
              </AnimatePresence>
              <AnimatePresence>
                {!summarisedText && !summarisingVideo && (
                  <motion.span variants={fadeInAndOutVariants()}>
                    <Shine shineDuration={1.325} />
                  </motion.span>
                )}
              </AnimatePresence>
            </CustomButton>
            <AnimatePresence>
              {summarisedText && (
                <SummarisedTextBox
                  custom={summarisedText}
                  theme={theme}
                  summarisedText={summarisedText}>
                  <h2>AI Generated Summary</h2>
                  <div id="summarisedText">
                    <Markup content={summarisedText.split('\n\n').join('<br /><br />')} noWrap />
                  </div>
                  <br />
                  <p>
                    <small>AI can make mistakes. Check important info.</small>
                  </p>
                </SummarisedTextBox>
              )}
            </AnimatePresence>
          </>
        )}
      </AnimatePresence>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;

const NotAvailable = styled.p`
  color: ${({ theme }) => (theme.className === 'contrast' ? 'yellow' : theme.primary)};
  font-weight: 700;
  text-transform: capitalize;
`;

const SummarisedTextBox = styled(motion.div).attrs({
  initial: {
    clipPath: 'polygon(0% 0, 0% 0%, 0% 0%, 0 0%, 0 0)'
  },
  animate: (summarisedText) => ({
    clipPath: summarisedText && 'polygon(100% 0, 100% 100%, 100% 100%, 0 100%, 0 0)'
  }),
  transition: {
    duration: 1,
    ease: 'easeInOut'
  }
})`
  animation: clipPath 0.8s ease-in-out forwards;
  background-color: rgb(255, 255, 255);
  border: 1px solid ${({ theme }) => (theme.className === 'contrast' ? 'yellow' : theme.tertiary)};
  color: ${({ theme }) => theme.primary};
  font-family: inherit;
  font-size: 1rem;
  height: 100%;
  left: 1.5em;
  line-height: 1.25em;
  padding: 40px 26px 20px;
  position: relative;
  top: -1.5em;
  font-kerning: none;
  -webkit-text-rendering: optimizeSpeed;
  text-rendering: optimizeSpeed;
  transform: translateZ(0);

  h2 {
    color: ${({ theme }) => (theme.className === 'contrast' ? 'yellow' : theme.fg)};
    margin-bottom: 1em;
  }
`;

const CustomButton = styled(motion.button).attrs({
  initial: false,
  animate: ({ summarisedText, summarisingVideo }) => ({
    justifyContent: 'flex-start',
    padding: summarisedText ? 0 : '0 1.625rem 0 1.5rem',
    width:
      !summarisedText && summarisingVideo ? 126 : !summarisedText && !summarisingVideo ? 233 : 48
  }),
  whileTap: ({ summarisedText, summarisingVideo }) =>
    !summarisedText &&
    !summarisingVideo && {
      outline: '0.0625rem solid #fff'
    }
})`
  align-items: center;
  background-color: ${({ theme, colors }) =>
    theme.className === 'contrast' ? 'yellow' : colors ? colors.primary : theme.tertiary};
  border-radius: 3em;
  color: #fff;
  cursor: ${({ summarisedText }) => (summarisedText ? 'default' : 'pointer')};
  display: flex;
  font-family: Helvetica, sans-serif;
  height: 3em;
  font-size: 1rem;
  font-weight: 600;
  overflow: hidden;
  position: relative;
  text-transform: capitalize;
  z-index: 1;

  :disabled {
    cursor: default;
  }
`;

const RobotHeadWrapper = styled(motion.div).attrs({
  initial: false,
  animate: (summarisedText) => ({
    x: summarisedText ? 12 : 0
  }),
  transition: {
    duration: 0.3,
    ease: 'linear'
  }
})`
  flex-shrink: 0;
  width: 1.5rem;
`;

const LoadingDots = styled(motion.div).attrs({
  initial: {
    opacity: 0
  },
  animate: {
    opacity: 1
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.1
    }
  }
})`
  display: grid;
  grid-gap: 0.325rem;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  margin-left: 0.825rem;
`;

const LoadingDot = styled(motion.div)`
  animation: animateDots 1s ${({ delay }) => delay}ms ease-in-out infinite;
  background-color: #fff;
  border-radius: 50%;
  height: 0.625rem;
  opacity: 0.7;
  width: 0.625rem;

  @keyframes animateDots {
    0% {
      transform: translateY(0);
    }

    28% {
      opacity: 0.4;
      transform: translateY(-0.5625rem);
    }

    44% {
      transform: translateY(0);
    }
  }
`;

const ButtonText = styled(motion.p).attrs({
  initial: {
    opacity: 0
  },
  animate: {
    opacity: 1
  },
  exit: {
    opacity: 0
  }
})`
  margin-left: 0.825rem;
  white-space: nowrap;

  span {
    text-transform: uppercase;
  }
`;

export default SummariseVideoViaAI;
