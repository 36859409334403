import React, { useMemo } from 'react';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { useLoader } from '@react-three/fiber';

function Stand() {
  const { nodes, scene } = useLoader(GLTFLoader, '/sceneDraco.gltf', (loader) => {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderConfig({ type: 'js' });
    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
    loader.setDRACOLoader(dracoLoader);
  });
  useMemo(
    () =>
      Object.values(nodes).forEach(
        (obj) => obj.isMesh && Object.assign(obj, { castShadow: true, receiveShadow: true })
      ),
    [nodes]
  );
  return <primitive object={scene} dispose={null} position={[0, -20, 0]} scale={15} />;
}

export default Stand;
