import * as React from 'react';

function SvgMedic(props) {
  return (
    <svg viewBox="0 0 131 131" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M65.5 131c36.174 0 65.5-29.326 65.5-65.5C131 29.325 101.674 0 65.5 0 29.325 0 0 29.325 0 65.5 0 101.674 29.325 131 65.5 131Z"
        fill="#36D1B7"
      />
      <path
        d="M65.5 97.006c-.332 0-.663 0-.83-.166-26.863-8.125-27.028-41.953-26.365-52.068.166-1.658 1.492-2.985 3.316-2.985H43.445c10.281 0 17.578-4.975 20.397-7.13.497-.498 1.16-.664 1.99-.664.663 0 1.326.166 1.99.664 2.653 2.155 10.114 7.13 20.23 7.13h1.99c1.658 0 3.15 1.327 3.316 2.985.663 10.115.498 43.943-26.366 52.068-.829.166-1.16.166-1.492.166ZM41.456 44.44c-.166 0-.332 0-.498.166-.166.166-.166.332-.166.497-.497 12.437.83 41.954 24.708 49.25h.331c24.874-7.462 24.874-39.632 24.376-49.25 0-.331-.331-.663-.663-.663h-1.658c-10.944 0-18.904-5.306-21.889-7.628-.166-.165-.331-.165-.331-.165-.166 0-.332 0-.332.165-2.985 2.322-10.944 7.628-21.889 7.628h-1.99Z"
        fill="#fff"
      />
      <path
        d="M61.52 80.258c-.663 0-1.327-.663-1.327-1.327v-7.96c0-.33-.331-.662-.663-.662h-7.96c-.663 0-1.326-.664-1.326-1.327v-7.96c0-.663.663-1.326 1.327-1.326h7.96c.33 0 .663-.332.663-.663v-7.96c0-.663.663-1.326 1.326-1.326h8.125c.664 0 1.327.663 1.327 1.326v7.96c0 .331.332.663.663.663h7.96c.663 0 1.326.663 1.326 1.327v7.959c0 .663-.663 1.327-1.326 1.327h-7.96c-.331 0-.663.331-.663.663v7.96c0 .663-.663 1.326-1.327 1.326H61.52Zm-7.96-18.075c-.331 0-.663.332-.663.664v3.98c0 .331.332.663.664.663h7.96c.662 0 1.326.663 1.326 1.326v8.126c0 .331.331.663.663.663h3.98c.331 0 .663-.332.663-.663v-7.96c0-.663.663-1.327 1.327-1.327h7.96c.33 0 .662-.331.662-.663v-3.98c0-.331-.331-.663-.663-.663h-7.794c-.663 0-1.326-.663-1.326-1.326v-7.96c0-.332-.332-.663-.663-.663h-3.98c-.332 0-.663.331-.663.663v7.96c0 .663-.664 1.326-1.327 1.326H53.56v-.166Z"
        fill="#fff"
      />
    </svg>
  );
}
export default SvgMedic;
