import React, { useState } from 'react';
import { motion } from 'framer-motion';

function AnimatedEyeIcon(props) {
  const [wink, setWink] = useState(false);

  return (
    <motion.svg
      onClick={() => setWink(true)}
      onAnimationEnd={() => setWink(false)}
      initial={{ scaleY: 1 }}
      animate={wink === true ? { scaleY: [1, 0, 1] } : { scaleY: 1 }}
      whileTap={{ scaleY: [1, 0, 1] }}
      transition={{ duration: 0.5 }}
      viewBox="0 0 30 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <motion.path
        d="M28 9s-5.822 7-13 7S2 9 2 9s5.822-7 13-7 13 7 13 7z"
        stroke="#093248"
        strokeWidth={3}
        strokeMiterlimit={10}
      />
      <path
        d="M15.054 14a4.968 4.968 0 004.973-5A5.033 5.033 0 0015 4a4.968 4.968 0 00-4.973 5 5.033 5.033 0 005.027 5z"
        fill="#093248"
        stroke="#093248"
        strokeWidth={3}
        strokeMiterlimit={10}
      />
    </motion.svg>
  );
}

export default AnimatedEyeIcon;
