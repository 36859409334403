import * as React from 'react';

function SvgDashedCircle(props) {
  return (
    <svg viewBox="0 0 205 209" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="m24.934 116.187 28.032 48.553c2.541 1.994 5.119 3.853 7.82 5.524L24.06 106.652c.097 3.175.417 6.337.874 9.535ZM24.56 95.285l46.421 80.402a93.986 93.986 0 0 0 5.922 2.235l-51.367-88.97c-.416 2.087-.696 4.21-.975 6.333ZM27.735 80.528l57.613 99.789a65.64 65.64 0 0 0 5.173.937L29.51 75.58c-.58 1.603-1.246 3.258-1.775 4.948ZM32.609 68.713l65.408 113.29c1.528.041 3.107.168 4.672.072L34.833 64.545c-.73 1.343-1.545 2.737-2.224 4.168ZM38.581 58.803 109.536 181.7c1.428-.133 2.857-.265 4.323-.535L41.205 55.326c-.829 1.171-1.708 2.256-2.624 3.477ZM45.54 50.4l74.702 129.388c1.329-.306 2.695-.748 4.024-1.054L48.514 47.529c-1.066.961-2.045 1.873-2.974 2.87ZM53.162 43.346l77.001 133.369c1.228-.479 2.544-1.008 3.723-1.573l-77.5-134.234c-1.116.875-2.195 1.613-3.224 2.438ZM63.22 36.5c-.608.35-1.129.652-1.687 1.09l77.9 134.926c.608-.351 1.179-.565 1.787-.916.608-.351 1.129-.652 1.687-1.089l-77.9-134.927c-.658.264-1.266.615-1.787.916ZM70.454 32.785l77.55 134.321c1.079-.738 2.195-1.613 3.224-2.438L74.178 31.212c-1.23.48-2.495 1.095-3.724 1.573ZM80.124 29.28l75.801 131.291a59.637 59.637 0 0 0 2.924-2.958L84.097 28.139c-1.328.306-2.694.748-3.973 1.14ZM90.444 26.898l72.604 125.753c.965-1.135 1.795-2.306 2.624-3.477L94.767 26.364c-1.429.133-2.807.352-4.323.534ZM101.613 25.988l67.807 117.445c.816-1.394 1.545-2.739 2.274-4.083L106.336 26.147a54.059 54.059 0 0 0-4.723-.159ZM113.832 26.896l60.961 105.588a65.74 65.74 0 0 0 1.775-4.948l-57.563-99.703a65.444 65.444 0 0 0-5.173-.937ZM127.4 30.141l51.317 88.884a95.39 95.39 0 0 0 1.025-6.246l-46.37-80.316c-1.892-.87-3.957-1.64-5.972-2.322ZM143.553 37.663l36.776 63.698c-.096-3.175-.416-6.337-.873-9.535l-28.082-48.64c-2.541-1.994-5.119-3.852-7.821-5.523Z"
        fill="#36A9E1"
      />
    </svg>
  );
}
export default SvgDashedCircle;
