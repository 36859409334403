import React, { useContext } from 'react';
import styled from 'styled-components';
import { Button, BulletPoint, HubHeaderBackground } from 'components';
import { LocalContext, FirebaseContext } from 'context';
import { Logo, LogoContrast } from 'assets/svgs';
import { scrollToAnchor } from 'utils';

function HubHeader() {
  const { isMobile, theme, setShowLoginOrSignUpDialog } = useContext(LocalContext);
  const { firebase } = useContext(FirebaseContext);
  const currentUser = firebase?.auth?.currentUser;

  const slide = {
    date: '21 - 23 March',
    title: 'Virtual Conference',
    captionTitle: 'Welcome to the Panacea Expo'
  };

  return (
    <Container>
      <HubHeaderBackground />
      <TextBox>
        <SlideDate>{slide.date}</SlideDate>
        <SlideTitle>{slide.title}</SlideTitle>
        <LogoContainer>
          {theme.className === 'contrast' ? <LogoContrast /> : <Logo />}
        </LogoContainer>
        <>
          <SlideCaptionSubtitle>
            {currentUser && (
              <BulletPoint
                top="-2rem"
                left="0"
                content={`
                    <p>
                    Here we create a custom designed welcome page for your online event. With key imagery and call to action (CTA) buttons such as Register here, Save the Date and essential information.
                    </p>
                  `}
              />
            )}
            A Panacea for your online event marketing?
          </SlideCaptionSubtitle>
          <SlideCaptionText>
            Welcome to our demo site to help you choose the right partner for your key client
            engagement event. Begin with your custom designed event for desktop and mobile access
            and browse key features from simple registration through speaker bios, sponsor support
            packages and content galleries. Sign up to request a demo today.
          </SlideCaptionText>
        </>
        {!currentUser && (
          <HeaderButtons>
            <Button
              shine
              onClick={() =>
                isMobile
                  ? setShowLoginOrSignUpDialog(true)
                  : scrollToAnchor('login-or-signup', { offset: -90, duration: 1100 })
              }>
              Log In / Sign Up
            </Button>
          </HeaderButtons>
        )}
      </TextBox>
    </Container>
  );
}

const LogoContainer = styled.div`
  height: auto;
  margin-bottom: 3rem;
  max-width: 80%;
  width: 750px;
  @media only screen and (min-width: 48rem) {
    margin: 4rem 0;
    max-width: 100%;
  }
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  position: relative;
  width: 100%;
  z-index: 3;
  p {
    color: ${({ theme }) => theme.secondary};
    font-weight: normal;
  }
  @media only screen and (min-width: 48rem) {
    grid-column: 2/6;
    padding: 4rem 0;
  }
  @media only screen and (min-width: 1150px) {
    grid-column: 2/9;
  }
  @media only screen and (min-width: 1441px) {
    grid-column: 3/8;
  }
`;

const SlideDate = styled.h2`
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.4em;
  margin-bottom: 0;
  margin-top: 0;
  :first-child {
    color: ${({ theme }) => theme.tertiary};
  }
  @media only screen and (min-width: 1150px) {
    font-size: 2.75rem;
    margin-bottom: 0;
  }
`;

const SlideTitle = styled(SlideDate)`
  margin-bottom: 3rem;
`;

const SlideCaptionSubtitle = styled.h3`
  color: inherit;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5em;
  margin-bottom: 1.25rem;
  margin-top: 0px;
  position: relative;
  @media only screen and (min-width: 1150px) {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
`;

const SlideCaptionText = styled.p`
  color: #007fc0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5em;
  margin-bottom: 1.5em;
  margin-top: 0;
  @media only screen and (min-width: 1150px) {
    font-size: 1.25rem;
    margin-bottom: 1em;
  }
`;

const HeaderButtons = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5rem;
`;

const Container = styled.header`
  align-items: center;
  background: ${({ theme }) => theme.contrast};
  color: ${({ theme }) => theme.primary};
  display: grid;
  grid-column: 1/7;
  grid-template-columns: repeat(6, 1fr);
  height: auto;
  justify-content: center;
  min-height: 100vh;
  overflow: visible;
  padding: 0.625rem;
  padding-top: 5rem;
  position: relative;
  width: 100%;

  .gatsby-image-wrapper {
    display: none;
  }

  @media only screen and (min-width: 48rem) and (min-height: 1000px) and (max-width: 1150px) {
    min-height: 70vh;
    padding: 4rem 0;
  }

  @media only screen and (min-width: 1150px) {
    grid-column: 1/13;
    grid-template-columns: repeat(12, 1fr);
    height: auto;
    /* margin-bottom: 3rem; */
    .gatsby-image-wrapper {
      bottom: 0;
      display: block;
      height: 90%;
      min-width: 600px;
      object-fit: contain !important;
      position: absolute !important;
      right: 0;
    }
  }
  svg {
    &.circle {
      bottom: -10px;
      left: 0;
      max-width: 200px;
      position: absolute;
      z-index: 2;
    }
  }
`;

export default HubHeader;
