import React, { useContext, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { LocalContext, FirebaseContext } from 'context';
import Autocomplete from 'react-google-autocomplete';
import BaseSelect from 'react-select';
import { motion, AnimatePresence } from 'framer-motion';
import { Button, ErrorMessage, LoadingSpinner, DialogCloseIcon } from 'components';
import {
  capitaliseFirstLetterOfText,
  checkIfNameIncludesOnlyStandardChars,
  includesSurname,
  constructQueryString
} from 'utils';
import {
  fadeInAndOutVariants,
  fadeInAndOutAndAnimateHeightVariants,
  customDropdownMenuStyles
} from 'styles';
import {
  FormContainer,
  FormInput,
  FormTerms,
  FormLabel,
  FormChecks,
  FormCheckbox
} from '../../FormComponents';
import FixRequiredSelect from '../../FixRequiredSelect';

function FormSelect(props) {
  return <FixRequiredSelect {...props} SelectComponent={BaseSelect} options={props.options} />;
}

function SignUpForm({
  formValues,
  setFormValues,
  errorMessage,
  setErrorMessage,
  hideCloseIcon,
  handleInputChange,
  resetForm,
  colors,
  isDialog
}) {
  const {
    theme,
    siteName,
    handleDialog,
    loginOrSignUpDialogRef,
    setShowLoginOrSignUpDialog,
    professionOptions,
    selectedEvent,
    registrationFormSubmissionStatus,
    setRegistrationFormSubmissionStatus,
    showSignUpForm
  } = useContext(LocalContext);
  const { firebase } = useContext(FirebaseContext);
  const [nameCheckError, setNameCheckError] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  let isMounted = true;
  let debounce;
  const debounceTimeout = 1200;

  const fieldsRef = useRef(null);
  const nameInputRef = useRef();

  useEffect(() => {
    isMounted = false;
  }, []);

  useEffect(() => {
    if (showSignUpForm) {
      nameInputRef?.current?.focus();
    }
  }, [showSignUpForm]);

  useEffect(() => {
    setFormValues((currentStates) => ({
      ...currentStates,
      action: 'registration'
    }));
  }, []);

  const handleCompanyAutocompleteSelection = (res) => {
    const { name } = res;
    if (errorMessage) {
      setErrorMessage('');
    }
    setFormValues((currentStates) => ({
      ...currentStates,
      company: name.split(',')[0]
    }));
  };

  useEffect(() => {
    const { name } = formValues;
    if (name) {
      debounce = setTimeout(() => {
        if (!checkIfNameIncludesOnlyStandardChars(name)) {
          setNameCheckError(true);
          setDisableSubmitButton(true);
          setErrorMessage('Please include only standard characters in your name');
        } else if (!includesSurname(name)) {
          setNameCheckError(true);
          setDisableSubmitButton(true);
          setErrorMessage('Please include a surname');
        } else {
          setNameCheckError(false);
          setDisableSubmitButton(false);
        }
        setDisableSubmitButton(false);
      }, debounceTimeout);
    }
    return () => clearTimeout(debounce);
  }, [formValues.name]);

  async function handleSubmit(e) {
    e.preventDefault();

    const { name, email, company, profession, termsAndConditions } = formValues;

    if (!name) {
      setErrorMessage('Please include your name');
      return;
    }

    if (!includesSurname(name)) {
      setErrorMessage('Please include a surname');
      return;
    }

    if (!checkIfNameIncludesOnlyStandardChars(name)) {
      setErrorMessage('Please include only standard characters in your name');
      return;
    }

    if (!profession) {
      setErrorMessage('Please include your profession');
      return;
    }

    if (!company) {
      setErrorMessage('Please include your workplace name');
      return;
    }

    if (!termsAndConditions) {
      setErrorMessage('You must agree to our Terms & Conditions to register');
      return;
    }

    try {
      setRegistrationFormSubmissionStatus({
        submitting: true,
        submitted: false
      });

      const userDetailsQueryString = constructQueryString({
        formValues,
        exclude: ['email', 'termsAndConditions']
      });

      const continueUrl = `${window.location.href}?${userDetailsQueryString}`;

      const actionCodeSettings = {
        url: continueUrl,
        handleCodeInApp: false
      };

      const firstName = name.split(' ')[0];

      const { data } = await firebase.emails.sendVerificationEmail({
        firstName: capitaliseFirstLetterOfText(firstName),
        email,
        colors,
        actionCodeSettings,
        origin: window.location.origin,
        pathname: window.location.pathname
      });

      if (data.Message === 'OK') {
        setRegistrationFormSubmissionStatus({
          submitting: false,
          submitted: true
        });
        window.localStorage.setItem(`newUserEmail-${siteName}`, JSON.stringify(email.trim()));
      } else {
        setRegistrationFormSubmissionStatus({
          submitting: false,
          submitted: false
        });
        setErrorMessage('Error sending email. Please try again');
      }

      setFormValues(resetForm({ siteName }));
    } catch (error) {
      console.error(error);

      if (isMounted) {
        setErrorMessage(error.message);
      }

      setRegistrationFormSubmissionStatus({
        submitting: false,
        submitted: false
      });
    }
  }

  return (
    <FormContainer
      onSubmit={(e) => handleSubmit(e)}
      colors={colors}
      initial={false}
      animate={{ rotateY: showSignUpForm ? 0 : 180 }}
      transition={{
        rotateY: {
          type: 'spring',
          duration: 2,
          stiffness: 60,
          damping: 8,
          mass: 1
        }
      }}
      style={{
        backfaceVisibility: 'hidden',
        display: showSignUpForm ? 'block' : 'none', // When <LoginForm /> is open and displayed in a dialog, this css rule allows a user to close the dialog by clicking above/below <LoginForm />. Without it their click would land on the backface of <SignUpForm />, preventing the dialog from closing.
        position: (isDialog && showSignUpForm) || !isDialog ? 'absolute' : 'relative'
      }}>
      {!hideCloseIcon && (
        <DialogCloseIcon
          onClick={() =>
            handleDialog({
              dialogRef: loginOrSignUpDialogRef,
              animation: 'dismiss',
              stateHandler: () => setShowLoginOrSignUpDialog(false)
            })
          }
          width="0.875em"
        />
      )}
      <FormLabel>Sign Up</FormLabel>
      <Fields
        ref={fieldsRef}
        variants={fadeInAndOutVariants()}
        initial="initial"
        animate="animate"
        exit="exit">
        <div>
          <FormLabel hide htmlFor="name">
            Name
          </FormLabel>
          <FormInput
            id="name"
            name="name"
            onChange={handleInputChange}
            placeholder="Full Name*"
            type="text"
            value={formValues.name}
            error={nameCheckError === true}
            ref={nameInputRef}
            required
          />
        </div>
        <div>
          <FormLabel hide htmlFor="profession">
            Profession
          </FormLabel>
          <FormSelect
            id="profession"
            name="profession"
            required
            defaultValue={formValues.profession}
            value={professionOptions.filter(({ value }) => value === formValues.profession)}
            controlShouldRenderValue
            onChange={({ value }) => {
              setFormValues((currentStates) => ({
                ...currentStates,
                profession: value
              }));
            }}
            placeholder="Profession*"
            styles={customDropdownMenuStyles({
              colors,
              theme,
              style: { marginBottom: '1.625rem' }
            })}
            options={professionOptions}
          />
        </div>
        <div>
          <FormLabel hide htmlFor="company">
            Company
          </FormLabel>
          <StyledAutoComplete
            id="company"
            name="company"
            apiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY}
            options={{
              types: ['establishment'],
              componentRestrictions: { country: 'ie' },
              fields: ['name']
            }}
            onChange={handleInputChange}
            onPlaceSelected={handleCompanyAutocompleteSelection}
            placeholder="Company/Workplace*"
            type="select"
            required
            value={formValues.company}
            selectedEvent={selectedEvent}
          />
        </div>
      </Fields>
      <FormChecks>
        <FormLabel hide htmlFor="termsAndConditions" style={{ display: 'inline-block' }}>
          Terms And Conditions
        </FormLabel>
        <FormCheckbox
          checked={formValues.termsAndConditions}
          id="termsAndConditions"
          name="termsAndConditions"
          onChange={handleInputChange}
          required
          colors={colors}
          type="checkbox"
        />
        <FormTerms>
          This event is reserved exclusively for healthcare professionals resident in the Republic
          of Ireland. I confirm that I am a healthcare professional resident in the Republic of
          Ireland.*
        </FormTerms>
      </FormChecks>
      <ErrorMessage
        errorMessage={errorMessage}
        style={{ color: colors.tertiary }}
        variants={fadeInAndOutAndAnimateHeightVariants()}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '1.25rem'
        }}>
        {registrationFormSubmissionStatus.submitted ? (
          <div
            style={{
              textAlign: 'center',
              marginBottom: '0.5rem',
              color: theme.className === 'contrast' ? theme.primary : theme.tertiary,
              width: '90%'
            }}>
            Check your inbox to verify your email address and complete your registration. Can&apos;t
            find our email? Be sure to check your junk!
          </div>
        ) : (
          <Button
            type="submit"
            disabled={disableSubmitButton || errorMessage}
            loadingButton
            loading={registrationFormSubmissionStatus.submitting}
            changeColorWhenDisabled={false}
            loadingSpinnerColor={theme.contrast}
            loadingSpinnerSize="2rem">
            Create An Account
          </Button>
        )}
      </div>
    </FormContainer>
  );
}

const Fields = styled(motion.div)`
  color: #ffffff;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.625rem;
  width: 100%;
  input {
    width: 100%;
  }
`;

const StyledAutoComplete = styled(Autocomplete)`
  align-items: center;
  background: transparent;
  border: 0.063rem solid ${({ theme }) => theme.contrast};
  color: ${({ theme }) => (theme.className === 'contrast' ? theme.primary : theme.contrast)};
  display: flex;
  font-size: 1rem;
  font-weight: bold;
  height: 3.75rem;
  letter-spacing: 0.031rem;
  outline: none;
  padding: 0 1.25rem;

  ::placeholder {
    color: ${({ theme }) => (theme.className === 'contrast' ? theme.primary : theme.contrast)};
    font-size: 1rem;
    font-style: italic;
    font-weight: bold;
    opacity: 0.65;
  }
`;

export default SignUpForm;
