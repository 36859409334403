import React, { useState, useContext, useRef } from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Button, UploadProject, BulletPoint, Dialog, ProjectDialog } from 'components';
import { LocalContext, FirebaseContext } from 'context';
import { Arrow } from 'assets/svgs';
import { wrap } from '@popmotion/popcorn';
import { motion, AnimatePresence } from 'framer-motion';

function Projects({ eventId, colors }) {
  const [galleryView, setGalleryView] = useState(true);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const { isMobile, galleryRef, handleDialog } = useContext(LocalContext);
  const { user, firebase } = useContext(FirebaseContext);
  const [startPoint, setStartPoint] = useState(false);
  const [projectSubmissionStatus, setProjectSubmissionStatus] = useState({
    submitting: false,
    submitted: false
  });
  const uploadDialogRef = useRef(null);
  const projectDialogRef = useRef(null);

  const [showProjectDialog, setShowProjectDialog] = useState(false);

  const data = useStaticQuery(graphql`
    query {
      allProject {
        edges {
          node {
            title
            author
            summary
            timestamp
            localPDF {
              publicURL
            }
            localPreviewImg {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  formats: [AUTO, WEBP]
                  width: 440
                  height: 623
                  placeholder: TRACED_SVG
                )
              }
            }
          }
        }
      }
    }
  `);

  const projects = data.allProject.edges.map((project, index) => ({
    node: {
      ...project.node,
      index
    }
  }));
  // .filter(({ node }) => (user ? !node.disableModal : node.disableModal));

  const [[page], setPage] = useState([0, 0]);

  const paginate = (dir) => {
    setPage([page + dir, dir]);
  };

  const index = wrap(0, projects.length, page);
  const leftIndex = wrap(0, projects.length, page - 1);
  const rightIndex = wrap(0, projects.length, page + 1);

  const [[galleryPage], setGalleryPage] = useState([0, 0]);

  const galleryNext = () => {
    if (galleryPage + 9 <= projects.length) setGalleryPage([galleryPage + 10, 1]);
  };

  const galleryPrev = () => {
    if (galleryPage >= 9) setGalleryPage([galleryPage - 10, -1]);
  };

  const handleCarouselViewThumbnailClick = ({ startPoint: _startPoint }) => {
    setStartPoint(_startPoint);
    setShowProjectDialog(true);
  };

  const handleCarouselViewThumbnailKeyDown = ({ e, startPoint: _startPoint }) => {
    const { key } = e;
    if (key && (key === ' ' || key === 'Enter')) {
      setStartPoint(_startPoint);
      setShowProjectDialog(true);
    }
  };

  const dismissProjectDialog = () =>
    handleDialog({
      dialogRef: uploadDialogRef,
      animation: projectSubmissionStatus.submitted ? 'close' : 'dismiss',
      stateHandler: () => setShowUploadDialog(false)
    });

  return (
    firebase && (
      <>
        <Container ref={galleryRef}>
          <Title>
            {user && (
              <BulletPoint
                top="-1rem"
                left="0rem"
                content={`
              <h4>Poster Gallery Module</h4>
              <p>Consider a section to showcase some key work, innovations or products on the home page. Some of our events give the opportunities for attendees to upload their latest work. Others allow for a top 10 show innovations that will be featured at the event. Equally this feature can be used as a gallery of past events, attendees, showcase material, excerpts from presentation slide decks, new launch imagery etc.In can be interactive allowing for registered guests to leave reviews and comments as required.</p>
              <p>It is not just limited to still imagery, consider info graphics and short video to engage the attention of your audience.</p>
            `}
              />
            )}
            <H3>Poster Gallery</H3>
          </Title>
          <P>
            Our virtual poster gallery is the perfect opportunity for you to share your research
            efforts and connect with the wider community. A number of posters will be selected for
            presentation as part of the main Scientific Programme. Young Investigator Awards will be
            presented on the day.
          </P>
          <AnimatePresence>
            {(galleryView || isMobile) && (
              <GalleryView
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.375 }}>
                {projects.length > 0 &&
                  projects
                    .map(({ node }) => (
                      <button
                        type="button"
                        key={node.index}
                        onClick={() => {
                          setStartPoint(node.index);
                          setShowProjectDialog(true);
                        }}>
                        <GalleryViewThumbnail
                          image={getImage(node.localPreviewImg)}
                          alt={node.title}
                          type="button"
                          tabIndex={0}
                        />
                      </button>
                    ))
                    .slice(galleryPage, galleryPage + 10)}
              </GalleryView>
            )}
            {user && (galleryView || isMobile) && projects.length > 10 && (
              <GalleryViewButtons>
                <button type="button" onClick={() => galleryPrev()}>
                  &larr; Prev
                </button>
                <button type="button" onClick={() => galleryNext()}>
                  Next &rarr;
                </button>
              </GalleryViewButtons>
            )}
            {!galleryView && !isMobile && (
              <CarouselView>
                {projects.length > 0 && (
                  <>
                    <Left
                      onClick={() => paginate(-1)}
                      whileTap={{ scale: 0.9 }}
                      transition={{ duration: 0.25 }}
                      $colors={colors}>
                      <Arrow />
                    </Left>
                    <AnimatePresence exitBeforeEnter>
                      <CarouselViewThumbnailWrapper
                        key={page - 1}
                        style={{ gridColumn: '2/5' }}
                        onClick={() =>
                          handleCarouselViewThumbnailClick({
                            startPoint: projects[leftIndex].node.index
                          })
                        }
                        onKeyDown={(e) =>
                          handleCarouselViewThumbnailKeyDown({
                            e,
                            startPoint: projects[leftIndex].node.index
                          })
                        }>
                        <CarouselViewThumbnail
                          image={getImage(projects[leftIndex].node.localPreviewImg)}
                          alt={projects[leftIndex].node.title}
                          tabIndex={0}
                        />
                      </CarouselViewThumbnailWrapper>
                    </AnimatePresence>
                    <AnimatePresence exitBeforeEnter>
                      <CarouselViewThumbnailWrapper
                        key={page}
                        style={{ gridColumn: '5/9' }}
                        onClick={() =>
                          handleCarouselViewThumbnailClick({
                            startPoint: projects[index].node.index
                          })
                        }
                        onKeyDown={(e) =>
                          handleCarouselViewThumbnailKeyDown({
                            e,
                            startPoint: projects[index].node.index
                          })
                        }>
                        <CarouselViewThumbnail
                          image={getImage(projects[index].node.localPreviewImg)}
                          alt={projects[index].node.title}
                          tabIndex={0}
                        />
                      </CarouselViewThumbnailWrapper>
                    </AnimatePresence>
                    <AnimatePresence exitBeforeEnter>
                      <CarouselViewThumbnailWrapper
                        key={page + 1}
                        style={{ gridColumn: '9/12' }}
                        onClick={() =>
                          handleCarouselViewThumbnailClick({
                            startPoint: projects[rightIndex].node.index
                          })
                        }
                        onKeyDown={(e) =>
                          handleCarouselViewThumbnailKeyDown({
                            e,
                            startPoint: projects[rightIndex].node.index
                          })
                        }>
                        <CarouselViewThumbnail
                          image={getImage(projects[rightIndex].node.localPreviewImg)}
                          alt={projects[rightIndex].node.title}
                          tabIndex={0}
                        />
                      </CarouselViewThumbnailWrapper>
                    </AnimatePresence>
                    <Right
                      onClick={() => paginate(1)}
                      whileTap={{ scale: 0.9 }}
                      transition={{ duration: 0.25 }}
                      $colors={colors}>
                      <Arrow />
                    </Right>
                  </>
                )}
              </CarouselView>
            )}
          </AnimatePresence>
          <P style={{ marginBottom: '0rem' }}>
            {user
              ? 'Click on the posters to see the comments'
              : 'Please register to submit your own poster and see comments'}
          </P>
          <GalleryFooter>
            {!isMobile && (
              <Toggle $active={galleryView} $colors={colors}>
                <Button disableRipple variant="text" onClick={() => setGalleryView(false)}>
                  Carousel View
                </Button>
                <Button disableRipple variant="text" onClick={() => setGalleryView(true)}>
                  Gallery View
                </Button>
              </Toggle>
            )}
            {firebase?.auth?.currentUser && (
              <Upload>
                <Button onClick={() => setShowUploadDialog(true)}>Upload</Button>
              </Upload>
            )}
          </GalleryFooter>
          <Dialog
            ref={uploadDialogRef}
            isVisible={showUploadDialog}
            onDismiss={dismissProjectDialog}
            contentStyle={{
              maxWidth: '31.25rem'
            }}>
            <UploadProject
              eventId={eventId}
              projectSubmissionStatus={projectSubmissionStatus}
              setProjectSubmissionStatus={setProjectSubmissionStatus}
              dismissProjectDialog={dismissProjectDialog}
            />
          </Dialog>
        </Container>
        <Dialog
          ref={projectDialogRef}
          isVisible={showProjectDialog}
          onDismiss={() =>
            handleDialog({
              dialogRef: projectDialogRef,
              animation: 'dismiss',
              stateHandler: () => setShowProjectDialog(false)
            })
          }
          contentStyle={{
            maxWidth: '85.375rem',
            maxHeight: '100%'
          }}>
          <ProjectDialog
            startPoint={startPoint}
            projects={projects}
            eventId={eventId}
            handleDialog={() =>
              handleDialog({
                dialogRef: projectDialogRef,
                animation: 'close',
                stateHandler: () => setShowProjectDialog(false)
              })
            }
          />
        </Dialog>
      </>
    )
  );
}

const GalleryViewButtons = styled.div`
  display: flex;
  grid-column: 1/7;
  margin: 0 auto;
  width: auto;
  button {
    background: none;
    border: none;
    color: ${({ theme }) => theme.primary};
    cursor: pointer;
    font-size: 1.25rem;
    margin: 2rem 1rem;
    outline: none;
  }
  @media only screen and (min-width: 48rem) {
    grid-column: 2/6;
  }
  @media only screen and (min-width: 1150px) {
    grid-column: 2/12;
  }
  @media only screen and (min-width: 1441px) {
    grid-column: 3/11;
  }
`;

const GalleryView = styled(motion.div)`
  display: grid;
  grid-column: 1/7;
  grid-gap: 0.625rem;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 2rem;
  padding: 0px 1.25rem;

  @media only screen and (min-width: 48rem) {
    grid-column: 2/6;
  }
  @media only screen and (min-width: 1441px) {
    grid-column: 3/11;
  }
  @media only screen and (min-width: 1150px) {
    grid-column: 2/12;
    grid-gap: 1.25rem;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
`;

const SlideChange = styled(motion.button)`
  align-items: center;
  background-color: ${({ theme, $colors }) =>
    theme.className === 'contrast' ? theme.contrast : $colors.primary};
  border: none;
  cursor: pointer;
  display: flex;
  height: 3rem;
  justify-content: center;
  justify-self: center;
  opacity: 0.8;
  outline: none;
  width: 3rem;
  svg {
    width: 1.5rem;
    path {
      stroke: white;
    }
  }
`;

const Left = styled(SlideChange)`
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;

  svg {
    transform: rotate(90deg);
  }
`;

const Right = styled(SlideChange)`
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;

  svg {
    transform: rotate(-90deg);
  }
`;

const CarouselView = styled.div`
  align-items: center;
  display: grid;
  grid-column: 1/7;
  grid-gap: 0.625rem;
  grid-template-columns: repeat(6, 1fr);
  height: auto;
  margin-bottom: 2rem;
  padding: 0 1.25rem;
  position: relative;

  @media only screen and (min-width: 1150px) {
    grid-column: 1/13;
    grid-gap: 1.25rem;
    grid-template-columns: repeat(12, 1fr);
    margin: 0 auto 2rem;
  }
`;

const Toggle = styled.div`
  align-items: center;
  display: flex;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  grid-column: 1/7;
  justify-content: center;
  line-height: 1.25rem;
  text-align: center;
  text-transform: uppercase;

  button {
    border-radius: unset !important;
    min-width: unset !important;
    padding: unset !important;

    &:first-child {
      color: ${({ $active, theme, $colors }) =>
        $active !== true && theme.className === 'contrast'
          ? theme.primary
          : $active !== true && theme.className !== 'contrast'
            ? $colors.primary
            : 'rgb(196, 196, 196)'};
    }

    &:last-child {
      color: ${({ $active, theme, $colors }) =>
        $active && theme.className === 'contrast'
          ? theme.primary
          : $active && theme.className !== 'contrast'
            ? $colors.primary
            : 'rgb(196, 196, 196)'};
    }

    &:first-child:after {
      content: '/';
      margin-left: 0.625rem;
      margin-right: 0.625rem;
    }
  }

  @media only screen and (min-width: 1150px) {
    grid-column: 4/8;
  }
`;

const GalleryFooter = styled.div`
  align-items: center;
  display: grid;
  grid-column: 1/7;
  grid-gap: 0.625rem;
  grid-template-columns: repeat(6, 1fr);
  padding: 0 1.25rem;
  @media only screen and (min-width: 48rem) {
    grid-column: 2/6;
  }
  @media only screen and (min-width: 1150px) {
    grid-column: 2/12;
    grid-gap: 1.25rem;
    grid-template-columns: repeat(10, 1fr);
  }
  @media only screen and (min-width: 1441px) {
    grid-column: 3/11;
  }
`;

const Upload = styled.div`
  display: flex;
  grid-column: 1/7;
  justify-content: center;
  @media only screen and (min-width: 1150px) {
    grid-column: 9/11;
    justify-content: flex-end;
  }
`;

const GalleryViewThumbnail = styled(GatsbyImage)`
  cursor: pointer;
  height: auto;
  object-fit: cover;
  width: 100%;
`;

const CarouselViewThumbnailWrapper = styled(motion.div).attrs({
  initial: { opacity: 0, zIndex: 0, x: 50 },
  animate: { opacity: 1, zIndex: 1, x: 0 },
  exit: { opacity: 0, zIndex: 0, x: -50 }
})`
  cursor: pointer;
  position: relative;
`;

const CarouselViewThumbnail = styled(GatsbyImage)``;

const Title = styled.div`
  grid-column: 1/7;
  position: relative;
  @media only screen and (min-width: 48rem) {
    grid-column: 2/6;
  }
  @media only screen and (min-width: 1150px) {
    grid-column: 2/12;
  }
  @media only screen and (min-width: 1441px) {
    grid-column: 3/11;
  }
`;

const H3 = styled.h3`
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem;
  margin-bottom: 2rem;
  text-align: center;
`;

const P = styled.p`
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  grid-column: 1/7;
  line-height: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  :nth-child(3) {
    margin-bottom: 4rem;
  }
  @media only screen and (min-width: 48rem) {
    grid-column: 2/6;
  }
  @media only screen and (min-width: 1441px) {
    grid-column: 3/11;
  }
  @media only screen and (min-width: 1150px) {
    grid-column: 2/12;
    font-size: 1.5rem;
  }
`;

const Container = styled.div`
  background: ${({ theme }) => (theme.className === 'contrast' ? theme.contrast : '#EAF5F9')};
  display: grid;
  grid-column: 1/7;
  grid-gap: 0.625rem;
  grid-template-columns: repeat(6, 1fr);
  padding: 3.75rem 1.25rem;
  p,
  h3 {
    color: ${({ theme }) => theme.primary};
  }
  @media only screen and (min-width: 48rem) {
    padding: 6rem 1.25rem;
  }
  @media only screen and (min-width: 1441px) {
    padding: 6rem 0;
  }
  @media only screen and (min-width: 1150px) {
    grid-column: 1/13;
    grid-gap: 1.25rem;
    grid-template-columns: repeat(12, 1fr);
  }
`;

export default Projects;
