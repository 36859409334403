import { collection, getDocs, limit, query, where, onSnapshot, orderBy } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

function AdminFactory(firebase) {
  const fetchAllFirebaseAuthUserData = async () => {
    const fetchAllFirebaseAuthUserDataCallable = httpsCallable(
      firebase.functions,
      'admin-reports-fetchAllFirebaseAuthUserData'
    );
    return fetchAllFirebaseAuthUserDataCallable();
  };

  const getUserFromDatabaseWithUID = async ({ uid }) =>
    getDocs(query(collection(firebase.fsdb, 'users'), where('uid', '==', uid), limit(1)));

  const getSpeakerFromDatabaseWithSpeakerId = async ({ speakerId }) =>
    getDocs(
      query(collection(firebase.fsdb, 'speakers'), where('speakerId', '==', speakerId), limit(1))
    );

  const grantAdminRole = async ({ uid }) => {
    const grantAdminRoleCallable = httpsCallable(firebase.functions, 'admin-roles-admin-grant');
    return grantAdminRoleCallable({ uid });
  };

  const grantModeratorRole = async ({ uid, eventIds }) => {
    const grantModeratorRoleCallable = httpsCallable(
      firebase.functions,
      'admin-roles-moderator-grant'
    );
    return grantModeratorRoleCallable({ uid, eventIds });
  };

  const removeModeratorRole = async ({ uid, eventIds }) => {
    const removeModeratorRoleCallable = httpsCallable(
      firebase.functions,
      'admin-roles-moderator-remove'
    );
    return removeModeratorRoleCallable({ uid, eventIds });
  };

  const grantEditorRole = async ({ uid, eventIds }) => {
    const grantEditorRoleCallable = httpsCallable(firebase.functions, 'admin-roles-editor-grant');
    return grantEditorRoleCallable({ uid, eventIds });
  };

  const removeEditorRole = async ({ uid, eventIds }) => {
    const removeEditorRoleCallable = httpsCallable(firebase.functions, 'admin-roles-editor-remove');
    return removeEditorRoleCallable({ uid, eventIds });
  };

  const grantOrganiserRole = async ({ uid, eventIds }) => {
    const grantOrganiserRoleCallable = httpsCallable(
      firebase.functions,
      'admin-roles-organiser-grant'
    );
    return grantOrganiserRoleCallable({ uid, eventIds });
  };

  const removeOrganiserRole = async ({ uid, eventIds }) => {
    const removeOrganiserRoleCallable = httpsCallable(
      firebase.functions,
      'admin-roles-organiser-remove'
    );
    return removeOrganiserRoleCallable({ uid, eventIds });
  };

  const grantSpeakerRole = async ({ uid, eventIds }) => {
    const grantSpeakerRoleCallable = httpsCallable(firebase.functions, 'admin-roles-speaker-grant');
    return grantSpeakerRoleCallable({ uid, eventIds });
  };

  const removeSpeakerRole = async ({ uid, eventIds }) => {
    const removeSpeakerRoleCallable = httpsCallable(
      firebase.functions,
      'admin-roles-speaker-remove'
    );
    return removeSpeakerRoleCallable({ uid, eventIds });
  };

  const addDummyUsers = async ({ amountOfDummyUsers, eventIds }) => {
    const addDummyUsersCallable = httpsCallable(firebase.functions, 'admin-dummyUsers-add');
    return addDummyUsersCallable({ amountOfDummyUsers, eventIds });
  };

  const deleteAllDummyUsers = async () => {
    const deleteAllDummyUsersCallable = httpsCallable(
      firebase.functions,
      'admin-dummyUsers-deleteAll'
    );
    return deleteAllDummyUsersCallable();
  };

  const forceEmailVerification = async () => {
    const forceEmailVerificationCallable = httpsCallable(
      firebase.functions,
      'admin-forceEmailVerification'
    );
    return forceEmailVerificationCallable();
  };

  const subscribeToEventUniqueViews = ({ eventId, snapshot }) =>
    onSnapshot(query(collection(firebase.fsdb, 'events', eventId, 'uniqueViews')), snapshot);

  return {
    addDummyUsers,
    deleteAllDummyUsers,
    fetchAllFirebaseAuthUserData,
    forceEmailVerification,
    getSpeakerFromDatabaseWithSpeakerId,
    getUserFromDatabaseWithUID,
    grantAdminRole,
    grantEditorRole,
    grantModeratorRole,
    grantOrganiserRole,
    grantSpeakerRole,
    removeEditorRole,
    removeModeratorRole,
    removeOrganiserRole,
    removeSpeakerRole,
    subscribeToEventUniqueViews
  };
}

export default AdminFactory;
