import { arrayUnion, doc, setDoc, serverTimestamp, getDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

function LivestreamFactory(firebase) {
  const updateVideoSessionData = async ({ eventId, uid, timeRange }) =>
    setDoc(
      doc(firebase.fsdb, 'users', uid, 'videoSessionData', eventId),
      {
        eventId,
        timeRanges: arrayUnion(...timeRange)
      },
      { merge: true }
    );

  const updateUniqueViews = async ({ eventId, uid }) =>
    setDoc(
      doc(firebase.fsdb, 'events', eventId, 'uniqueViews', uid),
      { uid, timestamp: serverTimestamp() },
      { merge: true }
    );

  const checkIfUniqueViewDataAlreadyExists = async ({ eventId, uid }) =>
    getDoc(doc(firebase.fsdb, 'events', eventId, 'uniqueViews', uid));

  const summariseVideoViaAI = async ({ videoId }) => {
    const summariseVideoViaAICallable = httpsCallable(
      firebase.functions,
      'misc-summariseVideoViaAI'
    );
    return summariseVideoViaAICallable({ videoId });
  };

  return {
    checkIfUniqueViewDataAlreadyExists,
    summariseVideoViaAI,
    updateUniqueViews,
    updateVideoSessionData
  };
}
export default LivestreamFactory;
