import * as React from 'react';

function SvgUnhappyEmoji(props) {
  return (
    <svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13 25c6.627 0 12-5.373 12-12S19.627 1 13 1 1 6.373 1 13s5.373 12 12 12Z"
        stroke="#C4C4C4"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M17.835 15.647c-1.694.494-8.294 2.259-9.388 2.506"
        stroke="#C4C4C4"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M16.706 11.059a1.235 1.235 0 1 0 0-2.47 1.235 1.235 0 0 0 0 2.47ZM9.294 11.059a1.235 1.235 0 1 0 0-2.47 1.235 1.235 0 0 0 0 2.47Z"
        fill="#C4C4C4"
      />
    </svg>
  );
}
export default SvgUnhappyEmoji;
