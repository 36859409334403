const firebaseConfig = {
  apiKey: 'AIzaSyCcRYL0F34yWTiZua_Zf-FesoF-RV4mnnQ',
  authDomain: 'panacea-37700.firebaseapp.com',
  databaseURL: 'https://panacea-realtime-db.europe-west1.firebasedatabase.app/',
  projectId: 'panacea-37700',
  storageBucket: 'panacea-37700.appspot.com',
  messagingSenderId: '894461208843',
  appId: '1:894461208843:web:28b3ddb81a71eb72c41a0e',
  measurementId: 'G-NDC8JRR2WQ'
};

export default firebaseConfig;
