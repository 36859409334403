import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useIntersection } from 'hooks';
import { motion, AnimatePresence } from 'framer-motion';
import { DefaultCommenterAvatar } from 'assets/svgs';
import { CommenterAvatar } from 'components/Events/Livestream/Interaction/Chat';

function Participant({
  eventId,
  participant,
  fetchPaginatedParticipants,
  showOnlinePresenceToUsers,
  handleParticipantAvatarOrNameClick,
  isLastFetchedParticipant,
  colors,
  particpantsContainerRef
}) {
  const { rolesString } = participant;
  const [showMoreParticipantDetails, setShowMoreParticipantDetails] = useState(false);

  const [ref, observerEntry] = useIntersection({
    root: particpantsContainerRef.current,
    rootMargin: '0px 0px 100px 0px',
    threshold: 0.1
  });

  useEffect(() => {
    if (participant?.groupWatching?.[eventId]?.length) {
      setShowMoreParticipantDetails(true);
    } else {
      setShowMoreParticipantDetails(false);
    }
  }, [participant]);

  useEffect(() => {
    if (isLastFetchedParticipant && observerEntry.isIntersecting) {
      fetchPaginatedParticipants();
    }
  }, [observerEntry.isIntersecting]);

  return (
    <Wrapper
      ref={ref}
      onClick={() => handleParticipantAvatarOrNameClick(participant)}
      rolesString={rolesString}
      colors={colors}
      key={participant.uid}>
      {participant.avatarUrl ? (
        <CommenterAvatar
          size="1.625rem"
          src={participant.avatarUrl}
          alt={participant.name}
          colors={colors}
        />
      ) : (
        <DefaultCommenterAvatar />
      )}
      <AnimatePresence>
        {showOnlinePresenceToUsers && participant.presence.selectedEventId === eventId && (
          <AvailabilityIndicator
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
        )}
      </AnimatePresence>
      <p>
        {participant.name}
        {rolesString && <span>{` (${rolesString})`}</span>}
        {showMoreParticipantDetails && (
          <span
            style={{
              fontWeight: 400,
              fontSize: '14px',
              color: '#C4C4C4'
            }}>{` +${participant.groupWatching[eventId].length} others at ${participant.company}`}</span>
        )}
      </p>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  align-items: center;
  align-self: flex-start;
  color: #c4c4c4;
  display: flex;
  font-weight: 600;
  padding: 0.313rem 0 0.5em;
  position: relative;
  img,
  svg {
    cursor: pointer;
    flex-shrink: 0;
    margin-right: 0.5rem;
    width: 1.625rem;
  }
  p {
    cursor: pointer;
  }
  span {
    color: ${({ rolesString, colors }) => (rolesString ? colors.secondary : '#000')};
    font-weight: ${({ rolesString }) => (rolesString ? 500 : 400)};
  }
`;

const AvailabilityIndicator = styled(motion.div)`
  background: #33cc66;
  border-radius: 50%;
  height: 0.5rem;
  left: 1.25rem;
  position: absolute;
  top: 0.5rem;
  width: 0.5rem;
  z-index: 1;
`;

export default Participant;
