import { httpsCallable } from 'firebase/functions';
import { asyncDebounce } from 'utils';

function Algolia(firebase) {
  const search = asyncDebounce(
    async ({ indexType, tag }) => {
      const searchAlgoliaCallable = httpsCallable(firebase.functions, 'algolia-search');
      return searchAlgoliaCallable({
        indexType,
        tag
      });
    },
    { debounceInterval: 400, leading: false }
  );

  const updateAllUsers = async () => {
    const updateAllUsersCallable = httpsCallable(firebase.functions, 'algolia-updateAllUsers');
    return updateAllUsersCallable();
  };

  const updateAllSpeakers = async () => {
    const updateAllSpeakersCallable = httpsCallable(
      firebase.functions,
      'algolia-updateAllSpeakers'
    );
    return updateAllSpeakersCallable();
  };

  return {
    search,
    updateAllUsers,
    updateAllSpeakers
  };
}

export default Algolia;
