import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
  onSnapshot,
  getCountFromServer
} from 'firebase/firestore';
import {
  ref,
  update,
  onDisconnect,
  onValue,
  serverTimestamp as rtdbServerTimestamp
} from 'firebase/database';

function ParticipantsFactory(firebase) {
  // fetchParticipantsWithRoles() and fetchPaginatedParticipants() bascially act like
  // logical OR queries. See 'Query limitations' here:
  // https://firebase.google.com/docs/firestore/query-data/queries#query_limitations
  // If we didn't do this then users would not be listed as non-available participants on event
  // pages if watching a concurrent livestream on another event page.

  const fetchParticipantsWithRoles = async (eventId) => {
    // We're not pushing participants with 'isAdmin' or 'isEditor' roles to the
    // participantsWithRoles list because attendees don't need to know who the site
    // admins and editors are.
    const roles = ['isOrganiser', 'isModerator', 'isSpeaker'];
    const docs = [];

    // Participants with roles who are currently online for this event.
    roles.forEach((role) => {
      docs.push(
        getDocs(
          query(
            collection(firebase.fsdb, 'users'),
            where(`roles.${role}`, 'array-contains', eventId),
            where('presence.selectedEventId', '==', eventId),
            orderBy('name')
          )
        )
      );
    });

    // Participants with roles who are not currently online for this event, or are currently
    // watching another event.
    roles.forEach((role) => {
      docs.push(
        getDocs(
          query(
            collection(firebase.fsdb, 'users'),
            where(`roles.${role}`, 'array-contains', eventId),
            where('presence.selectedEventId', '!=', eventId),
            orderBy('presence.selectedEventId'),
            orderBy('name')
          )
        )
      );
    });

    return Promise.all(docs);
  };

  const fetchPaginatedParticipants = async ({
    eventId,
    lastFetchedCurrentlyParticipatingUserDoc,
    lastFetchedNotCurrentlyParticipatingUserDoc
  }) => {
    let currentlyParticipatingUsersQuery;
    let notCurrentlyParticipatingUsersQuery;

    if (lastFetchedCurrentlyParticipatingUserDoc) {
      currentlyParticipatingUsersQuery = query(
        collection(firebase.fsdb, 'users'),
        where('eventsUserCanAccess', 'array-contains', eventId),
        where('presence.selectedEventId', '==', eventId),
        orderBy('name'),
        startAfter(lastFetchedCurrentlyParticipatingUserDoc),
        limit(20)
      );
    } else {
      currentlyParticipatingUsersQuery = query(
        collection(firebase.fsdb, 'users'),
        where('eventsUserCanAccess', 'array-contains', eventId),
        where('presence.selectedEventId', '==', eventId),
        orderBy('name'),
        limit(20)
      );
    }

    if (lastFetchedNotCurrentlyParticipatingUserDoc) {
      notCurrentlyParticipatingUsersQuery = query(
        collection(firebase.fsdb, 'users'),
        where('eventsUserCanAccess', 'array-contains', eventId),
        where('presence.selectedEventId', '!=', eventId),
        orderBy('presence.selectedEventId'),
        orderBy('name'),
        startAfter(lastFetchedNotCurrentlyParticipatingUserDoc),
        limit(20)
      );
    } else {
      notCurrentlyParticipatingUsersQuery = query(
        collection(firebase.fsdb, 'users'),
        where('eventsUserCanAccess', 'array-contains', eventId),
        where('presence.selectedEventId', '!=', eventId),
        orderBy('presence.selectedEventId'),
        orderBy('name'),
        limit(20)
      );
    }

    const docs = [];

    docs.push(getDocs(currentlyParticipatingUsersQuery));
    docs.push(getDocs(notCurrentlyParticipatingUsersQuery));

    return Promise.all(docs);
  };

  const fetchAllEventParticipants = async (eventId) =>
    getDocs(
      query(
        collection(firebase.fsdb, 'users'),
        where('eventsUserCanAccess', 'array-contains', eventId),
        orderBy('name')
      )
    );

  const updateUserPresence = async ({ user, eventId }) => {
    const userPresenceRTDBRef = ref(firebase.rtdb, `/presence/${user.uid}`);
    return onDisconnect(userPresenceRTDBRef)
      .set({
        selectedEventId: '',
        lastChanged: rtdbServerTimestamp(),
        lastRefreshAt: user.lastRefreshAt
      })
      .then(() => {
        update(userPresenceRTDBRef, {
          selectedEventId: eventId,
          lastChanged: rtdbServerTimestamp(),
          lastRefreshAt: user.lastRefreshAt
        });
      });
  };

  const getEventParticipantsOnlineCount = async ({ eventId, callback }) => {
    const usersCollection = collection(firebase.fsdb, 'users');
    const presenceQuery = query(usersCollection, where('presence.selectedEventId', '==', eventId));
    const snapshot = await getCountFromServer(presenceQuery);
    callback(snapshot);
  };

  const subscribeToAllEventParticipantsPresent = ({ eventId, snapshot }) =>
    onSnapshot(
      query(collection(firebase.fsdb, 'users'), where('presence.selectedEventId', '==', eventId)),
      snapshot
    );

  const subscribeToRTDBServer = ({ snapshot }) =>
    onValue(ref(firebase.rtdb, '.info/connected'), snapshot);

  return {
    fetchParticipantsWithRoles,
    fetchPaginatedParticipants,
    fetchAllEventParticipants,
    subscribeToAllEventParticipantsPresent,
    getEventParticipantsOnlineCount,
    updateUserPresence,
    subscribeToRTDBServer
  };
}

export default ParticipantsFactory;
