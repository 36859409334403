import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Markup } from 'interweave';
import { useEventData } from 'hooks';
import { hexToRGB } from 'utils';

function PreviousEvents() {
  const { edges: events } = useEventData();
  const previousEvents = events
    .filter(({ node }) => node.frontmatter.status === 'previous')
    .sort((a, b) => a.node.frontmatter.eventId - b.node.frontmatter.eventId);

  return (
    <Events>
      {previousEvents
        .map((event, i) => (
          <Event
            key={i}
            to={`events/${event.node.frontmatter.slug}`}
            colors={event.node.frontmatter.colors}>
            <GatsbyImage
              image={getImage(event.node.frontmatter.thumb)}
              alt={event.node.frontmatter.name}
            />
            <Details>
              <h3>{event.node.frontmatter.name}</h3>
              <h4>
                <Markup content={event.node.frontmatter.date} />
              </h4>
            </Details>
          </Event>
        ))
        .slice(0, 3)}
    </Events>
  );
}

const Details = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0.5em;
  h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.5em;
  }
  h4 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.5em;
  }
`;

const Event = styled(Link)`
  background: transparent;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 400px;
  padding: 1rem;
  transition: 0.375s;
  width: 100%;
  img {
    max-width: 100%;
    object-position: top left !important;
  }
  &:hover {
    background: ${({ theme }) =>
      theme.className === 'contrast'
        ? hexToRGB({ color: theme.primary, alpha: 0.25 })
        : hexToRGB({ color: '#CCCCCC', alpha: 0.25 })};
  }
  h3 {
    color: ${({ theme, colors }) =>
      theme.className === 'contrast' ? theme.primary : colors.primary};
  }
  h4 {
    color: ${({ theme, colors }) =>
      theme.className === 'contrast' ? theme.primary : colors.secondary};
  }
`;

const Events = styled.div`
  display: grid;
  grid-column: 1/7;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  height: auto;
  width: 100%;
  @media only screen and (min-width: 48rem) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media only screen and (min-width: 1150px) {
    grid-column: 2/12;
  }
`;

export default PreviousEvents;
