import * as React from 'react';

function SvgEmojiStar(props) {
  return (
    <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M48 25.632C48 39.952 38.32 48 24 48S0 39.952 0 25.632C0 10.768 9.152 0 24 0s24 10.768 24 25.632Z"
        fill="#004D9A"
      />
      <path
        d="M23.055 9.717c.312-.895 1.578-.895 1.89 0l2.823 8.124a1 1 0 0 0 .925.672l8.599.175c.948.02 1.339 1.224.584 1.797l-6.854 5.196a1 1 0 0 0-.353 1.086L33.159 35c.275.907-.75 1.652-1.528 1.11l-7.06-4.912a1 1 0 0 0-1.142 0l-7.06 4.912c-.778.542-1.803-.203-1.528-1.11l2.49-8.233a1 1 0 0 0-.353-1.086l-6.854-5.196c-.755-.573-.364-1.778.584-1.797l8.6-.175a1 1 0 0 0 .924-.672l2.823-8.124Z"
        fill="#fff"
      />
    </svg>
  );
}
export default SvgEmojiStar;
