import React, { useContext, useRef } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import SvgPlus from 'assets/svgs/Plus';
import { LocalContext } from 'context';
import { FieldContainer, FieldSection } from '../Shared';
import { FormInput, FormInputLabel } from '../../FormComponents';
import { FacebookIcon, InstagramIcon, LinkedinIcon, TwitterIcon } from '../../../../assets/svgs';

function SocialMediaFields({
  formValues,
  handleInputChange,
  showSocialMediaFields,
  setShowSocialMediaFields
}) {
  const { selectedEvent } = useContext(LocalContext);

  const addSocialMediaRef = useRef();

  const handleAddSocialMediaKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      if (!showSocialMediaFields) {
        setShowSocialMediaFields(true);
      }
    } else if (e.key === 'Escape') {
      e.preventDefault();
      addSocialMediaRef.current.blur();
    }
  };

  return showSocialMediaFields ? (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      style={{ marginBottom: '1.5rem' }}>
      <FormInputLabel htmlFor="social" style={{ fontWeight: 400, paddingLeft: '1rem' }}>
        Social Media (Optional)
      </FormInputLabel>
      <FieldContainer>
        <FieldSection>
          <SocialField>
            <SocialLink selectedEvent={selectedEvent}>
              <LinkedinIcon />
            </SocialLink>
            <FormInput
              id="social"
              name="linkedIn"
              type="text"
              value={formValues.socials.linkedIn}
              onChange={handleInputChange}
              style={{
                marginBottom: '0.95rem',
                width: '100%',
                height: '2.75rem'
              }}
              placeholder="LinkedIn Profile URL"
              autoComplete="off"
            />
          </SocialField>
          <SocialField>
            <SocialLink selectedEvent={selectedEvent}>
              <FacebookIcon />
            </SocialLink>
            <FormInput
              id="social"
              name="facebook"
              type="text"
              value={formValues.socials.facebook}
              onChange={handleInputChange}
              style={{
                marginBottom: '0.95rem',
                width: '100%',
                height: '2.75rem'
              }}
              placeholder="Facebook Profile URL"
              autoComplete="off"
            />
          </SocialField>
        </FieldSection>
        <FieldSection style={{ position: 'relative', top: '-0.063rem' }}>
          <SocialField>
            <SocialLink selectedEvent={selectedEvent}>
              <TwitterIcon />
            </SocialLink>
            <FormInput
              id="social"
              name="twitter"
              type="text"
              value={formValues.socials.twitter}
              onChange={handleInputChange}
              style={{
                marginBottom: '1rem',
                height: '2.75rem'
              }}
              placeholder="Twitter Handle"
              autoComplete="off"
            />
          </SocialField>
          <SocialField>
            <SocialLink selectedEvent={selectedEvent}>
              <InstagramIcon />
            </SocialLink>
            <FormInput
              id="social"
              name="instagram"
              type="text"
              value={formValues.socials.instagram}
              onChange={handleInputChange}
              style={{
                marginBottom: '0.95rem',
                height: '2.75rem'
              }}
              placeholder="Instagram Handle"
              autoComplete="off"
            />
          </SocialField>
        </FieldSection>
      </FieldContainer>
    </motion.div>
  ) : (
    <AddSocialMedia
      tabIndex={0}
      ref={addSocialMediaRef}
      onClick={(e) => {
        e.preventDefault();
        setShowSocialMediaFields(true);
      }}
      onKeyDown={handleAddSocialMediaKeyDown}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}>
      <SvgPlus height="0.75rem" width="0.75rem" />
      <span>Add Social Media (Optional)</span>
    </AddSocialMedia>
  );
}

const SocialLink = styled.a`
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
  transition: 0.5s;

  svg {
    height: 2.25rem;
    margin-right: 0.85rem;
    width: 2.25rem;

    circle {
      transition: 0.5s;
      fill: ${({ theme, selectedEvent }) =>
        theme.className === 'contrast'
          ? theme.primary
          : selectedEvent
          ? selectedEvent.colors.tertiary
          : theme.primary};
    }

    path {
      fill: ${({ theme }) => (theme.className === 'contrast' ? theme.contrast : '#fff')};
      transition: 0.5s;
    }
  }
`;

const SocialField = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`;

const AddSocialMedia = styled(motion.p)`
  align-items: center;
  bottom: 0.5rem;
  box-sizing: content-box;
  cursor: pointer;
  display: flex;
  left: -0.25rem;
  margin-bottom: 1rem;
  outline: none;
  padding: 0.5rem 0.625rem;
  position: relative;
  width: 15rem;

  svg path {
    stroke: ${({ theme }) => (theme.className === 'contrast' ? theme.primary : '#fff')};
  }

  span {
    border-bottom: 0.125rem solid transparent;
    font-weight: bold;
    margin-left: 0.5rem;
    padding-bottom: 0.063rem;
    position: relative;
    top: 0.125rem;
  }

  :hover span,
  :focus:not(:active) span {
    border-bottom: 0.125rem solid #fff;
  }
`;

export default SocialMediaFields;
