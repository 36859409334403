import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { hexToRGB, capitaliseFirstLetterOfText, generateNanoId } from 'utils';
import { LoadingSpinner, ErrorMessage, Button } from 'components';
import { FirebaseContext, LocalContext } from 'context';
import { fadeInAndOutVariants, fadeInAndOutAndAnimateHeightVariants } from 'styles';
import Filter from 'bad-words';
import TextInput from 'components/Events/Livestream/Interaction/Polls/Shared/TextInput';

const filter = new Filter();

function WordPollModal({ poll }) {
  const { selectedEvent } = useContext(LocalContext);
  const { firebase, user } = useContext(FirebaseContext);
  const [entries, setEntries] = useState([]);
  const [pollStatus, setPollStatus] = useState(null);
  const [userHasAlreadyAnsweredThisPoll, setUserHasAlreadyAnsweredThisPoll] = useState(false);
  const [submitButtonIsDisabled, setSubmitButtonIsDisabled] = useState(null);

  useEffect(() => {
    if (firebase && user) {
      firebase.interaction.polls
        .checkIfUserHasAlreadyAnsweredThisPoll({
          uid: user.uid,
          eventId: selectedEvent.eventId,
          pollId: poll.pollId
        })
        .then((doc) => {
          if (doc.data()) {
            setUserHasAlreadyAnsweredThisPoll(true);
            const { entries: _words } = doc.data();
            setEntries(_words.map((entry) => ({ id: generateNanoId(), text: entry })));
          } else {
            setEntries([
              {
                id: generateNanoId(),
                text: ''
              }
            ]);
          }
        });
    }
  }, [firebase, user, poll, pollStatus, selectedEvent]);

  useEffect(
    () =>
      setSubmitButtonIsDisabled(
        userHasAlreadyAnsweredThisPoll ||
          !entries.filter(({ text }) => text).length ||
          entries.filter(({ text }) => filter.isProfane(text)).length ||
          pollStatus === 'submitted'
      ),
    [userHasAlreadyAnsweredThisPoll, poll, entries, pollStatus]
  );

  const handleTextInputChange = (e, wordId) => {
    const { value } = e.target;

    const allEntryIds = entries.map((entry) => entry.id);

    const lastFieldIsFocused = wordId === allEntryIds[allEntryIds.length - 1];

    // If multiple inputs are allowed, then we add another input
    // field when user starts typing in the last input field.
    if (poll.multipleInputs.allowed && lastFieldIsFocused && value !== '') {
      setEntries((currentState) => [
        ...currentState,
        {
          id: generateNanoId(),
          text: ''
        }
      ]);
    }

    setEntries((currentState) =>
      currentState.map((entry) => {
        if (entry.id === wordId) {
          entry.text = value;
        }

        if (filter.isProfane(entry.text)) {
          entry.error = {
            code: 'profanity-detected',
            message: 'Please refrain from using profanity'
          };
        } else if (entry.error?.code === 'profanity-detected') {
          // If the text previously included profanity but
          // now no longer does then we reset the error
          entry.error = null;
        }

        return entry;
      })
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (userHasAlreadyAnsweredThisPoll) return;

    if (pollStatus !== 'submitted') {
      try {
        setPollStatus('submitting');

        await firebase.interaction.polls.answerWordCloudPoll({
          eventId: selectedEvent.eventId,
          poll,
          uid: user.uid,
          entries: entries.filter((entry) => entry.text).map((entry) => entry.text)
        });

        setPollStatus('submitted');
      } catch (error) {
        console.error(error);
        setPollStatus('error');
      }
    }
  };

  return (
    <Underlay data-underlay colors={selectedEvent.colors}>
      <Modal colors={selectedEvent.colors}>
        <Question colors={selectedEvent.colors}>
          <p>{capitaliseFirstLetterOfText(poll.question.text)}</p>
        </Question>
        {poll.description.allowed && (
          <Description colors={selectedEvent.colors}>
            <p>{poll.description.text}</p>
          </Description>
        )}
        <form onSubmit={(e) => handleSubmit(e)}>
          <AnimatePresence>
            {entries.map((entry, i) => (
              <motion.div
                key={entry.id}
                variants={fadeInAndOutAndAnimateHeightVariants()}
                style={{ position: 'relative' }}>
                <WordCloudTextInput
                  error={entry.error?.code === 'profanity-detected'}
                  selectedEvent={selectedEvent}
                  type="input"
                  placeholder={`Type here${i > 0 ? ' (Optional)' : ''}`}
                  onChange={(e) => handleTextInputChange(e, entry.id)}
                  value={entry.text}
                  disabled={userHasAlreadyAnsweredThisPoll}
                  maxLength={poll.characterLimit.allowed ? poll.characterLimit.max : 24}
                />
                <ErrorMessage
                  errorMessage={entry.error?.code === 'profanity-detected' && entry.error.message}
                  style={{
                    color: '#F55151',
                    fontSize: '0.75rem',
                    position: 'absolute',
                    top: '3.625rem',
                    width: '100%'
                  }}
                  variants={fadeInAndOutAndAnimateHeightVariants()}
                />
              </motion.div>
            ))}
          </AnimatePresence>
          <SubmitButton
            disabled={submitButtonIsDisabled}
            type="submit"
            loading={pollStatus === 'submitting'}
            loadingButton>
            {pollStatus === 'submitted' || userHasAlreadyAnsweredThisPoll ? 'Submitted' : 'Submit'}
          </SubmitButton>
        </form>
      </Modal>
    </Underlay>
  );
}

const Underlay = styled(motion.div).attrs(fadeInAndOutVariants())`
  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 1.625rem 1.625rem;
  position: absolute;
  top: 0;
  width: 100%;
  ::-webkit-scrollbar {
    width: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.31rem grey;
    border-radius: 0.625rem;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ colors }) => colors.secondary};
    border-radius: 0.625rem;
  }
`;

const Modal = styled.div`
  background-color: #fff;
  margin: ${({ pollTimerEnabled }) => (pollTimerEnabled ? '6rem auto 0' : '3rem auto 0')};
  max-width: 23.75rem;
  position: relative;
  form {
    display: flex;
    flex-direction: column;
    padding: 2.4rem 1.5rem 1.25rem;

    > div {
      button {
        margin-left: auto;
        margin-top: 2rem;
      }

      &:last-of-type input {
        margin-bottom: 56px;
      }
    }
  }
`;

const Question = styled.div`
  background-color: ${({ colors }) => hexToRGB({ color: colors.secondary, alpha: 0.2 })};
  color: ${({ colors }) => colors.secondary};
  padding: 0.75rem 1.5rem;
  width: 100%;
  p {
    font-weight: 700;
  }
`;

const Description = styled.div`
  background-color: ${({ colors }) => hexToRGB({ color: colors.secondary, alpha: 0.2 })};
  color: ${({ colors }) => colors.secondary};
  padding: 0 1.5rem 0.75rem 1.5rem;
  width: 100%;
  p {
    font-size: 0.75rem;
    font-weight: 300;
  }
`;

const WordCloudTextInput = styled(TextInput)`
  background-color: transparent;
  font-size: 1.5rem;
  line-height: 29px;
  margin: 0.5em 0;
  padding: 0.25em 0;
  text-align: center;
`;

const SubmitButton = styled(Button)`
  align-self: center;
  border-radius: 0.125rem !important;
  width: 11.875rem;
`;

export default WordPollModal;
