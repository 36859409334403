import React, { useState, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  Button,
  DialogCloseIcon,
  ErrorMessage,
  MainFields,
  SocialMediaFields,
  SpeakerAvatar as Avatar
} from 'components';
import { generateNanoId, includesSurname } from 'utils';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { LocalContext, FirebaseContext } from 'context';
import { defaultColors, fadeInAndOutAndAnimateHeightVariants } from 'styles';
import draftToHtml from 'draftjs-to-html';

let fileReader;

if (typeof window !== 'undefined') {
  fileReader = new FileReader();
}

function AddOrEditSpeakerDialog({
  eventId,
  speakerCurrentlyBeingEdited,
  setSpeakerCurrentlyBeingEdited,
  speakers,
  getFormInitialState,
  formValues,
  setFormValues,
  dismissAddOrEditSpeakerDialog,
  closeAddOrEditSpeakerDialog
}) {
  const { theme, selectedEvent } = useContext(LocalContext);
  const { firebase } = useContext(FirebaseContext);
  const [errorMessage, setErrorMessage] = useState('');
  const [uploadingSpeaker, setUploadingSpeaker] = useState(false);
  const [showSocialMediaFields, setShowSocialMediaFields] = useState(false);
  const [isSearchResultDropDownMenuOpen, setIsSearchResultDropDownMenuOpen] = useState(false);
  const [searchResultSpeakers, setSearchResultSpeakers] = useState([]);

  const hiddenFileUploadButtonRef = useRef(null);
  const saveButtonRef = useRef(null);

  useEffect(() => {
    fileReader.addEventListener('load', () => {
      // 'localMemoryAvatar' means any image that is selected from an editor's device for
      // uploading. These images are stored as base64 encoded strings in the editor's device's local
      // memory until they're sent to Firebase Cloud Functions, which decode the base64 strings
      // and store the resulting images in Firebase Storage.

      // 'speaker.avatar.remoteUrl'- After the above image is stored in Firebase Storage, a
      // 'Resize Avatar' Firebase Extension runs, which resizes the uploaded image to 738x738 and
      // stores it in a folder named 'resized' in Firebase Storage. When new files are added to this
      // folder the editor.speakers.onAvatarUpload function is called, which grabs the url of the newly
      // resized file and stores it on the appropriate speaker object in Firestore. The browser now
      // displays the resized image located at this url while the website rebuilds on
      // Netlify. The editor now sees their newly added content almost instantly, while Netlify
      // converts that content in Firebase to static content that Gatsby can query via GraphQL.

      // 'staticAvatar' - Once Netlify has finished rebuilding the site the Gatsby Image
      // optimised 'staticAvatar' is now accessible to all non-Editor users.
      // If editors refresh the page they'll also see the static avatar instead of the remote avatar
      //  located at 'speaker.avatar.remoteUrl'.
      const localMemoryAvatar = fileReader.result;
      setFormValues((currentFormValues) => {
        // If we're editing the speaker avatar then we no longer need either of the below properties,
        // so we remove them from the currentFormValues object.
        if (speakerCurrentlyBeingEdited) {
          if (currentFormValues?.avatar?.remoteUrl) {
            delete currentFormValues.avatar.remoteUrl;
          }
          if (currentFormValues.staticAvatar) {
            delete currentFormValues.staticAvatar;
          }
        }
        return {
          ...currentFormValues,
          localMemoryAvatar
        };
      });
    });
  }, []);

  const htmlToDraftBlocks = (bio) => {
    // eslint-disable-next-line global-require
    const htmlToDraft = require('html-to-draftjs').default; // Fixes this bug - https://github.com/jpuri/html-to-draftjs/issues/78
    const blocksFromHtml = htmlToDraft(bio);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  useEffect(() => {
    if (speakerCurrentlyBeingEdited) {
      setFormValues({
        ...speakerCurrentlyBeingEdited,
        bio: htmlToDraftBlocks(speakerCurrentlyBeingEdited.bio),
        presentationTitle: speakerCurrentlyBeingEdited.presentationTitles[eventId] || ''
      });
      setShowSocialMediaFields(Object.values(speakerCurrentlyBeingEdited.socials).some(Boolean));
    } else {
      setFormValues(getFormInitialState());
      setShowSocialMediaFields(false);
      if (errorMessage) {
        setErrorMessage('');
      }
    }
  }, [speakerCurrentlyBeingEdited]);

  const searchAlgoliaForSpeaker = async (speakerName) => {
    try {
      const result = await firebase.algolia.search({
        indexType: 'speakers',
        tag: speakerName
      });
      if (result.data.length > 0) {
        const searchResults = result.data;
        const searchResultsMinusSpeakersAlreadyAddedToThisEvent = searchResults.filter(
          (searchResultSpeaker) => !searchResultSpeaker.eventsSpeakingAt.includes(eventId)
        );
        const searchResultsSortedAlphabetically =
          searchResultsMinusSpeakersAlreadyAddedToThisEvent.sort((a, b) => a.name - b.name);
        setSearchResultSpeakers(searchResultsSortedAlphabetically);
        setIsSearchResultDropDownMenuOpen(true);
      } else {
        setSearchResultSpeakers([]);
      }
    } catch (error) {
      console.error(error);
      setSearchResultSpeakers([]);
    }
  };

  const handleInputChange = (e) => {
    if (errorMessage) {
      setErrorMessage('');
    }

    const { name, value } = e.target;

    if (name === 'facebook' || name === 'linkedIn' || name === 'twitter' || name === 'instagram') {
      setFormValues((currentValues) => ({
        ...currentValues,
        socials: {
          ...currentValues.socials,
          [name]:
            value && (name === 'twitter' || name === 'instagram')
              ? value.charAt(0) === '@'
                ? value
                : `@${value}`
              : value
        }
      }));
    } else {
      setFormValues((currentValues) => ({
        ...currentValues,
        [name]: value
      }));

      if (name === 'name' && value) {
        searchAlgoliaForSpeaker(value);
      } else {
        setSearchResultSpeakers([]);
      }
    }
  };

  const handleSaveSpeaker = async (e) => {
    e.preventDefault();

    const bio = formValues.bio.getCurrentContent();

    if (!bio.getPlainText().trim()) {
      setErrorMessage('Please include a speaker bio');
      return;
    }

    try {
      setUploadingSpeaker(true);

      const thisIsANewSpeaker = !speakerCurrentlyBeingEdited;

      const speakerId = thisIsANewSpeaker
        ? generateNanoId()
        : speakerCurrentlyBeingEdited?.speakerId;

      let addingASpeakerFromAnotherEvent = false;

      if (!thisIsANewSpeaker) {
        const eventIds = Object.values(speakerCurrentlyBeingEdited.eventsSpeakingAt);
        if (!eventIds.includes(eventId)) {
          addingASpeakerFromAnotherEvent = true;
        }
      }

      let avatar;

      if (formValues.localMemoryAvatar && !addingASpeakerFromAnotherEvent) {
        // If adding a brand new speaker, i.e. one that hasn't already been uploaded to this event or
        // any other event.
        avatar = formValues.localMemoryAvatar;
      } else if (
        addingASpeakerFromAnotherEvent ||
        formValues.avatar?.remoteUrl ||
        formValues.staticAvatar.publicURL
      ) {
        // If adding a speaker from another event or editing a speaker already added to this event
        // then we don't need to upload any new speaker avatar, so we just pass an empty string. A
        // conditional check in the cloud function will then skip any unnecessary avatar resizing.
        avatar = '';
      }

      const order = speakers.length + 1;

      const speaker = {
        ...formValues,
        bio: draftToHtml(convertToRaw(formValues.bio.getCurrentContent())),
        speakerId,
        avatar,
        eventsSpeakingAt: thisIsANewSpeaker
          ? [eventId]
          : addingASpeakerFromAnotherEvent
            ? [...speakerCurrentlyBeingEdited.eventsSpeakingAt, eventId]
            : speakerCurrentlyBeingEdited.eventsSpeakingAt,
        presentationTitles: thisIsANewSpeaker
          ? {
              [eventId]: formValues.presentationTitle.trim()
            }
          : {
              ...speakerCurrentlyBeingEdited.presentationTitles,
              [eventId]: formValues.presentationTitle.trim()
            },
        orders: thisIsANewSpeaker
          ? {
              [eventId]: order
            }
          : addingASpeakerFromAnotherEvent
            ? {
                ...speakerCurrentlyBeingEdited.orders,
                [eventId]: order
              }
            : speakerCurrentlyBeingEdited.orders
      };

      if (thisIsANewSpeaker) {
        await firebase.editor.createSpeaker(eventId, speaker);
      } else {
        await firebase.editor.updateSpeaker(eventId, speaker);
      }

      closeAddOrEditSpeakerDialog();
    } catch (error) {
      console.error(error);
    } finally {
      setUploadingSpeaker(false);
    }
  };

  const handleInvalid = (e) => {
    e.preventDefault();

    if (
      !formValues.localMemoryAvatar &&
      !formValues.avatar?.remoteUrl &&
      !formValues.staticAvatar
    ) {
      hiddenFileUploadButtonRef.current.focus();
      setErrorMessage('Please upload a speaker image');
      return;
    }

    if (!formValues.name.trim()) {
      setErrorMessage("Please include the speaker's name");
      return;
    }

    if (!includesSurname(formValues.name.trim())) {
      setErrorMessage("Please include the speaker's surname");
      return;
    }

    if (!formValues.titleOrRole.trim()) {
      setErrorMessage("Please include the speaker's title/role");
    }
  };

  return (
    <Form
      method="POST"
      enctype="multipart/form-data"
      onSubmit={handleSaveSpeaker}
      onInvalid={handleInvalid}
      showSocialMediaFields={showSocialMediaFields}
      colors={selectedEvent?.colors || defaultColors}>
      <DialogCloseIcon
        onClick={dismissAddOrEditSpeakerDialog}
        selectedEvent={selectedEvent}
        width="0.875em"
      />
      <Avatar
        formValues={formValues}
        fileReader={fileReader}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        speakerCurrentlyBeingEdited={speakerCurrentlyBeingEdited}
        hiddenFileUploadButtonRef={hiddenFileUploadButtonRef}
      />
      <MainFields
        formValues={formValues}
        setFormValues={setFormValues}
        handleInputChange={handleInputChange}
        isSearchResultDropDownMenuOpen={isSearchResultDropDownMenuOpen}
        setIsSearchResultDropDownMenuOpen={setIsSearchResultDropDownMenuOpen}
        searchResultSpeakers={searchResultSpeakers}
        setSpeakerCurrentlyBeingEdited={setSpeakerCurrentlyBeingEdited}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        saveButtonRef={saveButtonRef}
      />
      <SocialMediaFields
        formValues={formValues}
        handleInputChange={handleInputChange}
        showSocialMediaFields={showSocialMediaFields}
        setShowSocialMediaFields={setShowSocialMediaFields}
      />
      <ErrorMessage
        errorMessage={errorMessage}
        style={{
          color: theme.className === 'contrast' ? theme.primary : selectedEvent?.colors.tertiary,
          marginBottom: '1rem'
        }}
        variants={fadeInAndOutAndAnimateHeightVariants()}
      />
      <Button
        ref={saveButtonRef}
        disabled={errorMessage || uploadingSpeaker}
        loading={uploadingSpeaker}
        loadingButton
        changeColorWhenDisabled={false}
        type="submit"
        style={{
          margin: '0 auto'
        }}>
        {speakerCurrentlyBeingEdited ? 'Save' : 'Upload'}
      </Button>
    </Form>
  );
}

const Form = styled.form`
  background-color: ${({ theme, colors }) =>
    theme.className === 'contrast' ? 'black' : colors.primary};
  border: ${({ theme }) =>
    theme.className === 'contrast' ? `1px solid ${theme.primary}` : 'none'};
  border-radius: 0.625rem;
  color: ${({ theme }) => (theme.className === 'contrast' ? theme.primary : theme.contrast)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 3rem 1.25rem 2.25rem;
  position: relative;
  top: ${({ showSocialMediaFields }) =>
    showSocialMediaFields ? 'calc(43.45rem - 50vh)' : 'calc(37.188rem - 50vh)'};

  /* Close Icon */
  > div:first-of-type {
    position: absolute;
    right: 0.75rem;
    top: 0.75rem;
  }

  @media screen and (min-width: 48rem) {
    top: ${({ showSocialMediaFields }) =>
      showSocialMediaFields ? 'calc(30rem - 50vh)' : 'calc(26.325rem - 50vh)'};

    @media screen and (min-height: 47.813em) {
      top: ${({ showSocialMediaFields }) =>
        showSocialMediaFields ? 'calc(30.6rem - 50vh)' : 'calc(27.05rem - 50vh)'};
    }
  }
`;

export default AddOrEditSpeakerDialog;
