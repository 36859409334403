import * as React from 'react';

function SvgEmailIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={12} cy={12} r={12} fill="#BDBDBD" />
      <path
        d="M6.976 8h10.048c.586 0 .976.396.976.989v7.022c0 .593-.39.989-.976.989H6.976C6.39 17 6 16.604 6 16.011V8.989C6 8.396 6.39 8 6.976 8Z"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 8.5 12 14 6.5 8.5M14 12l4 4M10 12l-4 4"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgEmailIcon;
